import React, { Fragment } from 'react';
import { IMAGES } from '../../../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { getLastURL, ImageUrl, numberWithCommas, ProductTitleDisplay,totalPriceDisplay } from '../../../../helper';
import { AddFavoriteProduct, GetFavoriteProduct, GetFavoriteCategory, GetProductCount } from '../../../../actions/productActions';
import Loader from '../../../layout/Loader';
import { url } from '../../../../config';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const Jewelery = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const category_slug = getLastURL(location);

    const {getFavorite, loading} = useSelector(state => state.getFavorite);

    const handleFavoriteRemove = async (product_id) =>{
        await dispatch(AddFavoriteProduct(product_id));
        await dispatch(GetFavoriteProduct(category_slug))
        dispatch(GetFavoriteCategory());
        dispatch(GetProductCount());
    }

    
    const handleRedirectToDetailPage = (product_id) =>{
        history.push(url + `/details/diamonds/${product_id}`);
    }


    return (
        <Fragment>
        {loading && <Loader />}
        <Fragment>
        <div className="col-md-9">
            <div className="row pr_lists mt-4">
                {
                     getFavorite && getFavorite.data && getFavorite.data.length > 0 ? 
                    getFavorite && getFavorite?.data && getFavorite.data.map((data, key) =>{
                         return(
                             <div className="col-md-3 products">
                                 <span className="icon-close remove" style={{cursor:'pointer'}} onClick={(e) => handleFavoriteRemove(data.id)}></span>
                                 <div className="imgss" onClick={() => handleRedirectToDetailPage(data.id)}>
                                     <img src=
                                     {
                                        data && data?.single_image ?
                                        data && data.single_image && ImageUrl(data.single_image.file)
                                        :
                                        data && ImageUrl(data.single_image)
                                     } 
                                     width="100%" alt="Image" />
                                 </div>
                                 <Link to={url + `/details/jewellery/${data.id}`}> <p className="font-14 black-3B fm mb-2">{ ProductTitleDisplay('j',data.productDetails = data)}</p ></Link>
                                 <h4 className="font-14 black-3B fb">{totalPriceDisplay(data)}</h4>
                             </div>
                         )
                    })
                    :
                    <div className="col-md-12 center">
                                        <h4>
                                        <center><FormattedMessage id="ProductNotFound" defaultMessage="Product not Found"/></center>
                                            </h4> 
                                       </div>    
                } 
            </div>
        </div>
    </Fragment>
    </Fragment>
    )
}

export default Jewelery
