import React, {Fragment, useEffect} from 'react';
import { IMAGES } from '../../../constants';
import ProtectedHeader from '../../layout/ProtectedHeader';
import DetailsPage from './DetailsPage';
import SellerInformation from './SellerInformation';
import ProInfoList from './ProInfoList';
import Rating from './Rating';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import {getProductDetails} from '../../.././actions/productActions';
import {getLastURL} from '../../../helper';
import { useSelector } from 'react-redux';
import { getReviewAction } from '../../../actions/userActions';

const Index = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const product_id = getLastURL(location);

    useEffect(async () =>{

       await dispatch(getProductDetails(product_id))
        dispatch(getReviewAction(product_id, null))

    }, [dispatch])

    const {details} = useSelector(state => state.productDetails);


    return (
        <Fragment>
            <ProtectedHeader />
            {/* <ul className="breadcrub">
              <li>All Categories</li>
              <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
              <li>Wedding Bands</li>
              <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
              <li>Search results</li>
              <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
              <li>#{details && details.productDetails && details.productDetails.stock_number}</li>
            </ul> */}

            <DetailsPage />
            <SellerInformation />
            {/* <ProInfoList /> */}
            <Rating />
            
        </Fragment>
    )
}

export default Index
