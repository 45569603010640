import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { url } from '../../../config';
import { IMAGES } from '../../../constants';

const Sidebar = () => {
    return (
        <Fragment>
            <div className="j_l">
                <div className="logo mb-5 p-0">
                   <Link to={url}><img src={IMAGES.LOGO} alt="" className="w-100" /></Link>
                </div>
                <div className="card1">
                    <ul id="progressbar" className="text-left">
                        <li className="step0 active visited">
                            <h4 className="font-16 black-3B fr mt-3"><FormattedMessage id="Profile information"/></h4>
                            <span></span>
                            <span></span>
                            <span></span>
                        </li>
                        <li className="step0 active" id="busi-info-sidebar">
                            <h4 className="font-16 black-3B fr mt-3"><FormattedMessage id="Business information"/></h4>
                            <span></span>
                            <span></span>
                            <span></span>
                        </li>
                        <li className="step0" id="bill-info-sidebar">
                            <h4 className="font-16 black-3B fr mt-3"><FormattedMessage id="Business information"/></h4>
                        </li>
                    </ul>
                    <div className="sidemenu_footer">
                        <Link to={url} className="que"><FormattedMessage id="Have some questions?"/></Link>
                        <Link to={url + '/contact-us'} className="font-16 black-3B  d-block  mt-2" style={{ fontWeight: 600 }}><FormattedMessage id="main.header.contactsales"/></Link>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Sidebar
