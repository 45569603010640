import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import MetaData from '../../layout/MetaData';
import { getCity, getCountry, getState, submitBusinessInfo } from '../../../actions/userActions';
import Loader from '../../layout/Loader';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { url } from '../../../config';


const BusinessInformation = ({ rerenderParentCallback }) => {

    const [businessInfo, setBusinessInfo] = useState({
        company_name: "",
        business_phone: "",
        address: "",
        address_cont: "",
        zip_code: "",
        country: "",
        state: "",
        city: "",
        tax_certificate: "",
        vat_no: "",
    })
    const [taxCertificateName, setTaxCertificateName] = useState()

    const alert = useAlert()
    const dispatch = useDispatch()

    const { country, loading } = useSelector(state => state.country);
    const { state, loading_state } = useSelector(state => state.state);
    const { city, loading_city } = useSelector(state => state.city);
    const { businessInfoData, error } = useSelector(state => state.businessData);
    const { user } = useSelector(state => state.user);

    useEffect(() => {
        if (businessInfoData) {
            alert.success(businessInfoData)
            rerenderParentCallback();
        }
        if (error) {
            alert.error(error)
        }
    }, [businessInfoData, alert, error, rerenderParentCallback])

    
    useEffect(() => {
        dispatch(getCountry())
        setBusinessInfo({ ...businessInfo, country: user?.country_id })
        dispatch(getState({ "country_id": user?.country_id }));    
    }, [dispatch])

    const handleCountry = (event) => {
        const country_id = event.target.value;
        dispatch(getState(country_id));
        setBusinessInfo({ ...businessInfo, country: country_id })
    }

    const handleState = (event) => {
        const state_id = event.target.value;
        dispatch(getCity(state_id));
        setBusinessInfo({ ...businessInfo, state: state_id })
    }

    const handleCity = (event) => {
        const city_id = event.target.value;
        setBusinessInfo({ ...businessInfo, city: city_id })
    }

    const handleTaxCertificate = (event) => {
        setTaxCertificateName(event.target.files[0].name)
        setBusinessInfo({ ...businessInfo, tax_certificate: event.target.files[0] })
    }

    const handleInputChange = (event) => {
        setBusinessInfo({ ...businessInfo, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(submitBusinessInfo(businessInfo))
    }


    return (
        <Fragment>
            {loading_state || loading_city === true ? <Loader /> : ""}
            {
                loading ? <Loader /> :
                    <Fragment>
                        <MetaData title={`Business Information`} />
                        <div className="card2 first-screen ml-2 show" id="busi-info">
                            <div className="card_head">
                                <div className="card_head_left">
                                    <h3 className="black-3B font-20 fm">
                                        <FormattedMessage
                                            id="business.welcome"
                                            defaultMessage="Welcome"
                                        />
                                    </h3>
                                    <h2>{user && user.name}</h2>
                                </div>
                                <p>{user && user.role == 1 ? <FormattedMessage id="register.jeweler" /> : <FormattedMessage id="Supplier" />}</p>
                            </div>
                            <h4 className="font-20 black-3B fb mb-4">
                                <FormattedMessage
                                    id="business.business-information"
                                    defaultMessage="Business information"
                                />
                            </h4>
                            <form onSubmit={handleSubmit}>
                                <div className="row cus_border mb-5">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">
                                                <FormattedMessage
                                                    id="business.company-name"
                                                    defaultMessage="Company name"
                                                />
                                                <span className="strike_red">*</span></label>
                                            <input type="text" className="form-control" name="company_name" onChange={handleInputChange} value={businessInfo.company_name} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">
                                                <FormattedMessage
                                                    id="business.business-phone"
                                                    defaultMessage="Business Phone"
                                                />
                                                <span className="strike_red">*</span></label>
                                            <input type="text" className="form-control" name="business_phone" onChange={handleInputChange} value={businessInfo.business_phone} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group choose_file">
                                            <label>
                                                <FormattedMessage
                                                    id="business.tax-registration-certificate"
                                                    defaultMessage="Tax registration certificate"
                                                />
                                            </label>
                                            <input type="file" id="certificate" placeholder="90 212 512 9110" className="form-control" style={{ display: "none" }} onChange={handleTaxCertificate} />
                                            <label htmlFor="certificate" className="form-control d-flex align-items-center">
                                                <span>{taxCertificateName}</span>
                                                <span className=" c_btn ml-auto ">Choose file</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">
                                                VAT No.
                                            </label>
                                            <input type="text" className="form-control" name="vat_no" onChange={handleInputChange} value={businessInfo.vat_no} />
                                        </div>
                                    </div>
                                </div>
                                <h4 className="font-20 black-3B fb mb-4">
                                    <FormattedMessage
                                        id="business.mailing-address"
                                        defaultMessage="Mailing address"
                                    />
                                </h4>
                                <div className="row  mb-3">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">
                                                <FormattedMessage
                                                    id="business.address"
                                                    defaultMessage="Address"
                                                />
                                                <span className="strike_red">*</span></label>
                                            <input type="text" className="form-control" name="address" onChange={handleInputChange} value={businessInfo.address} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">
                                                <FormattedMessage
                                                    id="business.address-cont"
                                                    defaultMessage="Address (cont)"
                                                />
                                            </label>
                                            <input type="text" className="form-control" name="address_cont" onChange={handleInputChange} value={businessInfo.address_cont} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">
                                                <FormattedMessage
                                                    id="business.country"
                                                    defaultMessage="Country"
                                                />
                                                <span className="strike_red">*</span></label>
                                            <select name="countryName" className="form-control" value={businessInfo.country} onChange={handleCountry} required>
                                                <option value="">{`Select Country`}</option>
                                                {
                                                    country && country.countries && country.countries.map((country, key) => {
                                                        return (
                                                            <option key={key} value={country.id}>{country.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">
                                                <FormattedMessage
                                                    id="business.state"
                                                    defaultMessage="State"
                                                />
                                                <span className="strike_red">*</span></label>
                                            <select name="stateName" id="" className="form-control" value={businessInfo.state} onChange={handleState} required>
                                                <option value="">{`Select State`}</option>
                                                {
                                                    state && state.states && state.states.map((state, key) => {
                                                        return (
                                                            <option key={key} value={state.id}>{state.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">
                                                <FormattedMessage
                                                    id="business.city"
                                                    defaultMessage="City"
                                                />
                                            </label>
                                            <select name="city" id="" className="form-control" value={businessInfo.city} onChange={handleCity}>
                                                <option value="">{`Select City`}</option>
                                                {
                                                    city && city.cities && city.cities.map((city, key) => {
                                                        return (
                                                            <option key={key} value={city.id}>{city.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">
                                                <FormattedMessage
                                                    id="business.zipcode"
                                                    defaultMessage="Zipcode"
                                                />
                                            </label>
                                            <input type="text" className="form-control" name="zip_code" onChange={handleInputChange} value={businessInfo.zip_code} />
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group login_btn d-flex align-items-center mt-4">
                                    <button className="btn-lg btn-black next-button" type="submit">
                                        <FormattedMessage
                                            id="business.next"
                                            defaultMessage="Next"
                                        />
                                    </button>
                                    {/* <a href="/" className="font-16 black-3B fb d-block ml-3">
                                        <FormattedMessage
                                            id="main.header.contactsales"
                                            defaultMessage="Contact Sales"
                                        />
                                    </a> */}


                                    <Link to={url + "/category/diamonds"} className="font-16 black-3B fb d-block ml-3" >
                                        <FormattedMessage
                                            id="main.header.contactsales"
                                            defaultMessage="Contact Sales"
                                        />
                                    </Link>

                                </div>
                            </form>

                        </div>
                    </Fragment>
            }
        </Fragment>
    )
}

export default BusinessInformation
