import React, { Fragment, useEffect } from 'react';
import { useDispatch ,useSelector } from 'react-redux';
import Sidebar from './Sidebar';
import { IMAGES } from '../../../constants';
import MetaData from '../../layout/MetaData';
import ProtectedHeader from '../../layout/ProtectedHeader';
import { url,BASE_URL } from '../../../config';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { paymentHistory } from '../../../actions/userActions';

const PaymentInfo = () => {

    const dispatch = useDispatch()
    
    useEffect(async () => {
        await dispatch(paymentHistory());
  
    }, [])
    const { paymentHistoryData } = useSelector(state => state.getpaymentHistory);
    return (
        <Fragment>
            <MetaData title={`Payment Info`} />
            <ProtectedHeader />
            <ul className="breadcrub">
                <li>
                    <FormattedMessage
                        id="myaccount.myaccountname"
                        defaultMessage="myaccount"
                    />

                </li>
                <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                <li>
                    <FormattedMessage
                        id="myaccount.payment_history"
                        defaultMessage="Payment info"
                    />
                </li>
            </ul>
            <section className="container-fluid py-4 conPart">
                <div className="row">
                    <Sidebar />
                    <div className="col-md-10">
                        <h3 className="forms mt-3 fm">
                            <FormattedMessage
                                id="myaccount.payment_history"
                                defaultMessage="Payment info"
                            />
                        </h3>
                        <div className="filter_table">
                                <div className="tbles">
                                    <table width="100%">
                                        <tr>
                                            <th><FormattedMessage id="TransactionId"/></th>
                                            <th><FormattedMessage id="Plan"/></th>
                                            <th><FormattedMessage id="Amount"/></th>
                                            <th><FormattedMessage id="Status"/></th>
                                            <th><FormattedMessage id="Date"/></th>
                                            <th width="10%"><FormattedMessage id="Invoice"/></th>
                                        </tr>
                                        {
                                            paymentHistoryData && paymentHistoryData && paymentHistoryData.length > 0 ?
                                            paymentHistoryData  &&  paymentHistoryData.map((data, key) => {
                                                    return (
                                                       <tr>
                                                            <td>{data.payment_id}</td>
                                                            <td>{data?.plan?.name}</td>
                                                            <td>{data.amount}</td>
                                                            <td>{data.status == 1 ? 'Success':'Failure'}</td>
                                                            <td>{data.payment_date}</td>
                                                            <td><a href={BASE_URL+'/invoice-download?id='+data.id } download ><img src={IMAGES.DOWNLOAD} width="20%"></img></a></td>
                                                            
                                                       </tr>
                                                        
                                                    )})
                                                    :
                                                    <tr>
                                                        <th colspan="5"><FormattedMessage id="TransactionNotFound"/></th>
                                                    </tr>
                                        }
                                        
                                    </table>
                                </div>
                            </div>
                        </div>



                </div>
            </section >

        </Fragment >
    )
}

export default PaymentInfo
