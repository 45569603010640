import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import AddDiamonds from './AddDiamonds';
import AddWatches from './AddWatches';
import ProtectedHeader from '../../layout/ProtectedHeader';
import { IMAGES } from '../../../constants';
import { useLocation, useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { url } from '../../../config';
import AddColorStones from './AddColorStones';

const queryString = require('query-string');

const Index = () => {
    const location = useLocation();
    const history = useHistory();
    const parsed = queryString.parse(location.search);

    const [productType, setProductType] = useState();
    const [productTypeSlug, setProductTypeSlug] = useState(parsed && parsed.cat);

    const { category } = useSelector(state => state.categories);

    const handleProductType = (event) => {
        setProductType(event.target.options[event.target.selectedIndex].dataset.id);
        setProductTypeSlug(event.target.value);
        history.push({ pathname: `${url + '/add-single-product'}`, search: `?cat=${event.target.value}` })
    }

    return (
        <Fragment>
            <ProtectedHeader />
            <ul className="breadcrub">
                <li>
                    <FormattedMessage
                        id="myaccount.myaccountname"
                        defaultMessage="My Account"
                    />
                </li>
                <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                <li>
                    <FormattedMessage
                        id="Products"
                        defaultMessage="Products"
                    />
                </li>
                <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                <li>
                    <FormattedMessage
                        id="addsingleproduct.addproduct"
                        defaultMessage="Add Product"
                    />
                </li>
            </ul>
            <section className="container-fluid py-4 conPart">
                <div className="row justify-content-center">
                    <div className="col-md-9">
                        <div className="titles nbrd mt-4 mb-3 pb-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="m-0">
                                        <FormattedMessage
                                            id="addsingleproduct.addproduct"
                                            defaultMessage="Add Product"
                                        />
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row " style={{ borderBottom: "solid 1px #DADADA" }}>
                            <div className="col-lg-4 ">
                                <div className="form-group">
                                    <label htmlFor="">
                                        <FormattedMessage
                                            id="addsingleproduct.producttype"
                                            defaultMessage="Product Type"
                                        />
                                    </label>

                                    <select className="form-control" onChange={handleProductType} value={productTypeSlug}>
                                        <FormattedMessage id="addsingleproduct.choose" defaultMessage="Choose">
                                            {(id) => <option value="">{id}</option>}
                                        </FormattedMessage>
                                        {
                                            category && category.categories && category.categories.map((category) => {
                                                return (
                                                    <Fragment>
                                                        {category && category.dealer_can_add == 1 ?
                                                            <FormattedMessage id={"category." + category.slug} defaultMessage={category.name}>
                                                                {(id) => <option data-id={category.id} value={category.slug}>{id}</option>}
                                                            </FormattedMessage>
                                                            : null
                                                        }
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        {
                            parsed && parsed.cat === "diamonds" ?
                                <AddDiamonds /> :
                                parsed && parsed.cat === "watches" ?
                                    <AddWatches /> :
                                    // parsed && parsed.cat === "color-stones" ?
                                    //     <AddColorStones /> :
                                        null
                        }
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Index
