import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { validateFields } from '../../validation';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { useLocation, useHistory } from 'react-router-dom';
import { getLastURL } from '../../helper';
import { clearError, clearMessage, updateSingleProduct } from '../../actions/productActions';
import Loader from '../layout/Loader';
import { url } from '../../config';
import $ from 'jquery';


const EditSingleProduct = (param) => {

    const dispatch = useDispatch()
    const alert = useAlert()
    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const discount = searchParams.get('discount');
    const price = searchParams.get('price');
    const is_fancy = searchParams.get('is_fancy');
    const category_slug = searchParams.get('category_slug');

    const [addproduct, setAddProduct] = useState({
        product_id: getLastURL(location),
        discount: discount ? discount : "",
        price: price ? price : "",
    })

    const [validerror, setValidError] = useState({
        priceError: "",
        discountError: "",
    });

    const { updateproduct, error, loading } = useSelector(state => state.UpdateSingleProductReducer);

    useEffect(() => {

        if (updateproduct) {
            alert.success(updateproduct);
            dispatch(clearMessage())
            history.push(url + '/myproducts/diamonds')
        }

        if (error) {
            alert.error(error)
            dispatch(clearError())
        }

    }, [updateproduct, dispatch, error, alert])

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        const regex = /^-?[0-9+%.-]*$/;
        const isValid = regex.test(newValue) && (newValue.match(/-/g) || []).length <= 1 && (newValue.indexOf('-') <= 0);
        if (isValid) {
            event.target.value = newValue;
        } else {
            event.target.value = '';
        }

        setValidError({
            priceError: "",
            discountError: "",
        })
        // setAddProduct({ ...addproduct, [event.target.name]: event.target.value })

        if (event.target.name == "discount") {
            setAddProduct({ discount: event.target.value, product_id: getLastURL(location), price: "" })
            if (event.target.value != "") {
                $('.price').attr('readonly', 'readonly');
            } else {
                $('.price').removeAttr('readonly');
            }

        }
        if (event.target.name == "price") {
            setAddProduct({ price: event.target.value, product_id: getLastURL(location), discount: '' })
            if (event.target.value != "") {
                $('.discount').attr('readonly', 'readonly');
            } else {
                $('.discount').removeAttr('readonly');
            }

        }

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (category_slug == 'diamonds') {
            var discountError = validateFields.validateAny(addproduct.discount, 'Discount');
            var priceError = validateFields.validatePrice(addproduct.price);
            if (discountError) {
                priceError = priceError;
            } else {
                priceError = false;
            }
            discountError = priceError ? discountError : false
        } else {
            var priceError = false;
            var discountError = false;
        }

        // return 0;
        if ([priceError, discountError].every(e => e === false)) {
            setValidError({
                ...validerror, priceError, discountError
            })
            dispatch(updateSingleProduct(addproduct))
        }
        else {
            setValidError({
                ...validerror, priceError, discountError
            })
        }
        return;
    }
    console.log("addproduct--=", addproduct);
    return (
        <Fragment>
            {loading && <Loader />}
            <Fragment>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        {category_slug == 'diamonds' && is_fancy == 0 &&
                            <div className="col-lg-4" id="discount">
                                <div className="form-group">
                                    <label htmlFor="">
                                        <FormattedMessage
                                            id="addsingleproduct.discount"
                                            defaultMessage="Discount"
                                        />
                                        %  <span className="strike_red">*</span></label>
                                    <input
                                        className={classnames("form-control discount", { 'is-valid': validerror.discountError === false }, { 'is-invalid': validerror.discountError })}
                                        type="text" placeholder="Enter Discount"
                                        onChange={handleInputChange}
                                        name="discount"
                                        value={addproduct?.discount ? addproduct?.discount : ""} />
                                    <p className="invalid-feedback">{validerror.discountError}</p>
                                </div>
                            </div>
                        }
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label htmlFor="">
                                    {category_slug == 'diamonds' ?
                                        <FormattedMessage
                                            id="addsingleproduct.price-ct"
                                            defaultMessage="Price/ct"
                                        />
                                        :
                                        <FormattedMessage
                                            id="addsingleproduct.price"
                                            defaultMessage="Price"
                                        />
                                    }
                                    <span className="strike_red">*</span></label>
                                <input
                                    className={classnames("form-control price", { 'is-valid': validerror.priceError === false }, { 'is-invalid': validerror.priceError })}
                                    type="text" placeholder="Enter Price"
                                    onChange={handleInputChange}
                                    name="price"
                                    value={addproduct?.price ? addproduct?.price : ""} />
                                <p className="invalid-feedback">{validerror.priceError}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row actions my-5">
                        <div className="col-md-6">
                            <button type="submit" className="btn btn-blue mb-2">
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </Fragment>
        </Fragment>
    )
}

export default EditSingleProduct