import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery'
import { IMAGES } from '../../../constants';
import { url } from '../../../config';
import { getLastURL } from '../../../helper';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router';


const Sidebar = () => {
    const alert = useAlert();
    const location = useLocation();
    const history = useHistory();
    var { user } = useSelector(state => state.user)

    useEffect(() => {
        if (location) {
            let myclass = getLastURL(location);
            $('.' + myclass).addClass('active');
        }
    }, [location])


    const handleNavigate = (route) => {
        if (user?.is_business_step == 0) {
            alert.error('Please setup your buisness profile first.');
        } else {
            history.push(route)
        }
    }

    return (
        <div className="col-md-3 col-lg-2 lefts pl-5">
            <h2 className="mt-4 mb-3">
                <FormattedMessage
                    id="myaccount.myaccountname"
                    defaultMessage="myaccount"
                />
            </h2>
            <ul>
                <li className="profile"><Link to={url + "/profile"}>
                    <FormattedMessage
                        id="myaccount.profile"
                        defaultMessage="Profile"
                    />
                    <img src={IMAGES.ARROW_RIGHT} /></Link></li>
                <li className="change-password cursor-pointer"><a onClick={() => handleNavigate(url + "/change-password")}>
                    <FormattedMessage
                        id="myaccount.changepassword"
                        defaultMessage="Change Password"
                    />
                    <img src={IMAGES.ARROW_RIGHT} /></a></li>
                <li className="payment-history cursor-pointer"><a onClick={() => handleNavigate(url + "/payment-history")}>
                    <FormattedMessage
                        id="myaccount.payment_history"
                        defaultMessage="Payment History"
                    />
                    <img src={IMAGES.ARROW_RIGHT} /></a></li>
                <li className="plan-details cursor-pointer"><a onClick={() => handleNavigate(url + "/plan-details")}>
                    <FormattedMessage
                        id="myaccount.plan"
                        defaultMessage="Plan details"
                    />
                    <img src={IMAGES.ARROW_RIGHT} /></a></li>
                <li className="contact-us cursor-pointer"><a onClick={() => handleNavigate(url + "/contact-us")}>
                    <FormattedMessage
                        id="myaccount.contactus"
                        defaultMessage="Contact us"
                    />
                    <img src={IMAGES.ARROW_RIGHT} /></a></li>
            </ul>
        </div>
    )
}
export default Sidebar
