import { GET_CATEGORY_REQUEST, GET_CATEGORY_SUCCESS, GET_CATEGORY_FAIL } from '../constants/categoryConstant';

export const getCategoryReducer = (state = { category:null }, action) => {

    switch (action.type) {
        case GET_CATEGORY_REQUEST:
            return {
                loading: true,
                category: null
            }
        case GET_CATEGORY_SUCCESS:
            return {
                loading: false,
                category: action.payload
            }
        case GET_CATEGORY_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state     
    }

}