import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { handleSortBy, getLastURL, ImageUrl, numberWithCommas,CompanyNameDisplay ,RatingDisplay, ProductTitleDisplay } from '../../../helper';
import { url,postPerPage } from '../../../config';
import { IMAGES,SEARCH_LIMIT_MSG } from '../../../constants';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert';
import Loader from '../../layout/Loader';
import ReactPaginate from "react-paginate";
import $ from 'jquery';
import { jewelleryJson } from '../../../utils/json';
import { getAllProductsFilter, GetProductCount } from '../../../actions/productActions';
import { useLocation } from 'react-router';
import { getProductsFilterByAscending, AddFavoriteProduct, getAllProducts } from '../../../actions/productActions';
import PopupConfirm from '../../layout/PopupConfirm';
import mixpanel from 'mixpanel-browser';
import { FormattedMessage, useIntl } from 'react-intl';

 



const Jewelery = (props) => {


    const history = useHistory();
    const alert = useAlert();
    const dispatch = useDispatch();
    const location = useLocation();

    const { products, error, loading } = useSelector(state => state.products);

    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(postPerPage);
    const [filterdisplayname, setFilterDisplayName] = useState(<FormattedMessage id="Select Sort Option"/>);
    const [search, setSearch] = useState()

    const category_slug = getLastURL(location);
    const [popUpLimitTitle, setpopUpLimitTitle] = useState(<FormattedMessage id="Search Limit Finished"/>);
    const [popUpLimitMessage, setpopUpLimitMessage] = useState(SEARCH_LIMIT_MSG);


    useEffect(() => {
        if (error) {
            alert.error(error);
            return;
        }
   
    }, [alert, error])

    const handleResForm =  (status) => {  
        if(status){
            $("#dismiss-modal").trigger('click');
            history.push({ pathname: url + '/plan-details' });
        }
    }

    const handlePageChange = ({ selected }) => {
        props.updateResetPageHandler(selected)
        dispatch(getAllProducts(category_slug, (selected + 1)));
    }

    const pageCount = Math.ceil((products && products.products && products.products.total) / postsPerPage);


    const handleDetails = (id) => {
        history.push(url + `/details/jewellery/${id}`);
    }


    const handleAcendingFilter = (value, type, displayname) => {
        setFilterDisplayName(displayname)
        $('.sort_by_list').css({
            display: "none"
        })
        props.tabChangeHandler(1)
        dispatch(getProductsFilterByAscending(category_slug, value, type))
    }

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }


    const handleSearchFilter = (e) => {
        mixpanel.track('Search Jewelery')
        if (e.key === 'Enter') {
            dispatch(getAllProductsFilter(category_slug, { search: search }));
        }
    }

    const handleSearchIconClick = () => {
        mixpanel.track('Search Jewelery');
        dispatch(getAllProductsFilter(category_slug, { search: search }));
    };


    const handleAddFavorite = async (product_id) => {

        if($('.heart.checkheart_' + product_id).hasClass('active'))
        {
            $('.heart.checkheart_' + product_id).removeClass('active');
        }else{
            $('.heart.checkheart_' + product_id).addClass('active');
        }


        await dispatch(AddFavoriteProduct(product_id));
        dispatch(GetProductCount());
    }

    products && products.over_limit &&
        $("#popup-click-confirm").trigger('click')

    return (
        <Fragment>
            {loading && <Loader />}
            <Fragment>
                <div className="col-lg-9">
                    <div className="dashboard-content">
                        <div className="filter_search_right">
                            <div className="search_cat_top">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-sm-8">
                                        <div className="s_c">
                                            {
                                                $.map(products && products?.filter && products?.filter, (val, key) => {
                                                    return (
                                                        <div className="s_c">
                                                            <p><span> <b>{key}:</b> {val} </span></p>
                                                            {/* <i className="icon-close_1"></i> */}
                                                        </div>
                                                    )
                                                })

                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                        <div className="form-group sort_by_box">
                                            <p className="sort_b_h" onClick={handleSortBy}><b><FormattedMessage id="Sort_By" defaultMessage="Sort By"/>:</b> {filterdisplayname} <span className="icon-chevron_down"></span></p>
                                            <div className="sort_by_list">
                                                <ul>
                                                    {
                                                        jewelleryJson && jewelleryJson.map((data, key) => {
                                                            return (
                                                                <li className="activess" onClick={() => handleAcendingFilter(data.value, data.type, data.display_name)}>{data.display_name}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group seach_filter row justify-content-end">
                                <div className="col-xl-8 col-lg-6 col-md-6 col-sm-6">
                                    <div className="re_text">
                                        <h4 className="font-16 black-3B fb"><FormattedMessage id="Results" defaultMessage="Results"/></h4>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                    <input type="text" value={search} onKeyPress={(e) => handleSearchFilter(e)} onChange={handleSearch} className="form-control" placeholder={useIntl().formatMessage({id: 'SearchBySeller'})} />
                                    <span className="icon-search_1  input_icon" onClick={handleSearchIconClick} style={{cursor:'pointer'}}></span>
                                </div>
                            </div>
                            <div className="product_card">
                                <div className="row">

                                    {
                                        products && products.products && products.products.data.length > 0 ?
                                        products && products?.products && products?.products?.data && products?.products?.data.map((data, key) => {
                                            return (
                                                <div className="col-md-3 col-sm-3">
                                                    <div className="card_1">
                                                    <span onClick={() =>handleAddFavorite(data.id)} style={{cursor:'pointer'}}><i className={data.is_favourite >0 ? "fa fa-heart active heart checkheart_" + data.id : "fa fa-heart heart checkheart_" + data.id}></i></span> <Link to={url + `/details/jewellery/${data.id}`} target="_blank" className="not_a">
                                                        <div className="card_img">
                                                            <img src=
                                                              {   data && data?.single_image ?
                                                                data && data?.single_image && ImageUrl(data.single_image.file)
                                                                :
                                                                data && ImageUrl(data.single_image)
                                                              }
                                                            alt="Image" />
                                                        </div>
                                                        </Link>
                                                        <Link to={url + `/details/jewellery/${data.id}`} target="_blank" className="not_a">
                                                        <div className="card_details"  style={{cursor:"pointer"}}>
                                                            <p className="font-14 black-3B fm mb-2"><h5 >{data && data?.product_title ? data.product_title && (data.product_title).slice(0,50) : ProductTitleDisplay('j',data.productDetails = data)}</h5></p>
                                                            <h4 className="font-14 black-3B fb">${data && data?.price && numberWithCommas(parseFloat(data.price  ).toFixed(2))}</h4>
                                                            <p className="font-14 black-3B fm mb-2">{CompanyNameDisplay(data)}  <span ><i style={{color:"#003B75"}} className="fa fa-star"></i> {RatingDisplay('list',data.average_rating,data.total_rating)} </span></p>
                                                        </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div>
                                            <FormattedMessage id="ProductNotFound" defaultMessage="Product not Found"/>
                                       </div>
                                    }


                                </div>
                            </div>
                            <div className="table_pagination">
                                <ReactPaginate
                                    previousLabel={<i className="icon icon-chevron_gray-left"></i>}
                                    nextLabel={<i className="icon icon-chevron_black"></i>}
                                    pageCount={pageCount}
                                    onPageChange={handlePageChange}
                                    containerClassName={"paginationBttns"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                    forcePage={props?.pageReset}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
            {
            <PopupConfirm 
                message={popUpLimitMessage}
                title={popUpLimitTitle}
                handleResForm={handleResForm} 
            />
         }
        </Fragment>
    )
}

export default Jewelery
