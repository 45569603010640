import React, { Fragment, useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import Sidebar from './Sidebar';
import ProtectedHeader from '../../layout/ProtectedHeader';
import { useSelector, useDispatch } from 'react-redux';
import MetaData from '../../layout/MetaData';
import Loader from '../../layout/Loader';
import { IMAGES } from '../../../constants';
import { validateFields } from '../../../validation';
import classnames from 'classnames';
import { clearError, loadUser, updateProfileAction, getProfileData, getReviewActionProfile, getCity, getState } from '../../../actions/userActions';
import { useAlert } from 'react-alert';
import { UPDATE_PROFILE_RESET } from '../../../constants/userConstants';
import { FormattedMessage } from 'react-intl';
import Rating from './Rating';
import { IMAGE_BASE_URL, url } from '../../../config';
import mixpanel from 'mixpanel-browser';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ImageUrl } from '../../../helper';

const Profile = () => {

    const dispatch = useDispatch();
    const alert = useAlert();
    const history = useHistory();

    const [profile, setProfile] = useState({
        name: '',
        email: '',
        business_phone: '',
        company_name: '',
        vat_no: '',
        address: '',
        country: '',
        state: '',
        city: ''
    })

    const [profilePicture, setProfilePicture] = useState('')

    const [localState, setLocalState] = useState({
        states: [],
        cities: []
    })
    const [taxCertificateName, setTaxCertificateName] = useState()

    const [profileError, setProfileError] = useState({
        nameError: '',
        emailError: '',
        business_phoneError: '',
        company_nameError: '',
        addressError: '',
        countryError: '',
        stateError: '',
        cityError: '',
        vat_noError: ''
    })

    const handleChangeProfile = (event) => {
        setProfileError({
            nameError: '',
            emailError: '',
            business_phoneError: '',
            company_nameError: '',
            addressError: '',
            countryError: '',
            stateError: '',
            cityError: '',
            vat_noError: ''
        })
        setProfile({
            ...profile, [event.target.name]: event.target.value
        })
    }

    const handleTaxCertificate = (event) => {
        setTaxCertificateName(event.target.files[0].name)
        setProfile({ ...profile, tax_certificate: event.target.files[0] })
    }

    const handleProfilePicture = (event) => {
        //setTaxCertificateName(event.target.files[0].name)
        // setProfile({ ...profile, profile_picture: event.target.files[0] })
        // setProfilePicture({  profile_picture: event.target.files[0] })
        // setProfilePicture(URL.createObjectURL(event.target.files[0]));
        const file = event.target.files[0];
        if (file) {
            setProfile({ ...profile, profile_picture: file });
            setProfilePicture(URL.createObjectURL(file));
        }
    }

    const handleCountry = (event) => {
        const country_id = event.target.value;
        dispatch(getState(country_id));
        setProfile({ ...profile, country: country_id })
    }

    const handleState = (event) => {
        const state_id = event.target.value;
        dispatch(getCity(state_id));
        setProfile({ ...profile, state: state_id })
    }

    const { getProfile } = useSelector(state => state.GetProfileWithBusiness);
    const sellerId = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).id && JSON.parse(sessionStorage.getItem('user')).id;
    const { updateProfile, loading, error } = useSelector(state => state.updateProfile);
    const { state } = useSelector(state => state.state);
    const { city } = useSelector(state => state.city);


    useEffect(async () => {
        await dispatch(getProfileData());
        await dispatch(getReviewActionProfile(null, sellerId));
    }, [])

    useEffect(async () => {
        if (state && state.states) {
            await setLocalState({ ...localState, states: state && state.states })
        }
    }, [state])

    useEffect(async () => {
        if (city && city.cities) {
            await setLocalState({ ...localState, cities: city && city.cities })
        }
    }, [city])

    useEffect(() => {
        if (getProfile) {
            setProfile({
                name: getProfile && getProfile.user_detail && getProfile.user_detail.name,
                email: getProfile && getProfile.user_detail && getProfile.user_detail.email,
                company_name: getProfile && getProfile.user_business && getProfile.user_business.company_name,
                vat_no: getProfile && getProfile.user_business && getProfile.user_business.vat_no,
                address: getProfile && getProfile.user_business && getProfile.user_business.address,
                business_phone: getProfile && getProfile.user_business && getProfile.user_business.business_phone,
                country: getProfile && getProfile.user_business && getProfile.user_business.country,
                state: getProfile && getProfile.user_business && getProfile.user_business.state,
                city: getProfile && getProfile.user_business && getProfile.user_business.city,
                tax_certificate: getProfile && getProfile.user_business && getProfile.user_business.tax_certificate,
                // profile_picture: getProfile && getProfile.user_detail && getProfile.user_detail.profile_picture_full_thumb
                profile_picture: getProfile && getProfile.user_detail && getProfile.user_detail.profile_picture
            })
            // setProfilePicture(getProfile && getProfile.user_detail && getProfile.user_detail.profile_picture_full_thumb);
            setProfilePicture(getProfile && getProfile.user_detail && getProfile.user_detail.profile_picture);
            setLocalState({
                ...localState, states: getProfile && getProfile.states && getProfile.states,
                cities: getProfile && getProfile.cities && getProfile.cities
            })
        }

        if (updateProfile) {
            alert.success(updateProfile)
            history.push(url + '/plan-details')
            dispatch({
                type: UPDATE_PROFILE_RESET
            })
            dispatch(loadUser())
        }

        if (error) {
            alert.error(error)
            dispatch(clearError())
        }

    }, [getProfile, updateProfile, error])



    const handleSubmit = async (event) => {
        event.preventDefault();
        const nameError = validateFields.validateName(profile.name);
        const emailError = validateFields.validateEmail(profile.email);
        const company_nameError = validateFields.validateCompanyName(profile.company_name);
        const addressError = validateFields.validateAddress(profile.address);
        const business_phoneError = validateFields.validateBusinessPhone(profile.business_phone);
        const countryError = validateFields.validateCountry(profile.country);
        const stateError = validateFields.validateState(profile.state);
        const cityError = validateFields.validateCity(profile.city);
        const vat_noError = validateFields.validateAny(profile.vat_no, 'Vat Number');

        if ([nameError, emailError, company_nameError, addressError, business_phoneError, countryError, stateError, cityError, vat_noError].every(e => e === false)) {

            setProfileError({
                ...profileError, nameError, emailError, company_nameError, addressError, business_phoneError, countryError, stateError, cityError
            })
            await dispatch(updateProfileAction(profile));
            await dispatch(getProfileData());
        }
        else {

            setProfileError({
                ...profileError, nameError, emailError, company_nameError, addressError, business_phoneError, countryError, stateError, cityError, vat_noError
            })
        }
    }

    useEffect(() => {
        mixpanel.track('Profile')
    }, [])


    console.log("profilePicture==========", profilePicture);
    console.log("getProfile.user_detail==========", getProfile);

    return (
        <Fragment>
            {loading && <Loader />}
            <Fragment>
                <MetaData title={`Profile`} />
                <ProtectedHeader />

                <ul className="breadcrub">
                    <li>
                        <FormattedMessage
                            id="myaccount.myaccountname"
                            defaultMessage="myaccount"
                        />
                    </li>
                    <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                    <li>
                        <FormattedMessage
                            id="myaccount.profile"
                            defaultMessage="Profile"
                        />

                    </li>
                </ul>
                <section className="container-fluid py-4 conPart">
                    <div className="row">
                        <Sidebar />
                        <div className="col-md-9">
                            <h2 className="mt-4 mb-3">
                                <FormattedMessage
                                    id="myaccount.profile"
                                    defaultMessage="Profile"
                                />
                            </h2>
                            <div className="row mt-5 mb-4 pb-5 mx-0 brdr-btms profilePhot">
                                <div className="col-md-4 col-lg-2 pl-0">
                                    <input type="file" onChange={handleProfilePicture} id="uploaddd" accept="image/*" style={{ 'display': 'none' }} />
                                    <label className="nameW" htmlFor="uploaddd">
                                        {/* https://images-eu.ssl-images-amazon.com/images/I/41ezRvTwcaL._SX300_SY300_QL70_FMwebp_.jpg */}
                                        <Avatar round={true} color={'#F4EBE1'} name={profile && profile?.name} src={profilePicture ? ImageUrl(profilePicture) : IMAGES?.PROFILE1} />
                                        {/* <input type="file" name="dsd"></input> */}
                                        {/*                                         
                                        <img src={preview ? preview : (state?.image_full ? state?.image_full : String(images?.userDefaultImage))} alt="..." className="avatar-img rounded-circle" /> */}
                                        <i className="fa fa-camera" aria-hidden="true"></i>
                                    </label>
                                </div>
                                <div className="col-md-8 col-lg-4">
                                    <form onSubmit={handleSubmit}>

                                        <div className="form-group">
                                            <label htmlFor=""><FormattedMessage id="register.fullname" /> <span className="strike_red">*</span></label>
                                            <input type="text" name="name" className={classnames("form-control", { 'is-valid': profileError.nameError === false }, { 'is-invalid': profileError.nameError })} value={profile && profile.name} onChange={handleChangeProfile} />
                                            <p className="invalid-feedback">{profileError.nameError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor=""><FormattedMessage id="Email" /> <span className="strike_red">*</span></label>
                                            <input type="text" name="email" className={classnames("form-control", { 'is-valid': profileError.emailError === false }, { 'is-invalid': profileError.emailError })} value={profile && profile.email} onChange={handleChangeProfile} readOnly={true} />
                                            <p className="invalid-feedback">{profileError.emailError}</p>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor=""><FormattedMessage id="business.business-phone" /> <span className="strike_red">*</span></label>
                                            <input type="text" name="business_phone" className={classnames("form-control", { 'is-valid': profileError.business_phoneError === false }, { 'is-invalid': profileError.business_phoneError })} value={profile && profile.business_phone} onChange={handleChangeProfile} />
                                            <p className="invalid-feedback">{profileError.business_phoneError}</p>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor=""><FormattedMessage id="business.company-name" /><span className="strike_red">*</span></label>
                                            <input type="text" name="company_name" className={classnames("form-control", { 'is-valid': profileError.company_nameError === false }, { 'is-invalid': profileError.company_nameError })} value={profile && profile.company_name} onChange={handleChangeProfile} />
                                            <p className="invalid-feedback">{profileError.company_nameError}</p>
                                        </div>
                                        <div className="form-group choose_file">
                                            <label>
                                                <FormattedMessage id="business.tax-registration-certificate" /> &nbsp;&nbsp;&nbsp;
                                                {profile && profile.tax_certificate &&
                                                    <a href={IMAGE_BASE_URL + profile.tax_certificate} download={true}><button className="btns greys hblack dl" type="button" title="Download Certificate"><img src={IMAGES.DOWNLOAD_SVG} alt="" /></button></a>
                                                }
                                            </label>
                                            <input type="file" id="certificate" placeholder="90 212 512 9110" className="form-control" style={{ display: "none" }} onChange={handleTaxCertificate} />
                                            <label htmlFor="certificate" className="form-control d-flex align-items-center">
                                                <span>{taxCertificateName}</span>
                                                <span className=" c_btn ml-auto "><FormattedMessage id="ChooseFile" /></span>
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor=""><FormattedMessage id="VATNo" />.</label>
                                            <input type="text" name="vat_no" className={classnames("form-control", { 'is-valid': profileError.vat_noError === false }, { 'is-invalid': profileError.vat_noError })} value={profile && profile.vat_no} onChange={handleChangeProfile} />
                                            <p className="invalid-feedback">{profileError.vat_noError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor=""><FormattedMessage id="business.address" /><span className="strike_red">*</span></label>
                                            <input type="text" name="address" className={classnames("form-control", { 'is-valid': profileError.addressError === false }, { 'is-invalid': profileError.addressError })} value={profile && profile.address} onChange={handleChangeProfile} />
                                            <p className="invalid-feedback">{profileError.addressError}</p>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor=""> <FormattedMessage id="business.country" /><span className="strike_red">*</span></label>
                                            <select className={classnames("form-control", { 'is-valid': profileError.countryError === false }, { 'is-invalid': profileError.countryError })} onChange={handleCountry} name="country" value={profile && profile.country}>
                                                <option value="">{`Select Country`}</option>
                                                {
                                                    getProfile && getProfile.countries && getProfile.countries.map((countries) => {
                                                        return (
                                                            <option value={countries.id}>{countries.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <p className="invalid-feedback">{profileError.countryError}</p>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor=""><FormattedMessage id="business.state" /><span className="strike_red">*</span></label>
                                            <select className={classnames("form-control", { 'is-valid': profileError.stateError === false }, { 'is-invalid': profileError.stateError })} onChange={handleState} name="state" value={profile && profile.state}>
                                                <option value="">{`Select State`}</option>
                                                {
                                                    localState && localState.states && localState.states.map((states) => {
                                                        return (
                                                            <option value={states.id}>{states.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <p className="invalid-feedback">{profileError.stateError}</p>
                                        </div>


                                        <div className="form-group">
                                            <label htmlFor=""><FormattedMessage id="business.city" /><span className="strike_red">*</span></label>
                                            <select className={classnames("form-control", { 'is-valid': profileError.cityError === false }, { 'is-invalid': profileError.cityError })} onChange={handleChangeProfile} name="city" value={profile && profile.city}>
                                                <option value="">{`Select City`}</option>
                                                {
                                                    localState && localState.cities && localState.cities.map((cities) => {
                                                        return (
                                                            <option value={cities.id}>{cities.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <p className="invalid-feedback">{profileError.cityError}</p>
                                        </div>

                                        <div className="row actions my-4">
                                            <div className="col-md-6">
                                                <button type="submit" className="btns greys hblack"><FormattedMessage id="SaveChanges" /></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <Rating />
                        </div>
                    </div>
                </section>
            </Fragment>
        </Fragment>
    )
}

export default Profile
