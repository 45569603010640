import { GET_HOMEPAGE_REQUEST, GET_HOMEPAGE_SUCCESS, GET_HOMEPAGE_FAIL,
    TERM_CONDITION_REQUEST,
    TERM_CONDITION_SUCCESS,
    TERM_CONDITION_FAIL,
    POLICY_REQUEST,
    POLICY_SUCCESS,
    POLICY_FAIL } from "../constants/HomePageConstant";
import { makeRequestGet, makeRequestPost } from "../utils/apiHelper";


export const getHomePage = () => async (dispatch) =>{
    
    try {
        dispatch({ type: GET_HOMEPAGE_REQUEST })

        const data = await makeRequestGet('home-page');

        if (data.status) {
            dispatch({
                type: GET_HOMEPAGE_SUCCESS,
                payload:data.data
            })
        }
        else {
            dispatch({
                type: GET_HOMEPAGE_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_HOMEPAGE_FAIL,
            payload: error.response
        })

    }
}



export const getTermCondition = () => async (dispatch) =>{
    
    try {
        dispatch({ type: TERM_CONDITION_REQUEST })

        const data = await makeRequestPost('get-cms-page', {'slug':'terms-and-conditions'});

        if (data.status) {
            dispatch({
                type: TERM_CONDITION_SUCCESS,
                payload:data.data
            })
        }
        else {
            dispatch({
                type: TERM_CONDITION_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type: TERM_CONDITION_FAIL,
            payload: error.response
        })
    }
}

export const getPolicy = () => async (dispatch) =>{
    
    try {
        dispatch({ type: POLICY_REQUEST })

        const data = await makeRequestPost('get-cms-page', {'slug':'privacy-policy'});

        if (data.status) {
            dispatch({
                type: POLICY_SUCCESS,
                payload:data.data
            })
        }
        else {
            dispatch({
                type: POLICY_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type: POLICY_FAIL,
            payload: error.response
        })
    }
}
