import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {getProductDetails} from '../../.././actions/productActions';
import {useHistory} from 'react-router-dom';
import {url} from '../../../config';
import {numberWithCommas, CompanyNameDisplay} from '../../../helper';
import { FormattedMessage } from 'react-intl';

const ProInfoList = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { details } = useSelector(state => state.productDetails);

    const handleDetails = (product_id) => {
        history.push(url + `/details/parcels/${product_id}`);
        dispatch(getProductDetails(product_id))
    }

    return (
        <Fragment>
            <section className="pro_info_list">
                <div className="container-fluid">
                    <h4 className="font-16 black fm mb-4"><FormattedMessage id="You may also like" defaultMessage="You may also like" /></h4>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tbles">
                                <table width="100%">
                                    <tr>
                                    <th><FormattedMessage id="Seller" defaultMessage="Seller" /></th>
                                    <th><FormattedMessage id="Shape" defaultMessage="Shape" /></th>
                                    <th><FormattedMessage id="addsingleproduct.carat" defaultMessage="Carat" /></th>
                                        <th><FormattedMessage id="addsingleproduct.color" defaultMessage="Color" /></th>
                                        <th><FormattedMessage id="addsingleproduct.clarity" defaultMessage="Clarity" /></th>
                                        <th><FormattedMessage id="addsingleproduct.cut"  /></th>
                                        <th><FormattedMessage id="addsingleproduct.price-ct"  /></th>
                                    </tr>


                                   { 
                                   details && details.remainingProducts && details.remainingProducts.map((data, key) =>{
                                       return(
                                        <tr>
                                        <td onClick={(e) => handleDetails(data.id)}>{data && data.seller && CompanyNameDisplay(data)}</td>
                                        <td onClick={(e) => handleDetails(data.id)}>{data && data.a_shape && data.a_shape.display_name}</td>
                                        <td>{data && data.carat}</td>
                                        <td>{data && data.a_color && data.a_color.display_name}</td>
                                        <td>{data && data.a_clarity && data.a_clarity.display_name}</td>
                                        <td>{data && data.a_cut && data.a_cut.display_name}</td>
                                        <td>${data && numberWithCommas(parseFloat(data.price).toFixed(2))}</td>
                                    </tr>
                                       )
                                   })
                                   }  
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr />
        </Fragment>
    )
}

export default ProInfoList
