import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { currency, url } from '../../config';
import { savePercentage } from '../../helper';
import { setColor, numberWithCommas, deviceDisplay } from '../../helper';
import { FormattedMessage } from 'react-intl';


const Dealer = () => {

    const [plan_period, setPlanPeriod] = useState();

    const { Home } = useSelector(state => state.home)
    const { user, loading } = useSelector(state => state.user);
    const handlePlanPeriod = (event) => {
        setPlanPeriod(event.target.checked);
    }
    const stringReplace = (text) => {
        let replace = text.replace(/(<([^>]+)>)/ig, '');
        return replace;
    }


    return (
        <div className="tab-pane fade" id="dealer" role="tabpanel" aria-labelledby="profile-tab">
            <div className="row mb-3">
                <div className="col-lg-12">
                    <div className="cus_switch mb-3  d-flex align-item-center">
                        <p className="font-14 fr black-3B pr-3"><FormattedMessage id="Monthly" defaultMessage="Monthly" /></p>
                        <label className="switch">
                            <input type="checkbox" onChange={handlePlanPeriod} />
                            <span className="slider round"></span>
                        </label>
                        <p className="font-14 fr pl-3" style={{ color: "#236653", fontWeight: 600 }}><b
                            className="black-3B"><FormattedMessage id="Annual" defaultMessage="Annual" /></b> <FormattedMessage
                                id="billing.save-percentage"
                                defaultMessage="Save up to 16.19%"
                            /></p>
                    </div>
                </div>
                {
                    Home && Home?.SupplierPlans && Home?.SupplierPlans.map((DP, key) => {
                        return (
                            <div key={key} className="col-lg-3 col-md-4">
                                <div className="sub_plan_box">
                                    <h3 class={setColor(key)}>{DP.name}</h3>

                                    <p style={{ fontWeight: "bold", color: "red" }}> {
                                        DP.features !== null ?
                                            stringReplace(DP?.features)
                                            : ""
                                    }</p>


                                    <div className="device_d mb-2"><span className="fa fa-long-arrow-up"></span><p>
                                        {/* {DP.upload_limit == "unlimited" || "Unlimited" ? */}
                                        {DP?.upload_limit && DP?.upload_limit.toLowerCase() === "unlimited" ?
                                            localStorage.getItem('lang') == 'tr' ? ' Sınırsız Yükleme' : 'Unlimited upload'
                                            :

                                            localStorage.getItem('lang') == 'tr' ? "$" + (numberWithCommas(DP.upload_limit)) + ' Yükleme' : "$" + (numberWithCommas(DP.upload_limit)) + ' upload'} </p></div>
                                    <div className="device_d">
                                        <span className="icon-device_1"></span>
                                        <span className="icon-device_2"></span>

                                        <p>{DP.device_limit + " " + deviceDisplay(DP.device_limit)} </p>
                                    </div>
                                    <div className="plan_price">
                                        {
                                            plan_period ?
                                                <Fragment>
                                                    <span className="year_date"> /<FormattedMessage id="yr" /></span>
                                                    <h3><small>{currency}</small>{DP.yearly_price}</h3>
                                                    <span className="offer_price">{savePercentage(DP.monthly_price, DP.yearly_price)}%</span>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <span className="year_date"> /<FormattedMessage id="mo" /></span>
                                                    <h3><small>{currency}</small>{DP.monthly_price}</h3>
                                                </Fragment>
                                        }
                                    </div>
                                    {user || localStorage.getItem('isAuthenticated') ?
                                        <Link to={url + '/plan-details'} className="btn btn-white"><FormattedMessage id="ApplyNow" defaultMessage="Apply now" /></Link>
                                        :
                                        <Link to={url + '/register?t=2'} className="btn btn-white"><FormattedMessage id="ApplyNow" defaultMessage="Apply now" /></Link>
                                    }

                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Dealer
