import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Confirmation from './Confirmation';
import BillingInformation from './BillingInformation';
import BusinessInformation from './BusinessInformation';
import Sidebar from './sidebar';
import { loadUser } from '../../../actions/userActions';
import { url } from '../../../config';

const Registration = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const { user } = useSelector(state => state.user)

    if (!user) {
        history.push({ pathname: url + '/register' });
    }

    const rerenderParentCallback = () => {
        dispatch(loadUser())
    }


    return (
        <Fragment>
            <div className="top reg_2_page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2 col-md-3">
                            <Sidebar />
                        </div>
                        <div className="col-lg-8 col-md-9">
                            {user && user.is_business_step === 0 && user && user.is_billing_step === 0 &&
                                <BusinessInformation rerenderParentCallback={rerenderParentCallback} />
                            }
                            {user && user.is_business_step === 1 && user && user.is_billing_step === 0 &&
                                <BillingInformation rerenderParentCallback={rerenderParentCallback} />
                            }
                            {user && user.is_business_step === 1 && user && user.is_billing_step === 2 &&
                                <Confirmation />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Registration
