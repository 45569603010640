import React, { Fragment, useState, useEffect, useRef } from 'react';
import { IMAGES } from '../../../constants';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from 'react-avatar';

import { convertLocaleTime, convertLocaleDate, scrollToBottomChat, convertLocaleMomentDate, encryptData, decryptData } from '../../../helper';
import Welcome from './Welcome';
import $ from 'jquery';
import { socket } from '../../../utils/socket';
import { useAlert } from 'react-alert';
import { FormattedMessage, useIntl } from 'react-intl';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import validator from 'validator';
import { url, IMAGE_BASE_URL } from '../../../config';

const Chatbox = (props) => {

    const alert = useAlert();
    const dispatch = useDispatch();
    const [message, setMessage] = useState();
    // const [messageSent, setMessageSent] = useState(false);
    const user_id = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).id && JSON.parse(sessionStorage.getItem('user')).id;
    const user_name = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).name;
    const profile_picture_full_thumb = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).profile_picture_full_thumb;
    const other_user_id = sessionStorage.getItem('other_user_id');
    const other_user_name = sessionStorage.getItem('other_user_name');
    const other_profile_picture_full_thumb = sessionStorage.getItem('other_profile_picture_full_thumb');
    const { messageHistory } = useSelector(state => state.messageHistory);
    const messageSentRef = useRef(false);

    // const MessaageTransfer = useSelector(state => state.MessaageTransferState);
    // console.log("messageHistory--", messageHistory);
    const processText = (text) => {
        // console.log("text>>>>>>>>>", text);
        const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;

        // Split text by the URL pattern and process each part
        const parts = text.split(urlPattern).map((part, index) => {
            if (urlPattern.test(part)) {
                // If part is a URL, wrap it in an <a> tag
                return (
                    <a key={index} href={part.startsWith('www') ? `http://${part}` : part} target="_blank" rel="noopener noreferrer">
                        {part}
                    </a>
                );
            }
            // If part is not a URL, return it as plain text

            return part;
        });
        return parts;
    };

    const handleSendMessage = () => {
        if (!message || message === undefined || message.trim().length <= 0) {
            alert.error('Message is required');
            return 0;
        }

        if (socket.connected === false) {
            socket.connected = true;
            // socket.disconnected = false;
        }
        var data = {
            other_user_id: other_user_id,
            message: message,
            type: 'TEXT'
        }
        socket.emit('send_message', encryptData(data));
        setMessage('')
        socket.emit('total_message_count', encryptData({
            user_id: other_user_id,
        }));

        socket.emit('total_message_count', {});
        scrollToBottomChat();
    }

    const handleSendMessageKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
        else {
            return
        }
    }

    useEffect(() => {
        if (messageHistory) {
            scrollToBottomChat();
        }
    }, [messageHistory])

    setInterval(() => {
        socket.connected = true;
        // socket.disconnected = false;
    }, 3000)

    socket.off('receive_message').on('receive_message', res => {
        console.log('receive_message res---------------------->', res);
        var alignClass = 'ownCmnt';
        // res = res[0]
        const new_res = decryptData(res)
        console.log('res============== ', new_res)
        if (user_id === new_res?.user_id) {
            alignClass = 'u-messgae';
            var imageee = profile_picture_full_thumb ? profile_picture_full_thumb : IMAGES.IMAGE_01;
        } else {
            var imageee = new_res?.other_profile_pic ? new_res?.other_profile_pic : IMAGES.IMAGE_01;
        }

        // <img src=`+imageee+ ` />  
        if (new_res?.message_type == 'TEXT') {
            // eslint-disable-next-line no-undef
            if (validator.isURL(new_res.message)) {
                var varhtml = `<div class="d-flex my-4 align-content-end  ` + alignClass + ` mesgs">
        <div class="d-flex imgs">
        </div>
        <div class="m_t">
            <div class="txts">
                <a href=`+ processText(new_res.message) + ` target="_blank">
                    <p>`+ processText(new_res.message) + `</p>
                </a>
            </div>
            <div class="d-flex dats">
                <p>`+ convertLocaleMomentDate(Date.now()) + `</p>
            </div>
        </div>
    </div>`
            } else {
                var varhtml = `<div class="d-flex my-4 align-content-end  ` + alignClass + ` mesgs">
    <div class="d-flex imgs">
    </div>
    <div class="m_t">
        <div class="txts">
        
            <p>`+ processText(new_res.message) + `</p>
        </div>
        <div class="d-flex dats">
            <p>`+ convertLocaleMomentDate(Date.now()) + `</p>
        </div>
    </div>
</div>`
            }

        } else if (new_res?.message_type == 'image') {
            var imgObj = JSON.parse(new_res.attachment_url)
            var varhtml = `<div class="d-flex my-4 align-content-end ownCmnt mesgs  ` + alignClass + ` mesgs">
    <div class="d-flex  align-items-center txts">
        <div class="s-i-image">
            <LightGallery plugins={[lgThumbnail, lgZoom, lgVideo]} mode="lg-fade" download={false} >
                <img  src=`+ IMAGE_BASE_URL + imgObj.image_url + ` style={{ borderRadius: "10px 0 10px 10px", }} width="60" height="60" onclick="handleImage('${imgObj.image_url} /> 
            </LightGallery>
        </div>
        
        <div class="d-flex dats">
        <p>`+ imgObj.file_content + `</p>
            <p>`+ convertLocaleMomentDate(Date.now()) + `</p>
        </div>                
    </div>
</div>`
        } else if (new_res?.message_type == 'video') {
            var imgObj = JSON.parse(new_res.attachment_url)
            var varhtml = `<div class="d-flex my-4 align-content-end  ` + alignClass + ` mesgs">
    <div class="d-flex imgs">
    </div>
    <div class="m_t">
        <div class="txts">
        <video width="320" height="240" controls>
            <source src="`+ IMAGE_BASE_URL + imgObj.image_url + `" type="video/mp4" style={{ borderRadius: "10px 0 10px 10px", }} width={60} height={60}>
            <source src="`+ IMAGE_BASE_URL + imgObj.image_url + `" type="video/ogg" style={{ borderRadius: "10px 0 10px 10px", }} width={60} height={60}>
            Your browser does not support the video tag.
        </video>
        <p>`+ imgObj.file_content + `</p>
        </div>
        <div class="d-flex dats">
            <p>`+ convertLocaleMomentDate(Date.now()) + `</p>
        </div>
    </div>
</div>`
        } else if (new_res?.message_type == 'pdf') {
            var imgObj = JSON.parse(new_res.attachment_url)
            var varhtml = `<div class="d-flex my-4 align-content-end  ` + alignClass + ` mesgs">
    <div class="d-flex imgs">
    </div>
    <div class="m_t">
        <div class="txts">
            <a href="${imgObj}" target="_blank"> 
            <i class="fa fa-file-pdf-o" style="font-size:48px;color:red"></i>
            </a>
        </div>
        <div class="d-flex dats">
            <p>`+ convertLocaleMomentDate(Date.now()) + `</p>
        </div>
    </div>
</div>`
        }


        if (sessionStorage.getItem('socket_created_at') === undefined && sessionStorage.getItem('socket_group_id') === undefined && sessionStorage.getItem('socket_message') === undefined) {
            console.log("varhtmlvarhtml varhtml", varhtml);
            $(".message_list").append(varhtml);
            sessionStorage.setItem('socket_created_at', new_res?.created_at);
            sessionStorage.setItem('socket_group_id', new_res.group_id);
            sessionStorage.setItem('socket_message', new_res.message);
            sessionStorage.setItem('socket_flag', 1);
        }
        else if (sessionStorage.getItem('socket_created_at') === new_res?.created_at && sessionStorage.getItem('socket_group_id') === new_res?.group_id && sessionStorage.getItem('socket_message') === new_res?.message) {
            return
        } else {
            $(".message_list").append(varhtml);
            sessionStorage.setItem('socket_created_at', new_res?.created_at);
            sessionStorage.setItem('socket_group_id', new_res?.group_id);
            sessionStorage.setItem('socket_message', new_res?.message);
            sessionStorage.setItem('socket_flag', 1);
        }
        scrollToBottomChat();
    })

    const productDetails = JSON.parse(sessionStorage.getItem('productDetails'))

    // const checkProductMsg = () => {
    //     if (productDetails) {
    //         // console.log("MessaageTransfer?.details--", productDetails);
    //         const attachment = {
    //             product_url: productDetails?.slug + '/' + productDetails?.id,
    //             image_url: productDetails?.image
    //         }
    //         const { carat, certificate, clarity, color, shape, seller_id } = productDetails;
    //         var message = `${shape} ${carat}ct ${color} ${clarity} ${certificate}`;
    //         var data = {
    //             product_id: productDetails?.id,
    //             slug: productDetails?.slug,
    //             other_user_id: seller_id,
    //             message: message,
    //             attachment_url: JSON.stringify(attachment),
    //             type: 'ENQUIRY'
    //         }
    //         socket.emit('send_message', encryptData(data));
    //         // socket.emit('total_message_count', {
    //         //     user_id: other_user_id,
    //         // });
    //         socket.emit('total_message_count', {});
    //         scrollToBottomChat();
    //         // dispatch({
    //         //     type: 'MESSAGE_TRANSFER_FAIL',
    //         //     payload: null
    //         // })
    //         sessionStorage.removeItem('productDetails')
    //     }
    // }
    useEffect(() => {
        if (productDetails) {
            const attachment = {
                product_url: productDetails?.slug + '/' + productDetails?.id,
                image_url: productDetails?.image
            }
            const { carat, certificate, clarity, color, shape, seller_id } = productDetails;
            var message = `${shape} ${carat}ct ${color} ${clarity} ${certificate}`;
            var data = {
                product_id: productDetails?.id,
                slug: productDetails?.slug,
                other_user_id: seller_id,
                message: message,
                attachment_url: JSON.stringify(attachment),
                type: 'ENQUIRY'
            }
            socket.emit('send_message', encryptData(data));
            socket.emit('total_message_count', {});
            scrollToBottomChat();
        }
    }, [productDetails])

    useEffect(() => {
        if (socket.connected) {
            window.onload = () => {
                setTimeout(() => {
                    sessionStorage.removeItem('productDetails');
                }, 2500);
            }
        }
    }, [socket.connected, socket]);

    // useEffect(() => {
    //     if (socket.connected) {
    //         window.onload = () => {
    //             sessionStorage.removeItem('productDetails')
    //         };
    //     }
    // }, [socket.connected])

    const handleImage = async () => {
        $('.s-i-image').css('display', 'block');
    };

    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    return (
        <Fragment>
            {
                messageHistory ?
                    <Fragment>
                        <div className="col-md-9 pt-4">

                            <div className="msgdetail">
                                <h3>
                                    <Avatar name={other_user_name} src={other_profile_picture_full_thumb} color="#012F5B" size="45" value="86%" round />
                                    <span style={{ paddingLeft: '12px' }}>{other_user_id == '-1' ? 'Cevaheer' : other_user_name}</span>
                                </h3>
                                <div className="clearfix"></div>
                                <div className="message_list">
                                    {
                                        messageHistory && messageHistory.slice(0) && messageHistory.slice(0).reverse() && messageHistory.slice(0).reverse().map((messageData, key) => {
                                            // console.log("messageData---->", messageData);
                                            let attachment = messageData?.attachment_url
                                            console.log("messageData url->", messageData);
                                            console.log("attachment url->", attachment);
                                            let content = messageData?.file_content
                                            if (isJson(attachment)) {
                                                attachment = JSON.parse(attachment)
                                            }
                                            return (
                                                <Fragment key={messageData?.id}>
                                                    {
                                                        user_id === messageData.user_id ?
                                                            <div className={`d-flex my-4 align-content-end u-messgae mesgs ${messageData?.attachment_url != 'null' && 'NewChatLink'}`} >
                                                                <div>
                                                                    <div
                                                                        className="d-flex align-items-center txts"
                                                                        onClick={() => attachment?.product_url ? window.location.href = url + "/details/" + attachment?.product_url : {}}
                                                                        style={{ cursor: 'pointer' }}>
                                                                        {
                                                                            attachment?.image_url &&
                                                                            <div className="s-i-image">
                                                                                <LightGallery
                                                                                    plugins={[lgThumbnail, lgZoom, lgVideo]}
                                                                                    mode="lg-fade" 
                                                                                    download={false}  >
                                                                                    <img
                                                                                        src={attachment?.image_url ? IMAGE_BASE_URL + attachment?.image_url : attachment}
                                                                                        alt="Product Image"
                                                                                        style={{ borderRadius: "10px 0 10px 10px" }}
                                                                                        width={60}
                                                                                        height={60}
                                                                                        onClick={() => { handleImage(messageData?.attachment_url, 'image') }} />
                                                                                </LightGallery>
                                                                            </div>
                                                                        }
                                                                        <div className='chatlinktext'>
                                                                            <p>{processText(messageData.message)}</p>
                                                                            {/* {attachment?.product_url && <a href={attachment?.product_url}>{attachment?.product_url}</a>} */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex dats">
                                                                        <p>{convertLocaleMomentDate(messageData?.created_at)} </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className={`d-flex my-4 align-content-end ownCmnt mesgs ${messageData?.attachment_url && 'NewChatLink'}`}>
                                                                <div>
                                                                    <div className="d-flex  align-items-center txts">
                                                                        {attachment?.image_url && (
                                                                            <div className="s-i-image" onClick={() => attachment?.product_url ? window.location.href = url + "/details/" + attachment?.product_url : {}}>
                                                                                {messageData?.message_type == 'image' && (
                                                                                    <><LightGallery plugins={[lgThumbnail, lgZoom, lgVideo]} mode="lg-fade" download={false}>
                                                                                        <img src={attachment?.image_url ? IMAGE_BASE_URL + attachment?.image_url : attachment} alt="Product Image" style={{ borderRadius: "10px 0 10px 10px", }} width={60} height={60} onClick={() => { handleImage(attachment?.image_url ? attachment?.image_url : attachment, "image"); }} />

                                                                                    </LightGallery><p>{processText(attachment?.file_content ? attachment?.file_content : attachment)}</p>
                                                                                    </>

                                                                                )}
                                                                                {messageData?.message_type == 'video' && (
                                                                                    <><video width="320" height="240" controls>
                                                                                        <source src={attachment?.image_url ? IMAGE_BASE_URL + attachment?.image_url : attachment} type="video/mp4" style={{ borderRadius: "10px 0 10px 10px", }} width={60} height={60}></source>
                                                                                        <source src={attachment?.image_url ? attachment?.image_url : attachment} type="video/ogg" style={{ borderRadius: "10px 0 10px 10px", }} width={60} height={60}></source>

                                                                                        Your browser does not support the video tag.

                                                                                    </video><p>{processText(attachment?.file_content ? attachment?.file_content : attachment)}</p></>

                                                                                )}
                                                                                {messageData?.message_type == 'pdf' && (
                                                                                    <a href={attachment?.image_url ? IMAGE_BASE_URL + attachment?.image_url : attachment} target="_blank">
                                                                                        <i className="fa fa-file-pdf-o" style={{ fontSize: '48px', color: 'red' }}></i>
                                                                                    </a>

                                                                                )}
                                                                            </div>
                                                                        )}

                                                                        <div className="chatlinktext">
                                                                            {messageData?.message_type == 'text' || messageData?.message_type == 'TEXT' ?

                                                                                <div className="d-flex s-i-image">
                                                                                    {
                                                                                        attachment?.image_url &&
                                                                                        <LightGallery plugins={[lgThumbnail, lgZoom, lgVideo]} mode="lg-fade" download={false}  >
                                                                                            <img src={attachment?.image_url ? IMAGE_BASE_URL + attachment?.image_url : ""} alt="Product Image" style={{ borderRadius: "10px 0 10px 10px" }} width={60} height={60} onClick={() => { handleImage(messageData?.attachment_url, 'image') }} />
                                                                                        </LightGallery>
                                                                                    }
                                                                                    {attachment?.product_url ? <a href={url + "/details/" + attachment?.product_url}>{processText(messageData.message)}</a> : <p> {processText(messageData.message)}</p>}
                                                                                </div>
                                                                                :
                                                                                // <div className={`d-flex my-4 align-content-end u-messgae mesgs ${messageData?.attachment_url != 'null' && 'NewChatLink'}`} >
                                                                                <div>
                                                                                    <a
                                                                                        className="d-flex align-items-center txts"
                                                                                        // onClick={() => messageData?.product_id ? window.location.href = url + "/details/diamonds/" + messageData?.product_id : {}}
                                                                                        href={url + "/details/diamonds/" + messageData?.product_id}
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        target='_blank'
                                                                                    >
                                                                                        {
                                                                                            attachment?.image_url &&
                                                                                            <div className="s-i-image">
                                                                                                <LightGallery
                                                                                                    plugins={[lgThumbnail, lgZoom, lgVideo]}
                                                                                                    mode="lg-fade"
                                                                                                    download={false}
                                                                                                >
                                                                                                    <img
                                                                                                        src={attachment?.image_url ? IMAGE_BASE_URL + attachment?.image_url : attachment}
                                                                                                        alt="Product Image"
                                                                                                        style={{ borderRadius: "10px 0 10px 10px" }}
                                                                                                        width={60} height={60}
                                                                                                        onClick={() => { handleImage(messageData?.attachment_url, 'image') }} />
                                                                                                </LightGallery>
                                                                                            </div>
                                                                                        }
                                                                                        <div className='chatlinktext'>
                                                                                            <p>{processText(messageData?.message)}</p>
                                                                                            {/* {attachment?.product_url && <a href={attachment?.product_url}>{attachment?.product_url}</a>} */}
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                                // </div>

                                                                            }
                                                                        </div>

                                                                    </div>
                                                                    <div className="d-flex dats">
                                                                        <p>{convertLocaleMomentDate(messageData?.created_at)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </Fragment>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    other_user_id != '-1' &&
                                    <div className="row mt-5 pt-4 brdr-top msgFooter">
                                        <div className="col-md-9 pl-md-0">
                                            <input type="text" placeholder={localStorage.getItem('lang') == 'tr' ? 'Mesaj yazın...' : 'Write a message...'} className="form-control" value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={(e) => handleSendMessageKeyPress(e)} />
                                        </div>
                                        <div className="col-md-3 pl-md-0 pr-md-0">
                                            <button className="btn-black" onClick={handleSendMessage}><FormattedMessage id="Send" defaultMessage="Send" /></button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                    :
                    <Welcome />
            }
        </Fragment>
    )
}

export default Chatbox
