import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import MetaData from '../layout/MetaData';
import { forgetPassword, clearError } from '../../actions/userActions';
import { IMAGES } from '../../constants';
import { url } from '../../config';
import Loader from '../layout/Loader';
import { validateFields } from '../../validation';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

const ForgetPassword = () => {

    const [email, setEmail] = useState('');

    const alert = useAlert();
    const dispatch = useDispatch();

    const { error, message, loading } = useSelector(state => state.forgetPassword);
    const [validerror, setValidError] = useState({
        emailError: ""
    });

    useEffect(() => {

        if (error) {
            alert.error(error);
            dispatch(clearError());
        }

        if (message) {
            alert.success(message)
            setEmail('')
        }

    }, [error, message, dispatch, alert])

    const handleSubmit = (event) => {
        event.preventDefault();
        const emailError = validateFields.validateEmail(email);

        if ([emailError].every(e => e === false)) {
            setValidError({
                ...validerror, emailError
            })
            dispatch(forgetPassword(email));
        }
        else {
            setValidError({
                ...validerror, emailError
            })
        }
    }

    return (
        <Fragment>
            {loading && <Loader />}
            <Fragment>
                <MetaData title={`Forget Password`} />
                <form onSubmit={handleSubmit}>
                    <section className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-md-5 mt-5">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <Link to={url}><img className="mb-4" src={IMAGES.LOGO_BLACK} alt="" /></Link>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 Loginbox p-4">
                                        <h4 className="font-20 black-3B fb my-4">
                                            <FormattedMessage
                                                id="login.forgotpassword"
                                                defaultMessage="Forgot Password"
                                            />
                                        </h4>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                <FormattedMessage
                                                    id="login.email-address"
                                                    defaultMessage="Email address"
                                                />
                                            </label>
                                            <input type="text" placeholder="Email" className={classnames("form-control", { 'is-valid': validerror.emailError === false }, { 'is-invalid': validerror.emailError })} value={email || ''} onChange={(e) => setEmail(e.target.value)} />
                                            <p className="invalid-feedback">{validerror.emailError}</p>
                                        </div>
                                        <button type="submit" className="btn-lg full btn-black my-4 ">SEND</button>

                                        <p className="acntHelp m-0">
                                            <span>
                                                <FormattedMessage
                                                    id="login.dont-have-an-account"
                                                    defaultMessage="Don’t have an account ?"
                                                />
                                                <span>{` `}</span>
                                                <Link to={url + "/register"}>
                                                    <FormattedMessage
                                                        id="main.header.signup"
                                                        defaultMessage="Sign Up"
                                                    />
                                                </Link></span>
                                            <span className="float-right">
                                                <FormattedMessage
                                                    id="main.header.contactsales"
                                                    defaultMessage="Contact Sales"
                                                />
                                            </span>
                                        </p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </form>
            </Fragment>
        </Fragment>
    )
}

export default ForgetPassword
