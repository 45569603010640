import React, { Fragment } from 'react';
import {IMAGES} from '../../constants';


const OwlCrousel = () => {
    return (
        <Fragment>
            <div className="owl-carousel owl-theme">
                <div className="item">
                    <img src={IMAGES.C1_PNG} />
                    <h4>1</h4>
                </div>
                <div className="item">
                <img src={IMAGES.C1_PNG} />
                    <h4>2</h4>
                </div>
                <div className="item">
                         <img src={IMAGES.C1_PNG} />
                    <h4>3</h4>
                </div>
                <div className="item">
                         <img src={IMAGES.C1_PNG} />
                    <h4>4</h4>
                </div>
                <div className="item">
                         <img src={IMAGES.C1_PNG} />
                    <h4>5</h4>
                </div>
                <div className="item">
                         <img src={IMAGES.C1_PNG} />
                    <h4>6</h4>
                </div>
                <div className="item">
                         <img src={IMAGES.C1_PNG} />
                    <h4>7</h4>
                </div>
                <div className="item">
                         <img src={IMAGES.C1_PNG} />
                    <h4>8</h4>
                </div>
                <div className="item">
                         <img src={IMAGES.C1_PNG} />
                    <h4>9</h4>
                </div>
                <div className="item">
                         <img src={IMAGES.C1_PNG} />
                    <h4>10</h4>
                </div>
                <div className="item">
                         <img src={IMAGES.C1_PNG} />
                    <h4>11</h4>
                </div>
                <div className="item">
                         <img src={IMAGES.C1_PNG} />
                    <h4>12</h4>
                </div>
            </div>
        </Fragment>
    )
}

export default OwlCrousel
