import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { url } from '../../config';
import { IMAGES } from '../../constants';

const EmptyPage = () => {
    return (
        <div className="error-page-sec">
            <div className="inner-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 mb-md-0 mb-5">
                          
                            <img className="img-fluid" src={IMAGES.EmptyPageImage} alt="404 page" />
                        </div>
                        <div className="col-md-6 text-center">
                            <h2 className="titlehead"><FormattedMessage id="404" defaultMessage="404"/></h2>
                            <h6 className="subtitle"><FormattedMessage id="PageNotFound" defaultMessage="Page Not Found"/> </h6>
                            <p className="paratext">
                            <FormattedMessage id="WeAreSorry" defaultMessage="We're sorry, the page you requested could not be found."/>  
                            </p>
                            <p className="paratext">
                            <FormattedMessage id="PleaseGoBack" defaultMessage="Please go back to the homepage."/>    
                            </p>
                            <a href='' className="btn"><FormattedMessage id="GO_HOME" defaultMessage="GO HOME"/> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmptyPage