import React, { useEffect, useState, Fragment } from 'react';
import { IMAGES } from '../../../constants';
import ProtectedHeader from '../../layout/ProtectedHeader';
import Sidebar from './Sidebar';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import MetaData from '../../layout/MetaData';
import { sendOtpAction, clearError } from '../../../actions/userActions';
import { SEND_OTP_RESET } from '../../../constants/userConstants';
import { validateFields } from '../../../validation';
import classnames from 'classnames';
import Loader from '../../layout/Loader';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import { url } from '../../../config';

const ChangePassword = () => {

    const history = useHistory();

    const [new_password, setnewPassword] = useState('');
    const [confirm_password, setconfirmPassword] = useState('');

    const [validerror, setValidError] = useState({
        new_passwordError: '',
        confirm_passwordError: ''
    })

    const alert = useAlert();
    const dispatch = useDispatch();

    const { error, sendOtp, loading } = useSelector(state => state.SendOtp);

    useEffect(() => {

        if (error) {
            alert.error(error.message)
            dispatch(clearError());
        }

        if (sendOtp) {

            alert.success(sendOtp)
            dispatch({
                type: SEND_OTP_RESET
            })
            history.push({ pathname: url + '/otp', customData: { new_password } })
        }
    }, [dispatch, error, sendOtp, alert])

    const handleShowPassword = (id) => {
        let x = document.getElementById(id);
        if (x.type === "password") { x.type = "text"; } else { x.type = "password"; }
    }



    const handleSubmit = async (event) => {
        event.preventDefault();
        const new_passwordError = validateFields.validatePassword(new_password);
        const confirm_passwordError = validateFields.validateConfirmPassword(new_password, confirm_password);

        if ([new_passwordError, confirm_passwordError].every(e => e === false)) {
            setValidError({
                ...validerror, new_passwordError, confirm_passwordError
            })
            await dispatch(sendOtpAction());
        }
        else {
            setValidError({
                ...validerror, new_passwordError, confirm_passwordError
            })
        }
    }


    return (
        <Fragment>
            {loading && <Loader />}
            <Fragment>
                <MetaData title={`Change password`} />
                <ProtectedHeader />
                <ul className="breadcrub">
                    <li>
                        <FormattedMessage
                            id="myaccount.myaccountname"
                            defaultMessage="myaccount"
                        />
                    </li>
                    <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                    <li>
                        <FormattedMessage
                            id="myaccount.changepassword"
                            defaultMessage="Change Password"
                        />

                    </li>
                </ul>

                <section className="container-fluid py-4 conPart">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <Sidebar />
                            <div className="col-md-9">
                                <h2 className="mt-4 mb-3">
                                    <FormattedMessage
                                        id="myaccount.changepassword"
                                        defaultMessage="Change Password"
                                    />
                                </h2>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor=""><FormattedMessage id="NewPassword" defaultMessage="New Password"/> <span className="strike_red">*</span></label>
                                            <input type="password" id="new-pass" name='new_password' placeholder="********" className={classnames("form-control", { 'is-valid': validerror.new_passwordError === false }, { 'is-invalid': validerror.new_passwordError })} value={new_password || ''} onChange={(e) => setnewPassword(e.target.value)} />
                                            <span className="input_icon"><img src={IMAGES.VIEW_SVG} alt="show/hide" onClick={(e) => handleShowPassword('new-pass')} /></span>
                                            <p className="invalid-feedback">{validerror.new_passwordError}</p>
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor=""><FormattedMessage id="ConfirmNewPassword" defaultMessage="Confirm New Password "/> <span className="strike_red">*</span></label>
                                            <input type="password" id="confirm-new-pass" name='confirm_password' placeholder="********" className={classnames("form-control", { 'is-valid': validerror.confirm_passwordError === false }, { 'is-invalid': validerror.confirm_passwordError })} value={confirm_password || ''} onChange={(e) => setconfirmPassword(e.target.value)} />
                                            <span className="input_icon"><img src={IMAGES.VIEW_SVG} alt="show/hide" onClick={(e) => handleShowPassword('confirm-new-pass')} /></span>
                                            <p className="invalid-feedback">{validerror.confirm_passwordError}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row actions my-3">
                                    <div className="col-md-6">
                                        <button className="btns mr-3 sndBtn" type="submit"><FormattedMessage id="SendOTP" defaultMessage="Send OTP "/></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </Fragment>
        </Fragment>
    )
}

export default ChangePassword
