import React, { Fragment, useEffect ,useState} from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import WhiteDimonds from './WhiteDimonds';
import Sidebar from './Sidebar';
import ProtectedHeader from '../../layout/ProtectedHeader';
import MetaData from '../../layout/MetaData';
import { IMAGES } from '../../../constants';
import { getAllProducts,getAllProductsFilter } from '../../../actions/productActions';
import { getLastURL } from '../../../helper';
import mixpanel from 'mixpanel-browser';
import { FormattedMessage } from 'react-intl';


const Index = () => {


    const dispatch = useDispatch();
    const location = useLocation();
    const queryString = require('query-string');
    const parsed = queryString.parse(location.search);
    
    const filter = [];
    const pathname = getLastURL(location);
    
    const [pageNumber, setPageNumber] = useState(0);
    const [pageReset, setPageReset] = useState(0)

    useEffect(() => {
       var filter =  JSON.parse(localStorage.getItem(pathname+"filter"));
        if(parsed && parsed.search  && parsed.search.length > 0 ){
            dispatch(getAllProductsFilter(pathname, filter,0));
        }else{
            localStorage.removeItem(pathname+"filter");
            dispatch(getAllProducts(pathname, 0));
        }
    }, [dispatch])

    const handleEditPage= (pageNumber) => {
        setPageNumber(pageNumber)
    }


    const tabChangeHandler = () => {
        setPageReset(0)
    }
    
    const updateResetPageHandler = (i) => {
        setPageReset(i);
    }

    useEffect(() =>{
      mixpanel.track('FindDiamond')
    }, [])

    return (
        <Fragment>
            <MetaData title={`White Dimonds`} />
            <ProtectedHeader />
          
            <main className="sp-inner-page-body sp_dashboard">
                <section className="sp-order-req-sec">
                    <div className="container-fluid">
                        <span className="menu-btn">
                            <i className="fa fa-filter" aria-hidden="true"></i> <FormattedMessage id="Filter"/>
                        </span>
                        <div className="dashboard-sec">
                            <div className="row">
                                <Sidebar pageNumber={pageNumber} handleEditPage={handleEditPage} tabChangeHandler={tabChangeHandler} />
                                <WhiteDimonds pageNumber={pageNumber} updateResetPageHandler={updateResetPageHandler} pageReset={pageReset}  tabChangeHandler={tabChangeHandler}  />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            
        </Fragment>
    )
}

export default Index
