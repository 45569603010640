import React from 'react';
import { useSelector } from 'react-redux';
import { IMAGES } from '../../constants';
import { ImageUrl } from '../../helper';

const Testimonial = () => {


    const { Home } = useSelector(state => state.home)

    return (
        <section id="testimonails" className="testimonal container py-5">
            <h2 className="mt-5 mb-3 text-center black fm font-40 ">{Home && Home?.homePage && Home?.homePage?.testimonials_sect_title}</h2>

            <div id="carouselExampleControls" className="carousel slide mt-5" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                    {Home && Home.testimonials && Home.testimonials.map((testiO, key) => {
                        return (
                            <div key={key} className={key === 0 ? "carousel-item active" : "carousel-item"}>
                                <div className="row justify-content-md-center c_index">
                                    <div className="col-md-10 ">
                                        <div className="row">
                                            {testiO && testiO.map((testi, key2) => {
                                                return (
                                                    <div key={key2} className="col-md-4 d-flex " >
                                                        <div className="tblocks">
                                                            <p>{testi?.description && testi?.description.substr(0, 300)}</p>
                                                           <div className="user_car">
                                                            <img src={ImageUrl(testi.image)} alt={IMAGES.AVATAR} />
                                                            <h5>{testi.posted_by}</h5>
                                                            <span>{testi.address}</span>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                </a>
                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                </a>
            </div>
        </section>
    )
}

export default Testimonial