import { IMAGE_BASE_URL, CERTIFICATE_BASE_URL, SC } from "./config";
import { IMAGES } from "./constants";
import $ from 'jquery';
import moment from 'moment';
import CryptoJS from "crypto-js";

export const ImageTag = (imageUrl, altTag = null) => {
    if (imageUrl) {
        const htmlTag = <img src={IMAGE_BASE_URL + imageUrl} alt={altTag} />
        return htmlTag
    }
    return
}


export const GenerateCertificatePdf = (url) => {
    if (url) {
        let cert_url = url;
        if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
            cert_url = url
        } else {
            cert_url = CERTIFICATE_BASE_URL + url
        }

        return cert_url
    }
    return
}

export const ImageUrl = (imageUrl) => {
    var URLTag = ""
    if (imageUrl) {
        if (imageUrl.indexOf("http://") !== -1 || imageUrl.indexOf("https://") !== -1) {
            URLTag = imageUrl
        } else {
            URLTag = IMAGE_BASE_URL + imageUrl
        }
        return URLTag
    }
    else {
        return IMAGES.NO_IMAGE
    }
}

export const ShapeImageUrl = (imageUrl) => {
    if (imageUrl) {
        var URLTag = IMAGE_BASE_URL + imageUrl
        return URLTag
    }
    else {
        return IMAGES.HEARTSHOW_IMG
    }
}

export const getHeader = () => {
    var head_lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
    var head_country = localStorage.getItem('country') ? localStorage.getItem('country') : 'turkey';
    return {
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('isAuthenticated'),
            'lang': head_lang,
            'country': head_country,
            'sc': SC
        }
    }
}
export const getFormDataHeader = () => {
    var head_lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
    var head_country = localStorage.getItem('country') ? localStorage.getItem('country') : 'turkey';
    return {
        headers: {
            // 'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('isAuthenticated'),
            'lang': head_lang,
            'country': head_country,
            'sc': SC
        }
    }
}

export const handleSortBy = () => {

    if ($('.sort_by_list').css('display') === 'block') {
        $('.sort_by_list').css({
            display: 'none'

        })

    } else {
        $('.sort_by_list').css({
            display: 'block'
        })

    }
}

export const handleSortByAction = () => {

    if ($('.sort_by_action').css('display') === 'block') {
        $('.sort_by_action').css({
            display: 'none'
        })
    } else {
        $('.sort_by_action').css({
            display: 'block'
        })
    }
}


export const handleLangList = () => {

    if ($('.lang_list').css('display') === 'block') {
        $('.lang_list').css({
            display: 'none'
        })
    } else {
        $('.lang_list').css({
            display: 'block'
        })
    }
}

export const handlephoneList = () => {

    if ($('.phone_lists').css('display') === 'block') {
        $('.phone_lists').css({
            display: 'none'
        })
    } else {
        $('.phone_lists').css({
            display: 'block'
        })
    }
}


export const handleCountryList = () => {

    if ($('.country_list').css('display') === 'block') {
        $('.country_list').css({
            display: 'none'
        })
    } else {
        $('.country_list').css({
            display: 'block'
        })
    }
}



export const flip = () => {
    $('.debit-card').toggleClass('flipped');
}

export const setColor = (key) => {
    if (key === 0) {
        return 'Trial'
    }
    else if (key === 1) {
        return 'Light'
    }
    else if (key === 2) {
        return 'Plus'
    }
    else if (key === 3) {
        return 'Pro'
    }
    else {
        return 'Light'
    }
}


export const savePercentageforAds = (amount) => {
    let totalSave = amount * 100;
    return totalSave.toFixed(2);
}

export const savePercentage = (monthly, yearly) => {
    let totalSave = monthly * 12 - yearly;
    let totalPercentageSave = (totalSave / (monthly * 12)) * 100
    return totalPercentageSave.toFixed(2);
}

export const getLastURL = (location) => {
    const path = location && location.pathname && location.pathname.split('/').pop();
    return path;
}

export const getSessionId = (location) => {
    const path = location && location.search && location.search.split('=').pop();
    return path;
}

export const getParamsByName = (location, param) => {
    var url = new URL(location);
    var value = url.searchParams.get(param);
    return value;
}


export const getSecondLastURL = (location) => {
    const path = location && location.pathname && location.pathname.split('/').slice(-2)[0];
    return path;
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const capitalizeFirstLetterWithOnlyLetter = (string) => {
    const arr = string.split(" ");

    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase();;
    }

    var str2 = arr.join("");
    if (string === "Excellent") {
        str2 = "EX";
    } else if (string === "Very Slight") {
        str2 = "VSL";
    } else if (string === "Faint/Slight") {
        str2 = "F/SL";
    } else if (string === "Strong") {
        str2 = "ST";
    } else if (string === "Very Strong") {
        str2 = "VST";
    }
    return str2;
}

export const movetoNext = (current, nextFieldID, maxLength) => {
    if (current.length >= maxLength) {
        document.getElementById(nextFieldID).focus();
    }
}

export const convertLocaleTime = (datetime) => {
    return new Date(datetime).toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute: '2-digit'
    })
}

export const convertLocaleDate = (datetime) => {
    return new Date(datetime).toLocaleDateString(navigator.language, {
        month: 'short', day: '2-digit', year: '2-digit'
    })
}

export const convertLocaleMomentDate = (datetime) => {
    return moment.utc(datetime).local().format('hh:mm a')
    // return new Date(datetime).toLocaleDateString(navigator.language, {
    //     month: 'short', day: '2-digit', year: '2-digit'
    // })
}

export const scrollToBottomChat = () => {
    $(".message_list").animate({ scrollTop: $('.message_list').prop("scrollHeight") }, 500);
}

export const generateTemplate = (slug) => {
    if (slug === 'watches') {
        return IMAGES.WATCH_TEMPLATE
    }
    else if (slug === 'diamonds') {
        return IMAGES.DIAMOND_TEMPLATE
    }
    else if (slug === 'parcels') {
        return IMAGES.PARCEL_TEMPLATE
    }
    else if (slug === 'wedding-bands') {
        return IMAGES.WEDDING_BAND_TEMPLATE
    }
    else if (slug === 'color-stones') {
        return IMAGES.COLOR_STONE_TEMPLATE
    }
    else if (slug === 'jewellery') {
        return IMAGES.JEWELRY_TEMPLATE
    }
    else {
        return null
    }
}

export const Year = () => {
    var year_arr = []
    var currentYear = (new Date()).getFullYear();
    for (var i = 1950; i <= currentYear; i++) {
        year_arr.push(i)
    }
    return year_arr.reverse();
}

export const pColmDisplay = (data, keyName) => {
    return data && data[keyName] && data[keyName].display_name
}

export const numberWithCommas = (price) => {
    if (price != null) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return price;
    }

}

export const RatingDisplay = (type = 'list', rating, total) => {
    var string = type === 'list' ? '' : localStorage.getItem('lang') === 'tr' ? ' Değerlendirme' : ' ratings';
    var rating1 = rating ? rating.toFixed(2) : 0;

    var ratingString = '<span><i style={{color:"#003B75"}} className="fa fa-star"></i></span>';
    //return ratingString ;
    const parser = new DOMParser();
    parser.parseFromString(ratingString, "text/html");
    return rating1 + " (" + total + string + ")";
}

export const CompanyNameDisplay = (data) => {

    var companyName = data && data.company_name && data.company_name.split(/(\s+)/).filter(e => e.trim().length > 0)[0] && data.company_name.split(/(\s+)/).filter(e => e.trim().length > 0)[0].slice(0, 8)
    return companyName;

}

export const totalPriceDisplayForAds = (data) => {
    if (data) {
        var price = "";
        if (data.product.is_fancy === 1) {
            price = data.product.price * data.product.carat;
            price = numberWithCommas(parseFloat(price).toFixed(0))
        } else {
            price = data && data.product.total_price && numberWithCommas(parseFloat(data.product.total_price).toFixed(2))
        }
        return price != undefined ? "$" + price : price;
    }
}

// export const totalPriceDisplay = (data) => {
//     if (!data) return; 
//     let price;
//     if (data.is_fancy === 1) {
//       price = data.price * data.carat;
//     } else {
//       price = data && data.total_price;
//     }

//     if (price != undefined) {
//       const hasCents = parseFloat(price).toFixed(2) % 1 !== 0;
//       if (!hasCents) {

//         return  "$"+ numberWithCommas(parseFloat(price).toFixed(0));
//       } else {
//         return  "$"+ numberWithCommas(parseFloat(price).toFixed(2));
//       }
//     }
//     console.log(price,"price-------------------------------")
//     return price; 
//   }


export const totalPriceDisplay = (data) => {
    if (data) {
        var price = "";
        if (data?.is_fancy === 1) {
            price = data?.price * data?.carat;
            //console.log("price ---",price);
            return "$" + numberWithCommas(parseFloat(price).toFixed(2))
        } else {
            //var  price = numberWithCommas(parseFloat(data.total_price).toFixed(2)) ;
            //console.log("price","$"+ data && data.total_price && numberWithCommas(parseFloat(data.total_price).toFixed(2)));
            return (data && data?.total_price ? ("$" + numberWithCommas(parseFloat(data?.total_price).toFixed(2))) : "")
        }
    }
}

//   export const totalPriceforParcel = (data) => {
//     if (data) {

//         var price = data && parseFloat(data.carat *data.pieces * data.price);
//         if (price != undefined) {
//         const hasCents = parseFloat(price).toFixed(2) % 1 !== 0;
//         if (!hasCents) {
//           return  "$"+ numberWithCommas(parseFloat(price).toFixed(0));
//         } else {
//           return  "$"+ numberWithCommas(parseFloat(price).toFixed(2));
//         }
//     }
//         return price ;
//     }
// }

export const totalPriceforParcel = (data) => {
    if (data) {
        var price = data && parseFloat(data.carat * data.pieces * data.price);
        if (price != undefined) {
            return "$" + numberWithCommas(parseFloat(price).toFixed(2));
        }
        return price;
    }

}


// export const totalPriceDisplayforWeddingBand = (data) => {
//     if (data) {
//         var price = data && parseFloat(data && data.pricegr);
//         if (price != undefined) {
//         const hasCents = parseFloat(price).toFixed(2) % 1 !== 0;
//         if (!hasCents) {
//           return numberWithCommas(parseFloat(price).toFixed(0)) +"gr";
//         } else {
//           return  numberWithCommas(parseFloat(price).toFixed(2)) +"gr";
//         }
//     }
//         return price ;
//     }
// }


export const totalPriceDisplayforWeddingBand = (data) => {
    if (data) {
        var price = data && parseFloat(data && data.pricegr);
        if (price != undefined) {
            return numberWithCommas(parseFloat(price).toFixed(2)) + "gr";
        }
        return price;
    }
}

// export const totalPriceDisplayforAdminPrice = (data) => {
//     if (data) {
//         var price = data && parseFloat(data && data.admin_price);

//         if (price != undefined) {
//         const hasCents = parseFloat(price).toFixed(2) % 1 !== 0;
//         if (!hasCents) {
//           return "$"+numberWithCommas(parseFloat(price).toFixed(0));
//         } else {
//           return  "$"+numberWithCommas(parseFloat(price).toFixed(2));
//         }
//     }
//         return price;
//     }
// }

export const totalPriceDisplayforAdminPrice = (data) => {
    if (data) {
        var price = data && parseFloat(data && data.admin_price);
        if (price != undefined) {
            return "$" + numberWithCommas(parseFloat(price).toFixed(2));
        }
        return price;
    }
}

// export const totalPriceDisplayforTotalPrice = (data) => {
//     if (data) {
//         var price = data && parseFloat(data && data.total_price);
//         if (price != undefined) {
//         const hasCents = price % 1 !== 0;
//         if (!hasCents) {
//           return "$"+numberWithCommas(parseFloat(price).toFixed(0));
//         } else {
//           return  "$"+numberWithCommas(parseFloat(price).toFixed(2));
//         }
//     }
//         return price ;
//     }
// }

export const totalPriceDisplayforTotalPrice = (data) => {
    if (data) {
        var price = data && parseFloat(data && data.total_price);
        if (price != undefined) {
            return "$" + numberWithCommas(parseFloat(price).toFixed(2));
        }
        return price;
    }
}

// export const totalPriceDisplayforDiscount = (data) => {
//     if (data) {
//         var price = data && parseFloat(data && data.discount);
//         if (price != undefined) {
//         const hasCents = parseFloat(price).toFixed(2) % 1 !== 0;
//         if (!hasCents) {
//           return parseFloat(price).toFixed(0) + "%" ;
//         } else {
//           return parseFloat(price).toFixed(2) + "%" ;
//         }
//     }
//         return price ;
//     }
// }


export const totalPriceDisplayforDiscount = (data) => {
    if (data) {
        var price = data && parseFloat(data && data.discount);
        if (price != undefined) {
            return parseFloat(price).toFixed(2) + "%";
        }
        return price;
    }
}

// export const totalCarat = (data) => {
//     if (data) {
//         var carat = data && parseFloat(data && data.carat);
//         if (carat != undefined) {
//         const hasCents = parseFloat(carat).toFixed(2) % 1 !== 0;
//         if (!hasCents) {
//           return numberWithCommas(parseFloat(carat).toFixed(0));
//         } else {
//           return numberWithCommas(parseFloat(carat).toFixed(2));
//         }
//     }
//         return carat ;
//     }
// }

export const totalCarat = (data) => {
    if (data) {
        var carat = data && parseFloat(data && data.carat);
        if (carat != undefined) {
            return numberWithCommas(parseFloat(carat).toFixed(2));
        }
        return carat;
    }
}

// export const PriceCTDisplay = (data) => {
//     if (data) {
//         var price = ""
//         if (data.is_fancy === 1) {
//             price = data.price;
//         } else {
//             price = data.price;
//         }

//         if (price != undefined) {
//             const hasCents = parseFloat(price).toFixed(2) % 1 !== 0;
//             if (!hasCents) {
//                return "$"+ numberWithCommas(parseFloat(price).toFixed(0));
//             } else {
//                 return  "$"+ numberWithCommas(parseFloat(price).toFixed(2));
//             }
//         }
//         return price;
//     }
// }

export const PriceCTDisplay = (data) => {
    if (data) {
        var price = ""
        if (data.is_fancy === 1) {
            price = data.price;
            return price = "$" + numberWithCommas(parseFloat(price).toFixed(2))
        } else {
            price = data.price;
            return price = "$" + (parseFloat(price).toFixed(2))
        }
    }
}





export const UnlimitedUpload = (type = 'list', rating, total) => {
    var string = localStorage.getItem('lang') === 'tr' ? ' Sınırsız Yükleme' : ' Unlimited Upload';
    return string;

}

export const ProductTitleDisplay = (type = 'd', details, total) => {
    let milky = details?.productDetails?.milky?.display_name ?? '';
    let a_fancy_color = details?.productDetails?.a_fancy_color?.display_name ?? '';
    let clarity = details?.productDetails?.a_clarity?.display_name ?? '';
    let a_fancy_intensity = details?.productDetails?.a_fancy_intensity?.display_name ?? '';
    let a_fancy_overtone = details?.productDetails?.a_fancy_overtone?.display_name ?? '';
    let a_sieve = details?.productDetails?.a_sieve?.display_name ?? 'sv';
    let a_jewelry_style = details?.productDetails?.a_jewelry_style?.display_name ?? '';
    let a_brand = details?.productDetails?.a_brand?.display_name ?? '';
    let a_band_finish = details?.productDetails?.a_band_finish?.display_name ?? '';
    let metal_weight = details?.productDetails?.metal_weight?.display_name ?? '';
    let a_metal_type = details?.productDetails?.a_metal_type?.display_name ?? '';
    let gold_karat = details?.productDetails?.gold_karat?.display_name ?? '';
    let stone_carat = details?.productDetails?.stone_carat?.display_name ?? 'ct';
    let a_stone_type = details?.productDetails?.a_stone_type?.display_name ?? '';
    let a_stone_shape = details?.productDetails?.a_stone_shape?.display_name ?? '';
    let a_band_surface = details?.productDetails?.a_band_surface?.display_name ?? '';
    let measurements = details?.productDetails?.measurements?.display_name ?? '';
    let comment = details?.productDetails?.comment?.display_name ?? '';
    let a_model = details?.productDetails?.a_model?.display_name ?? '';
    let year = details?.productDetails?.year?.display_name ?? '';
    let a_papers_box = details?.productDetails?.a_papers_box?.display_name ?? '';
    let a_gender = details?.productDetails?.a_gender?.display_name ?? '';
    let shape = details?.productDetails?.a_shape?.display_name ?? '';
    let a_color = details?.productDetails?.a_color?.display_name ?? '';
    let carat = details?.productDetails?.carat ?? '';
    let certificate = details?.productDetails?.a_certificate?.display_name ?? '';
    let fluorescence = details?.productDetails?.a_fluorescence?.display_name
        ? capitalizeFirstLetterWithOnlyLetter(details.productDetails.a_fluorescence.display_name)
        : 'N';
    let polish = details?.productDetails?.a_polish?.display_name
        ? capitalizeFirstLetterWithOnlyLetter(details.productDetails.a_polish.display_name)
        : '';
    let symmetry = details?.productDetails?.a_symmetry?.display_name
        ? capitalizeFirstLetterWithOnlyLetter(details.productDetails.a_symmetry.display_name)
        : '';
    let cut = details?.productDetails?.a_cut?.display_name
        ? capitalizeFirstLetterWithOnlyLetter(details.productDetails.a_cut.display_name)
        : '';

    let wideness = details && details?.productDetails && details?.productDetails?.wideness && parseFloat(details?.productDetails?.wideness).toFixed(2) + 'mm';

    let color = details && details?.productDetails && details?.productDetails.is_fancy === 0 ? a_color : a_fancy_intensity + " " + a_fancy_overtone + " " + a_fancy_color;


    carat = carat && parseFloat(carat).toFixed(2) && parseFloat(carat).toFixed(2) + "ct";
    var productTitle = ""
    if (type === 'd') {
        productTitle = shape + " " + carat + " " + color + " " + clarity + " " + certificate + " " + cut + " " + polish + " " + symmetry + " " + fluorescence;

        //console.log("productTitle--------",productTitle);

    } else if (type === 'p') {

        productTitle = shape + " " + carat + " " + color + " " + clarity + " " + a_sieve + " " + measurements;
    } else if (type === 'j') {
        gold_karat = localStorage.getItem('lang') === 'tr' ? gold_karat + ' Ayar' : gold_karat + ' Karat';
        productTitle = details && details.productDetails && details.productDetails.product_title ? details.productDetails.product_title : a_jewelry_style + ', ' + a_brand + ', ' + metal_weight + ', ' + a_metal_type + ', ' + gold_karat + ', ' + stone_carat + ', ' + a_stone_type + ', ' + a_stone_shape;
    }
    else if (type === 'cs') {

        productTitle = details && details.productDetails && details.productDetails.product_title ? details.productDetails.product_title : carat + ', ' + a_stone_type + ', ' + shape + ', ' + certificate + ', ' + measurements + ', ' + (comment ? comment : '');
    }
    else if (type === 'w') {

        productTitle = details && details.productDetails && details.productDetails.product_title ? details.productDetails.product_title : a_brand + ', ' + a_model + ', ' + year + ', ' + a_papers_box + ', ' + a_gender;
    }
    else if (type === 'wb') {
        gold_karat = localStorage.getItem('lang') === 'tr' ? gold_karat + ' Ayar' : gold_karat + ' Karat';
        productTitle = details && details.productDetails && details.productDetails.product_title ? details.productDetails.product_title : a_band_surface + ', ' + a_band_finish + ', ' + a_gender + ', ' + metal_weight + ', ' + a_metal_type + ', ' + gold_karat + ', ' + wideness;
    }
    //productTitle.replace('null','');
    let ppTitle = productTitle.replace('null', '');
    ppTitle = ppTitle.replace('null,', '');
    ppTitle = ppTitle.replace(' ,', '');

    return ppTitle;
}


export const AdProductTitleDisplay = (type = 'd', details, total) => {
    let milky = details?.productDetails?.milky?.display_name ?? '';
    let stock_number = details?.productDetails?.stock_number ?? '';
    let a_fancy_color = details?.productDetails?.a_fancy_color?.display_name ?? '';
    let clarity = details?.productDetails?.a_clarity?.display_name ?? '';
    let a_fancy_intensity = details?.productDetails?.a_fancy_intensity?.display_name ?? '';
    let a_fancy_overtone = details?.productDetails?.a_fancy_overtone?.display_name ?? '';
    let shape = details?.productDetails?.a_shape?.display_name ?? '';
    let a_color = details?.productDetails?.a_color?.display_name ?? '';
    let carat = details?.productDetails?.carat ?? '';
    let certificate = details?.productDetails?.a_certificate?.display_name ?? '';
    let fluorescence = details?.productDetails?.a_fluorescence?.display_name
        ? capitalizeFirstLetterWithOnlyLetter(details.productDetails.a_fluorescence.display_name)
        : 'N';
    let polish = details?.productDetails?.a_polish?.display_name
        ? capitalizeFirstLetterWithOnlyLetter(details.productDetails.a_polish.display_name)
        : '';
    let symmetry = details?.productDetails?.a_symmetry?.display_name
        ? capitalizeFirstLetterWithOnlyLetter(details.productDetails.a_symmetry.display_name)
        : '';
    let cut = details?.productDetails?.a_cut?.display_name
        ? capitalizeFirstLetterWithOnlyLetter(details.productDetails.a_cut.display_name)
        : '';

    let color = details && details?.productDetails && details?.productDetails.is_fancy === 0 ? a_color : a_fancy_intensity + " " + a_fancy_overtone + " " + a_fancy_color;

    carat = carat && parseFloat(carat).toFixed(2) && parseFloat(carat).toFixed(2) + "ct";
    //var productTitle = carat + "," + shape  + "," + color + "," + clarity  + "," + cut + "," + polish + "," + symmetry + ", " + fluorescence + "," + certificate + "," + stock_number;
    var productTitle = "";

    if (carat) {
        productTitle += carat;
    }

    if (shape) {
        productTitle += (productTitle ? ", " : "") + shape;
    }

    if (color) {
        productTitle += (productTitle ? ", " : "") + color;
    }

    if (clarity) {
        productTitle += (productTitle ? ", " : "") + clarity;
    }

    if (cut) {
        productTitle += (productTitle ? ", " : "") + cut;
    }

    if (polish) {
        productTitle += (productTitle ? ", " : "") + polish;
    }

    if (symmetry) {
        productTitle += (productTitle ? ", " : "") + symmetry;
    }

    if (fluorescence) {
        productTitle += (productTitle ? " " : "") + fluorescence;
    }

    if (certificate) {
        productTitle += (productTitle ? " " : "") + certificate;
    }

    if (stock_number) {
        productTitle += (productTitle ? " " : "") + stock_number;
    }


    // console.log("productTitle--------",productTitle);

    let ppTitle = productTitle.replace('null', '');
    ppTitle = ppTitle.replace('null,', '');
    ///ppTitle = ppTitle.replace(' ,', '');

    return ppTitle;
}


export const deviceDisplay = (number) => {
    var divice = localStorage.getItem('lang') === 'tr' ? 'Cihaz' : 'Device';
    if (number > 1) {
        divice = localStorage.getItem('lang') === 'tr' ? 'Cihaz' : 'Devices';
    }
    return divice
}

export const getSearchQueryParams = (key) => {
    console.log("getSearchQueryParams called", key);
    const searchParams = new URLSearchParams(window.location.search);
    const value = searchParams.get(key);
    console.log("value", value);
    return value;
}

export const arraysHaveSameValues = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;

    const createFrequencyMap = (arr) => {
        const freqMap = {};
        for (let i = 0; i < arr.length; i++) {
            const value = arr[i];
            if (freqMap[value]) {
                freqMap[value]++;
            } else {
                freqMap[value] = 1;
            }
        }
        return freqMap;
    };

    const freqMap1 = createFrequencyMap(arr1);
    const freqMap2 = createFrequencyMap(arr2);

    for (let key in freqMap1) {
        if (freqMap1[key] !== freqMap2[key]) {
            return false;
        }
    }

    for (let key in freqMap2) {
        if (freqMap2[key] !== freqMap1[key]) {
            return false;
        }
    }

    return true;
};
// const sc = socket.handshake.query.sc;
const ppwwdd = '=CHF81MU45aVNmRUczSFTIKRsdWtUT2xVQmx3b2J#enYTOXQ1UE9oc0tzRjh0WnVlbjFIc0xtOUNkeGg2&90c0ZlZ1WD=vqwlkmskdbvksEfbksdbFfksbdkjanFGfskdfksEDFkrIQQ7!SSDjfkbsdfkbksbdfksdbfksbkfssdfsdfbkshnasDFGGGbdfkbkfkbksKaGu';

export const encryptData = (data) => {
    const encrypttext = JSON.stringify(data);
    if (SC && SC === 1) {
        // console.log("socket data...", data);
        const ciphertext = CryptoJS.AES.encrypt(encrypttext, ppwwdd, { keySize: 256 / 8 }).toString();
        // console.log("encrypted socket data...", ciphertext);
        // console.log("decrypted socket data...", decryptData(ciphertext));
        return ciphertext;
    } else {
        return data;
    }
};

export const encryptUserId = (data) => {
    const encrypttext = JSON.stringify(data);
    // console.log("socket data...", data);
    const ciphertext = CryptoJS.AES.encrypt(encrypttext, ppwwdd, { keySize: 256 / 8 }).toString();
    // console.log("encrypted socket data...", ciphertext);
    // console.log("decrypted socket data...", decryptData(ciphertext));
    return ciphertext;
};

export const decryptData = (ciphertext) => {
    if (SC === 1) {
        const bytes = CryptoJS.AES.decrypt(ciphertext, ppwwdd, { keySize: 256 / 8 });
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    }
    return ciphertext
};


// export const hashReqEncodeData = (data) => {
//     const encrypttext = JSON.stringify(data);
//     // if (sc && sc === "1") {
//     const ciphertext = CryptoJS.AES.encrypt(encrypttext, process.env.key, { keySize: 256 / 8 }).toString();
//     return ciphertext;
//     // } else {
//     //     return text;
//     // }
// };

export const hashResEncodeData = (str) => {
    let singleEncode = ''
    if (str instanceof FormData) {
        const obj = {};
        str.forEach((value, key) => {
            obj[key] = value;
        });
        // singleEncode = btoa(JSON.stringify(obj));
        singleEncode = btoa(unescape(encodeURIComponent(JSON.stringify(obj))));
    } else {
        // singleEncode = btoa(JSON.stringify(str));
        singleEncode = btoa(unescape(encodeURIComponent(JSON.stringify(str))));
    }
    const encodeSingleWithPass = btoa(singleEncode + ppwwdd);
    const sendEncode = btoa(singleEncode + ppwwdd + encodeSingleWithPass);

    const replaceString = sendEncode.replace(/[abcdefghijklmnopqrstuvwxyz]/g, (char) => {
        const replaceMap = {
            'a': 'ई', 'b': 'औ', 'c': 'ऐ', 'd': 'ऋ', 'e': 'ए', 'f': 'झ',
            'h': 'ड', 'i': 'ढ', 'j': 'न', 'k': 'से', 'l': 'ब', 'm': 'भ',
            'n': 'र', 'o': 'ष', 'p': 'च', 'q': 'य', 'r': 'द', 's': 'ठ',
            't': 'फ', 'u': 'ट', 'v': 'छ', 'w': 'म', 'x': 'प', 'y': 'ध', 'z': 'थ'
        };
        return replaceMap[char] || char;
    });

    return { data: replaceString };
};

export const hashReqDecodeData = (encodedData) => {
    let replacedString = replaceCharactersToDecode(encodedData)
    const decodedString = atob(replacedString);
    const decodedData = decodeHashResString(decodedString);
    return decodedData;
}

/*
const replaceCharactersToDecode = (str) => {
    // return str.replace(/[ईऔऐऋएझडढनसेबभरषचयदठफटछमपधथ]/g, (char) => {
    //     let index = array2.indexOf(char);
    //     if (index !== -1) {
    //         return array1[index];
    //     } else {
    //         console.log('missed char is', char);
    //         return char;
    //     }
    // });
};
*/

const replaceCharactersToDecode = (str) => {
    const replaceMap = {
        'ई': 'a', 'औ': 'b', 'ऐ': 'c', 'ऋ': 'd', 'ए': 'e', 'झ': 'f',
        'ड': 'h', 'ढ': 'i', 'न': 'j', 'से': 'k', 'ब': 'l', 'भ': 'm',
        'र': 'n', 'ष': 'o', 'च': 'p', 'य': 'q', 'द': 'r', 'ठ': 's',
        'फ': 't', 'ट': 'u', 'छ': 'v', 'म': 'w', 'प': 'x', 'ध': 'y', 'थ': 'z'
    };
    Object.keys(replaceMap).forEach(key => {
        str = str.replace(new RegExp(key, 'g'), replaceMap[key]);
    });
    return str;
};

function decodeHashResString(replacedString) {
    const indexOfPassword = replacedString.lastIndexOf(ppwwdd);
    const originalEncodedString = replacedString.substring(0, indexOfPassword);
    const singleEncodedString = atob(originalEncodedString);
    const decodedData = JSON.parse(singleEncodedString);
    return decodedData;
}

export const translationMap = {
    shapes: "Şekil",
    carat: "Karat",
    color: "Renk",
    clarity: "Berraklık",
    finish: "Bitiş",
    polish: "Cila",
    symmetry: "Simetri",
    fluorescence: "Floresan",
    certificate: "Sertifika",
    search: "Aramak"
}