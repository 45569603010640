import React, { useEffect, useState, Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../layout/Loader';
import MetaData from '../layout/MetaData';
import { login, clearError } from '../../actions/userActions';
import { IMAGES } from '../../constants';
import { url } from '../../config';
import { validateFields } from '../../validation';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import mixpanel from 'mixpanel-browser';

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validerror, setValidError] = useState({
        emailError: "",
        passwordError: ""
    });

    const alert = useAlert();
    const history = useHistory();
    const dispatch = useDispatch();

    const { isAuthenticated, error, user, loading } = useSelector(state => state.user)

    useEffect(() => {

        if (isAuthenticated) {
            if (user?.country_id === 225) {
                localStorage.setItem("country", 'turkey')
            } else {
                localStorage.setItem("country", 'uk')
            }
            history.push(url + "/category/diamonds")
        }

        if (error) {
            dispatch(clearError());
            alert.error(error)
        }

    }, [dispatch, error, isAuthenticated, history, alert])

    const handleShowPassword = () => {
        let x = document.getElementById('ty-pass');
        if (x.type === "password") { x.type = "text" } else { x.type = "password" }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const emailError = validateFields.validateEmail(email);
        const passwordError = validateFields.validatePassword(password);

        if ([emailError, passwordError].every(e => e === false)) {
            setValidError({
                ...validerror, emailError, passwordError
            })
            dispatch(login(email, password))
        }
        else {
            setValidError({
                ...validerror, emailError, passwordError
            })
        }
        return;
    }

    useEffect(() => {
        mixpanel.track('Login')
    }, [])

    return (
        <Fragment>
            {loading && <Loader />}
            <Fragment>
                <MetaData title={'login'} />
                <form onSubmit={handleSubmit}>
                    <section className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-md-5 mt-5">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <Link to={url}><img className="mb-4" src={IMAGES.LOGO} alt="" /></Link>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 Loginbox p-4">
                                        <h4 className="font-20 black-3B fb my-4">
                                            <FormattedMessage
                                                id="login.log-in-to-you-account"
                                                defaultMessage="Log in to you account"
                                            />
                                        </h4>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                <FormattedMessage
                                                    id="login.email-address"
                                                    defaultMessage="Email address"
                                                />
                                            </label>
                                            <input type="text" placeholder="Email" className={classnames("form-control", { 'is-valid': validerror.emailError === false }, { 'is-invalid': validerror.emailError })} value={email} onChange={(e) => setEmail(e.target.value)} />
                                            <p className="invalid-feedback">{validerror.emailError}</p>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="">
                                                <FormattedMessage
                                                    id="login.password"
                                                    defaultMessage="Password"
                                                />
                                                *
                                            </label>
                                            <input type="password" id="ty-pass" placeholder="********" className={classnames("form-control", { 'is-valid': validerror.passwordError === false }, { 'is-invalid': validerror.passwordError })} value={password} onChange={(e) => setPassword(e.target.value)} />
                                            <span className="input_icon"><img src={IMAGES.VIEW_SVG} alt="show/hide" onClick={handleShowPassword} /></span>
                                            <p className="invalid-feedback">{validerror.passwordError}</p>
                                        </div>
                                        <button type="submit" className="btn-lg full btn-black my-4 ">
                                            <FormattedMessage
                                                id="login.login"
                                                defaultMessage="Login"
                                            />
                                        </button>
                                        <Link to={url + "/forget-password"}><button type="button" className="btn-lg full btn-grey mb-4 ">
                                            <FormattedMessage
                                                id="login.forgotpassword"
                                                defaultMessage="Forgot Password ?"
                                            />
                                            ?
                                        </button></Link>

                                        <p className="acntHelp m-0">
                                            <span>
                                                <FormattedMessage
                                                    id="login.dont-have-an-account"
                                                    defaultMessage="Don’t have an account ?"
                                                />
                                                <span>{` `}</span>
                                                <Link to={url + "/register"}>
                                                    <FormattedMessage
                                                        id="main.header.signup"
                                                        defaultMessage="Sign Up"
                                                    />
                                                </Link></span>
                                            <span className="float-right">
                                                <FormattedMessage
                                                    id="main.header.contactsales"
                                                    defaultMessage="Contact Sales"
                                                />
                                            </span>
                                        </p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </form>
            </Fragment>
        </Fragment>
    )
}

export default Login