import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const SellerInformation = () => {

    const {details} = useSelector(state => state.productDetails);

    return (
        <Fragment>
            <section className="seller_information pt-3" id="seller-info" style={{display:'none'}}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="seller_info">
                                <h4 className="font-20 black fb mb-3"><FormattedMessage
                                                        id="SellerInformation"
                                                        defaultMessage="Seller information"
                                                    /></h4>
                                <div className="seller_info_details row">
                                    <div className="seller_info_1 mb-4 col-md-2">
                                        <p className="font-16 gray-75 mb-2"><FormattedMessage
                                                        id="business.company-name"
                                                        defaultMessage="Company Name"
                                                    /></p>
                                        <h4 className="font-16 fm black-3B">{details && details.sellerDetails && details.sellerDetails.business && details.sellerDetails.business.company_name}</h4>
                                    </div>
                                    <div className="seller_info_1 mb-4 col-md-4">
                                        <p className="font-16 gray-75 mb-2"><FormattedMessage
                                                        id="business.address"
                                                        defaultMessage="Address"
                                                    /></p>
                                        <h4 className="font-16 fm black-3B">{details && details.sellerDetails && details.sellerDetails.business && details.sellerDetails.business.address}</h4>
                                    </div>
                                    <div className="seller_info_1 mb-4 col-md-2">
                                        <p className="font-16 gray-75 mb-2"><FormattedMessage
                                                        id="Contact"
                                                        defaultMessage="Contact"
                                                    /></p>
                                        <h4 className="font-16 fm black-3B">{details && details.sellerDetails && details.sellerDetails.name}</h4>
                                    </div>
                                    <div className="seller_info_1 mb-4 col-md-2">
                                        <p className="font-16 gray-75 mb-2"><FormattedMessage
                                                        id="Tel"
                                                        defaultMessage="Tel"
                                                    /></p>
                                        <h4 className="font-16 fm black-3B">{details && details.sellerDetails && details.sellerDetails.business && details.sellerDetails.business.business_phone}</h4>
                                    </div>
                                    <div className="seller_info_1 mb-4 col-md-2">
                                        <p className="font-16 gray-75 mb-2"><FormattedMessage
                                                        id="Email"
                                                        defaultMessage="Email"
                                                    /></p>
                                        <h4 className="font-16 fm black-3B">{details && details.sellerDetails && details.sellerDetails.email}</h4>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default SellerInformation
