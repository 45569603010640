import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import parse from "html-react-parser";
import { ImageTag } from '../../helper';
import { url } from '../../config';
import ContactUsPopUp from './ContactUsPopUp';
import { FormattedMessage, useIntl } from 'react-intl';
import $ from 'jquery';
// import sumo from '../../assets/js/sumoscript';


const HomeSection = () => {

    const {Home} = useSelector(state => state.home)

    const handleResForm = (status) => {
        $("#dismiss-modal").trigger('click');
    }
    const handleContactUsPopUp= (event) => {
        $("#popup-click-confirm").trigger('click');
    }

    // useEffect(()=>{
    //     const script = document.createElement("script");
    //     script.src = "js/sumoscript.js";
    //     script.id = "__sumo_script";
    //     script.async = true;
    //     document.body.appendChild(script);
    // })
    return (
        <div className="bg_img">
            
            <section className="Hmesliders py-5 container-fluid">
                <div className="row text-center">
                    <div className="col-lg-12">
                        <div className="barndName">
                            <h1>{Home && Home?.homePage && parse(Home.homePage.banner_text)}</h1>
                            <p>{Home && Home?.homePage && parse(Home.homePage.banner_sub_text)}</p>
                            <div className="btn-groups">
                            <a href={url + "#pricings"}>
                                <button className="btn btn-black">{Home && Home?.homePage && parse(Home.homePage.banner_button_text_first)}</button>
                                </a>
                                
                                {/* <Link to={url + '/contact-us'}> */}
                                    <button className="btn btn-outline" onClick={handleContactUsPopUp}>{Home && Home.homePage && parse(Home.homePage.banner_button_text_sec)}</button>
                                    {/* </Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 pt-5">
                        {Home && Home?.homePage && ImageTag(Home.homePage.banner_image , 'Banner Image')}
                    </div>
                </div>
            </section>
            {
                <ContactUsPopUp 
                    message={useIntl().formatMessage({id: 'deleteAllProducts'})}
                    title={useIntl().formatMessage({id: 'Confirmation'})}
                    handleResForm={handleResForm} 
                />
            }
            </div>
    )
}

export default HomeSection
