import React, { Fragment } from 'react';


const PopupConfirm = ({message,title,handleResForm}) => {

const handlBtnClick = (status) => {
    handleResForm(status);
    
}
    
    return (
        <Fragment>
            <div className="modal fade PopsData pop-plan-details-modal" id="popup-confirm" tabindex="-1" role="dialog" aria-labelledby="comment" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                       
                        <div className="modal-body">
                            <div className="succes">
                                <span className="icon">
                                <i className="fa fa-question-circle" aria-hidden="true"></i>
                                </span>
                                <h4>{title && title}</h4>
                                <p>{message && message}</p>
                                <button id="dismiss-modal" type="button" className="close m-0 custom-plan-details" data-dismiss="modal" aria-label="Close" >
                                     <span aria-hidden="true">&times;</span>
                                </button>
                                <button id="dismiss-modal" type="button" className="closes no plan-no" data-dismiss="modal" aria-label="Close" >
                                    No
                                </button>
                                <button id="dismiss-modal-yes" type="button" className="closes yes plan-no" data-dismiss="modal" aria-label="Close" onClick={() => handlBtnClick(true)}>
                                    Yes
                                </button>
                                <button style={{display:'none'}} className="btn-black active-all" id="popup-click-confirm" data-toggle="modal" data-target="#popup-confirm">
                                        pop up
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PopupConfirm

