import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAttributes } from "../../actions/productActions";
import { validateFields } from '../../validation';
import classnames from 'classnames';
import $ from 'jquery';
import { IMAGES } from '../../constants';

const Parcel = ({ props, handleEditExcelForm }) => {

    const dispatch = useDispatch();

    const [addproduct, setAddProduct] = useState({
        stock_no: "",
        shape: "",
        carat: "",
        cash_pricect: "",
        clarity: "",
        color: "",
        pricect: "",
        total_parcel_carat: "",
        showcase_image:"",
        cut:"",
        measurement:"",
        sieve:"",
        comment: "",
        image_1: "",
        image_2: "",
        image_3:"",
        image_4:"",
        image_5: "",
        city:"",
        country:"",
        state:""
    })

    const [validerror, setValidError] = useState({
        stock_noError: "",
        shapeError: "",
        caratError: "",
        cash_pricectError: "",
        clarityError: "",
        colorError: "",
        pricectError: "",
        measurementError:"",
        sieveError:""
    });

    const handleInputChange = (event) => {
        setValidError({
            stock_noError: "",
            shapeError: "",
            caratError: "",
            cash_pricectError: "",
            clarityError: "",
            colorError: "",
            pricectError: "",
            total_parcel_caratError: "",
            measurementError:"",
            sieveError:""
        })
        setAddProduct({ ...addproduct, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const stock_noError = validateFields.validateStock_Number(addproduct.stock_no);
        const shapeError = validateFields.validateShape(addproduct.shape);
        const caratError = validateFields.validateCarat(addproduct.carat);
        const cash_pricectError = validateFields.validatePrice(addproduct.cash_pricect);
        const clarityError = validateFields.validateClarity(addproduct.clarity);
        const colorError = validateFields.validateColor(addproduct.color);
        const pricectError = validateFields.validatePrice(addproduct.pricect);
        const measurementError = validateFields.validateMeasurement(addproduct.measurement);
        const sieveError = validateFields.validateSieve(addproduct.sieve);


        if ([stock_noError, shapeError, caratError, cash_pricectError, clarityError, colorError, pricectError, measurementError, sieveError].every(e => e === false)) {
            setValidError({
                ...validerror, stock_noError, shapeError, caratError, cash_pricectError, clarityError, colorError, pricectError, measurementError, sieveError
            })
            handleEditExcelForm(addproduct);
            $('#dismiss-modal').trigger('click');
        }
        else {
            setValidError({
                ...validerror, stock_noError, shapeError, caratError, cash_pricectError, clarityError, colorError, pricectError, measurementError, sieveError
            })
        }
        return;
    }

    const { attributes } = useSelector(state => state.attributes)

    useEffect(() => {
        dispatch(getAttributes('parcels'))
    }, [dispatch])

    useEffect(() => {
        if (props) {
            setAddProduct({
                stock_no: props && props.stock_no,
                shape: props && props.shape,
                carat: props && props.carat,
                cash_pricect: props && props.cash_pricect,
                clarity: props && props.clarity,
                color: props && props.color,
                pricect: props && props.pricect,
                total_parcel_carat: props && props.total_parcel_carat,
                image_1: props && props.image_1,
                image_2: props && props.image_2,
                image_3: props && props.image_3,
                image_4: props && props.image_4,
                image_5: props && props.image_5,
                showcase_image: props && props.showcase_image,
                cut: props && props.cut,
                measurement: props && props.measurement,
                city: props && props.city,
                country: props && props.country,
                state: props && props.state,
                sieve: props && props.sieve
            })
        }
    }, [props])


    const delete_image = (key) => {
        if (key === 1) {
            setAddProduct({ ...addproduct, image_1: false })
        }
        else if (key === 2) {
            setAddProduct({ ...addproduct, image_2: false })
        }
        else if (key === 3) {
            setAddProduct({ ...addproduct, image_3: false })
        }
        else if (key === 4) {
            setAddProduct({ ...addproduct, image_4: false })
        }
        else if (key === 5) {
            setAddProduct({ ...addproduct, image_5: false })
        }
    }


    return (
        <Fragment>
            <div className="modal fade editproduct_form" id="editproductparcel" tabindex="-1" role="dialog" aria-labelledby="editproductTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <form onSubmit={handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header align-items-center">
                                <button id="dismiss-modal" type="button" className="close m-0" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h5 className="modal-title" id="editproductTitle">Parcels</h5>
                                <button type="submit" className="btn btn-black">Save Changes</button>
                            </div>
                            <div className="modal-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <div className="titles nbrd mt-4 mb-3 pb-3">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h2 className="m-0">Edit Product</h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Stock No.</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.stock_noError === false }, { 'is-invalid': validerror.stock_noError })} type="text" placeholder="Stock No." name="stock_no" value={addproduct.stock_no} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.stock_noError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Shape</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.shapeError === false }, { 'is-invalid': validerror.shapeError })} value={addproduct.shape} name="shape" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.shape && attributes.shape.map((shape) => {
                                                                return (
                                                                    <option value={shape.name}>{shape.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.shapeError}</p>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="row">


                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Carat</label>
                                                    {/* <select className={classnames("form-control", { 'is-valid': validerror.caratError === false }, { 'is-invalid': validerror.caratError })} name="carat" value={addproduct.carat} onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.carat && attributes.carat.map((carat) => {
                                                                return (
                                                                    <option value={carat.name}>{carat.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.caratError}</p> */}
                                                     <input className={classnames("form-control", { 'is-valid': validerror.caratError === false }, { 'is-invalid': validerror.caratError })} type="text" placeholder="Carat" name="carat" value={addproduct.carat} onChange={handleInputChange} />
                                                     <p className="invalid-feedback">{validerror.caratError}</p>
                                                </div>
                                            </div>


                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Color</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.colorError === false }, { 'is-invalid': validerror.colorError })} name="color" value={addproduct.color} onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.color && attributes.color.map((color) => {
                                                                return (
                                                                    <option value={color.name}>{color.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.colorError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Cut</label>
                                                    <select className="form-control" name="cut" value={addproduct.cut} onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.cut && attributes.cut.map((cut) => {
                                                                return (
                                                                    <option value={cut.name}>{cut.name}</option>
                                                                )
                                                            })
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Measurement</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.measurementError === false }, { 'is-invalid': validerror.measurementError })} type="text" placeholder="measurement" name="measurement" value={addproduct.measurement} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.measurementError}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Clarity</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.clarityError === false }, { 'is-invalid': validerror.clarityError })} name="clarity" value={addproduct.clarity} onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.clarity && attributes.clarity.map((clarity) => {
                                                                return (
                                                                    <option value={clarity.name}>{clarity.name}</option>
                                                                )
                                                            })
                                                        }

                                                    </select>
                                                    <p className="invalid-feedback">{validerror.clarityError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Price/ct</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.pricectError === false }, { 'is-invalid': validerror.pricectError })} type="text" placeholder="Price/ct" name="pricect" value={addproduct.pricect} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.pricectError}</p>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Cash Price/ct</label>
                                                    <input onChange={handleInputChange} className={classnames("form-control", { 'is-valid': validerror.cash_pricectError === false }, { 'is-invalid': validerror.cash_pricectError })} type="text" placeholder="cash price/ct" value={addproduct.cash_pricect} name="cash_pricect" />
                                                    <p className="invalid-feedback">{validerror.cash_pricectError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Total Parcel Carat</label>
                                                    <input onChange={handleInputChange} className="form-control" type="text" placeholder="total parcel carat" value={addproduct.total_parcel_carat} name="total_parcel_carat" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Sieve</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.sieveError === false }, { 'is-invalid': validerror.sieveError })} name="sieve" value={addproduct.sieve} onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.sieve && attributes.sieve.map((sieve) => {
                                                                return (
                                                                    <option value={sieve.name}>{sieve.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.sieveError}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Comment (optional)</label>
                                                    <textarea onChange={handleInputChange} placeholder="Stone looks milky" rows="5" className="form-control" value={addproduct.comment} name="comment"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <h3 className="forms toBrdr">
                                            Upload Product Photos or Videos
                                            <p>JPEG, PNG supported, max 10mb per image</p>
                                        </h3>

                                        <div className="row imgEdits mb-4">
                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_1} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(1)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_2} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(2)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_3} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(3)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_4} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(4)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_5} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(5)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row actions my-5">
                                            <div className="col-md-6">
                                                <button type="submit" className="btns btn-black  mr-2 mb-2">Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default Parcel
