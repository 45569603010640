import React, { Fragment, useState, useEffect } from 'react';
import { IMAGES } from '../../../constants';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../layout/Loader';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { url } from '../../../config';
import { getLastURL, ImageUrl, numberWithCommas, RatingDisplay, ProductTitleDisplay, totalPriceDisplay, PriceCTDisplay, GenerateCertificatePdf, totalPriceDisplayforAdminPrice, totalPriceDisplayforDiscount, totalCarat } from '../../../helper';
import { addChatUserAction } from '../../../actions/chatAction';
import { AddFavoriteProduct, GetProductCount } from '../../../actions/productActions';
import $ from 'jquery';
import ReactStars from 'react-rating-stars-component';
import { FormattedMessage } from 'react-intl';
// LightGallery
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

const defaultProductImage = require("../../../assets/images/default_product.png")
const Diamond360ViewImage = require("../../../assets/images/diamond-360.png")

const DetailsPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { details, loading } = useSelector(state => state.productDetails);
    const MessaageTransfer = useSelector(state => state.MessaageTransferState);

    const [image, setImage] = useState();
    const [imageKey, setImageKey] = useState(0)
    const [show3DLink, setShow3DLink] = useState(false)
    const [showFileType, setShowFileType] = useState('image')
    const { user } = useSelector(state => state.user);

    useEffect(() => {
        if (details && details.productImages && details.productImages[0] && details.productImages[0].file) {
            setImage(details && details.productImages && details.productImages[0] && details.productImages[0].file);
            $('.s-image').css('display', 'block');
            $('.s-video').css('display', 'none');
        } else if (details && details.singleVideo && details.singleVideo.video) {
            $('.s-image').css('display', 'none');
            $('.s-video').css('display', 'block');
        }

    }, [details])

    const handleMessageSupplier = async () => {
        await dispatch(addChatUserAction(details && details?.productDetails && details?.productDetails?.seller_id))
        const payload = {
            image: details?.productImages[0]?.file,
            id: details?.productDetails?.id,
            slug: details?.productDetails?.category?.slug,
            product_slug: details?.productDetails?.slug,
            shape: details?.productDetails?.a_shape?.display_name,
            carat: details?.productDetails?.carat,
            color: details?.productDetails?.a_color?.display_name,
            clarity: details?.productDetails?.a_clarity?.display_name,
            certificate: details?.productDetails?.a_certificate?.display_name,
            stockNumber: details?.productDetails?.stock_number,
            productUrl: window.location.href,
            seller_id: details?.sellerDetails?.id
        }
        sessionStorage.setItem('productDetails', JSON.stringify(payload));
        setTimeout(() => {
            window.location = url + '/message?chat=' + details.productDetails.seller_id;
        }, 200);

        // dispatch({
        //     type: 'MESSAGE_TRANSFER_SUCCESS',
        //     payload: payload
        // })
        // history.push({ pathname: `${url}/message?chat=${details?.productDetails?.seller_id}` });
        // history.push( url + '/message?chat=' + details?.productDetails?.seller_id ); 
        //http://localhost:3000/message?chat=5895
        //http://localhost:3000/message?chat=5895
        // window.location.href = url + '/message?chat=' + details?.productDetails?.seller_id;
    }

    // useEffect(() => {
    //     if (MessaageTransfer?.details) {
    //     }
    // }, [MessaageTransfer])


    const handleAddFavorite = async () => {

        if ($('.heart.checkheart').hasClass('active')) {
            $('.heart.checkheart').removeClass('active');
        } else {
            $('.heart.checkheart').addClass('active');
        }

        const product_id = getLastURL(location)
        await dispatch(AddFavoriteProduct(product_id));
        dispatch(GetProductCount());
    }

    const handleContactSupllier = () => {
        $('#seller-info').toggle()
    }

    const handleImage = async (path, type, filekey) => {
        setShow3DLink(false);
        setShowFileType(type)
        setImage(path);
        if (type === 'image') {
            var video = document.getElementById('video');
            if (video) {
                video.pause();
            }
            $('.s-image').css('display', 'block');
            $('.s-video').css('display', 'none');
        } else {
            var video = document.getElementById('video');
            var source = document.createElement('source');
            await source.setAttribute('src', ImageUrl(path));
            await source.setAttribute('type', 'video/mp4');

            if (video) {
                await video.load();
                await video.play();
            }
            $('.s-image').css('display', 'none');
            $('.s-video').css('display', 'block');
        }
        setImageKey(filekey);
    };


    const showGallery = (filekey, type) => {
        $('#imageg-' + filekey).trigger('click');
    }
    const profile_picture_url = details && details?.sellerDetails && details?.sellerDetails?.profile_picture_full_thumb;

    const bgm_val = details && details?.productDetails && details?.productDetails?.bgm;
    //     const bgm = bgm_val == "0" ? "Yes" : "No";
    // console.log(details?.productDetails);
    useEffect(() => {
        if ((!details?.productImages || details?.productImages?.length === 0) && details?.singleVideo === null) {
            setShow3DLink(true);
        }
    }, [details?.productImages?.length === 0, details?.singleVideo === null])

    return (
        <Fragment>
            {loading && <Loader />}
            <Fragment>
                <section className="details_page pt-5">
                    <div className="product_part pb-3">
                        <div className="container-fluid">
                            <div className="row">
                                {/* <div className="col-md-7">
                                    <div className="product_name">
                                        <Link to={url + '/category/diamonds'}><span className="icon icon-arrow_left"></span></Link> 
                                        <h4 className="font-24 black fb mb-0">Diamond</h4> 
                                       <p className="font-20 black mb-0">Stock# {details && details.productDetails && details.productDetails.stock_number}</p>
                                        <div className="like_btn">
                                            <span className="faon" style={{ cursor: 'pointer' }} onClick={handleAddFavorite}>
                                                <i className={details && details.productDetails && details.productDetails.is_favourite > 0 ? "fa fa-heart active heart checkheart" : "fa fa-heart heart checkheart"}></i>
                                            </span>
                                        </div> 
                                    </div>
                                </div>
                                <div className="col-md-5 TitleBtns">
                                    </div> */}

                                <div className="col-lg-6 col-md-6">
                                    <div className="like_btn">
                                        <span className="faon" style={{ cursor: 'pointer' }} onClick={handleAddFavorite}>
                                            <i className={details && details?.productDetails && details?.productDetails?.is_favourite > 0 ? "fa fa-heart active heart checkheart" : "fa fa-heart heart checkheart"}></i>
                                        </span>
                                    </div>
                                    <div className="pro_images">
                                        <div className="simpleLens-gallery-container" id="demo-1">
                                            <div className="simpleLens-thumbnails-container">
                                                {
                                                    details && details?.productImages &&
                                                    <LightGallery plugins={[lgThumbnail, lgZoom, lgVideo]} mode="lg-fade" download={false}  >
                                                        {
                                                            details && details?.productImages && details?.productImages.slice(0, 5) && details?.productImages.slice(0, 5).map((data, key) => {
                                                                return (
                                                                    <a
                                                                        data-lg-size="1406-1390"
                                                                        className="gallery-item "
                                                                        data-src={ImageUrl(data.file)}
                                                                        style={{ display: 'none' }}
                                                                    >
                                                                        <img
                                                                            id={'imageg-' + key}
                                                                            className="img-responsive"
                                                                            src={ImageUrl(data.file)}
                                                                        />
                                                                    </a>
                                                                )
                                                            })
                                                        }

                                                        {
                                                            details && details?.singleVideo && details?.singleVideo?.video &&

                                                            <a
                                                                className="gallery-item"
                                                                key={5}
                                                                data-video={'{"source": [{"src":"' + ImageUrl(details.singleVideo.video) + '", "type":"video/mp4"}], "attributes": {"preload": false, "controls": true}}'}
                                                                style={{ display: 'none' }}
                                                            >
                                                                <img
                                                                    style={{ maxWidth: '400px' }}
                                                                    className="img-responsive"
                                                                    alt=""
                                                                    src={IMAGES.VideoDefaultThumb}
                                                                />
                                                            </a>
                                                        }
                                                    </LightGallery>
                                                }

                                                {
                                                    details && details?.productImages && details?.productImages.slice(0, 5) && details?.productImages.slice(0, 5).map((data, key) => {
                                                        return (
                                                            <a
                                                                className="simpleLens-thumbnail-wrapper"
                                                                onClick={() => handleImage(data.file, 'image', key)}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <img src={ImageUrl(data.file)} alt="" />
                                                            </a>
                                                        )
                                                    })
                                                }
                                                {
                                                    details && details?.singleVideo && details?.singleVideo?.video &&

                                                    <a
                                                        className="simpleLens-thumbnail-wrapper"
                                                        onClick={() => handleImage(details.singleVideo.video, 'video')}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <img src={IMAGES.VideoDefaultThumb} alt="" />
                                                    </a>
                                                }

                                                {
                                                    details?.productDetails?.diamond_3d_link !== null && details?.productDetails?.diamond_3d_link !== undefined && details?.productDetails?.diamond_3d_link.trim() !== '' &&
                                                    <a
                                                        className="simpleLens-thumbnail-wrapper"
                                                        onClick={() => setShow3DLink(true)} >
                                                        <img
                                                            src={Diamond360ViewImage} alt=""
                                                            style={{ cursor: 'pointer' }} />
                                                    </a>
                                                }
                                            </div>
                                            <div className="simpleLens-container">
                                                <div className="simpleLens-big-image-container">
                                                    <div className="s-image">
                                                        {
                                                            show3DLink === false ?
                                                                <img
                                                                    src={ImageUrl(image)}
                                                                    className="simpleLens-big-image"
                                                                    onClick={() => showGallery(imageKey, 'image')} />
                                                                :
                                                                details?.productDetails?.diamond_3d_link !== null && details?.productDetails?.diamond_3d_link !== undefined && details?.productDetails?.diamond_3d_link.trim() !== '' ?
                                                                    <img
                                                                        src={Diamond360ViewImage}
                                                                        className="simpleLens-big-image"
                                                                        onClick={() => window.open(details?.productDetails?.diamond_3d_link)} />
                                                                    :
                                                                    <img src={defaultProductImage} className="simpleLens-big-image" />
                                                        }

                                                    </div>

                                                    {details && details?.singleVideo && details?.singleVideo.video &&
                                                        <>
                                                            {show3DLink === true ?
                                                                details?.productDetails?.diamond_3d_link !== null &&
                                                                details?.productDetails?.diamond_3d_link !== undefined &&
                                                                details?.productDetails?.diamond_3d_link.trim() !== '' &&
                                                                showFileType === 'video' &&
                                                                <img
                                                                    src={Diamond360ViewImage}
                                                                    className="simpleLens-big-image"
                                                                    onClick={() => window.open(details?.productDetails?.diamond_3d_link)}
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                                :
                                                                <div
                                                                    className="s-video"
                                                                    style={{ 'display': 'none' }}>
                                                                    <video
                                                                        id="video"
                                                                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                                                                        controls>
                                                                        <source src={ImageUrl(details.singleVideo.video)} type="video/mp4" />
                                                                        <source src={ImageUrl(details.singleVideo.video)} type="video/ogg" />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-5 col-md-5">
                                    <div className="d-flex hwerating TitleBtns" >
                                        {profile_picture_url ?
                                            <img className="img-responsive logo-img" src={profile_picture_url && ImageUrl(profile_picture_url)} />
                                            : <img className="img-responsive logo-img" src={IMAGES.CHAT_LOGO} />
                                        }
                                        <a href={location.pathname + "#ratings-sec"} className="not_a text-capitalize d-block" >
                                            <div className='d-flex align-items-center flex-wrap'>
                                                <h6>{details && details?.sellerDetails && details?.sellerDetails?.business && details?.sellerDetails?.business?.company_name}</h6>
                                                <div> &nbsp;
                                                    <i style={{ color: "#003B75" }} className="fa fa-star"></i>
                                                    &nbsp;{details && details?.productDetails && RatingDisplay('detail', details.productDetails.average_rating, details.productDetails.total_rating)}
                                                </div>

                                            </div>
                                            <h6 className="H_title ">
                                                {details && details?.productDetails && `${details?.productDetails?.user_state},${details?.productDetails?.user_country}`}
                                            </h6>
                                        </a>

                                        {
                                            details && details?.productDetails?.seller_id !== user.id && (
                                                <div className="average-rating d-flex ml-portauto align-items-center">
                                                    <button className="btn" data-toggle="modal" data-target="#comment"><FormattedMessage
                                                        id="addsingleproduct.WriteAReview"
                                                        defaultMessage="Write a review"
                                                    /></button>
                                                </div>
                                            )}
                                    </div>
                                    <div className="mb-3 border-bottom">
                                        <h5 className="H_title ">{details && ProductTitleDisplay('d', details)}</h5>
                                    </div>
                                    <div className="pro_des">
                                        <div className="row">
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="Shape"
                                                        defaultMessage="Shape"
                                                    /></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details?.productDetails?.a_shape && details.productDetails?.a_shape?.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="addsingleproduct.carat"
                                                        defaultMessage="Shape"
                                                    /></p>
                                                    <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.carat && totalCarat(details.productDetails) + "ct"}</h4>
                                                </div>
                                            </div>

                                            {
                                                details && details?.productDetails && details?.productDetails?.is_fancy == 1 ?
                                                    <Fragment>
                                                        <div className="col-md-4 col-sm-4 col-4">
                                                            <div className="pro_d_text mb-4">
                                                                <p className="font-16 gray-75 mb-1"> <FormattedMessage
                                                                    id="Intensity"
                                                                    defaultMessage="Intensity"
                                                                /></p>
                                                                <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.a_fancy_intensity && details?.productDetails?.a_fancy_intensity?.display_name}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-4 col-4">
                                                            <div className="pro_d_text mb-4">
                                                                <p className="font-16 gray-75 mb-1"> <FormattedMessage
                                                                    id="Overtone"
                                                                    defaultMessage="Overtone"
                                                                /></p>
                                                                <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.a_fancy_overtone && details?.productDetails?.a_fancy_overtone?.display_name}</h4>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-sm-4 col-4">
                                                            <div className="pro_d_text mb-4">
                                                                <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                                    id="addsingleproduct.color"
                                                                    defaultMessage="Color"
                                                                /></p>
                                                                <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.a_fancy_color && details?.productDetails?.a_fancy_color.display_name}</h4>
                                                            </div>
                                                        </div>
                                                    </Fragment> :
                                                    <Fragment>
                                                        <div className="col-md-4 col-sm-4 col-4">
                                                            <div className="pro_d_text mb-4">
                                                                <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                                    id="addsingleproduct.color"
                                                                    defaultMessage="Color"
                                                                /></p>
                                                                <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.a_color && details?.productDetails?.a_color?.display_name}</h4>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                            }


                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="addsingleproduct.clarity"
                                                        defaultMessage="Clarity"
                                                    /></p>
                                                    <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.a_clarity && details.productDetails?.a_clarity?.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="addsingleproduct.cut"
                                                        defaultMessage="Cut"
                                                    /></p>
                                                    <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.a_cut && details.productDetails?.a_cut?.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="addsingleproduct.polish"
                                                        defaultMessage="Polish"
                                                    /></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details?.productDetails?.a_polish && details?.productDetails?.a_polish?.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="addsingleproduct.symmetry"
                                                        defaultMessage="Symmetry"
                                                    /></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details?.productDetails?.a_symmetry && details.productDetails?.a_symmetry?.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="addsingleproduct.fluorescence"
                                                        defaultMessage="Fluorescence"
                                                    /></p>
                                                    <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.a_fluorescence && details.productDetails?.a_fluorescence?.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="addsingleproduct.milky"
                                                        defaultMessage="Milky"
                                                    /></p>
                                                    <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.milky && details?.productDetails?.milky?.display_name}</h4>
                                                </div>
                                            </div>

                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="addsingleproduct.bgm"
                                                        defaultMessage="No BGM"
                                                    /></p>
                                                    <h4 className="font-16 fb black-3B">{bgm_val}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="addsingleproduct.table"
                                                        defaultMessage="Table"
                                                    /> %</p>
                                                    <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.table_per}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="addsingleproduct.depth"
                                                        defaultMessage="Depth"
                                                    /> %</p>
                                                    <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.depth_per}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="Measurements"
                                                        defaultMessage="Measurements"
                                                    /></p>
                                                    <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.measurements}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="addsingleproduct.certificate"
                                                        defaultMessage="Certificate"
                                                    /></p>
                                                    <h4 className="font-16 fb black-3B">
                                                        {details && details?.productDetails && details?.productDetails.certificate_url ?
                                                            <a href={GenerateCertificatePdf(details?.productDetails?.certificate_url)} target={GenerateCertificatePdf(details.productDetails.certificate_url)}>{details && details?.productDetails && details?.productDetails?.a_certificate && details?.productDetails?.a_certificate?.display_name}</a>
                                                            :
                                                            <aks>{details && details?.productDetails && details?.productDetails?.a_certificate && details?.productDetails.a_certificate?.display_name}</aks>
                                                        }
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="addsingleproduct.certificate-no"
                                                        defaultMessage="Certificate No."
                                                    /></p>
                                                    <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.certificate_number}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="addsingleproduct.status"
                                                        defaultMessage="Status"
                                                    /></p>
                                                    <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.availability_status && details?.productDetails?.availability_status?.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                        id="addsingleproduct.stockno"
                                                        defaultMessage="Stock No."
                                                    /></p>
                                                    <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.stock_number}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="pro_d_text cmnt_box mb-4">
                                                    <p className="font-16 gray-75 mb-3"><span className="mr-2"><img
                                                        src="images/svg/Figma Icons/comment.svg" alt="" /></span> <FormattedMessage
                                                            id="Comments"
                                                            defaultMessage="Comments"
                                                        /></p>
                                                    <h4 className="font-16 fb black-3B">{details && details?.productDetails && details?.productDetails?.comment}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="c_price">

                                                    {
                                                        details && details?.productDetails && details?.productDetails.is_fancy == 0 ?
                                                            <div className="c_pri_d price_box">
                                                                <div className="pro_d_text mb-4">
                                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                                        id="TotalPrice"
                                                                        defaultMessage="Total Price"
                                                                    /></p>
                                                                    <h4 className="font-20 fb black-3B">{details && details?.productDetails && totalPriceDisplay(details.productDetails)}</h4>
                                                                </div>
                                                                <div className="pro_d_text mb-4">
                                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                                        id="addsingleproduct.discount"
                                                                        defaultMessage="Discount"
                                                                    /></p>
                                                                    <h4 className="font-20 fr black-3B">{details && details?.productDetails && details?.productDetails?.discount && details?.productDetails?.admin_price > 0 ? totalPriceDisplayforDiscount(details.productDetails) : "--"}</h4>
                                                                </div>

                                                                <div className="pro_d_text mb-4">
                                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                                        id="addsingleproduct.price-ct"
                                                                        defaultMessage="Price/ct"
                                                                    /></p>
                                                                    <h4 className="font-20 fr black-3B">{details && details?.productDetails && details?.productDetails?.price && PriceCTDisplay(details.productDetails)}{/*{details && details.productDetails && details.productDetails.price ? "$"+numberWithCommas(parseFloat(details.productDetails.price).toFixed(2)):"--" } */}
                                                                    </h4>
                                                                </div>
                                                                <div className="pro_d_text mb-4">
                                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                                        id="Listprice"
                                                                        defaultMessage="List price"
                                                                    /></p>
                                                                    <h4 className="font-20 fr black-3B"> {details && details?.productDetails?.admin_price && totalPriceDisplayforAdminPrice(details.productDetails) ? totalPriceDisplayforAdminPrice(details.productDetails) : "---"}</h4>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="c_pri_d price_box">
                                                                <div className="pro_d_text mb-4">
                                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                                        id="TotalPrice"
                                                                        defaultMessage="Total Price"
                                                                    /></p>
                                                                    <h4 className="font-20 fb black-3B">{details && details?.productDetails && totalPriceDisplay(details.productDetails)}</h4>
                                                                </div>
                                                                <div className="pro_d_text mb-4">
                                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                                        id="addsingleproduct.discount"
                                                                        defaultMessage="Discount"
                                                                    /></p>
                                                                    <h4 className="font-20 fr black-3B">--</h4>
                                                                </div>
                                                                <div className="pro_d_text mb-4">
                                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                                        id="addsingleproduct.price-ct"
                                                                        defaultMessage="Price/ct"
                                                                    /></p>
                                                                    <h4 className="font-20 fr black-3B">{details && details?.productDetails && totalPriceDisplay(details.productDetails)}</h4>
                                                                </div>
                                                                <div className="pro_d_text mb-4">
                                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                                        id="Listprice"
                                                                        defaultMessage="List price"
                                                                    /></p>
                                                                    <h4 className="font-20 fr black-3B">--</h4>
                                                                </div>
                                                            </div>
                                                    }

                                                    <div className="add_btn">
                                                        {details && details?.productDetails && user && user.id != details.productDetails?.seller_id &&
                                                            <a href="javascript:void(0)" className="btn btn-black-outline" onClick={handleMessageSupplier}><FormattedMessage
                                                                id="MessageSupplier"
                                                                defaultMessage="Message supplier"
                                                            />  </a>
                                                        }
                                                        <a onClick={handleContactSupllier} className="btn btn-black"><FormattedMessage
                                                            id="ContactSupplier"
                                                            defaultMessage="Contact supplier"
                                                        /></a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <hr />
            </Fragment>
        </Fragment>
    )
}

export default DetailsPage




