import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {getProductDetails} from '../../.././actions/productActions';
import {useHistory} from 'react-router-dom';
import {url} from '../../../config';
import {numberWithCommas, CompanyNameDisplay, totalPriceDisplay,totalCarat,totalPriceDisplayforDiscount} from '../../../helper';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
const ProInfoList = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { details } = useSelector(state => state.productDetails);

    const handleDetails = (product_id) => {
        history.push(url + `/details/diamonds/${product_id}`);
        dispatch(getProductDetails(product_id))
    }


    return (
        <Fragment>
            <section className="pro_info_list">

                <div className="container-fluid">

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tbles">
                                <h4 className="font-16 black fm mt-2 mb-4"><FormattedMessage id="You may also like" defaultMessage="You may also like" /></h4>
                                <table width="100%">

                                    <tr>
                                        <th><FormattedMessage id="Seller" defaultMessage="Seller" /></th>
                                        <th><FormattedMessage id="Shape" defaultMessage="Shape" /></th>
                                        <th><FormattedMessage id="addsingleproduct.carat" defaultMessage="Carat" /></th>
                                        <th><FormattedMessage id="addsingleproduct.color" defaultMessage="Color" /></th>
                                        <th><FormattedMessage id="addsingleproduct.clarity" defaultMessage="Clarity" /></th>
                                        <th><FormattedMessage id="addsingleproduct.cut"  /></th>
                                        <th><FormattedMessage id="addsingleproduct.pol"  /></th>
                                        <th><FormattedMessage id="addsingleproduct.sym"  /></th>
                                        <th><FormattedMessage id="addsingleproduct.Fluor"  /></th>
                                        <th><FormattedMessage id="Cert" /></th>
                                        <th><FormattedMessage id="Disc"  /></th>
                                        <th><FormattedMessage id="TotalPrice" defaultMessage="Total Price" /></th>
                                    </tr>
                                   { details && details.remainingProducts && details.remainingProducts.map((data, key) =>{
                                       return( 
                                        <tr>
                                        <td onClick={(e) => handleDetails(data.id)}>{data && data.seller && CompanyNameDisplay(data)}</td>
                                        <td>{data && data.a_shape && data.a_shape.display_name}</td>
                                        <td>{data && data.carat && totalCarat(data)}</td>
                                        <td>{data && data.a_color && data.a_color.display_name}</td>
                                        <td>{data && data.a_clarity && data.a_clarity.display_name}</td>
                                        <td>{data && data.a_cut && data.a_cut.display_name}</td>
                                        <td>{data && data.a_polish && data.a_polish.display_name}</td>
                                        <td>{data && data.a_symmetry && data.a_symmetry.display_name}</td>
                                        <td>{data && data.a_fluorescence && data.a_fluorescence.display_name}</td>
                                        <td>{data && data.a_certificate && data.a_certificate.display_name}</td>
                                        {
                                                                data && data.is_fancy == 0 ?
                                                                    <Fragment>
                                                                        <td><Link to={url + `/details/diamonds/${data.id}`} target="_blank" className="not_a">{data && data.discount && data.admin_price != 'null' ? totalPriceDisplayforDiscount(data) :"--"}</Link></td>
                                                                    </Fragment> :
                                                                    <Fragment>
                                                                        <td>--</td>
                                                                    </Fragment>
                                                            }
                                        <td>{data && totalPriceDisplay(data)}</td>
                                        </tr>
                                       )
                                   })}
                                   

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr />
        </Fragment>
    )
}

export default ProInfoList
