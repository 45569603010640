//  export const BASE_URL = "https://demo.dev9server.com/Cevaheer/api"
//export const BASE_URL = "http://localhost/Cevaheer/api"
export const BASE_URL = "https://www.cevaheer.com/cevaheer/api"
export const IMAGE_BASE_URL = 'https://d28q6ef240x938.cloudfront.net/'
//export const IMAGE_BASE_URL = 'http://localhost/Cevaheer/'
// export const IMAGE_BASE_URL = 'https://demo.dev9server.com/Cevaheer/storage/app/public/'

export const CERTIFICATE_BASE_URL = 'https://d28q6ef240x938.cloudfront.net/'
export const TOKEN = localStorage.getItem('isAuthenticated')
export const url = process.env.PUBLIC_URL;
export const hrdurl = "https://my.hrdantwerp.com/?record_number="
export const giaurl = "https://www.gia.edu/report-check?reportno="
export const currency = "$"

export const BACKEND_BASE_URL = 'https://www.cevaheer.com:4568'
// export const BACKEND_BASE_URL = 'http://192.168.1.91:4568'
//  export const BACKEND_BASE_URL ="https://demo.dev9server.com:4568"
//  export const BACKEND_BASE_URL ="http://localhost:4568"

export const postPerPage = 20

export const SC = 1