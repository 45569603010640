import React, { Fragment } from 'react'

const Lens = () => {
    return (
        <Fragment>
            <article>
                <h1>Demo with mouseenter - mouseleave</h1>
                <div className="simpleLens-gallery-container" id="demo-1">
                    <div className="simpleLens-container">
                        <div className="simpleLens-big-image-container">
                            <a className="simpleLens-lens-image" data-lens-image="demo/large/benvado_elisa_bianco.jpg">
                                <img src="demo/medium/benvado_elisa_bianco.jpg" className="simpleLens-big-image" />
                            </a>
                        </div>
                    </div>

                    <div className="simpleLens-thumbnails-container">
                        <a href="#" className="simpleLens-thumbnail-wrapper"
                            data-lens-image="demo/large/benvado_elisa_bianco.jpg"
                            data-big-image="demo/medium/benvado_elisa_bianco.jpg">
                            <img src="demo/thumbnail/benvado_elisa_bianco.jpg" />
                        </a>

                        <a href="#" className="simpleLens-thumbnail-wrapper"
                            data-lens-image="demo/large/camper_21926_red.jpg"
                            data-big-image="demo/medium/camper_21926_red.jpg">
                            <img src="demo/thumbnail/camper_21926_red.jpg" />
                        </a>

                        <a href="#" className="simpleLens-thumbnail-wrapper"
                            data-lens-image="demo/large/flylondon_most_red.jpg"
                            data-big-image="demo/medium/flylondon_most_red.jpg">
                            <img src="demo/thumbnail/flylondon_most_red.jpg" />
                        </a>
                    </div>
                </div>
            </article>

            {/* <article>
                <h1>Demo with click - mouseleave</h1>

                <div className="simpleLens-gallery-container" id="demo-2">
                    <div className="simpleLens-container">
                        <div className="simpleLens-big-image-container">
                            <a className="simpleLens-lens-image" data-lens-image="demo/large/benvado_elisa_bianco.jpg">
                                <img src="demo/medium/benvado_elisa_bianco.jpg" className="simpleLens-big-image" />
                            </a>
                        </div>
                    </div>

                    <div className="simpleLens-thumbnails-container">
                        <a href="#" className="simpleLens-thumbnail-wrapper"
                            data-lens-image="demo/large/benvado_elisa_bianco.jpg"
                            data-big-image="demo/medium/benvado_elisa_bianco.jpg">
                            <img src="demo/thumbnail/benvado_elisa_bianco.jpg" />
                        </a>

                        <a href="#" className="simpleLens-thumbnail-wrapper"
                            data-lens-image="demo/large/camper_21926_red.jpg"
                            data-big-image="demo/medium/camper_21926_red.jpg">
                            <img src="demo/thumbnail/camper_21926_red.jpg" />
                        </a>

                        <a href="#" className="simpleLens-thumbnail-wrapper"
                            data-lens-image="demo/large/flylondon_most_red.jpg"
                            data-big-image="demo/medium/flylondon_most_red.jpg">
                            <img src="demo/thumbnail/flylondon_most_red.jpg" />
                        </a>
                    </div>
                </div>
            </article> */}
        </Fragment>
    )
}

export default Lens
