import React from 'react';
import QRCode from 'qrcode.react';

const QRcode = () => {
    return (
        <div style={{textAlign:'center'}}>
           <QRCode value="http://dev9server.com/cevaheer-front" /> 
        </div>
    )
}

export default QRcode
