import React, { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router';
import Sidebar from './Sidebar';
import ProtectedHeader from '../../layout/ProtectedHeader';
import { IMAGES } from '../../../constants';
import WhiteDiamonds from './products/WhiteDiamonds';
import Watch from './products/Watch';
import Parcels from './products/Parcels';
import ColorStone from './products/ColorStone';
import WeddingBand from './products/WeddingBand';
import Jewelery from './products/Jewelery';
import { getLastURL } from '../../../helper';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { GetFavoriteProduct } from '../../../actions/productActions';
import EmptyPage from '../../layout/404';

const Index = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const path = getLastURL(location);

    useEffect(() =>{
        dispatch(GetFavoriteProduct(path));
    })

    return (
        <Fragment>
            <ProtectedHeader />
            <ul className="breadcrub">
                <li>
                <FormattedMessage
                id="Favorites"
                defaultMessage="Favorites"
                />
                </li>
                <li><img src={IMAGES.ARROW_SVG} /></li>
                <li>
                <FormattedMessage
                id={"category."+path}
                defaultMessage={path}
                />
                </li>
            </ul>
            <section className="container-fluid py-4 conPart">
                <div className="row">
                    <Sidebar />
                    {
                        path == 'diamonds' ?
                        <WhiteDiamonds /> :
                        path == 'parcels' ?
                        <Parcels /> :
                        path == 'jewellery' ?
                        <Jewelery /> :
                        path == 'watches' ?
                        <Watch /> :
                        path == 'color-stones' ?
                        <ColorStone /> :
                        path == 'wedding-bands' ?
                        <WeddingBand /> :
                        <EmptyPage />
                    }
                </div>
            </section>
        </Fragment>
    )
}

export default Index
