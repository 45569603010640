import { GET_CHATUSER_REQUEST, GET_CHATUSER_SUCCESS, GET_CHATUSER_FAIL,
    GET_MESSAGE_HISTORY_REQUEST,
    GET_MESSAGE_HISTORY_SUCCESS,
    GET_MESSAGE_HISTORY_FAIL,
    ADD_CHATUSER_REQUEST,
    ADD_CHATUSER_SUCCESS,
    ADD_CHATUSER_FAIL
 } from '../constants/chatConstant';

export const getChatUserReducer = (state = { chatuser:null }, action) => {

    switch (action.type) {
        case GET_CHATUSER_REQUEST:
            return {
                loading: true,
                chatuser: null
            }
        case GET_CHATUSER_SUCCESS:
            return {
                loading: false,
                chatuser: action.payload
            }
        case GET_CHATUSER_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state     
    }

}


export const getMessageHistoryReducer = (state = { messageHistory:null }, action) => {

    switch (action.type) {
        case GET_MESSAGE_HISTORY_REQUEST:
            return {
                loading: true,
                messageHistory: null
            }
        case GET_MESSAGE_HISTORY_SUCCESS:
            return {
                loading: false,
                messageHistory: action.payload
            }
        case GET_MESSAGE_HISTORY_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state     
    }

}


export const addChatUserReducer = (state = { addChatUser:null }, action) => {

    switch (action.type) {
        case ADD_CHATUSER_REQUEST:
            return {
                loading: true,
                addChatUser: null
            }
        case ADD_CHATUSER_SUCCESS:
            return {
                loading: false,
                addChatUser: action.payload
            }
        case ADD_CHATUSER_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state     
    }

}