import {
    DOWNLOAD_REQUEST,
    DOWNLOAD_SUCCESS,
    DOWNLOAD_FAIL,
    ALL_PRODUCTS_REQUEST,
    ALL_PRODUCTS_SUCCESS,
    ALL_PRODUCTS_FAIL,
    CLEAR_ERRORS,
    ALL_PRODUCT_TYPE_REQUEST,
    ALL_PRODUCT_TYPE_SUCCESS,
    ALL_PRODUCT_TYPE_FAIL,
    ALL_ATTRIBUTES_REQUEST,
    ALL_ATTRIBUTES_SUCCESS,
    ALL_ATTRIBUTES_FAIL,
    ADD_PRODUCT_REQUEST,
    ADD_PRODUCT_SUCCESS,
    ADD_PRODUCT_FAIL,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    MY_PRODUCT_REQUEST,
    MY_PRODUCT_SUCCESS,
    MY_PRODUCT_FAIL,
    MY_PRODUCT_SELECTACTION_REQUEST,
    MY_PRODUCT_SELECTACTION_SUCCESS,
    MY_PRODUCT_SELECTACTION_FAIL,
    MY_PRODUCT_ACTIVEALL_REQUEST,
    MY_PRODUCT_ACTIVEALL_SUCCESS,
    MY_PRODUCT_ACTIVEALL_FAIL,
    ADD_FAVORITE_REQUEST,
    ADD_FAVORITE_SUCCESS,
    ADD_FAVORITE_FAIL,
    GET_FAVORITE_REQUEST,
    GET_FAVORITE_SUCCESS,
    GET_FAVORITE_FAIL,
    GET_FAVORITE_CATEGORY_REQUEST,
    GET_FAVORITE_CATEGORY_SUCCESS,
    GET_FAVORITE_CATEGORY_FAIL,
    GET_PRODUCT_COUNT_REQUEST,
    GET_PRODUCT_COUNT_SUCCESS,
    GET_PRODUCT_COUNT_FAIL,
    GET_MODEL_ATTRIBUTES_REQUEST,
    GET_MODEL_ATTRIBUTES_SUCCESS,
    GET_MODEL_ATTRIBUTES_FAIL,
    GET_RATING_TAGS_REQUEST,
    GET_RATING_TAGS_SUCCESS,
    GET_RATING_TAGS_FAIL,
    UPDATE_SINGLE_PRODUCT_REQUEST,
    UPDATE_SINGLE_PRODUCT_SUCCESS,
    UPDATE_SINGLE_PRODUCT_FAIL,
    ALL_SELLER_REQUEST,
    ALL_SELLER_SUCCESS,
    ALL_SELLER_FAIL,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    ADVERTISEMENT_REQUEST,
    ADVERTISEMENT_SUCCESS,
    ADVERTISEMENT_FAIL,
    MY_PRODUCT_STATUS_REQUEST,
    MY_PRODUCT_STATUS_SUCCESS,
    MY_PRODUCT_STATUS_FAIL
} from '../constants/productConstants';
import { hashResEncodeData } from "../helper";
import { CLEAR_MESSAGE } from '../constants/userConstants';
import { makeRequestGet, makeRequestPost, makeFormDataRequestPost } from '../utils/apiHelper';
import $ from 'jquery';

export const getAllProducts = (category_slug, page = 0) => async (dispatch) => {
    try {
        dispatch({ type: ALL_PRODUCTS_REQUEST })
        var filter = {};
        var order = {};
        var order_by_type = '';
        var order_by_value = '';
        if (page >= 1) {
            filter = JSON.parse(localStorage.getItem(category_slug + "filter"));
            order = JSON.parse(localStorage.getItem(category_slug + "_sorting"));
            if (order) {
                order_by_type = order.order_by_type
                order_by_value = order.order_by_value
            }
        } else {
            localStorage.removeItem(category_slug + "filter")
            localStorage.removeItem(category_slug + "_sorting")
        }

        const data = await makeRequestPost('get-products-by-category', { category_slug, page, filter, order_by_type, order_by_value })

        if (data.status) {
            dispatch({
                type: ALL_PRODUCTS_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: ALL_PRODUCTS_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: ALL_PRODUCTS_FAIL,
            payload: error
        })
    }
}


export const getAllProductsFilter = (category_slug, filter, page = 0, discountDetails) => async (dispatch) => {
    try {
        dispatch({ type: ALL_PRODUCTS_REQUEST })
        localStorage.setItem(category_slug + "filter", JSON.stringify(filter));
        let order_by_type = discountDetails?.order_by_type;
        let order_by_value = discountDetails?.order_by_value;
        const data = await makeRequestPost('get-products-by-category', { category_slug, filter, page, order_by_type, order_by_value })

        if (data.status) {
            dispatch({
                type: ALL_PRODUCTS_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: ALL_PRODUCTS_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: ALL_PRODUCTS_FAIL,
            payload: error
        })
    }
}

export const myProductsSideFilter = (category_slug, filter, page = 0, discountDetails) => async (dispatch) => {
    try {
        dispatch({ type: MY_PRODUCT_REQUEST })
        localStorage.setItem(category_slug + "filter", JSON.stringify(filter));
        let order_by_type = discountDetails?.order_by_type;
        let order_by_value = discountDetails?.order_by_value;
        const data = await makeRequestPost('get-products-by-category', { category_slug, filter, page, order_by_type, order_by_value, userProducts: true })

        if (data.status) {
            dispatch({
                type: MY_PRODUCT_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: MY_PRODUCT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: MY_PRODUCT_FAIL,
            payload: error
        })
    }
}

export const getProductType = () => async (dispatch) => {

    try {
        dispatch({ type: ALL_PRODUCT_TYPE_REQUEST })

        const data = await makeRequestGet('get-product-types')

        dispatch({
            type: ALL_PRODUCT_TYPE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PRODUCT_TYPE_FAIL,
            payload: error.message
        })

    }
}

export const getAttributes = (category_slug) => async (dispatch) => {

    try {
        dispatch({ type: ALL_ATTRIBUTES_REQUEST })

        const categoryId = {
            category_slug: category_slug
        }

        const { data } = await makeRequestPost('get-attributes', categoryId);

        dispatch({
            type: ALL_ATTRIBUTES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_ATTRIBUTES_FAIL,
            payload: error.message
        })

    }
}


export const addProduct = (addData) => async (dispatch) => {
    try {
        dispatch({ type: ADD_PRODUCT_REQUEST })

        const formData = new FormData();

        var obj = addData;
        let obj2 = {}

        for (var key in obj) {
            console.log(key, obj[key]);
            if (key === 'images') {
                for (let key2 in obj[key]) {
                    formData.append(`images[${key2}]`, obj[key][key2]);
                }
            } else if (key === 'video') {
                console.log(key, obj[key]);
                formData.append(`${key}`, obj[key]);
            }
            else {
                console.log(key, obj[key]);
                if (obj.hasOwnProperty(key)) {
                    obj2[key] = obj[key]
                }
            }
        }

        if (obj2 && Object.keys(obj2).length > 0) {
            const encodedData = hashResEncodeData(obj2);
            formData.append('req', encodedData?.data);
        }

        const data = await makeFormDataRequestPost('add-product', formData);
        if (data.status === "import_error") {
            dispatch({
                type: "IMPORT_ERROR",
                payload: data.message
            })
        }
        else if (data.status === true) {
            dispatch({
                type: ADD_PRODUCT_SUCCESS,
                payload: data.message
            })
        }
        else {
            dispatch({
                type: ADD_PRODUCT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: ADD_PRODUCT_FAIL,
            payload: error.message
        })
    }
}


export const addExcelProduct = (addData) => async (dispatch) => {
    try {
        dispatch({ type: ADD_PRODUCT_REQUEST })

        const data = await makeRequestPost('add-product', addData);

        if (data.status === "import_error") {
            dispatch({
                type: "IMPORT_ERROR",
                payload: data.message
            })
        }
        else if (data.status === true) {
            dispatch({
                type: ADD_PRODUCT_SUCCESS,
                payload: data.message
            })
        }
        else {
            dispatch({
                type: ADD_PRODUCT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: ADD_PRODUCT_FAIL,
            payload: error
        })
    }
}



export const getProductDetails = (product_id) => async (dispatch) => {

    try {
        dispatch({ type: PRODUCT_DETAILS_REQUEST })

        const productId = {
            product_id: product_id
        }

        const { data } = await makeRequestPost('get-product-details', productId);

        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.message
        })

    }
}


export const myProductAction = (category_slug, page) => async (dispatch) => {
    try {
        var order = {};
        var order_by_type = '';
        var order_by_value = '';
        if (page >= 1) {
            order = JSON.parse(localStorage.getItem(category_slug + "_sortingmy"));
            if (order) {
                order_by_type = order.order_by_type
                order_by_value = order.order_by_value
            }
        } else {

            localStorage.removeItem(category_slug + "_sortingmy")
        }
        dispatch({ type: MY_PRODUCT_REQUEST })
        const data = await makeRequestPost('get-products-by-category', { category_slug, userProducts: true, page, order_by_type, order_by_value })

        if (data.status) {
            dispatch({
                type: MY_PRODUCT_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: MY_PRODUCT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: MY_PRODUCT_FAIL,
            payload: error
        })
    }
}

export const getProductsFilterByAscending = (category_slug, value, type) => async (dispatch) => {
    try {
        dispatch({ type: ALL_PRODUCTS_REQUEST })
        var filter = {};
        filter = JSON.parse(localStorage.getItem(category_slug + "filter"));
        localStorage.setItem(category_slug + "_sorting", JSON.stringify({ order_by_value: value, order_by_type: type }));
        const data = await makeRequestPost('get-products-by-category', { category_slug, order_by_value: value, order_by_type: type, filter })

        if (data.status) {
            dispatch({
                type: ALL_PRODUCTS_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: ALL_PRODUCTS_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: ALL_PRODUCTS_FAIL,
            payload: error
        })
    }
}


export const myProductFilterByAscending = (category_slug, value, type) => async (dispatch) => {
    try {
        dispatch({ type: MY_PRODUCT_REQUEST })
        const filter = JSON.parse(localStorage.getItem(category_slug + "filter"))

        const data = await makeRequestPost('get-products-by-category', { category_slug, order_by_value: value, order_by_type: type, userProducts: true, filter })
        localStorage.setItem(category_slug + "_sortingmy", JSON.stringify({ order_by_value: value, order_by_type: type }));
        if (data.status) {
            dispatch({
                type: MY_PRODUCT_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: MY_PRODUCT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: MY_PRODUCT_FAIL,
            payload: error
        })
    }
}


export const myProductFilter = (category_slug, filter) => async (dispatch) => {
    try {
        dispatch({ type: MY_PRODUCT_REQUEST })

        const data = await makeRequestPost('get-products-by-category', { category_slug, filter, userProducts: true, })

        if (data.status) {
            dispatch({
                type: MY_PRODUCT_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: MY_PRODUCT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: MY_PRODUCT_FAIL,
            payload: error
        })
    }
}

export const AdvertismentList = (category_slug, filter, value, search) => async (dispatch) => {
    try {
        dispatch({ type: ADVERTISEMENT_REQUEST })

        const data = await makeRequestPost('advertisment-list', { category_slug, filter, value, search, userProducts: true, })

        if (data.status) {
            dispatch({
                type: ADVERTISEMENT_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: ADVERTISEMENT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: ADVERTISEMENT_FAIL,
            payload: error
        })
    }
}

export const getAllAdvertismentList = (category_slug, page = 0) => async (dispatch) => {
    try {
        dispatch({ type: ADVERTISEMENT_REQUEST })
        var filter = null;
        var search = null;
        const data = await makeRequestPost('advertisment-list', { category_slug, page, filter, search })
        if (data.status) {
            dispatch({
                type: ADVERTISEMENT_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: ADVERTISEMENT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: ADVERTISEMENT_FAIL,
            payload: error
        })
    }
}

export const myProductSelectAction = (ids, action) => async (dispatch) => {
    try {
        dispatch({ type: MY_PRODUCT_SELECTACTION_REQUEST })

        const data = await makeRequestPost('change-status-product-bulk', { ids, action })

        if (data.status) {
            dispatch({
                type: MY_PRODUCT_SELECTACTION_SUCCESS,
                payload: data.message
            })
            return;
        }
        else {
            dispatch({
                type: MY_PRODUCT_SELECTACTION_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: MY_PRODUCT_SELECTACTION_FAIL,
            payload: error
        })
    }
}

export const myProductStatusAction = (id, action) => async (dispatch) => {
    try {
        dispatch({ type: MY_PRODUCT_STATUS_REQUEST })

        const data = await makeRequestPost('change-availability-status', { id, action })

        if (data.status) {
            dispatch({
                type: MY_PRODUCT_STATUS_SUCCESS,
                payload: data.message
            })
            return;
        }
        else {
            dispatch({
                type: MY_PRODUCT_STATUS_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: MY_PRODUCT_STATUS_FAIL,
            payload: error
        })
    }
}


export const myProductActiveAll = () => async (dispatch) => {
    try {
        dispatch({ type: MY_PRODUCT_ACTIVEALL_REQUEST })

        const data = await makeRequestPost('active-all-product')

        if (data.status) {
            dispatch({
                type: MY_PRODUCT_ACTIVEALL_SUCCESS,
                payload: data.message
            })
            return;
        }
        else {
            dispatch({
                type: MY_PRODUCT_ACTIVEALL_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: MY_PRODUCT_ACTIVEALL_FAIL,
            payload: error
        })
    }
}
export const myProductDeactiveAll = (category_slug) => async (dispatch) => {
    try {
        dispatch({ type: MY_PRODUCT_ACTIVEALL_REQUEST })

        const data = await makeRequestPost('inactive-all-product', { category_slug })

        if (data.status) {
            dispatch({
                type: MY_PRODUCT_ACTIVEALL_SUCCESS,
                payload: data.message
            })
            return;
        }
        else {
            dispatch({
                type: MY_PRODUCT_ACTIVEALL_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: MY_PRODUCT_ACTIVEALL_FAIL,
            payload: error
        })
    }
}

export const myProductDeleteAll = (category_slug) => async (dispatch) => {
    try {
        dispatch({ type: MY_PRODUCT_ACTIVEALL_REQUEST })

        const data = await makeRequestPost('delete-all-product', { category_slug })

        if (data.status) {
            dispatch({
                type: MY_PRODUCT_ACTIVEALL_SUCCESS,
                payload: data.message
            })
            return;
        }
        else {
            dispatch({
                type: MY_PRODUCT_ACTIVEALL_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: MY_PRODUCT_ACTIVEALL_FAIL,
            payload: error
        })
    }
}

export const myProductShowSold = (category_slug, sold) => async (dispatch) => {
    try {
        dispatch({ type: MY_PRODUCT_REQUEST })

        const data = await makeRequestPost('get-products-by-category', { category_slug, sold, userProducts: true })

        if (data.status) {
            dispatch({
                type: MY_PRODUCT_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: MY_PRODUCT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: MY_PRODUCT_FAIL,
            payload: error
        })
    }
}

export const getAllProductsCompare = (category_slug, compare_product_ids, getPdf = false, type, filter) => async (dispatch) => {
    try {
        dispatch({ type: ALL_PRODUCTS_REQUEST })

        const data = await makeRequestPost('get-products-by-category', { category_slug, compare_product_ids, getPdf, type, filter })

        if (data.status) {
            dispatch({
                type: ALL_PRODUCTS_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: ALL_PRODUCTS_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: ALL_PRODUCTS_FAIL,
            payload: error
        })
    }
}

export const getUrlToDownloadDocument = (category_slug, compare_product_ids, getPdf = false, type, filter) => async (dispatch) => {
    try {
        dispatch({ type: DOWNLOAD_REQUEST })
        const data = await makeRequestPost('get-products-by-category', { category_slug, compare_product_ids, getPdf, type, filter })
        if (data.status) {
            console.log("get-products-by-category===", data);
            dispatch({
                type: DOWNLOAD_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: DOWNLOAD_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: DOWNLOAD_FAIL,
            payload: error
        })
    }
}

export const AddFavoriteProduct = (product_id) => async (dispatch) => {
    try {
        dispatch({ type: ADD_FAVORITE_REQUEST })

        const data = await makeRequestPost('add-favorite', { product_id })

        if (data.status) {
            dispatch({
                type: ADD_FAVORITE_SUCCESS,
                payload: data.message
            })
            return;
        }
        else {
            dispatch({
                type: ADD_FAVORITE_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: ADD_FAVORITE_FAIL,
            payload: error
        })
    }
}


export const GetFavoriteProduct = (category_slug) => async (dispatch) => {
    try {
        dispatch({ type: GET_FAVORITE_REQUEST })

        const data = await makeRequestPost('get-favorites', { category_slug })

        if (data.status) {
            dispatch({
                type: GET_FAVORITE_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: GET_FAVORITE_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: GET_FAVORITE_FAIL,
            payload: error
        })
    }
}


export const GetFavoriteCategory = () => async (dispatch) => {
    try {
        dispatch({ type: GET_FAVORITE_CATEGORY_REQUEST })

        const data = await makeRequestPost('get-categories-for-favorite')

        if (data.status) {
            dispatch({
                type: GET_FAVORITE_CATEGORY_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: GET_FAVORITE_CATEGORY_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: GET_FAVORITE_CATEGORY_FAIL,
            payload: error
        })
    }
}



export const GetProductCount = () => async (dispatch) => {
    try {
        dispatch({ type: GET_PRODUCT_COUNT_REQUEST })

        const data = await makeRequestPost('global-count')

        if (data.status) {
            dispatch({
                type: GET_PRODUCT_COUNT_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: GET_PRODUCT_COUNT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: GET_PRODUCT_COUNT_FAIL,
            payload: error
        })
    }
}



export const GetModelAttribute = (category_slug, parent_id) => async (dispatch) => {
    try {
        dispatch({ type: GET_MODEL_ATTRIBUTES_REQUEST })

        const data = await makeRequestPost('get-attributes-by-parent', { "category_slug": category_slug, "parent_id": parent_id })

        if (data.status) {
            dispatch({
                type: GET_MODEL_ATTRIBUTES_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: GET_MODEL_ATTRIBUTES_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: GET_PRODUCT_COUNT_FAIL,
            payload: error
        })
    }
}


export const GetRatingTagAction = () => async (dispatch) => {
    try {
        dispatch({ type: GET_RATING_TAGS_REQUEST })

        const data = await makeRequestGet('get-rating-tags')

        if (data.status) {
            dispatch({
                type: GET_RATING_TAGS_SUCCESS,
                payload: data.data
            })
            return;
        }
        else {
            dispatch({
                type: GET_RATING_TAGS_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: GET_PRODUCT_COUNT_FAIL,
            payload: error
        })
    }
}



export const updateSingleProduct = (editData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_SINGLE_PRODUCT_REQUEST })

        const data = await makeRequestPost('update-product', editData);
        console.log("data update-product", data);
        if (data.status) {
            dispatch({
                type: UPDATE_SINGLE_PRODUCT_SUCCESS,
                payload: data.message
            })
        }
        else {
            dispatch({
                type: UPDATE_SINGLE_PRODUCT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: UPDATE_SINGLE_PRODUCT_FAIL,
            payload: error
        })
    }
}

export const updateDiamond = (editData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PRODUCT_REQUEST })
        const data = await makeRequestPost('update-diamond', editData);


        if (data.status) {
            dispatch({
                type: UPDATE_PRODUCT_SUCCESS,
                payload: data.message
            })
        }
        else {
            dispatch({
                type: UPDATE_PRODUCT_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: UPDATE_PRODUCT_FAIL,
            payload: error
        })
    }
}


export const getSuppliers = (search) => async (dispatch) => {

    try {
        dispatch({ type: ALL_SELLER_REQUEST })

        const categoryId = {
            category_slug: 'diamonds',
            search: search
        }
        const { data } = await makeRequestPost('top-sellers', categoryId);
        dispatch({
            type: ALL_SELLER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_SELLER_FAIL,
            payload: error.message
        })

    }
}



export const clearError = () => (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}

export const clearMessage = () => (dispatch) => {
    dispatch({
        type: CLEAR_MESSAGE
    })
}




export const DownloadPdfWithFilterAction = (filter) => async (dispatch) => {
    try {
        dispatch({
            type: "APPLY_FILTER_SUCCESS",
            payload: filter
        })
    } catch (error) {
        dispatch({
            type: "APPLY_FILTER_FAIL",
            payload: "APPLY_FILTER_FAIL"
        })
    }
}



export const SoryByFilterAction = (filter) => async (dispatch) => {
    try {
        dispatch({
            type: "SORT_BY_FILTER_SUCCESS",
            payload: filter
        })
    } catch (error) {
        dispatch({
            type: "SORT_BY_FILTER_FAIL",
            payload: "SORT_BY_FILTER_FAIL"
        })
    }
}

