import React, {Fragment} from 'react';
import { useLocation } from 'react-router';
import Diamonds from './diamonds';
import Parcel from './parcels';
import Jewelery from './jewelery';
import ColorStone from './colorStone';
import Watch from './watch';
import WeddingBand from './weddingBands';
import { getSecondLastURL } from '../../helper';
import EmptyPage from '../layout/404';


const Index = () => {

    const location = useLocation();
    const pathname = getSecondLastURL(location)

    return (
        <Fragment>
            {
                pathname === 'diamonds' ?
                 <Diamonds />
                :
                pathname === 'parcels' ?
                 <Parcel />
                : 
                pathname === 'jewellery' ?
                  <Jewelery />
                :
                pathname === 'color-stones' ?
                  <ColorStone />
                :
                pathname === 'watches' ?
                  <Watch />
                :
                pathname === 'wedding-bands' ?
                  <WeddingBand />
                :
                <EmptyPage />
            }
        </Fragment>
    )
}

export default Index
