import React, { useEffect, useState, Fragment } from 'react';
import { IMAGES } from '../../../constants';
import ProtectedHeader from '../../layout/ProtectedHeader';
import Sidebar from './Sidebar';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import MetaData from '../../layout/MetaData';
import { changePassword,  clearError, logout } from '../../../actions/userActions';
import { UPDATE_PASSWORD_RESET } from '../../../constants/userConstants';
import { validateFields } from '../../../validation';
import classnames from 'classnames';
import Loader from '../../layout/Loader';
import { FormattedMessage } from 'react-intl';
import { url } from '../../../config';
import { Link, useHistory } from 'react-router-dom';

const Otp = (props) => {

    const history = useHistory();
    const [otp, setOtp] = useState('');

    const [validerror, setValidError] = useState({
        otpError: ''
    })

    const alert = useAlert();
    const dispatch = useDispatch();

    const { error, isUpdated, loading } = useSelector(state => state.profile);

    useEffect(() => {

        if (error) {
            alert.error(error.message)
            dispatch(clearError());
        }

        if (isUpdated) {

            alert.success('Password has been changed successfully,please login with new password')
            setOtp('')
            setValidError({
                otpError:''
            })
            dispatch({
                type: UPDATE_PASSWORD_RESET
            })
            //logout
            dispatch(logout());
            localStorage.removeItem('isAuthenticated');
            sessionStorage.removeItem('user');
           // alert.success('Logout successful')
            window.location.href = url;

            //history.push(url)
        }
       
    }, [dispatch, error, isUpdated, alert])




    const handleSubmit = async (event) => {
        event.preventDefault();
        const otpError = validateFields.validateOtp(otp);

        if ([otpError].every(e => e === false)) {
            setValidError({
                ...validerror, otpError
            })
            await dispatch(changePassword(otp, props && props.location && props.location.customData && props.location.customData.new_password));
        }
        else {
            setValidError({
                ...validerror, otpError
            })
        }
    }


    return (
        <Fragment>
        {loading && <Loader />}
        <Fragment>
            <MetaData title={`Change password`} />
            <ProtectedHeader />
            <ul className="breadcrub">
                <li>
                    <FormattedMessage
                        id="myaccount.myaccountname"
                        defaultMessage="myaccount"
                    />
                </li>
                <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                <li>
                    <FormattedMessage
                        id="myaccount.changepassword"
                        defaultMessage="Change Password"
                    />

                </li>
            </ul>

            <section className="container-fluid py-4 conPart">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <Sidebar />
                        <div className="col-md-9">
                            <h2 className="mt-4 mb-3">
                                <FormattedMessage
                                    id="myaccount.changepassword"
                                    defaultMessage="Change Password"
                                />
                            </h2>
                            <div className="row">
                               <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor=""><FormattedMessage id="EnterOTP" defaultMessage="Enter OTP "/></label>
                                        <input type="text" placeholder="OTP" className={classnames("form-control", { 'is-valid': validerror.otpError === false }, { 'is-invalid': validerror.otpError })} value={otp || ''} onChange={(e) => setOtp(e.target.value)} />
                                        <p className="invalid-feedback">{validerror.otpError}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row actions my-3">
                                <div className="col-md-6">
                                    <button className="btns mr-3 sndBtn" type="submit"><FormattedMessage id="submit" defaultMessage="Submit"/></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </Fragment>
    </Fragment>
    )
}

export default Otp
