import {
    STRIPE_PAYMENT_REQUEST,
    STRIPE_PAYMENT_SUCCESS,
    STRIPE_PAYMENT_FAIL,
} from "../constants/StripeConstant"

const initialStateStripe = {
    payment_loading: false,
    payment_status: false,
    payment_data: null,
    payment_error: null
}

export const PurchasePlanStripeReducer = (state = initialStateStripe, action) => {
    switch (action.type) {
        case STRIPE_PAYMENT_REQUEST:
            return {
                ...state,
                payment_loading: true
            }
        case STRIPE_PAYMENT_SUCCESS:
            return {
                ...state,
                payment_loading: false,
                payment_status: true,
                payment_data: action.payload
            }
        case STRIPE_PAYMENT_FAIL:
            return {
                ...state,
                payment_loading: false,
                payment_status: false,
                payment_error: action.payload
            }
        default:
            return state
    }
}



