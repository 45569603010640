import React, { Fragment } from 'react';
import { useLocation } from 'react-router';
import WeddingBand from '../products/weddingBand';
import WhiteDimonds from '../products/whiteDimonds';
import Watch from '../products/watch';
import Parcel from '../products/parcels';
import ColorStone from '../products/colorStone';
import Jewelery from '../products/jewelery';
import { getLastURL } from '../../helper';
import EmptyPage from '../../components/layout/404';

const ProductRoute = () => {

    const location = useLocation();
    const path = getLastURL(location);

    return (
        <Fragment>
            {
                path === 'wedding-bands' ?
                <WeddingBand /> :
                path === 'diamonds' ?
                <WhiteDimonds /> :
                path === 'watches' ?
                <Watch /> :
                path === 'parcels' ?
                <Parcel /> :
                path === 'color-stones' ?
                <ColorStone /> :
                path === 'jewellery' ?
                <Jewelery /> :
                <EmptyPage />
            }
        </Fragment>
    )
}

export default ProductRoute

