import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../layout/Loader';
import { IMAGES } from '../../../constants';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { url } from '../../../config';
import { getLastURL, ImageUrl, numberWithCommas ,RatingDisplay ,ProductTitleDisplay} from '../../../helper';
import { addChatUserAction } from '../../../actions/chatAction';
import { AddFavoriteProduct, GetProductCount } from '../../../actions/productActions';
import $ from 'jquery';
import ReactStars from 'react-rating-stars-component';
import { FormattedMessage } from 'react-intl';
// LightGallery
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgThumbnail from 'lightgallery/plugins/thumbnail';

const DetailsPage = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const { details, loading } = useSelector(state => state.productDetails);

    const [image, setImage] = useState()
    const [imageKey, setImageKey] = useState(0)
    const { user } = useSelector(state => state.user);
    useEffect(() =>{
        if(details && details.productImages && details.productImages[0] && details.productImages[0].file){
            setImage(details && details.productImages && details.productImages[0] && details.productImages[0].file);
            $('.s-image').css('display','block');
            $('.s-video').css('display','none');
        }else if(details && details.singleVideo && details.singleVideo.video){
            $('.s-image').css('display','none');
            $('.s-video').css('display','block');

           
        }
       
    }, [details])

    const handleMessageSupplier = () => {
        //dispatch(addChatUserAction(JSON.parse(sessionStorage.getItem('user')).id))
        dispatch(addChatUserAction(details && details.productDetails && details.productDetails.seller_id))
        window.location = url + '/message?chat='+details.productDetails.seller_id;
        // history.push(url + '/message');
    }

    const handleAddFavorite = async () => {

        if($('.heart.checkheart').hasClass('active'))
        {
            $('.heart.checkheart').removeClass('active');
        }else{
            $('.heart.checkheart').addClass('active');
        }

        
        const product_id = getLastURL(location)
       await dispatch(AddFavoriteProduct(product_id));
        dispatch(GetProductCount());
    }


    const handleContactSupllier = () =>{
        $('#seller-info').toggle()
    }

    const handleImage = (path,type,filekey) => {
         setImage(path)
         if(type == 'image'){
             var video = document.getElementById('video');
             if(video){
                 video.pause();
             }
             $('.s-image').css('display','block')
             $('.s-video').css('display','none')
         }else{
             var video = document.getElementById('video');
             var source = document.createElement('source');
             source.setAttribute('src', ImageUrl(path));
             source.setAttribute('type', 'video/mp4');
             video.load();
             video.play();
             $('.s-image').css('display','none')
             $('.s-video').css('display','block')
         }
         setImageKey(filekey)
     }
     const showGallery = (filekey,type) => {
            $('#imageg-'+filekey).trigger('click');
     }
    return (
        <Fragment>
            {loading && <Loader />}
            <Fragment>
                <section className="details_page pt-5">
                    <div className="product_part pb-3">
                        <div className="container-fluid">
                            <div className="row">
                                 <div className="col-lg-7 col-md-6">
                                    <div className="like_btn">
                                        <span className="faon" style={{ cursor: 'pointer' }} onClick={handleAddFavorite}>
                                            <i className={details && details.productDetails && details.productDetails.is_favourite > 0 ? "fa fa-heart active heart checkheart" : "fa fa-heart heart checkheart"}></i>
                                        </span>
                                    </div> 
                                    <div className="pro_images">
                                        <div className="simpleLens-gallery-container " id="demo-1">
                                            <div className="simpleLens-thumbnails-container order-lg-0 order-2">
                                            {
                                            details && details.productImages && 
                                            <LightGallery plugins={[lgThumbnail,lgZoom, lgVideo]} mode="lg-fade"   download={false}  >
                                            {
                                                    details && details.productImages && details.productImages.slice(0, 5) && details.productImages.slice(0, 5).map((data, key) => {
                                                        return (    
                                                                <a
                                                                data-lg-size="1406-1390"
                                                                className="gallery-item "
                                                                data-src={ImageUrl(data.file)}
                                                                style={{display:'none'}}
                                                                >
                                                                <img 
                                                                 id={'imageg-'+key}
                                                                className="img-responsive"
                                                                src={ImageUrl(data.file)}
                                                                />
                                                            </a>
                                                        )
                                                    })
                                                }

                                                {
                                                    details && details.singleVideo && details.singleVideo.video &&
                                                     
                                                    <a
                                                        className="gallery-item"
                                                        key={5}
                                                        data-video={'{"source": [{"src":"'+ImageUrl(details.singleVideo.video)+'", "type":"video/mp4"}], "attributes": {"preload": false, "controls": true}}'}
                                                        style={{display:'none'}}
                                                    >
                                                        <img
                                                        style={{ maxWidth: '400px' }}
                                                        className="img-responsive"
                                                        alt=""
                                                        src={IMAGES.VideoDefaultThumb}
                                                        />
                                                    </a>

                                                           
                                                    
                                                 
                                                }
                                                </LightGallery>
                                        }
                                                {
                                                    details && details.productImages && details.productImages.slice(0, 5) && details.productImages.slice(0, 5).map((data, key) => {
                                                        return (
                                                            <a className="simpleLens-thumbnail-wrapper" onClick={() => handleImage(data.file ,'image',key)} >
                                                                <img src={ImageUrl(data.file)} alt="" />
                                                            </a>
                                                        )
                                                    })
                                                }
                                                {
                                                    details && details.singleVideo && details.singleVideo.video &&
                                                     
                                                            <a className="simpleLens-thumbnail-wrapper" onClick={() => handleImage(details.singleVideo.video ,'video')} >
                                                                <img src={IMAGES.VideoDefaultThumb} alt="" />
                                                            </a>
                                                    
                                                 
                                                }
                                            </div>
                                            <div className="simpleLens-container">
                                            <div className="simpleLens-big-image-container">
                                                    <div className="s-image">
                                                        <img src={ImageUrl(image)} className="simpleLens-big-image" onClick ={() => showGallery(imageKey,'image')} />
                                                    </div>
                                                    
                                                   { details && details.singleVideo && details.singleVideo.video &&
                                                   <div className="s-video" style={{'display':'none'}}>
                                                        <video id="video" width="600" height="400" controls>
                                                            <source src={ImageUrl( details.singleVideo.video)} type="video/mp4"/>
                                                            <source src={ImageUrl(details.singleVideo.video)}  type="video/ogg"/>
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                   
                                                    }
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-6">
                                <div className="d-xl-flex hwerating TitleBtns">
                                <a href={location.pathname+"#ratings-sec"} className="not_a text-capitalize" >
                                        <h6>{details && details.sellerDetails && details.sellerDetails.business && details.sellerDetails.business.company_name }</h6>
                                        <div> &nbsp; <i style={{color:"#003B75"}} className="fa fa-star"></i> &nbsp;{RatingDisplay('detail',details && details.productDetails && details.productDetails.average_rating ,details && details.productDetails && details.productDetails.total_rating )} </div>
                                        </a>
                                        <div className="average-rating d-flex ml-auto align-items-center">
                                       
                                            
                                            <button className="btn" data-toggle="modal" data-target="#comment"><FormattedMessage
                                                        id="addsingleproduct.WriteAReview"
                                                        defaultMessage="Write a review"
                                                    /></button>
                                        </div>
                                    </div>
                                <div className="mb-3 border-bottom">
                                   <h5 className="H_title">{ProductTitleDisplay('j',details)}</h5>
                               </div>
                                    <div className="pro_des">
                                        <div className="row">
                                           <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage
                                                                            id="JewelryType"
                                                                            defaultMessage="Jewelry Type"/></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details.productDetails.a_jewelry_type && details.productDetails.a_jewelry_type.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage id="JewelryStyle" defaultMessage="Jewelry Style"/></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details.productDetails.a_jewelry_style && details.productDetails.a_jewelry_style.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage id="JewelryBrand" defaultMessage="Jewelry Brand"/></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details.productDetails.a_brand && details.productDetails.a_brand.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage id="MetalWeight" defaultMessage="Metal Weight"/></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details.productDetails.metal_weight &&  parseFloat(details.productDetails.metal_weight).toFixed(2) +'gr' }</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage id="MetalType" defaultMessage="Metal Type"/></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details.productDetails.a_metal_type && details.productDetails.a_metal_type.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage id="GoldKarat" defaultMessage="Gold Karat"/></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && 
                                                       <FormattedMessage id="Karat" values={{ value: details.productDetails.gold_karat }} />
                                                    }</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage id="StoneCarat" defaultMessage="Stone Carat"/></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details.productDetails.stone_carat &&  parseFloat(details.productDetails.stone_carat).toFixed(2) }ct</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage id="StoneType" defaultMessage="Stone Type"/></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details.productDetails.a_stone_type && details.productDetails.a_stone_type.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage id="StoneShape" defaultMessage="Stone Shape"/></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details.productDetails.a_stone_shape && details.productDetails.a_stone_shape.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage id="StoneColor" defaultMessage="Stone Color"/></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details.productDetails.a_color && details.productDetails.a_color.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage id="StoneClarity" defaultMessage="Stone Clarity"/></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details.productDetails.a_clarity && details.productDetails.a_clarity.display_name}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage id="Measurements" defaultMessage="Measurements"/> </p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details.productDetails.measurements }</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-4">
                                                <div className="pro_d_text mb-4">
                                                    <p className="font-16 gray-75 mb-1"><FormattedMessage id="addsingleproduct.stockno" defaultMessage="Stock No."/></p>
                                                    <h4 className="font-16 fb black-3B">{details && details.productDetails && details.productDetails.stock_number}</h4>
                                                </div>
                                            </div>
                                            <hr className="w-100" />
                                            <div className="col-lg-12">
                                                <h4 className="font-16 black-3B fb mb-4"><FormattedMessage id="AdditionalItemInformation.stockno" defaultMessage="Additional item information"/></h4>
                                                <div className="tbles j_table mb-5">
                                                    <table width="100% ">
                                                        <tr>
                                                            <th>#</th>
                                                            <th><FormattedMessage id="Stone" defaultMessage="Stone"/></th>
                                                            <th><FormattedMessage id="Shape" defaultMessage="Shape"/></th>
                                                            <th><FormattedMessage id="addsingleproduct.color" defaultMessage="Color"/></th>
                                                            <th><FormattedMessage id="addsingleproduct.clarity" defaultMessage="Clarity"/></th>
                                                            <th><FormattedMessage id="Pieces" defaultMessage="Pieces"/></th>
                                                            <th><FormattedMessage id="addsingleproduct.carat" defaultMessage="Carat"/></th>
                                                        </tr>
                                                        {
                                                            details && details.productDetails && details.productDetails.jewellery_stones && details.productDetails.jewellery_stones.map((data, key) =>{
                                                                return(
                                                                    <tr>
                                                                    <td>S{key+1}</td>
                                                                    <td>{data && data.a_stone && data.a_stone.display_name}</td>
                                                                    <td>{data && data.a_shape && data.a_shape.display_name}</td>
                                                                    <td>{data && data.a_color && data.a_color.display_name}</td>
                                                                    <td>{data && data.a_clarity && data.a_clarity.display_name}</td>
                                                                    <td>{data && data.pieces}</td>
                                                                    <td>{parseFloat(data && data.carat).toFixed(2)}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                     
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-md-12 ">
                                                <div className="pro_d_text cmnt_box mb-4 ">
                                                    <p className="font-16 gray-75 mb-3 "><span className="mr-2 "><img
                                                        src={IMAGES.COMMENTSVG} alt=" " /></span> <FormattedMessage id="Comments" defaultMessage="Comments"/></p>
                                                    <h4 className="font-16 fb black-3B ">{details && details.productDetails && details.productDetails.comment}</h4>
                                                </div>
                                            </div>
                                            <div className="col-md-12 ">
                                                <div className="c_price ">
                                                    <div className="c_pri_d price_box ">
                                                        <div className="pro_d_text mb-4 ">
                                                            <p className="font-16 gray-75 mb-1 "><FormattedMessage
                                                        id="TotalPrice"
                                                        defaultMessage="Total Price"
                                                    /></p>
                                                            <h4 className="font-20 fb black-3B mb-0">${details && details.productDetails && numberWithCommas(parseFloat(details.productDetails.price ).toFixed(2))}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="add_btn ">
                                                    {details && details.productDetails && user && user.id != details.productDetails.seller_id &&
                                                        <a href="javascript:void(0)" className="btn btn-black-outline" onClick={handleMessageSupplier}><FormattedMessage
                                                        id="MessageSupplier"
                                                        defaultMessage="Message supplier"
                                                    />  </a>
                                                        }
                                                        <a onClick={handleContactSupllier} className="btn btn-black "><FormattedMessage
                                                        id="ContactSupplier"
                                                        defaultMessage="Contact supplier"
                                                    /></a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <hr />
            </Fragment>
        </Fragment>
    )
}

export default DetailsPage
