import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IMAGES } from '../../../constants';

const MessageSent = () => {
    return (
        <div className="col-md-9 pt-4 conthanks" id="conthanks">
        <div className="titles mb-4 nbrd">
            <div className="row">
                <div className="col-md-12">
                    <h2 className="m-0"><FormattedMessage
                                            id="messageSentSuccess"
                                            defaultMessage="Message sent successfully!"
                                        /></h2>
                </div>

            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <img src={IMAGES.CONTACT_SVG} className="float-left" />
                <p><FormattedMessage id="contactThanks"/></p>
            </div>
        </div>
    </div>
    )
}

export default MessageSent
