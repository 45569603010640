import React, { Fragment, useContext , useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import { logout } from '../../actions/userActions';
import { IMAGES } from '../../constants';
import { url } from '../../config'
import { FormattedMessage } from 'react-intl';
import { Context } from "../Wrapper";
import { handleLangList, handleSortBy,handlephoneList} from '../../helper';
import $ from 'jquery';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
const Header = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const alert = useAlert();
    console.log("location===", location)
    const { user, loading } = useSelector(state => state.user);
    const context = useContext(Context);
    const handleLogout = () => {
        dispatch(logout());
        localStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('user');
        alert.success('Logout successful')
        window.location.href = url;
    }



    const handleAcendingFilter = (value) => {
        //setFilterDisplayName(displayname)
        $('.sort_by_list').css({
            display: "none"
        })
        context.selectLanguage(value)
       // dispatch(myProductFilterByAscending(category_slug, value, type))
    }

    useEffect(() => {
        if(user && location?.pathname !== '/terms&conditions')
        {
            window.location.href = url+'/category/diamonds';
        }
    }, [user])

    const handleResForm = (status) => {
        $("#dismiss-modal").trigger('click');
    }
    const handleContactUsPopUp= (event) => {
        $("#popup-click-confirm").trigger('click');
    }
    return (
        <div className="bg_img">
            <header className="homes">
                <div className="container-fluid  d-flex align-items-center justify-content-between">
                    <nav className="navbar navbar-expand-lg py-3 w-100">

                        <Link to={url} className="logo"> <img src={IMAGES.WHITE_LOGO} alt="" /></Link>
                      
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                       {/* 
                        <div className="text-center header-nav">
                        <ul>
                            <li><a href={url + "#whyus"}>
                                <FormattedMessage
                                    id="main.header.item1"
                                    defaultMessage="Why Cevaheer?"
                                />
                            </a></li>
                            <li><a href={url + "#pricings"}>
                                <FormattedMessage
                                    id="main.header.item2"
                                    defaultMessage="Pricing"
                                />
                            </a></li>
                            <li><a href={url + "#testimonails"}>
                                <FormattedMessage
                                    id="main.header.item3"
                                    defaultMessage="Testimonials"
                                />
                            </a></li>
                            <li><a href={url + "#nUsers"}>
                                <FormattedMessage
                                    id="main.header.item4"
                                    defaultMessage="Number of Members"
                                />
                            </a></li>
                        </ul>
                        </div> */}
                       
                        <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                            <ul className="navbar-nav list-style-none d-flex justify-content-center align-items-center header-menus pl-3">
                                <li className="nav-item active">
                                    <a href={url + "#whyus"}>
                                        <FormattedMessage
                                            id="main.header.item1"
                                            defaultMessage="Why Cevaheer?"
                                        />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href={url + "#pricings"}>
                                        <FormattedMessage
                                            id="main.header.item2"
                                            defaultMessage="Pricing"
                                        />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href={url + "#testimonails"}>
                                        <FormattedMessage
                                            id="main.header.item3"
                                            defaultMessage="Testimonials"
                                        />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href={url + "#nUsers"}>
                                        <FormattedMessage
                                            id="main.header.item4"
                                            defaultMessage="Numbers"
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>

                        {
                            user || localStorage.getItem('isAuthenticated') ? (
                                <div className=" right order-md-2 order-3 filter_search_right">
                                    <div className="btn-groups  search_cat_top nav_btns">
                                        <div className="form-group pb-0 sort_by_box" >
                                            <p className="sort_b_h text-right lang_p" onClick={handleLangList} ><b className="mr-2"><i className="fa fa-globe"></i></b> </p>
                                            <div className="lang_list" >
                                                {
                                                    <ul>
                                                        <li className={context.locale == 'en' && 'active'} onClick={() => handleAcendingFilter('en')} ><i className="fa fa-globe"></i> English</li>
                                                        <li className={context.locale == 'tr' && 'active'} onClick={() => handleAcendingFilter('tr')}><i className="fa fa-globe"></i> Turkish</li>
                                                    </ul>
                                                }
                                            </div>
                                        </div>

                                        {/* <div className="language">
            <select value={context.locale} onChange={context.selectLanguage}>
            <option value='en-US'>{<i className="fas fa-globe"></i>} English</option>
            <option value='tr'><i className="fas fa-globe"></i> Turkish</option>
            </select>
        </div> */}
                                        <Link to={url + "/contact-us"}>
                                            <FormattedMessage
                                                id="main.header.contactsales"
                                                defaultMessage="Contact Sales"
                                            />
                                        </Link>
                                        <Link to={url + "/profile"} className="MyProfiles">
                                            <FormattedMessage
                                                id="main.header.myprofile"
                                                defaultMessage="My Profile"
                                            />
                                        </Link>
                                        <Link to={url + "/"} onClick={handleLogout} className="signups">
                                            <FormattedMessage
                                                id="main.header.logout"
                                                defaultMessage="Logout"
                                            />
                                        </Link>
                                    </div>
                                </div>
                            ) : !loading &&
                            <Fragment>
                                <div className="right order-md-2 order-3 filter_search_right">
                                    <div className="btn-groups search_cat_top nav_btns">
                                        

                                        <div className="form-group pb-0 sort_by_box"   >
                                            <p className="sort_b_h text-right lang_p" onClick={handleLangList} ><b className="mr-2" ><i className="fa fa-globe"></i></b> </p>
                                            <div className=" lang_list" >
                                                {
                                                    <ul>
                                                        <li className={context.locale == 'tr' && 'active'} onClick={() => handleAcendingFilter('tr')}><i className="fa fa-globe"></i> Turkish</li>
                                                        <li className={context.locale == 'en' && 'active'} onClick={() => handleAcendingFilter('en')} ><i className="fa fa-globe"></i> English</li>
                                                    </ul>
                                                }
                                            </div>
                                        </div>
                                        
                                        <Link onClick={handleContactUsPopUp}>
                                            <FormattedMessage
                                                id="main.header.contactsales"
                                                defaultMessage="Contact Sales"
                                            />
                                        </Link>
                                        <Link to={url + "/login"}>
                                            <FormattedMessage
                                                id="main.header.signin"
                                                defaultMessage="Sign In"
                                            />
                                        </Link>

                                        <div className="form-group pb-0 sort_by_box"   >
                                            <p className="sort_b_h text-right lang_p" onClick={handlephoneList}><b className="mr-2" ><i className="fas fa-phone"></i></b></p>
                                            <div className="phone_lists" >
                                                {
                                                    <ul>
                                                        <li className=""><i><img src={url + "/static/media/turkey.009996bfa350133ea7d1.png"} alt="Country Flag" width="25px" /></i> {"0530 235 9078"}</li>
                                                    </ul>
                                                }
                                            </div>
                                        </div>
                                        
                                        
                                        
                                        <Link to={url + "/register"} className="signups">
                                            <FormattedMessage
                                                id="main.header.signup"
                                                defaultMessage="Sign Up"
                                            />
                                        </Link>
                                        
                                    </div>

                                    <button className="navbar-toggler p-0 collapsed ml-3" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <i className="fa fa-bars" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </Fragment>
                        }
                    </nav>
                </div>
            </header>
        </div>
    )
}

export default Header

