import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { url } from '../../config';
import { useSelector, useDispatch } from 'react-redux';
import { IMAGES } from '../../constants';
import ProtectedHeader from '../layout/ProtectedHeader';
import { FormattedMessage } from 'react-intl';
import * as XLSX from 'xlsx';
import Watch from '../editProducts/Watch';
import Dimonds from '../editProducts/Diamonds';
import Parcel from '../editProducts/Parcels';
import Jewellery from '../editProducts/Jewellery';
import ColorStone from '../editProducts/ColorStone';
import WeddingBand from '../editProducts/WeddingBand';
import classnames from 'classnames';
import { validateFields } from '../../validation';
import { addExcelProduct, GetProductCount, clearMessage } from "../../actions/productActions";
import { useAlert } from 'react-alert';
import { decryptData, generateTemplate } from '../../helper';
import $ from 'jquery';
import ProgressLoader from '../layout/ProgressLoader';
import PopupSucess from '../layout/PopupSucess';
import PopupFail from './PopupFail';
import { clearError } from '../../actions/userActions';
import { socket } from '../../utils/socket';
import { getCategory } from '../../actions/categoryAction';
import Loader from '../layout/Loader';

const Index = () => {

    const dispatch = useDispatch()
    const alert = useAlert()

    const [productTypeName, setProductTypeName] = useState('Product');
    const [productType, setProductType] = useState();
    const [productTypeSlug, setProductTypeSlug] = useState();
    let [excel_products, setExcelProducts] = useState();
    const [singleExcelData, setSingleExcelData] = useState();
    const [currentEditIndex, setCurrentEditIndex] = useState();
    const [download, setDownload] = useState(false);
    const [excelTemplate, setExcelTemplate] = useState();
    const [excelFileName, setExcelFileName] = useState();

    const [validerror, setValidError] = useState({
        categoryError: "",
        excelError: ""
    });

    const { category } = useSelector(state => state.categories);
    const { data, loading, import_error, errormsg, error } = useSelector(state => state.productAdd);

    const handleProductType = (event) => {
        console.log('event.target.options[event.target.selectedIndex].dataset >>>>', event.target.options[event.target.selectedIndex].dataset);
        setProductType(event.target.value);
        setProductTypeName(event.target.options[event.target.selectedIndex].dataset.name);
        setProductTypeSlug(event.target.options[event.target.selectedIndex].dataset.slug);
        if (event.target.options[event.target.selectedIndex].dataset.dealer_can_add == 1) {
            $('#single-product-upload').css({
                display: 'block'
            });
            $('.brdr-top').removeClass('border-0');
        } else {
            $('#single-product-upload').css({
                display: 'none'
            });
            $('.brdr-top').addClass('border-0');
        }
    }

    const handleUpload = (event) => {
        if (productType) {
            setExcelProducts('');
            const file = event?.target?.files[0];
            setExcelFileName(file.name);
            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file)
                fileReader.onload = (e) => {
                    const bufferArray = e?.target?.result;
                    const wb = XLSX?.read(bufferArray, { type: 'buffer' });
                    const wsname = wb?.SheetNames[0];
                    const ws = wb?.Sheets[wsname];
                    const data = XLSX?.utils?.sheet_to_json(ws);
                    resolve(data)
                }
                fileReader.onerror = ((error) => {
                    reject(error)
                })
            })
            promise.then((d) => {
                let newArr = []
                d && d.map((data, key) => {
                    let obj = {}
                    for (var i in data) {
                        var j = i.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "").trim().replace(/\s+/g, "_");
                        obj[j] = data[i];
                    }
                    newArr[key] = obj;
                })
                setExcelProducts(newArr);
            })
        }
        else {
            setExcelProducts('');
            alert.error('Please select Product Type before uploading excel sheet');
            return;
        }
    };

    const handleEditExcel = (data, key) => {
        setSingleExcelData(data);
        setCurrentEditIndex(key);
    }

    const handleEditExcelForm = (addProduct) => {
        excel_products = excel_products && excel_products.map((prev, key) => key === currentEditIndex ? addProduct : prev);
        setExcelProducts(excel_products);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const categoryError = validateFields.validateProductType(productTypeSlug);
        const excelError = validateFields.validateExcel(excel_products);

        if ([categoryError, excelError].every(e => e === false)) {
            setValidError({
                ...validerror, categoryError, excelError
            })

            let addData = {
                category_slug: productTypeSlug,
                import: 1,
                excel_products: excel_products
            }
            await dispatch(addExcelProduct(addData))
            dispatch(GetProductCount());
            dispatch(getCategory());
        }
        else {
            setValidError({
                ...validerror, categoryError, excelError
            })
        }
        return;
    }

    const handleDeleteExcelItem = (key) => {
        //excel_products && excel_products.splice(key, 1);
        const newExcelData = excel_products && excel_products.filter((data, key2) => key2 !== key);
        setExcelProducts(newExcelData);
    }

    const handleCancel = () => {
        setExcelProducts('');
        setProductType('');
        setProductTypeName('Product')
        setProductTypeSlug('')
        setExcelFileName('')
    }

    const handleClick = event => {
        const { target = {} } = event || {};
        target.value = "";
    }


    const handleDownloadExcelTemplate = () => {

        if (productTypeSlug) {
            setExcelTemplate(generateTemplate(productTypeSlug));
            setDownload(true);
        }
        else {
            alert.error('Please select Product Type before download excel template');
            return;
        }

    }

    useEffect(() => {

        if (loading) {
            $('body').addClass('progress-loader')
        }

    }, [loading])


    useEffect(() => {
        if (data) {

            $("#popup-click-sucess").trigger('click');
            // alert.success(message)
            dispatch(clearMessage());
            setExcelProducts('');
            setProductType('');
            setProductTypeName('Product');
            setProductTypeSlug('');
            setExcelFileName('');
            $('body').removeClass('progress-loader');
            setTempData(0)
            setTimeout(() => {
                window.location = url + '/myproducts/diamonds';
            }, 3000);
        }

        if (error || import_error) {
            $("#popup-click-fail").trigger('click');
            $('body').removeClass('progress-loader');
            dispatch(clearError());
        }
    }, [data, error, import_error])


    const singularize = (word) => {
        const endings = {
            ves: 'fe',
            ies: 'y',
            i: 'us',
            zes: '',
            ses: '',
            es: '',
            s: ''
        };
        return word.replace(
            new RegExp(`(${Object.keys(endings).join('|')})$`),
            r => endings[r]
        );
    }
    const history = useHistory();
    $('.upgrade-redirect').on('click', function (params) {
        history.push(url + `/plan-details`);
        $("#dismiss-modal").trigger('click')
    })

    const [tempData, setTempData] = useState(0);

    setInterval(() => {
        if (socket != undefined) {
            socket.connected = true;
        }
        // socket.disconnected = false;
    }, 2000)

    if (socket != undefined) {        
        socket.on('progress_count', res => {
            // setTempData(decryptData(res))
            setTempData(res)
        })
    }
    
    return (
        <Fragment>
            {loading && <ProgressLoader tempData={tempData} />}
            <Fragment>
                <ProtectedHeader />
                <ul className="breadcrub">
                    <li>
                        <FormattedMessage
                            id="myaccount.myaccountname"
                            defaultMessage="My Account"
                        />
                    </li>
                    <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                    <li>
                        <FormattedMessage
                            id="Products"
                            defaultMessage="Products"
                        />
                    </li>
                    <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                    <li>
                        <FormattedMessage
                            id="addsingleproduct.addproduct"
                            defaultMessage="Add Product"
                        />
                    </li>
                </ul>
                <form onSubmit={handleSubmit}>
                    <section className="container-fluid py-4 conPart">
                        <div className="row justify-content-center">
                            <div className="col-md-9">
                                <div className="titles nbrd mt-4 mb-3 pb-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h2 className="m-0">
                                                <FormattedMessage
                                                    id="addsingleproduct.addproduct"
                                                    defaultMessage="Add Product"
                                                />
                                            </h2>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{ borderBottom: "solid 1px #DADADA" }}>
                                    <div className="col-lg-4">
                                        <div className="form-group">
                                            <label htmlFor="">
                                                <FormattedMessage
                                                    id="addsingleproduct.producttype"
                                                    defaultMessage="Product Type"
                                                />
                                            </label>
                                            <select
                                                className={classnames("form-control", { 'is-valid': validerror.categoryError === false }, { 'is-invalid': validerror.categoryError })}
                                                onChange={handleProductType}
                                                value={productType}>
                                                <FormattedMessage id="addsingleproduct.choose" defaultMessage="Choose">
                                                    {(id) => <option value="">{id}</option>}
                                                </FormattedMessage>
                                                {
                                                    category && category.categories && category.categories.map((category) => {
                                                        return (
                                                            <FormattedMessage 
                                                            id={"category." + category.slug} 
                                                            defaultMessage={category.name}>
                                                                {(id) => <option data-dealer_can_add={category.dealer_can_add} data-slug={category.slug} data-name={singularize(category.name).toLowerCase()} value={category.id}>{id}</option>}
                                                            </FormattedMessage>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <p className="invalid-feedback">{validerror.categoryError}</p>
                                        </div>
                                    </div>
                                </div>

                                <div id="single-product-upload" style={{ display: 'none' }}>
                                    <h3 className="forms">
                                        <FormattedMessage
                                            id="addsingleproduct.upload-single"
                                            defaultMessage="Upload Single"
                                        />
                                        <span>{` `}</span>
                                        <p>
                                            <FormattedMessage
                                                id="addsingleproduct.upload-single"
                                                defaultMessage="Upload single"
                                            />
                                            <span>{` `}</span>
                                        </p>
                                    </h3>
                                    <div className="row actions my-4">
                                        <div className="col-xl-4 col-md-6 col-sm-6 ">
                                            <Link to={url + '/add-single-product?cat=' + productTypeSlug}>
                                                <button type="button" className="btns" style={{ width: "100%" }}>
                                                    <FormattedMessage
                                                        id="addsingleproduct.add-a-single"
                                                        defaultMessage="Add a single"
                                                    />
                                                    <span>{` `}</span>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <h3 className="forms brdr-top border-0 pt-4">
                                    <FormattedMessage
                                        id="addsingleproduct.upload-excel-file"
                                        defaultMessage="Upload Excel File"
                                    />
                                    <p>
                                        <FormattedMessage
                                            id="addsingleproduct.excel-info"
                                            defaultMessage="Upload the product information from the Excel File."
                                        />
                                        <span>
                                            <span>{` `}</span>
                                            <a href={excelTemplate} style={{ cursor: 'pointer' }} onClick={handleDownloadExcelTemplate} download={download ? true : false}>
                                                <FormattedMessage
                                                    id="addsingleproduct.excel-download"
                                                    defaultMessage="Download Excel Template"
                                                />
                                                <img src={IMAGES.DOWNLOAD_SVG} />
                                            </a>
                                        </span>
                                    </p>
                                </h3>

                                <div className="row imgEdits mb-4">
                                    <div className="col-md-4">
                                        <div className={classnames("form-control moreUpload", { 'is-valid': validerror.excelError === false }, { 'is-invalid': validerror.excelError })}>

                                            <span><img src={IMAGES.DOWNLOAD_SVG} alt="" /></span>
                                            <FormattedMessage
                                                id="addsingleproduct.drop-excel"
                                                defaultMessage="Drop Excel File"
                                            />
                                            <input type="file" accept=".xlsx, .xls, .csv" onChange={handleUpload} onClick={handleClick} />
                                        </div>
                                        <p>{excelFileName}</p>
                                        <p className="invalid-feedback">{validerror.excelError}</p>
                                    </div>
                                </div>

                                <h3 className="forms brdr-top pt-4 heading_count d-flex align-items-center">
                                    <FormattedMessage
                                        id="addsingleproduct.items"
                                        defaultMessage="Items"
                                    />
                                    <span>{excel_products && excel_products.length || 0}</span>
                                </h3>

                                <div className="items_list">
                                    {
                                        excel_products && excel_products.map((data, key) => {
                                            return (
                                                <div className="item_list1" key={key}>
                                                    <h4>{data.stock_no}</h4>
                                                    <a href="javascript:void(0)" data-toggle="modal" data-target={productTypeSlug === 'diamonds' ? "#editproductdiamond" : productTypeSlug === 'watches' ? "#editproductwatch" : productTypeSlug === 'parcels' ? '#editproductparcel' : productTypeSlug === 'jewellery' ? '#editproductjewellery' : productTypeSlug === 'color-stones' ? '#editproductcolorstone' : productTypeSlug === 'wedding-bands' ? '#editproductweddingband' : null} onClick={(e) => handleEditExcel(data, key)}>Edit details</a>
                                                    <span className="icon-close_1" style={{ cursor: 'pointer' }} onClick={(e) => handleDeleteExcelItem(key)}></span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className="w_btn">
                                    <button className="btn btn-white mb-2 mr-2" type="button" onClick={handleCancel}>
                                        <FormattedMessage
                                            id="addsingleproduct.cancel"
                                            defaultMessage="Cancel"
                                        />
                                    </button>
                                    <button className="btn btn-blue mb-2" type="submit">
                                        <FormattedMessage
                                            id="addsingleproduct.addproducts"
                                            defaultMessage="Add Products"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </form>

                {
                    singleExcelData && productTypeSlug === 'watches' ?
                        <Watch props={singleExcelData} handleEditExcelForm={handleEditExcelForm} />
                        :
                        singleExcelData && productTypeSlug === 'diamonds' ?
                            <Dimonds props={singleExcelData} handleEditExcelForm={handleEditExcelForm} />
                            :
                            singleExcelData && productTypeSlug === 'parcels' ?
                                <Parcel props={singleExcelData} handleEditExcelForm={handleEditExcelForm} />
                                :
                                singleExcelData && productTypeSlug === 'jewellery' ?
                                    <Jewellery props={singleExcelData} handleEditExcelForm={handleEditExcelForm} />
                                    :
                                    singleExcelData && productTypeSlug === 'color-stones' ?
                                        <ColorStone props={singleExcelData} handleEditExcelForm={handleEditExcelForm} />
                                        :
                                        singleExcelData && productTypeSlug === 'wedding-bands' ?
                                            <WeddingBand props={singleExcelData} handleEditExcelForm={handleEditExcelForm} />
                                            :
                                            null
                }
            </Fragment>
            {
                import_error &&
                <PopupFail title={'UPLOADING ERROR'} props={errormsg} />
            }

            {
                (data && !import_error) &&
                <PopupSucess title={'SUCCESS'} props={data} />
            }

            {
                error &&
                <PopupFail title={'UPLOADING ERROR'} props={error} />
            }

        </Fragment>
    )
}

export default Index
