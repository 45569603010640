import React, { useEffect, useContext } from 'react';
import './App.css';
import './assets/css/style.css';
import './assets/mycss/dloader.css';
import './assets/mycss/error.css';
import './assets/mycss/style.css';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-video.css';
import {TOKEN, url} from './config';
import { BrowserRouter as Router, Redirect, Route ,Switch} from 'react-router-dom';
import Home from './components/Home';
import Login from './components/user/Login';
import Register from './components/user/registrationProcess/Register';
import Registration from './components/user/registrationProcess';
import store from './store';
import { loadUser } from './actions/userActions';
import ForgetPassword from './components/user/ForgetPassword';
import ProtectedRoute from './components/route/ProtectedRoute';
import SupplierProtectedRoute from './components/route/SupplierProtectedRoute';
import PlanProtectedRoute from './components/route/PlanProtectedRoute';
import AddProduct from './components/addProducts';
import ContactUs from './components/user/myAccount/ContactUs';
import Profile from './components/user/myAccount/Profile';
import ChangePassword from './components/user/myAccount/ChangePassword';
import PaymentInfo from './components/user/myAccount/PaymentInfo';
import NewPayment from './components/user/myAccount/NewPayment';
import PlanDetails from './components/user/myAccount/PlanDetails';
import ProductRoute from './components/route/ProductRoute';
import MyProducts from './components/user/myProducts';
import Favorites from './components/user/favorites';
import AddSingleProduct from './components/addProducts/addSingleProduct';
import Privacy from './components/HomePage/Privacy';
import TermCondition from './components/HomePage/Term&Condition';
import Chat from './components/user/chat';
import QRcode from './components/product/QRcode';
import IyzipayComponent from './components/product/Iyzipay';
import DetailsPage from './components/detailsProducts';
import { Context } from "./components/Wrapper";
import Lens from './components/product/Lens';
import OwlCrousel from './components/product/OwlCrousel';
import Otp from './components/user/myAccount/Otp';
import EditSingleProduct from './components/editSingleProduct';
import EditProduct from './components/editProducts';
import Advertisement from './components/advertisement';
import AdvertisementPlanDetails from './components/advertisePlanDetail';
import AdvertisedProducts from './components/advertisedProducts';
import EmptyPage from './components/layout/404';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import Confirmation from './components/user/registrationProcess/Confirmation';
import StripeForm from './components/stripe/StripeForm';
mixpanel.init('b00914fe4bd3518056747df88e6af79a', {
  debug: true
});

function App() {

  const context = useContext(Context);

  useEffect(() => {
    if (TOKEN) {
      store.dispatch(loadUser())
    }
  })
   

  var uuid = localStorage.getItem("uuid");
  var lang = localStorage.getItem("lang");
  var country = localStorage.getItem("country");
  if(lang == null){
    localStorage.setItem("lang",'tr');
  }
  if(country ===null){
    localStorage.setItem("country",'turkey');
  }

  const date = moment.utc().format();
  const local = moment.utc(date).local().format();


 
  return (
    <div className="App">   
      <Router>
        <Switch>
            {/* <Route exact path={url} component={Home} /> */}
            {/* <Route exact path={url + "/purchase-subscription-plan"} component={IyzipayComponent} /> */}
            {/* <Route exact path={url + "/purchase-subscription-plan"} component={StripeForm} /> */}
            <Route exact path={url + "/payment-success"} component={Confirmation} />


            <Route exact path={url + "/"} component={Home} />
            <Route exact path={url + "/login"} component={Login} />
            <Route exact path={url + "/register"} component={Register} />
            <Route exact path={url + "/forget-password"} component={ForgetPassword} />
            <ProtectedRoute exact path={url + "/profile"} component={Profile} />
            <ProtectedRoute exact path={url + "/change-password"} component={ChangePassword} />
            <ProtectedRoute exact path={url + "/payment-history"} component={PaymentInfo} />
            <ProtectedRoute exact path={url + "/new-payment"} component={NewPayment} />
            <ProtectedRoute exact path={url + "/contact-us"} component={ContactUs} />
            <PlanProtectedRoute exact path={url + "/category/:slug"} component={ProductRoute} />
            <SupplierProtectedRoute exact path={url + "/add-product"} component={AddProduct} />
            <ProtectedRoute exact path={url + "/business-info"} component={Registration} />
            <ProtectedRoute exact path={url + "/plan-details"} component={PlanDetails} />
            <SupplierProtectedRoute exact path={url + "/myproducts/:slug"} component={MyProducts} />  
            <ProtectedRoute exact path={url + "/favorites/:slug"} component={Favorites} /> 
            <ProtectedRoute exact path={url + "/add-single-product"} component={AddSingleProduct} /> 
            <ProtectedRoute exact path={url + "/message"} component={Chat} /> 
            <PlanProtectedRoute exact path={url + "/details/:category/:id"} component={DetailsPage} /> 
            <ProtectedRoute exact path={url + "/otp"} component={Otp} /> 
            <ProtectedRoute exact path={url + "/edit-product/:id"} component={EditSingleProduct} /> 
            <ProtectedRoute exact path={url + "/edit-full-product/:id"} component={EditProduct} /> 
            <ProtectedRoute exact path={url + "/advertisement/:slug"} component={Advertisement} /> 
            <ProtectedRoute exact path={url + "/advertise-plan-details/:slug"} component={AdvertisementPlanDetails} /> 
            <ProtectedRoute exact path={url + "/advertised-products"} component={AdvertisedProducts} /> 

            <Route exact path={url + "/privacy"} component={Privacy} />
            <Route exact path={url + "/terms&conditions"} component={TermCondition} /> 
            <Route exact path={url + '/qrcode'} component={QRcode} />
            <Route exact path={url + '/lens'} component={Lens} />
            <Route exact path={url + '/owl-crousel'} component={OwlCrousel} />
            {/* <Route path={url + '/404'} component={EmptyPage} /> */}
            <Route path='*' exact={true} component={EmptyPage} />
            {/* <Route component={EmptyPage} /> */}
            {/* <Redirect from='*' to={url + '/404'} /> */}
          </Switch>
      </Router>
    </div>
  );
}

export default App;
