export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'

export const USER_LOAD_REQUEST = 'USER_LOAD_REQUEST'
export const USER_LOAD_SUCCESS = 'USER_LOAD_SUCCESS'
export const USER_LOAD_FAIL = 'USER_LOAD_FAIL'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_RESET = 'UPDATE_PROFILE_RESET'
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_RESET = 'UPDATE_PROFILE_RESET'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'

export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST'
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL'

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL'

export const GET_PLAN_REQUEST = 'GET_PLAN_REQUEST'
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS'
export const GET_PLAN_FAIL = 'GET_PLAN_FAIL'

export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST'
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS'
export const GET_COUNTRY_FAIL = 'GET_COUNTRY_FAIL'

export const GET_STATE_REQUEST = 'GET_STATE_REQUEST'
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS'
export const GET_STATE_FAIL = 'GET_STATE_FAIL'

export const GET_CITY_REQUEST = 'GET_CITY_REQUEST'
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS'
export const GET_CITY_FAIL = 'GET_CITY_FAIL'

export const SUBMIT_BUSINESSINFO_REQUEST = 'SUBMIT_BUSINESSINFO_REQUEST'
export const SUBMIT_BUSINESSINFO_SUCCESS = 'SUBMIT_BUSINESSINFO_SUCCESS'
export const SUBMIT_BUSINESSINFO_FAIL = 'SUBMIT_BUSINESSINFO_FAIL'
 
export const SUBMIT_PAYMENTINFO_REQUEST = 'SUBMIT_PAYMENTINFO_REQUEST'
export const SUBMIT_PAYMENTINFO_SUCCESS = 'SUBMIT_PAYMENTINFO_SUCCESS'
export const SUBMIT_PAYMENTINFO_FAIL = 'SUBMIT_PAYMENTINFO_FAIL'

export const CONTACT_US_REQUEST = 'CONTACT_US_REQUEST'
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS'
export const CONTACT_US_FAIL = 'CONTACT_US_FAIL'

export const CURRENT_PLAN_REQUEST = 'CURRENT_PLAN_REQUEST'
export const CURRENT_PLAN_SUCCESS = 'CURRENT_PLAN_SUCCESS'
export const CURRENT_PLAN_FAIL = 'CURRENT_PLAN_FAIL'

export const GET_PLAN_ROLE_REQUEST = 'GET_PLAN_ROLE_REQUEST'
export const GET_PLAN_ROLE_SUCCESS = 'GET_PLAN_ROLE_SUCCESS'
export const GET_PLAN_ROLE_FAIL = 'GET_PLAN_ROLE_FAIL'

export const SEND_OTP_REQUEST = 'SEND_OTP_REQUEST'
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS'
export const SEND_OTP_FAIL = 'SEND_OTP_FAIL'
export const SEND_OTP_RESET = 'SEND_OTP_RESET'

export const ADD_REVIEW_REQUEST = 'ADD_REVIEW_REQUEST'
export const ADD_REVIEW_SUCCESS = 'ADD_REVIEW_SUCCESS'
export const ADD_REVIEW_FAIL = 'ADD_REVIEW_FAIL'
export const ADD_REVIEW_RESET = 'ADD_REVIEW_RESET'

export const GET_REVIEW_REQUEST = 'GET_REVIEW_REQUEST'
export const GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS'
export const GET_REVIEW_FAIL = 'GET_REVIEW_FAIL'

export const PAYMENTHISTORY_REQUEST = 'PAYMENTHISTORY_REQUEST'
export const PAYMENTHISTORY_SUCCESS = 'PAYMENTHISTORY_SUCCESS'
export const PAYMENTHISTORY_FAIL = 'PAYMENTHISTORY_FAIL'

export const CANCEL_SUBSCRIPTION_REQUEST = 'PAYMENTHISTORY_REQUEST'
export const CANCEL_SUBSCRIPTION_SUCCESS = 'PAYMENTHISTORY_SUCCESS'
export const CANCEL_SUBSCRIPTION_FAIL = 'PAYMENTHISTORY_FAIL'


export const CLEAR_ERRORS = 'CLEAR_ERRORS' 
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'