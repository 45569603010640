import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { IMAGES } from '../../../constants';
import MetaData from '../../layout/MetaData';
import { url } from '../../../config';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { toast, ToastContainer } from 'react-toastify';
import { loadUser, logout } from '../../../actions/userActions';


const Confirmation = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        $('#busi-info-sidebar').addClass('active visited')
        $('#bill-info-sidebar').addClass('active visited')
    }, [])

    useEffect(() => {
        dispatch(loadUser({}))
    }, [])

    const user = useSelector(state => state.user)



    useEffect(() => {
        setTimeout(() => {
            dispatch(logout());
            localStorage.removeItem('isAuthenticated');
            sessionStorage.removeItem('user');
        }, 1000);
    }, [])

    const handleClick = () =>{
        window.location.href =url
    }
    return (
        <Fragment>
            <MetaData title={`confirmation`} />
            <div className="card2 ml-2">
                <div className="row px-3 mt-3 justify-content-center">
                    <div className="col-md-6 ">
                        <img src={IMAGES.LOGO_BLACK} alt="" className="d-block m-auto" />
                        {/* <img src={IMAGES.WHITE_LOGO} alt="" className="d-block m-auto" /> */}

                        <div className="confrim_box">
                            <div className="con_1">
                                <span className="co_t"><i className="icon-check-mark_2"></i></span>
                                <h4 className="font-24  black-3B fm">{user?.user?.name}
                                    <br />
                                    <FormattedMessage
                                        id="confirm.congratulations"
                                        defaultMessage="Congratulations!!"
                                    />
                                </h4>
                                <p className="font-20 black-3B fr">
                                    <FormattedMessage
                                        id="confirm.message"
                                        defaultMessage="Your account has been successfully set up!"
                                    />
                                </p>
                            </div>
                            <div className="con_2">
                                <p className="email"><span>{user?.user?.email}</span> <img src="images/edit.png" alt="" /></p>
                                <p>
                                    <FormattedMessage
                                        id="confirm.message-tag"
                                        defaultMessage="We will notify your activation very soon by email after we review your documents"
                                    />
                                </p>
                            </div>
                            {/* <Link to={url} className="back_home btn btn-white"> */}
                            {/* <button onClick={handleClick} className="back_home btn btn-white"> */}
                            <button onClick={handleClick} className="back_home btn btn-white">
                                <FormattedMessage
                                    id="confirm.back-home"
                                    defaultMessage="Home"
                                />
                            </button>
                            {/* </Link> */}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                autoClose={2500}
                hideProgressBar={true}
                className="toast-chilling"
                position="top-right"
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                theme="light"
            />

        </Fragment>
    )
}

export default Confirmation

