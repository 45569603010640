import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    // CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    // Elements,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import 'react-toastify/dist/ReactToastify.css';
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import { IMAGES } from '../../constants';
import MetaData from '../layout/MetaData';
import ProtectedHeader from '../layout/ProtectedHeader';
import Sidebar from './Sidebar';
import { advertisementPlan, AdvertisementPurchaseAction, clearError } from '../../actions/Advertisement';
import { currentPlan, cancelPlan, loadUser } from '../../actions/userActions';
import { getSearchQueryParams, savePercentageforAds, UnlimitedUpload } from '../../helper';
import { setColor, numberWithCommas, getLastURL, getParamsByName, deviceDisplay } from '../../helper';
import { makeRequestGet } from '../../utils/apiHelper';
import $, { event } from 'jquery';
import { FormattedMessage } from 'react-intl';
import PopupConfirm from '../layout/PopupConfirm';
import { useAlert } from 'react-alert';
import { currency, fetchFromAPI, url } from '../../config';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import Loader from '../layout/Loader';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { PurchasePlanStripeAction } from '../../actions/stripeAction';
import { SubscriptionStripeAction } from '../../actions/SubscriptionAction';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { validateFieldsNatively } from '@hookform/resolvers';
// import Dealer from '../../HomePage/Dealer';

const AdPlan = () => {
    const [cancelPopUp, setcancelPopUp] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch()
    const alert = useAlert();
    const location = useLocation()
    const [email, setEmail] = useState('');
    const stripe = useStripe();
    const elements = useElements();
    const [selectedPlan, setSelectedPlan] = useState({
        planName: "",
        planPrice: "",
        stripePlanId: ""
    });
    const { currentplan, loadingCp } = useSelector(state => state.currentPlan)
    const { plan } = useSelector(state => state.AdvertisementPlanReducer)

    console.log("plan", plan);

    const { user } = useSelector(state => state.user)
    const { payment_loading, payment_status, payment_data, payment_error } = useSelector((state) => state.PurchasePlanStripeState)
    const [loaderStripe, setLoaderStripe] = useState(false)
    const [clickCount, setClickCount] = useState(0);
    const product_id = getLastURL(location);

    // useEffect(async () => {
    //     var dump = getParamsByName(window.location.href, 'dump')
    //     await sessionStorage.setItem('user', JSON.stringify(currentplan && currentplan.profile && currentplan.profile));
    //     if (dump == 1) {
    //         window.location.href = url + '/advertise-plan-details'
    //     }
    // }, [currentplan])

    const handleCancelSubsClick = (event) => {
        setcancelPopUp(true);
        $("#popup-click-confirm").trigger('click');
    }

    const handleResForm = async (status) => {
        if (status) {
            // setcancelPopUp(false);
            setLoaderStripe(true)
            await dispatch(currentPlan());
            setLoaderStripe(false)
        }
        $("#dismiss-modal").trigger('click');

    }


    const selectPlan = (stripePlanId, planName, planPrice) => {
        setSelectedPlan({
            planName: planName,
            planPrice: planPrice,
            stripePlanId: stripePlanId
        })
    }

    const [name, setName] = useState("");

    const handleSubmit = async (event) => {

        try {
            await setLoaderStripe(true)
            let payload = await {};
            setLoaderStripe(true)
            payload = {
                price_id: event.stripePlanId,
                product_id: product_id,
                success_url: window.location.href + '?payment_status=success',
                cancel_url: window.location.href + '?payment_status=failed'
            };

            await dispatch(AdvertisementPurchaseAction(payload))
            await setLoaderStripe(false)
            setLoaderStripe(false)
        } catch (err) {
            console.error(err?.message);
        }
    };
    const paymentStatus = getSearchQueryParams('payment_status');

    useEffect(() => {
        if (paymentStatus === 'success') {
            history.push({ pathname: url + '/myproducts/diamonds' });
            alert.success("Your payment is successfully completed !!");
        }if (paymentStatus === 'failed') {
            history.push({ pathname: url + '/myproducts/diamonds' });
            alert.error("Your payment is failed !!");  
        }
    }, [paymentStatus])

    //console.log("paymentStatus ------- ", paymentStatus );

    useEffect(async () => {
        if (payment_data?.status) {
            window.location.href = payment_data?.data?.url

        } else if (payment_error) {
            await alert.error(payment_error);
        }
    }, [payment_data, payment_error])


    const cardNumberElementOptions = {
        style: {
            base: {
                iconColor: "#c4f0ff",
                color: "#fff",
                fontWeight: "500",
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": {
                    color: "#fff",
                },
                // "::placeholder": {
                //   color: "#87BBFD",
                // },
            },
            invalid: {
                iconColor: "#FFC7EE",
                color: "#eb1c26",
            },
        },
        placeholder: "Card Number",
    };

    const cardExpireElementOptions = {
        style: {
            base: {
                iconColor: "#c4f0ff",
                color: "#fff",
                fontWeight: "500",
                fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                ":-webkit-autofill": {
                    color: "#fff",
                },
                // "::placeholder": {
                //   color: "#87BBFD",
                // },
            },
            invalid: {
                iconColor: "#FFC7EE",
                color: "#eb1c26",
            },
        },
    };

    useEffect(() => {
        const handleOutsideClick = (e) => {
            const modal = document.querySelector('#stripePayment');
            if (modal && !modal.contains(e.target)) {
                setName('');
                elements.getElement('cardNumber').clear();
                elements.getElement('cardExpiry').clear();
                elements.getElement('cardCvc').clear();

            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    });


    const onCloseModal = () => {
        setName('');
        elements.getElement('cardNumber').clear();
        elements.getElement('cardExpiry').clear();
        elements.getElement('cardCvc').clear();
    }


    const stripePayment = (stripePlanId, planName, planPrice) => {
        const payload = {
            stripePlanId,
            planName,
            planPrice
        }
        dispatch(SubscriptionStripeAction(payload))
        history.push(url + '/stripe');

    }

    const stringReplace = (text) => {
        let replace = text.replace(/(<([^>]+)>)/ig, '');
        return replace;
    }


    useEffect(() => {
        dispatch(advertisementPlan({}))
    }, [])

    return (
        <Fragment>

            <MetaData title={`Plan Details`} />

            <section className="container-fluid py-4 conPart ">
                <div className="row">
                    <Sidebar />
                    <div className="col-md-10 crntp">
                        <section id="pricings inner" className="container  pricings">
                            <div className="tab-content pt-5 innerdiv" id="myTabContent">
                                <div className="tab-pane fade show active" id="dealer" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className="row  mb-3">
                                        {
                                            plan.length > 0 ?
                                            plan.map((plans, key) => {
                                                const planDetails = {
                                                    "stripePlanId": plans?.product_id,
                                                    "planName": plans?.title,
                                                    "planPrice": plans?.amount,
                                                    "plan_validity": plans?.plan_days
                                                }
                                                return (
                                                    <div className="col-lg-3 col-md-4" >

                                                        <div onClick={() => handleSubmit(planDetails)}>

                                                            <div className="sub_plan_box active_new_plan " data-toggle="modal" data-target="#UpdatePlans">
                                                                <h3 className={setColor(plans.order_by)}>{plans.title}</h3>
                                                                <br />

                                                                <div className="device_d">
                                                                    {/* <span className="icon-device_2"></span> */}
                                                                    {/* <p>{plans.plan_days + " Days"} </p> */}
                                                                     <p>{plans.description} </p>
                                                                    
                                                                </div>
                                                                {

                                                                    <h4>{currency}{plans.amount}
                                                                        {/* /<FormattedMessage id="day" /> */}
                                                                    </h4>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            })
                                           : 
                                            <div className="col-lg-3 col-md-4" >
                                             <FormattedMessage id="ProductNotFound" defaultMessage="Product not Found"/>
                                           </div>
                                            
                                        }
                                    </div>
                                </div>
                            </div>

                        </section>



                    </div>
                </div>
            </section>

            {/* //PAYMENT Model BOX */}
            <div
                className="modal moder_s2 fade"
                id="stripePayment"
                tabIndex={-1}
                aria-labelledby="stripePaymentTitle"
                aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered" >
                    {loaderStripe || payment_loading ? <Loader /> : ""}
                    <div className="modal-content">

                        <div className="modal-body p-5 text-center">
                            <button type="button" className="close" onClick={() => onCloseModal()} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <input type="hidden" id="selectedStripePlan" />
                            <input type="hidden" id="selectedPayPalPlan" />

                            <div>
                                <h6 className='text-start'>Card Details</h6>
                                <form >
                                    <div className="row inputstyle1">
                                        <div className="col-md-12 iconInput mb-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={name}
                                                placeholder="Card holder Name"
                                                onChange={(e) => setName(e.target.value)}
                                                required={true}
                                            />
                                        </div>


                                        <div className="col-12 iconInput mb-3">
                                            <CardNumberElement
                                                className="cardNumberElement"
                                                options={cardNumberElementOptions}
                                            />
                                            <i className="fa fa-credit-card-alt" aria-hidden="true"></i>
                                        </div>

                                        <div className="col-6 mb-3">
                                            <CardExpiryElement
                                                className="cardExpiryElement"
                                                options={cardExpireElementOptions}
                                            />
                                        </div>
                                        <div className="col-6 mb-3">
                                            <CardCvcElement
                                                className="cardCvcElement"
                                                options={cardExpireElementOptions}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type='submit'
                                        onClick={handleSubmit}
                                        className="btn btn-primary px-5 my-4"
                                        disabled={!stripe || !elements}
                                    >
                                        Pay
                                    </button>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                autoClose={2500}
                hideProgressBar={true}
                className="toast-chilling"
                position="top-right"
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                theme="light"
            />

        </Fragment >
    )
}

export default AdPlan
