import React from 'react';
import { useSelector } from 'react-redux';

const NumberOfMember = () => {

    const { Home } = useSelector(state => state.home)

    return (
        <section id="nUsers" className="nUsers container py-5">
            <h2 className="mt-5 mb-5 text-center black fm font-40">{Home && Home?.homePage && Home?.homePage.nom_sect_title}</h2>

            <div className="row justify-content-md-center al">
                <div className="col-md-10 ">

                    <div className="row pt-5 align-item-center">
                        <div className="col-md-4 text-center">
                            <div className="mem_box">
                                <span>{Home && Home?.homePage && Home?.homePage?.nom_col_one_value}</span>
                                <p>{Home && Home?.homePage && Home.homePage?.nom_col_one_text}</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="mem_box">
                                <span>{Home && Home?.homePage && Home?.homePage?.nom_col_two_value}</span>
                                <p>{Home && Home?.homePage && Home?.homePage?.nom_col_two_text}</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="mem_box">
                                <span>{Home && Home?.homePage && Home?.homePage?.nom_col_three_value}</span>
                                <p>{Home && Home?.homePage && Home?.homePage?.nom_col_three_text}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NumberOfMember
