import React from 'react';
import { useSelector } from 'react-redux';
import { IMAGES } from '../../constants';
import { ImageUrl } from '../../helper';


const WhyCevaheer = () => {


    const { Home } = useSelector(state => state.home)

    return (
        <section className="why_cavehher" id="whyus">
            <div className="container">
                <h2 className="mt-5 mb-3 text-center black fm font-40 ">{Home && Home?.homePage && Home?.homePage?.why_sect_title}</h2>
            </div>
            <div className="new_why">
                <div className="container">
                    <div className="largest_sec">
                        <h2 className="mt-5 mb-3 text-center black fm font-40 ">{Home && Home?.homePage && Home?.homePage?.why_section_one_heading}</h2>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="l_1">
                                    <img src={IMAGES.L1_SVG} alt="" />
                                    <p>{Home && Home?.homePage && Home?.homePage?.w_sec_one_col_one}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="l_1">
                                    <img src={IMAGES.L1_SVG} alt="" />
                                    <p>{Home && Home?.homePage && Home?.homePage?.w_sec_one_col_two}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="l_1">
                                    <img src={IMAGES.L1_SVG} alt="" />
                                    <p>{Home && Home?.homePage && Home?.homePage?.w_sec_one_col_three}</p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="w_img">
                                    <img src={Home && Home?.homePage && ImageUrl(Home.homePage.w_sec_one_image)} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="largest_sec">
                        <h2 className="mt-5 mb-3 text-center black fm font-40">{Home && Home?.homePage && Home?.homePage?.w_sec_two_heading}</h2>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="l_1">
                                    <img src={IMAGES.L1_SVG} alt="" />
                                    <p>{Home && Home?.homePage && Home?.homePage?.w_sec_two_col_one}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="l_1">
                                    <img src={IMAGES.L1_SVG} alt="" />
                                    <p>{Home && Home?.homePage && Home?.homePage?.w_sec_two_col_two}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="l_1">
                                    <img src={IMAGES.L1_SVG} alt="" />
                                    <p>{Home && Home?.homePage && Home?.homePage?.w_sec_two_col_three}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="w_img">
                                    <img src={Home && Home?.homePage && ImageUrl(Home.homePage.w_sec_two_col_one_img)} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="w_img">
                                    <img src={Home && Home?.homePage && ImageUrl(Home.homePage.w_sec_two_col_two_img)} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="w_img">
                                    <img src={Home && Home?.homePage && ImageUrl(Home.homePage.w_sec_two_col_three_img)} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="largest_sec mb-0">
                    <h2 className="mt-5 mb-3 text-center black fm font-40 ">{Home && Home?.homePage && Home?.homePage?.w_sec_three_heading}</h2>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="l_1">
                                    <img src={IMAGES.L1_SVG} alt="" />
                                    <p>{Home && Home?.homePage && Home?.homePage?.w_sec_three_col_one}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="l_1">
                                    <img src={IMAGES.L1_SVG} alt="" />
                                    <p>{Home && Home?.homePage && Home?.homePage?.w_sec_three_col_two}</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="l_1">
                                    <img src={IMAGES.L1_SVG} alt="" />
                                    <p>{Home && Home?.homePage && Home?.homePage?.w_sec_three_col_three}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid home_pro_slider">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="pro_info_list container-fluid">
                                    <div className=" mx-0 py-3">
                                        <div className="col-lg-12">
                                            <div className="product_card owl-carousel owl-theme client_list">
                                                <div className="item">
                                                    <div className="card_1">
                                                        <div className="card_img">
                                                            <img src={IMAGES.W1_IMG} alt="" />
                                                        </div>
                                                        <div className="card_details">
                                                            <p className="font-14 black-3B fm mb-2">
                                                                <a href="watch-details.html"
                                                                    className="black-3B"> Rolex Cosmograph Daytona Luxury Men's
                                                                    Watch 116500... </a></p>
                                                            <h4 className="font-14 black-3B fb">$37,500</h4>
                                                        </div>
                                                    </div>
                                                    <div className="hover_btn">
                                                        <a href="#" className="btn btn-white mb-2 w-100">Message supplier</a>
                                                        <a href="#" className="btn btn-black mb-2 w-100">Contact supplier</a>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="card_1">
                                                        <div className="card_img">
                                                            <img src={IMAGES.W1_IMG} alt="" />
                                                        </div>
                                                        <div className="card_details">
                                                            <p className="font-14 black-3B fm mb-2"><a href="watch-details.html"
                                                                className="black-3B"> Rolex Cosmograph Daytona Luxury Men's
                                                                Watch 116500... </a></p>

                                                            <h4 className="font-14 black-3B fb">$37,500</h4>
                                                        </div>
                                                        <div className="hover_btn">
                                                            <a href="#" className="btn btn-white mb-2 w-100">Message
                                                                supplier</a>
                                                            <a href="#" className="btn btn-black mb-2 w-100">Contact
                                                                supplier</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="card_1">
                                                        <div className="card_img">
                                                            <img src={IMAGES.W1_IMG} alt="" />
                                                        </div>
                                                        <div className="card_details">
                                                            <p className="font-14 black-3B fm mb-2"><a href="watch-details.html"
                                                                className="black-3B"> Rolex Cosmograph Daytona Luxury Men's
                                                                Watch 116500... </a></p>
                                                            <h4 className="font-14 black-3B fb">$37,500</h4>
                                                        </div>
                                                    </div>
                                                    <div className="hover_btn">
                                                        <a href="#" className="btn btn-white mb-2 w-100">Message supplier</a>
                                                        <a href="#" className="btn btn-black mb-2 w-100">Contact supplier</a>
                                                    </div>
                                                </div>
                                                <div className="item">

                                                    <div className="card_1">
                                                        <div className="card_img">
                                                            <img src={IMAGES.W1_IMG} alt="" />
                                                        </div>
                                                        <div className="card_details">
                                                            <p className="font-14 black-3B fm mb-2"><a href="watch-details.html"
                                                                className="black-3B"> Rolex Cosmograph Daytona Luxury Men's
                                                                Watch 116500... </a></p>
                                                            <h4 className="font-14 black-3B fb">$37,500</h4>
                                                        </div>
                                                    </div>
                                                    <div className="hover_btn">
                                                        <a href="#" className="btn btn-white mb-2 w-100">Message supplier</a>
                                                        <a href="#" className="btn btn-black mb-2 w-100">Contact supplier</a>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="card_1">
                                                        <div className="card_img">
                                                            <img src={IMAGES.W1_IMG} alt="" />
                                                        </div>
                                                        <div className="card_details">
                                                            <p className="font-14 black-3B fm mb-2"><a href="watch-details.html"
                                                                className="black-3B"> Rolex Cosmograph Daytona Luxury Men's
                                                                Watch 116500... </a></p>
                                                            <h4 className="font-14 black-3B fb">$37,500</h4>
                                                        </div>
                                                    </div>
                                                    <div className="hover_btn">
                                                        <a href="#" className="btn btn-white mb-2 w-100">Message supplier</a>
                                                        <a href="#" className="btn btn-black mb-2 w-100">Contact supplier</a>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="card_1">
                                                        <div className="card_img">
                                                            <img src={IMAGES.W1_IMG} alt="" />
                                                        </div>
                                                        <div className="card_details">
                                                            <p className="font-14 black-3B fm mb-2"><a href="watch-details.html"
                                                                className="black-3B"> Rolex Cosmograph Daytona Luxury Men's
                                                                Watch 116500... </a></p>
                                                            <h4 className="font-14 black-3B fb">$37,500</h4>
                                                        </div>
                                                    </div>
                                                    <div className="hover_btn">
                                                        <a href="#" className="btn btn-white mb-2 w-100">Message supplier</a>
                                                        <a href="#" className="btn btn-black mb-2 w-100">Contact supplier</a>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="card_1">
                                                        <div className="card_img">
                                                            <img src={IMAGES.W1_IMG} alt="" />
                                                        </div>
                                                        <div className="card_details">
                                                            <p className="font-14 black-3B fm mb-2"><a href="watch-details.html"
                                                                className="black-3B"> Rolex Cosmograph Daytona Luxury Men's
                                                                Watch 116500... </a></p>
                                                            <h4 className="font-14 black-3B fb">$37,500</h4>
                                                        </div>
                                                    </div>
                                                    <div className="hover_btn">
                                                        <a href="#" className="btn btn-white mb-2 w-100">Message supplier</a>
                                                        <a href="#" className="btn btn-black mb-2 w-100">Contact supplier</a>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="card_1">
                                                        <div className="card_img">
                                                            <img src={IMAGES.W1_IMG} alt="" />
                                                        </div>
                                                        <div className="card_details">
                                                            <p className="font-14 black-3B fm mb-2"><a href="watch-details.html"
                                                                className="black-3B"> Rolex Cosmograph Daytona Luxury Men's
                                                                Watch 116500... </a></p>
                                                            <h4 className="font-14 black-3B fb">$37,500</h4>
                                                        </div>
                                                    </div>
                                                    <div className="hover_btn">
                                                        <a href="#" className="btn btn-white mb-2 w-100">Message supplier</a>
                                                        <a href="#" className="btn btn-black mb-2 w-100">Contact supplier</a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default WhyCevaheer