import React, { Fragment, useEffect, useState } from 'react';
import { handleSortBy, pColmDisplay, getLastURL, numberWithCommas , CompanyNameDisplay ,RatingDisplay ,totalPriceDisplay} from '../../../helper';
import { useSelector, useDispatch } from 'react-redux';
import { url,postPerPage } from '../../../config';
import { IMAGES,SEARCH_LIMIT_MSG } from '../../../constants';
import { useHistory } from 'react-router';
import Loader from '../../layout/Loader';
import ReactPaginate from "react-paginate";
import { useAlert } from 'react-alert';
import $ from 'jquery';
import { parcelJson } from '../../../utils/json';
import { getProductsFilterByAscending, getAllProductsCompare, getAllProducts } from '../../../actions/productActions';
import { useLocation } from 'react-router';
import { getAllProductsFilter } from '../../../actions/productActions';
import ReactStars from "react-rating-stars-component";
import { Link } from 'react-router-dom';
import PopupConfirm from '../../layout/PopupConfirm';
import mixpanel from 'mixpanel-browser';
import { FormattedMessage, useIntl } from 'react-intl';

const Parcel = (props) => {

    const history = useHistory();
    const alert = useAlert();
    const dispatch = useDispatch();
    const location = useLocation();

    const handleDetails = (id) => {
        history.push(url + `/details/parcels/${id}`);
    }

    const category_slug = getLastURL(location);

    const { products, error, loading } = useSelector(state => state.products);

    const [currentPage, setCurrentPage] = useState(0);
    const [postsPerPage, setPostPerPage] = useState(postPerPage);
    const [filterdisplayname, setFilterDisplayName] = useState(<FormattedMessage id="Select Sort Option"/>);
    const [search, setSearch] = useState();

    const [selectAllCompare, setSelectAllCompare] = useState(new Array(products && products.products && products.products.data && products.products.data.length).fill(false));
    const [selectedId, setSelectedId] = useState([]);
    const [popUpLimitTitle, setpopUpLimitTitle] = useState(<FormattedMessage id="Search Limit Finished"/>);
    const [popUpLimitMessage, setpopUpLimitMessage] = useState(SEARCH_LIMIT_MSG);
 


    useEffect(() => {
        if (error) {
            alert.error(error);
            return;
        }
    }, [alert, error])

    const handlePageChange = ({ selected }) => {
        props.updateResetPageHandler(selected)
        dispatch(getAllProducts(category_slug, (selected + 1)));
    }
    
    const handleResForm =  (status) => {  
        if(status){
            $("#dismiss-modal").trigger('click');
            history.push({ pathname: url + '/plan-details' });
        }
    }

    const pageCount = Math.ceil((products && products.products && products.products.total) / postsPerPage);



    const handleAcendingFilter = (value, type, displayname) => {
        setFilterDisplayName(displayname)
        $('.sort_by_list').css({
            display: "none"
        })
        props.tabChangeHandler(1)
        dispatch(getProductsFilterByAscending(category_slug, value, type))
    }

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }


    const handleSearchFilter = (e) => {
        mixpanel.track('Search Parcel')
        if (e.key === 'Enter') {
            dispatch(getAllProductsFilter(category_slug, { search: search, is_fancy: products && products.is_fancy  }));
        }
    }

    const handleSearchIconClick = () => {
        mixpanel.track('Search Parcel');
        dispatch(getAllProductsFilter(category_slug, { search: search }));
    };


    const handleSelectCompare = (id, position) => {
        const updatedSelectAllCompare = selectAllCompare && selectAllCompare.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setSelectAllCompare(updatedSelectAllCompare);

        if (!selectedId.includes(id)) {
            setSelectedId([...selectedId, id]);
        } else {
            selectedId.splice(selectedId.indexOf(id), 1);
        }
    }


    const handleCompare = () => {
        if (selectedId.length > 1) {
            dispatch(getAllProductsCompare(category_slug, selectedId))
            setSelectAllCompare(new Array(products && products.products && products.products.data && products.products.data.length).fill(true))
        } else {
            alert.error('Please select product');
            return;
        }

    }

    const handleCompareRemove = (id) => {
        if (selectedId.includes(id)) {
            selectedId.splice(selectedId.indexOf(id), 1);
        }
        dispatch(getAllProductsCompare(category_slug, selectedId))
    }

    const handleDeselectAll = () => {
        setSelectedId([]);
        setSelectAllCompare(new Array(products && products.products && products.products.data && products.products.data.length).fill(false))
        dispatch(getAllProductsCompare(category_slug))
    }

    products && products.over_limit &&
        $("#popup-click-confirm").trigger('click')



    return (
        <Fragment>
            {loading && <Loader />}
            <Fragment>
                <div className="col-lg-9">
                    <div className="dashboard-content">
                        <div className="filter_search_right">
                            <div className="search_cat_top">
                                <div className="row">
                                    <div className="col-lg-7 col-md-7 col-sm-7">
                                            {/* <div className="c_box1 c_btn mb-2">
                                                <button className="btn btn-black compare-product-btn" type="button" onClick={handleCompare}><span className="mr-1"><img src={IMAGES.COMPARE_IMG} alt="" /></span> Compare products</button>
                                            </div> */}
                                            {
                                                $.map(products && products?.filter && products?.filter, (val, key) => {
                                                    return (
                                                        <div className="s_c">
                                                            <p><span> <b>{key}:</b> {val} </span></p>
                                                            {/* <i className="icon-close_1"></i> */}
                                                        </div>
                                                    )
                                                })
                                            }
                                      
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-5">
                                        <div className="form-group sort_by_box d-flex justify-content-end">
                                           
                                            <div className="form-group sort_by_box">
                                                <p className="sort_b_h" onClick={handleSortBy}><b><FormattedMessage id="Sort_By" defaultMessage="Sort By"/>:</b> {filterdisplayname} <span className="icon-chevron_down"></span></p>
                                                <div className="sort_by_list">
                                                    <ul>
                                                        {
                                                            parcelJson && parcelJson.map((data, key) => {
                                                                return (
                                                                    <li className="activess" onClick={() => handleAcendingFilter(data.value, data.type, data.display_name)}>{data.display_name}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="form-group seach_filter row justify-content-end">
                                <div className="col-xl-8 col-lg-6 col-md-6 col-sm-6">
                                    <div className="re_text">
                                        <h4 className="font-16 black-3B fb"><FormattedMessage id="Results" defaultMessage="Results"/></h4>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                    <input type="text" value={search} onKeyPress={(e) => handleSearchFilter(e)} onChange={handleSearch} className="form-control" placeholder={useIntl().formatMessage({id: 'SearchBySeller'})} />
                                    <span className="icon-search_1  input_icon" onClick={handleSearchIconClick} style={{cursor:'pointer'}}></span>
                                </div>
                            </div>
                            <div className="filter_table">
                                <div className="tbles">
                                    <table width="100%">
                                        <tr>
                                            {/* <th width="5%"></th> */}
                                            <th><FormattedMessage id="Seller" defaultMessage="Seller"/></th>
                                            <th><FormattedMessage id="Shape" defaultMessage="Shape"/></th>
                                            <th><FormattedMessage id="Sieve" defaultMessage="Sieve"/></th>
                                            <th><FormattedMessage id="addsingleproduct.carat" defaultMessage="Carat"/></th>
                                            {
                                                products && products.is_fancy == 1 ?
                                                    <Fragment>
                                                       <th><FormattedMessage id="Intensity" defaultMessage="Intensity"/></th>
                                                       <th><FormattedMessage id="Overtone" defaultMessage="Overtone"/></th>
                                                    </Fragment> :
                                                    <Fragment>
                                                    </Fragment>
                                            }
                                            <th><FormattedMessage id="addsingleproduct.color" defaultMessage="Color"/></th>
                                            <th><FormattedMessage id="addsingleproduct.clarity" defaultMessage="Clarity"/></th>
                                            <th><FormattedMessage id="Measurements" defaultMessage="Measurements"/></th>
                                           
                                            <th><FormattedMessage id="addsingleproduct.price-ct" defaultMessage="Price/ct"/></th>
                                        </tr>

                                        {
                                            products && products.products && products.products.data.length > 0 ?
                                                products && products?.products && products?.products?.data && products.products.data.map((data, key) => {
                                                    return (
                                                        <tr>
                                                            {/* <td>
                                                                <div className="form-group mb-0 mini checks inss">
                                                                    <input type="checkbox" className="checkbox" id={data.id} name="check" checked={selectAllCompare[key]} onChange={() => handleSelectCompare(data.id, key)} />
                                                                    <label htmlFor={data.id}></label>
                                                                </div>
                                                            </td> */}
                                                            <td>
                                                                <Link to={url + `/details/parcels/${data.id}`} target="_blank" className="not_a">
                                                                <span>{CompanyNameDisplay(data)}</span> &nbsp; <br /><i style={{color:"#003B75"}} className="fa fa-star"></i> {RatingDisplay('list',data.average_rating,data.total_rating)} </Link>

                                                            
                                                                
                                                            </td>
                                                            <td> <Link to={url + `/details/parcels/${data.id}`} target="_blank" className="not_a">{pColmDisplay(data, 'a_shape')}</Link></td>
                                                            <td> <Link to={url + `/details/parcels/${data.id}`} target="_blank" className="not_a">{pColmDisplay(data, 'a_sieve')}</Link></td>
                                                            <td>{data.carat}</td>
                                                            {
                                                                products && products.is_fancy == 1 ?
                                                                    <Fragment>
                                                                        <td> <Link to={url + `/details/parcels/${data.id}`} target="_blank" className="not_a">{pColmDisplay(data, 'a_fancy_intensity')}</Link></td>
                                                                        <td> <Link to={url + `/details/parcels/${data.id}`} target="_blank" className="not_a">{pColmDisplay(data, 'a_fancy_overtone')}</Link></td>
                                                                        <td> <Link to={url + `/details/parcels/${data.id}`} target="_blank" className="not_a">{pColmDisplay(data, 'a_fancy_color')}</Link></td>
                                                                    </Fragment> :
                                                                    <Fragment>
                                                                               <td> <Link to={url + `/details/parcels/${data.id}`} target="_blank" className="not_a">{pColmDisplay(data, 'a_color')}</Link></td>
                                                                    </Fragment>
                                                            }
                                                            <td> <Link to={url + `/details/parcels/${data.id}`} target="_blank" className="not_a">{pColmDisplay(data, 'a_clarity')}</Link></td>
                                                            <td> <Link to={url + `/details/parcels/${data.id}`} target="_blank" className="not_a">{data && data.mm_l && parseFloat(data.mm_l).toFixed(2)}x{ data && data.mm_w && parseFloat(data.mm_w).toFixed(2)}x{ data && data.mm_h && parseFloat(data.mm_h).toFixed(2)}</Link></td>
                                                            
                                                            <td> <Link to={url + `/details/parcels/${data.id}`} target="_blank" className="not_a">${data  && numberWithCommas(parseFloat(data.price).toFixed(2))}</Link></td>
                                                        </tr>
                                                    )
                                                })
                                                :

                                                <tr>
                                                    <th colspan="12"><FormattedMessage id="ProductNotFound" defaultMessage="Product not Found"/></th>
                                                </tr>




                                        }
                                    </table>
                                </div>
                                <div className="table_pagination">
                                    <ReactPaginate
                                        previousLabel={<i className="icon icon-chevron_gray-left"></i>}
                                        nextLabel={<i className="icon icon-chevron_black"></i>}
                                        pageCount={pageCount}
                                        onPageChange={handlePageChange}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                        forcePage={props?.pageReset}
                                    />
                                </div>
                                <div className="compare_list">
                                    {
                                        products && products.is_compare ?
                                            products && products?.products && products?.products?.data && products?.products?.data.map((data, key) => {
                                                return (
                                                    <div className="c_box1">
                                                        <i className="icon_b icon-minus" onClick={() => handleCompareRemove(data.id, key)}></i>
                                                        <p className="font-14 black-3B "><b>{data.company_name}</b>${numberWithCommas(parseFloat(data.price).toFixed(2))}</p>
                                                    </div>
                                                )
                                            })
                                            :
                                            null
                                    }
                                    {
                                        products && products?.is_compare
                                            ?
                                            <div className="c_box1">
                                                <i className="icon_black icon-minus" style={{ cursor: 'pointer' }} onClick={handleDeselectAll}></i>
                                                <p className="font-14 black-3B">Deselect all</p>
                                            </div>
                                            :
                                            null
                                    }
                                    {/* <div className="c_box1 c_btn">
                                        <button className="btn btn-black" type="button" onClick={handleCompare}><span className="mr-1"><img src={IMAGES.COMPARE_IMG} alt="" /></span> Compare products</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
            {
            <PopupConfirm 
                message={popUpLimitMessage}
                title={popUpLimitTitle}
                handleResForm={handleResForm} 
            />
         }
        </Fragment>

    )
}

export default Parcel
