import { 
    GET_CATEGORY_REQUEST, 
    GET_CATEGORY_SUCCESS, 
    GET_CATEGORY_FAIL } from "../constants/categoryConstant";
import { makeRequestGet } from "../utils/apiHelper";

export const getCategory = () => async (dispatch) =>{
    try {
        dispatch({ type: GET_CATEGORY_REQUEST })

        const data = await makeRequestGet('get-categories');

        if (data.status) {
            dispatch({
                type: GET_CATEGORY_SUCCESS,
                payload:data.data
            })
        }
        else {
            dispatch({
                type: GET_CATEGORY_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_CATEGORY_FAIL,
            payload: error.response
        })

    }
}