import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getAttributes, myProductsSideFilter, getSuppliers } from "../../../actions/productActions";
import $ from "jquery";
import { getLastURL } from "../../../helper";
import { ShapeImageUrl, arraysHaveSameValues } from "../../../helper";
import { CaratDiamondJson } from "../../../utils/json";
import { FormattedMessage, useIntl } from "react-intl";
import "react-autocomplete-input/dist/bundle.css";
import "./model.css";
import { FINISH_COMB } from "../../../constants";

const MyProductsSideFilter = ({
  setShowFilters,
  setSideFilters,
  resetPage,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const pathname = getLastURL(location);

  const { myproduct, loading } = useSelector(state => state.myproduct);
  const { attributes } = useSelector((state) => state.attributes);
  const [shape, setShape] = useState([]);
  const [clarity, setClarity] = useState([]);
  const [cut, setCut] = useState([]);
  const [polish, setPolish] = useState([]);
  const [symmetry, setSymmetry] = useState([]);
  const [fluoresence, setFluoresence] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [defaultSeller, setDefaultSeller] = useState([]);
  const [caratId, setCaratId] = useState(null);
  const [carat, setCarat] = useState({
    from: "",
    to: "",
  });
  const [price, setPrice] = useState({
    from: "",
    to: "",
  });

  const [color_, setColor] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [fancy_color_intensity, setFancyColorIntensity] = useState([]);
  const [fancy_color_overtone, setFancyColorOvertone] = useState([]);
  const [fancy_color, setFancyColor] = useState([]);
  const [selectColor, setSelectColor] = useState(false);
  const [finishCheck, setFinishCheck] = useState(false, false, false);
  const [selectFinishId, setSelectFinishId] = useState(null);
  const [finish, setFinish] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const [milky, setMilky] = useState([]);
  const [selectBgm, setSelectBgm] = useState(false);
  const [checkedBoxId, setCheckedBoxId] = useState([]);
  const [allAttributesArray, setAllAttributesArray] = useState([]);
  const [greatestPosition, setGreatestPosition] = useState([]);
  const prevFiltersRef = useRef();

  const filter = {
    shapes: shape,
    clarity: clarity,
    cut: cut,
    polish: polish,
    symmetry: symmetry,
    fluorescence: fluoresence,
    certificate: certificate,
    carat: carat,
    price: price,
    color: color_,
    fancy_color: fancy_color,
    fancy_intensity: fancy_color_intensity,
    fancy_overtone: fancy_color_overtone,
    finish: finish,
    seller: supplier,
    is_fancy: selectColor,
    seller: sellerList,
    milky: milky,
    no_BGM: selectBgm,
    status: checkedBoxId,
  };

  useEffect(() => {
    // if (myproduct?.filter && !Array.isArray(myproduct?.filter)) {
    setShape(myproduct?.filter?.shapes?.map(item => item.attribute_id) || [])
    setClarity(myproduct?.filter?.clarity?.map(item => item.attribute_id) || [])
    setPolish(myproduct?.filter?.polish?.map(item => item.attribute_id) || [])
    setCut(myproduct?.filter?.cut?.map(item => item.attribute_id) || [])
    setSymmetry(myproduct?.filter?.symmetry?.map(item => item.attribute_id) || [])
    setFluoresence(myproduct?.filter?.fluorescence?.map(item => item.attribute_id) || [])
    setCertificate(myproduct?.filter?.certificate?.map(item => item.attribute_id) || [])
    setPrice(myproduct?.filter?.shapes?.map(item => item.attribute_id) || [])
    setCarat(myproduct?.filter?.shapes?.map(item => item.attribute_id) || [])
    setColor(myproduct?.filter?.color?.map(item => item.attribute_id) || [])
    setFancyColor(myproduct?.filter?.fancy_color?.map(item => item.attribute_id) || [])
    setFancyColorOvertone(myproduct?.filter?.fancy_overtone?.map(item => item.attribute_id) || [])
    setFancyColorIntensity(myproduct?.filter?.fancy_intensity?.map(item => item.attribute_id) || [])
    // }
  }, [myproduct?.filter])

  useEffect(() => {
    const fetch = async () => {
      await dispatch(getAttributes("diamonds"));
      await dispatch(getSuppliers(""));
      sessionStorage.removeItem("discountDetails");

      const searchFilter = JSON.parse(sessionStorage.getItem("searchIdFilter"));
      const isSellerFilter = sessionStorage.getItem("isSellerFilter");
      if (searchFilter && isSellerFilter) {
        let userId = [];
        searchFilter.map((d) => {
          userId.push(d.value);
        });
        filter.seller = userId;

        setDefaultSeller(searchFilter);
        await dispatch(myProductsSideFilter(pathname, filter));

        sessionStorage.removeItem("isSellerFilter");
        sessionStorage.removeItem("searchIdFilter");
      } else {
        sessionStorage.removeItem("isSellerFilter");
        sessionStorage.removeItem("searchIdFilter");
      }
    };
    fetch();
  }, [dispatch]);

  const loadMoreDefault = (p_class) => {
    let see_more =
      localStorage.getItem("lang") == "tr" ? "Daha fazla gör" : "See More";
    let see_less =
      localStorage.getItem("lang") == "tr" ? "Daha Az Gör" : "See Less";
    if ($("." + p_class).hasClass("mainclass_hide")) {
      $("." + p_class).removeClass("mainclass_hide");
      $("." + p_class + " .c_hide").hide();
      $("." + p_class + " .see_more").html(
        ' <span className="icon-add_1"></span> &nbsp; ' + see_more
      );
    } else {
      $("." + p_class + " .c_hide").show();
      $("." + p_class).addClass("mainclass_hide");
      $("." + p_class + " .see_more").html(
        ' <span className="icon-close_1"></span> &nbsp; ' + see_less
      );
    }
  };

  const loadMoreShape = (p_class) => {
    let see_more =
      localStorage.getItem("lang") == "tr" ? "Daha fazla gör" : "See More";
    let see_less =
      localStorage.getItem("lang") == "tr" ? "Daha Az Gör" : "See Less";
    if ($("." + p_class).hasClass("mainclass_hide")) {
      $("." + p_class).removeClass("mainclass_hide");
      // $('.' + p_class + ' .c_hide').hide()
      $("." + p_class + " .c_hide").attr("style", "display: none !important;");
      $("." + p_class + " .see_more").html(
        ' <span className="icon-add_1"></span> &nbsp; ' + see_more
      );
    } else {
      // $('.' + p_class + ' .c_hide').show()
      $("." + p_class).addClass("mainclass_hide");
      $("." + p_class + " .c_hide").attr(
        "style",
        "display: inline-block !important;"
      );
      $("." + p_class + " .see_more").html(
        ' <span className="icon-close_1"></span> &nbsp; ' + see_less
      );
    }
  };

  useEffect(() => {
    prevFiltersRef.current = filter;
  });

  const handleFilter = (e) => {
    setShowFilters(false);
    setSideFilters(filter);
    resetPage(0);

    e.preventDefault();

    filter.is_fancy = selectColor;
    filter.no_BGM = selectBgm;
    filter.seller = sellerList;

    dispatch(myProductsSideFilter(pathname, filter));
    $(".paginationBttns ").removeClass("paginationActive");
    $(".paginationBttns li").siblings().removeClass("paginationActive");

  };

  const handleFilterShape = (e, id) => {
    e.preventDefault();
    // if (!shape.includes(id)) {
    //   setShape([...shape, id]);
    // } else {
    //   shape.splice(shape.indexOf(id), 1);
    //   setShape([...shape]);
    // }

    // if ($(".shape-items-show li.shapec_" + id).hasClass("active")) {
    //   $(".shape-items-show li.shapec_" + id).removeClass("active");
    // } else {
    //   $(".shape-items-show li.shapec_" + id).addClass("active");
    // }

    //kartik's
    setShape(prevShape => {
      if (!prevShape.includes(id)) {
        return [...prevShape, id];
      } else {
        return prevShape.filter(shapeId => shapeId !== id);
      }
    })
  };

  const handleFilterClarity = (id) => {
    if (!clarity.includes(id)) {
      setClarity([...clarity, id]);
    } else {
      setClarity(clarity.filter((_id) => _id !== id));
    }
  };

  const handleFilterFinish = (position, id) => {
    if (!finish.includes(id)) {
      setFinish([...finish, id])
    } else {
      setFinish(finish.filter(_id => _id !== id))
    }
    // Kartik's Code
    setCut([]);
    setPolish([]);
    setSymmetry([]);
    if (!greatestPosition?.includes(position)) {
      setGreatestPosition([...greatestPosition, position])
    } else {
      setGreatestPosition(greatestPosition?.filter(_id => _id !== position))
    }
  };
console.log("greatestPosition:", greatestPosition);

  useEffect(() => {
    // Kartik's Code
    if (greatestPosition?.length > 0) {
      const newCut = [];
      const newPolish = [];
      const newSymmetry = [];
      let maxPos = greatestPosition[0]
      for (let i = 0; i < greatestPosition?.length; i++) {
        if (greatestPosition[i] > maxPos) {
          maxPos = greatestPosition[i]
        }
      }

      for (let i = 0; i < FINISH_COMB[maxPos].length; i++) {
        const polishId = attributes?.polish[FINISH_COMB[maxPos][i]]?.id;
        const symmetryId = attributes?.symmetry[FINISH_COMB[maxPos][i]]?.id;
        const cutId = attributes?.cut[FINISH_COMB[maxPos][i]]?.id;

        if (polishId) newPolish.push(polishId);
        if (symmetryId) newSymmetry.push(symmetryId);
        if (cutId) newCut.push(cutId);
      }
      setPolish(newPolish);
      setSymmetry(newSymmetry);
      setCut(newCut);
    }
  }, [greatestPosition])

  const handleFilterPolish = (id) => {
    if (!polish.includes(id)) {
      setPolish([...polish, id]);
    } else {
      setPolish(polish?.filter(_id => _id !== id))
    }
  };

  const handleFilterCut = (id) => {
    if (!cut.includes(id)) {
      setCut([...cut, id]);
    } else {
      setCut(cut?.filter(_id => _id !== id))
    }
  };

  const handleFilterSymmetry = (id) => {
    if (!symmetry.includes(id)) {
      setSymmetry([...symmetry, id]);
    } else {
      setSymmetry(symmetry.filter(_id => _id !== id))
    }
  };

  const handleFilterFluoresence = (id) => {
    if (!fluoresence.includes(id)) {
      setFluoresence([...fluoresence, id]);
    } else {
      setFluoresence(fluoresence.filter(_id => _id !== id))
    }
  };

  const handleFilterCertitficate = (id) => {
    if (!certificate.includes(id)) {
      setCertificate([...certificate, id]);
    } else {
      setCertificate(certificate.filter(_id => _id !== id))
    }
  };

  const handleCarat = (event) => {
    setCarat({ ...carat, [event.target.name]: event.target.value });
  };

  const handleFilterIntensity = (id) => {
    if (!fancy_color_intensity.includes(id)) {
      setFancyColorIntensity([...fancy_color_intensity, id]);
    } else {
      setFancyColorIntensity(
        fancy_color_intensity.filter((colorId) => colorId !== id)
      );
    }
  };

  const handleFilterOvertone = (id) => {
    if (!fancy_color_overtone.includes(id)) {
      setFancyColorOvertone([...fancy_color_overtone, id]);
    } else {
      setFancyColorOvertone(
        fancy_color_overtone.filter((colorId) => colorId !== id)
      );
    }
  };

  const handleFilterFancyColor = (id) => {
    if (!fancy_color.includes(id)) {
      setFancyColor([...fancy_color, id]);
    } else {
      setFancyColor(fancy_color.filter((c_id) => c_id !== id));
    }
  };

  const handleSelectColor = async (e) => {
    setSelectColor(e.target.checked);
    setShowFilters(false)

    filter.is_fancy = e.target.checked;

    dispatch(myProductsSideFilter(pathname, filter));
    if (e.target.checked) {
      setColor([]);
      $("#d_intensity").css({
        display: "block",
      });
      $("#d_overtone").css({
        display: "block",
      });
      $("#d_fancycolor").css({
        display: "block",
      });
      $("#d_color").css({
        display: "none",
      });
      $(".handlehidesshow").css({
        display: "none",
      });
    } else {
      setFancyColorOvertone([]);
      setFancyColorIntensity([]);
      setFancyColor([]);
      $("#d_intensity").css({
        display: "none",
      });
      $("#d_overtone").css({
        display: "none",
      });
      $("#d_fancycolor").css({
        display: "none",
      });
      $("#d_color").css({
        display: "block",
      });
      $(".handlehidesshow").css({
        display: "block",
      });
    }
  };

  const handleFilterWhiteColor = (id) => {
    if (!color_.includes(id)) {
      setColor([...color_, id]);
    } else {
      setColor(color_.filter((colorId) => colorId !== id));
    }
  };

  useEffect(() => {
    const allAttributes = attributes?.availability_status?.map((item) => {
      return item?.id;
    });
    const filteredwithoutall = allAttributes?.filter((item) => item !== 2321);
    setAllAttributesArray(filteredwithoutall);
  }, [attributes]);

  useEffect(() => {
    if (checkedBoxId?.length > 0) {
      if (
        checkedBoxId.includes(2321) ||
        arraysHaveSameValues(checkedBoxId, allAttributesArray)
      ) {
        setCheckedBoxId([-1]);
      }
    }
  }, [checkedBoxId]);

  const handleFilterAvailabilty = (value, id) => {
    if (value == false && id == 2321) {
      setCheckedBoxId([]);
    } else {
      if (value == true) {
        setCheckedBoxId((prevItems) => [...prevItems, id]);
      } else {
        if (value == false && checkedBoxId?.includes(-1)) {
          const filteredwithone = allAttributesArray?.filter(
            (item) => item !== id
          );
          setCheckedBoxId(filteredwithone);
        } else {
          const filterdata = checkedBoxId?.filter((item) => item !== id);
          setCheckedBoxId(filterdata);
        }
      }
    }
  };

  const handleSelectBgm = async (e) => {
    setSelectBgm(e.target.checked);
    filter.no_BGM = e.target.checked;
  };

  const handleResetAll = () => {
    setShowFilters(false);
    setSideFilters(null);
    resetPage(0);
    setShape([]);
    setClarity([]);
    setCut([]);
    setPolish([]);
    setSymmetry([]);
    setFluoresence([]);
    setMilky([]);
    setCertificate([]);
    setCarat({
      from: "",
      to: "",
    });
    setPrice({
      from: "",
      to: "",
    });
    setCaratId(null)
    setColor([]);
    setSelectBgm(false);
    setFancyColorIntensity([]);
    setFancyColorOvertone([]);
    setFancyColor([]);
    setSelectColor(false);
    setFinish([]);
    setSupplier([]);
    setGreatestPosition([])
    dispatch(myProductsSideFilter(pathname, {}));
  };

  const handleFilterCaratCheckbox = (From, To, position) => {
    setCaratId(position)
    setCarat({
      from: From,
      to: To,
    });
  };

  return (
    <div className="col-lg-12">
      <div className="dashboard-sidebar ">
        <div className="filter_list">
          <form onSubmit={handleFilter} className="side-filter-form">
            <div className="wrap-filter-option">
              <h2 className="mb-4">
                <FormattedMessage
                  id="addsingleproduct.filters"
                  defaultMessage="Filters"
                />
              </h2>
              <div className="side-filter-data">
                <div className="filter_top  d_shape">
                  <div className="filter_head">
                    <p className="font-16 black-3B fm ">
                      <FormattedMessage
                        id="addsingleproduct.shape"
                        defaultMessage="Shape"
                      />
                    </p>
                  </div>
                  {/* <ul id="myList" className="shape-items-show">
                    {attributes &&
                      attributes?.shape &&
                      attributes?.shape &&
                      attributes.shape.map((shape, key) => {
                        return (
                          <li
                            className={
                              key < 8
                                ? "c_show shapec_" + shape?.id
                                : "c_hide shapec_" + shape?.id
                            }
                            onClick={(e) => handleFilterShape(e, shape?.id)}
                          >
                            <img src={ShapeImageUrl(shape?.image)} alt="" />
                            <span>{shape?.name}</span>
                          </li>
                        );
                      })}
                  </ul> */}

                  {/* kartik's */}
                  <ul id="myList" className="shape-items-show">
                    {attributes?.shape?.map((s, key) => (
                      <li
                        key={s?.id}
                        className={`shapec_${s?.id} ${shape?.includes(s?.id) ? 'active' : ''} ${key < 8 ? 'c_show' : 'c_hide'}`}
                        onClick={(e) => handleFilterShape(e, s?.id)}
                      >
                        <img src={ShapeImageUrl(s?.image)} alt="" />
                        <span>{s?.name}</span>
                      </li>
                    ))}
                  </ul>
                  <p
                    id="loadMore"
                    onClick={(e) => loadMoreShape("d_shape")}
                    className="see_more font-14 gray-65"
                  >
                    <span className="icon-add_1"></span> &nbsp;{" "}
                    <FormattedMessage id="SeeMore" defaultMessage="See More" />
                  </p>
                </div>

                <div className="filter_top">
                  <div className="filter_head">
                    <h4 className="font-16 black-3B fb">
                      <FormattedMessage
                        id="addsingleproduct.carat"
                        defaultMessage="Carat"
                      />
                    </h4>
                  </div>

                  <div className="price_box d-flex">
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        value={carat.from}
                        placeholder={useIntl().formatMessage({ id: "Low" })}
                        name="from"
                        onChange={handleCarat}
                        className="form-control"
                      />
                    </div>
                    <span className="icon-minus d-block w-25"></span>
                    <div className="form-group mb-0">
                      <input
                        type="text"
                        value={carat.to}
                        className="form-control"
                        name="to"
                        onChange={handleCarat}
                        placeholder={useIntl().formatMessage({ id: "High" })}
                      />
                    </div>
                  </div>

                  <div className="filter_top mt-3 d_carat_checkbox">
                    <div className="multi_price">
                      {CaratDiamondJson &&
                        CaratDiamondJson.map((data, key) => {
                          return (
                            <div
                              className={
                                key < 5
                                  ? "form-group mb-2 c_show"
                                  : "form-group mb-2 c_hide"
                              }
                            >
                              <input
                                type="checkbox"
                                value={data.display_name}
                                checked={caratId == key}
                                id={data.id}
                                className="checkbox"
                                onChange={() =>
                                  handleFilterCaratCheckbox(
                                    data.from,
                                    data.to,
                                    key
                                  )
                                }
                              />
                              <label htmlFor={data.id}>
                                {data.display_name}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                    <p
                      id="loadMore"
                      onClick={(e) => loadMoreDefault("d_carat_checkbox")}
                      className="see_more font-14 gray-65"
                    >
                      <span className="icon-add_1"></span> &nbsp;{" "}
                      <FormattedMessage
                        id="SeeMore"
                        defaultMessage="See More"
                      />
                    </p>
                  </div>
                </div>

                <div className="filter_top mt-3">
                  <div className="filter_head">
                    <h4 className="font-16 black-3B fb">
                      <FormattedMessage
                        id="addsingleproduct.color"
                        defaultMessage="Color"
                      />
                    </h4>
                  </div>
                  <div className="cus_switch d-flex align-item-center">
                    <p className="font-14 fm black-3B pr-3">
                      <FormattedMessage
                        id="addsingleproduct.White"
                        defaultMessage="White"
                      />
                    </p>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={selectColor}
                        onChange={handleSelectColor}
                      />
                      <span className="slider round"></span>
                    </label>
                    <p className="gray-75 font-14 fm pl-3">
                      <FormattedMessage
                        id="addsingleproduct.Fancy"
                        defaultMessage="Fancy"
                      />
                    </p>
                  </div>
                </div>

                <div
                  className="filter_top d_color mt-3"
                  id="d_color"
                  style={{ display: "block" }}
                >
                  <ul id="myList" className="shape-items-show">
                    {attributes &&
                      attributes?.color &&
                      attributes?.color &&
                      attributes.color.map((color, key) => {
                        return (
                          <li
                            className={
                              `colorc_${color.id} ` +
                              (key < 12 ? "c_show" : "c_hide") +
                              (color_.includes(color.id) ? " active" : "")
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => handleFilterWhiteColor(color.id)}
                          >
                            <span>{color.name}</span>
                          </li>
                        );
                      })}
                  </ul>
                  <p
                    id="loadMore"
                    onClick={(e) => loadMoreShape("d_color")}
                    className="see_more font-14 gray-65"
                  >
                    <span className="icon-add_1"></span> &nbsp;
                    <FormattedMessage id="SeeMore" defaultMessage="See More" />
                  </p>
                </div>

                <div
                  className="filter_top mt-3 d_intensity"
                  id="d_intensity"
                  style={{ display: "none" }}
                >
                  <div className="filter_head">
                    <h4 className="font-16 black-3B fb">
                      <FormattedMessage
                        id="addsingleproduct.finishcolorintensity"
                        defaultMessage="Fancy Intensity"
                      />
                    </h4>
                  </div>
                  <div className="multi_price mt-3">
                    {attributes &&
                      attributes?.fancy_intensity &&
                      attributes.fancy_intensity.map((data, key) => {
                        return (
                          <div
                            className={
                              key < 5
                                ? "form-group mb-2 c_show"
                                : "form-group mb-2 c_hide"
                            }
                          >
                            <input
                              type="checkbox"
                              value={data.name}
                              checked={fancy_color_intensity.includes(data.id)}
                              id={data.id}
                              className="checkbox"
                              onChange={() => handleFilterIntensity(data.id)}
                            />
                            <label htmlFor={data.id}>{data.name}</label>
                          </div>
                        );
                      })}
                  </div>
                  <p
                    id="loadMore"
                    onClick={(e) => loadMoreDefault("d_intensity")}
                    className="see_more font-14 gray-65"
                  >
                    <span className="icon-add_1"></span> &nbsp;
                    <FormattedMessage id="SeeMore" defaultMessage="See More" />
                  </p>
                </div>

                <div
                  className="filter_top mt-3 d_overtone"
                  id="d_overtone"
                  style={{ display: "none" }}
                >
                  <div className="filter_head">
                    <h4 className="font-16 black-3B fb">
                      <FormattedMessage
                        id="addsingleproduct.finishcolorovertone"
                        defaultMessage="Fancy Overtone"
                      />
                    </h4>
                  </div>
                  <div className="multi_price mt-3">
                    {attributes &&
                      attributes?.fancy_overtone &&
                      attributes.fancy_overtone.map((data, key) => {
                        return (
                          <div
                            className={
                              key < 5
                                ? "form-group mb-2 c_show"
                                : "form-group mb-2 c_hide"
                            }
                          >
                            <input
                              type="checkbox"
                              value={data.name}
                              checked={fancy_color_overtone.includes(data.id)}
                              id={data.id}
                              className="checkbox"
                              onChange={() => handleFilterOvertone(data.id)}
                            />
                            <label htmlFor={data.id}>{data.name}</label>
                          </div>
                        );
                      })}
                  </div>
                  <p
                    id="loadMore"
                    onClick={(e) => loadMoreDefault("d_overtone")}
                    className="see_more font-14 gray-65"
                  >
                    <span className="icon-add_1"></span> &nbsp;
                    <FormattedMessage id="SeeMore" defaultMessage="See More" />
                  </p>
                </div>

                <div
                  className="filter_top mt-3 d_fancycolor"
                  id="d_fancycolor"
                  style={{ display: "none" }}
                >
                  <div className="filter_head">
                    <h4 className="font-16 black-3B fb">
                      <FormattedMessage
                        id="addsingleproduct.finishcolor"
                        defaultMessage="Fancy Color"
                      />
                    </h4>
                  </div>
                  <div className="multi_price mt-3">
                    {attributes &&
                      attributes?.fancy_color &&
                      attributes.fancy_color.map((data, key) => {
                        return (
                          <div
                            className={
                              key < 5
                                ? "form-group mb-2 c_show"
                                : "form-group mb-2 c_hide"
                            }
                          >
                            <input
                              type="checkbox"
                              value={data.name}
                              checked={fancy_color.includes(data?.id)}
                              id={data.id}
                              className="checkbox"
                              onChange={() => handleFilterFancyColor(data?.id)}
                            />
                            <label htmlFor={data.id}>{data.name}</label>
                          </div>
                        );
                      })}
                  </div>
                  <p
                    id="loadMore"
                    onClick={(e) => loadMoreDefault("d_fancycolor")}
                    className="see_more font-14 gray-65"
                  >
                    <span className="icon-add_1"></span> &nbsp;{" "}
                    <FormattedMessage id="SeeMore" defaultMessage="See More" />
                  </p>
                </div>

                <div className="filter_top mt-3  d_clarity">
                  <div className="filter_head">
                    <h4 className="font-16 black-3B fb">
                      <FormattedMessage
                        id="addsingleproduct.clarity"
                        defaultMessage="Clarity"
                      />
                    </h4>
                  </div>
                  <div className="multi_price mt-3">
                    {attributes &&
                      attributes?.clarity &&
                      attributes.clarity.map((data, key) => {
                        return (
                          <div
                            className={
                              key < 8
                                ? "form-group mb-2 c_show"
                                : "form-group mb-2 c_hide"
                            }
                          >
                            <input
                              type="checkbox"
                              value={data.name}
                              checked={clarity.includes(data.id)}
                              id={data.id}
                              className="checkbox"
                              onChange={() => handleFilterClarity(data.id)}
                            />
                            <label htmlFor={data.id}>{data.name}</label>
                          </div>
                        );
                      })}
                  </div>
                  <p
                    id="loadMore"
                    onClick={(e) => loadMoreDefault("d_clarity")}
                    className="see_more font-14 gray-65"
                  >
                    <span className="icon-add_1"></span> &nbsp;{" "}
                    <FormattedMessage id="SeeMore" defaultMessage="See More" />
                  </p>
                </div>

                <div className="filter_top mt-3  d_finish">
                  <div className="filter_head">
                    <h4 className="font-16 black-3B fb">
                      <FormattedMessage
                        id="addsingleproduct.finish"
                        defaultMessage="Finish"
                      />
                    </h4>
                  </div>
                  <div className=" mt-3">
                    {attributes &&
                      attributes?.finish &&
                      attributes.finish.map((data, key) => {
                        return (
                          <div
                            className={
                              key < 50
                                ? "form-group mb-2 c_show"
                                : "form-group mb-2 c_hide"
                            }
                          >
                            <input
                              type="checkbox"
                              value={data.name}
                              checked={finish.includes(data.id)}
                              id={data.id}
                              className="checkbox"
                              onChange={() =>
                                handleFilterFinish(key, data.id)
                              }
                            />
                            <label htmlFor={data.id}>{data.name}</label>
                          </div>
                        );
                      })}
                  </div>
                </div>

                {/* //CutAks */}
                {/* {(shape.includes(35) || shape.length == 0) &&
                  <div className="filter_top mt-3 d_cut">
                    <div className="filter_head">
                      <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.cut" defaultMessage="Cut" /></h4>
                    </div>
                    <div className="multi_price mt-3">
                      {
                        attributes && attributes?.cut && attributes.cut.map((data, key) => {
                          return (
                            <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                              <input
                                type="checkbox"
                                value={data.name}
                                checked={cutCheck[key]}
                                id={data.id}
                                className="checkbox ceva_cut"
                                onChange={() => handleFilterCut(key, data.id)}
                              />
                              <label htmlFor={data.id}>{data.name}</label>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                }
 */}

                <div className="filter_top mt-3 d_polish">
                  <div className="filter_head">
                    <h4 className="font-16 black-3B fb">
                    <FormattedMessage id="addsingleproduct.cut" defaultMessage="Cut" />
                    </h4>
                  </div>
                  <div className="multi_price mt-3">
                    {attributes &&
                      attributes?.cut &&
                      attributes.cut.map((data, key) => {
                        return (
                          <div
                            className={
                              key < 50
                                ? "form-group mb-2 c_show"
                                : "form-group mb-2 c_hide"
                            }
                          >
                            <input
                              type="checkbox"
                              value={data.name}
                              checked={cut?.includes(data?.id)}
                              id={data.id}
                              className="checkbox ceva_polish"
                              onChange={() => handleFilterCut(data.id)}
                            />
                            <label htmlFor={data.id}>{data.name}</label>
                          </div>
                        );
                      })}
                  </div>
                </div>


                <div className="filter_top mt-3 d_polish">
                  <div className="filter_head">
                    <h4 className="font-16 black-3B fb">
                      <FormattedMessage
                        id="addsingleproduct.polish"
                        defaultMessage="Polish"
                      />
                    </h4>
                  </div>
                  <div className="multi_price mt-3">
                    {attributes &&
                      attributes?.polish &&
                      attributes.polish.map((data, key) => {
                        return (
                          <div
                            className={
                              key < 50
                                ? "form-group mb-2 c_show"
                                : "form-group mb-2 c_hide"
                            }
                          >
                            <input
                              type="checkbox"
                              value={data.name}
                              checked={polish?.includes(data?.id)}
                              id={data.id}
                              className="checkbox ceva_polish"
                              onChange={() => handleFilterPolish(data.id)}
                            />
                            <label htmlFor={data.id}>{data.name}</label>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="filter_top mt-3 d_sym">
                  <div className="filter_head">
                    <h4 className="font-16 black-3B fb">
                      <FormattedMessage
                        id="addsingleproduct.symmetry"
                        defaultMessage="Symmetry"
                      />
                    </h4>
                  </div>
                  <div className="multi_price mt-3">
                    {attributes &&
                      attributes?.symmetry &&
                      attributes.symmetry.map((data, key) => {
                        return (
                          <div
                            className={
                              key < 50
                                ? "form-group mb-2 c_show"
                                : "form-group mb-2 c_hide"
                            }
                          >
                            <input
                              type="checkbox"
                              value={data.name}
                              checked={symmetry.includes(data?.id)}
                              id={data.id}
                              className="checkbox ceva_symmetry"
                              onChange={() =>
                                handleFilterSymmetry(data.id)
                              }
                            />
                            <label htmlFor={data.id}>{data.name}</label>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div className="filter_top mt-3 d_flu">
                  <div className="filter_head">
                    <h4 className="font-16 black-3B fb">
                      <FormattedMessage
                        id="addsingleproduct.fluorescence"
                        defaultMessage="Fluorescence"
                      />
                    </h4>
                  </div>
                  <div className="multi_price mt-3">
                    {attributes &&
                      attributes?.fluorescence &&
                      attributes.fluorescence.map((data, key) => {
                        return (
                          <div
                            className={
                              key < 50
                                ? "form-group mb-2 c_show"
                                : "form-group mb-2 c_hide"
                            }
                          >
                            <input
                              type="checkbox"
                              value={data.name}
                              checked={fluoresence.includes(data.id)}
                              id={data.id}
                              className="checkbox"
                              onChange={() =>
                                handleFilterFluoresence(data.id)
                              }
                            />
                            <label htmlFor={data.id}>{data.name}</label>
                          </div>
                        );
                      })}
                  </div>
                </div>

                {/* 
                                <div className="filter_top mt-3 handlehidesshow">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.milky" defaultMessage="Milky" /></h4>
                                    </div>
                                </div>
                                <div className="multi_price mt-3" style={{ display: 'block' }}>
                                    {
                                        attributes && attributes?.milky && attributes.milky.map((data, key) => {
                                            return (
                                                <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={milky.includes(data?.id)} id={data.id} className="checkbox" onChange={() => handleFilterMilky(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className="filter_top mt-3 handlehidesshow">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.bgm" defaultMessage="No BGM" /></h4>
                                    </div>
                                    <div className="cus_switch d-flex align-item-center">
                                        <label className="switch">
                                            <input type="checkbox" onChange={handleSelectBgm} checked={selectBgm} />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div> */}

                <div className="filter_top mt-3 d_cert">
                  <div className="filter_head">
                    <h4 className="font-16 black-3B fb">
                      <FormattedMessage
                        id="addsingleproduct.certificate"
                        defaultMessage="Certificate"
                      />
                    </h4>
                  </div>
                  <div className="multi_price mt-3">
                    {attributes &&
                      attributes?.certificate &&
                      attributes.certificate.map((data, key) => {
                        return (
                          <div
                            className={
                              key < 5
                                ? "form-group mb-2 c_show"
                                : "form-group mb-2 c_hide"
                            }
                          >
                            <input
                              type="checkbox"
                              value={data.name}
                              checked={certificate.includes(data.id)}
                              id={data.id}
                              className="checkbox"
                              onChange={() =>
                                handleFilterCertitficate(data.id)
                              }
                            />
                            <label htmlFor={data.id}>{data.name}</label>
                          </div>
                        );
                      })}
                  </div>
                  <p
                    id="loadMore"
                    onClick={(e) => loadMoreDefault("d_cert")}
                    className="see_more font-14 gray-65"
                  >
                    <span className="icon-add_1"></span> &nbsp;{" "}
                    <FormattedMessage id="SeeMore" defaultMessage="See More" />
                  </p>
                </div>

                {/* <div className="filter_top mt-3">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fb"><FormattedMessage id="Status" defaultMessage="Status" /></h4>
                                    </div>
                                </div>

                                <div className="filter_top d_color mt-3" id="d_color" style={{ display: 'block' }}>
                                    <div className="form-group">
                                        {
                                            attributes && attributes?.availability_status && attributes.availability_status.map((data, key) => {
                                                return (
                                                    <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" id={`attributes${data.id}`} className="checkbox attributescheckbox" checked={checkedBoxId?.includes(-1) ? true : !checkedBoxId?.includes(-1) && checkedBoxId?.includes(data.id) ? true : false} onChange={(e) => handleFilterAvailabilty(e?.target?.checked, data.id)} />
                                                        <label htmlFor={`attributes${data.id}`} >{data.name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="filter_top mb-3">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.price-ct" defaultMessage="Price/ct" /></h4>
                                    </div>
                                    <div className="price_box d-flex">
                                        <div className="form-group mb-0">
                                            <input type="text" placeholder={useIntl().formatMessage({ id: 'Low' })} value={price.from} name="from" onChange={handlePrice} className="form-control" />
                                        </div>
                                        <span className="icon-minus d-block w-25"></span>
                                        <div className="form-group mb-0">
                                            <input type="text" className="form-control" value={price.to} name="to" onChange={handlePrice} placeholder={useIntl().formatMessage({ id: 'High' })} />
                                        </div>
                                    </div>
                                </div> */}

                {/* <div className="filter_top mt-3  d_seller">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fb">
                                            <FormattedMessage id="TopSupplier" defaultMessage="TopSupplier" />
                                        </h4>
                                    </div>
                                    <div
                                        className="multi_price mt-3 autocomplete"
                                        style={{ 'position': 'relative', paddingBottom: '25px' }}>
                                        <AsyncSelect
                                            value={defaultSeller}
                                            isMulti
                                            onChange={chooseOption}
                                            loadOptions={promiseOptions}
                                            placeholder={'Search'}
                                            noOptionsMessage={({ inputValue }) => "No result"} />
                                    </div>
                                    {
                                        attributes && attributes?.top_sellers && attributes.top_sellers.length > 20 &&
                                        <p
                                            id="loadMore"
                                            onClick={(e) => loadMoreDefault('d_seller')}
                                            className="see_more font-14 gray-65">
                                            <span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More" />
                                        </p>
                                    }
                                </div> */}
              </div>
            </div>
            <div className="side-filter-foot">
              <div className="">
                <div className="filter_btn">
                  <button className="btn btn-black" type="submit">
                    <FormattedMessage
                      id="ApplyFilters"
                      defaultMessage="Apply filters"
                    />
                  </button>
                  <p
                    className="font-14 gray-65 text-center mt-3 mb-0"
                    type="button"
                    onClick={handleResetAll}
                  >
                    <FormattedMessage
                      id="Reset_all"
                      defaultMessage="Reset all"
                    />
                    <span className="icon-close_1"></span>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MyProductsSideFilter;
