import {
    STRIPE_PAYMENT_REQUEST,
    STRIPE_PAYMENT_SUCCESS,
    STRIPE_PAYMENT_FAIL,
} from "../constants/StripeConstant"
import { makeRequestPost } from "../utils/apiHelper"

export const PurchasePlanStripeAction = (payload) => async (dispatch) => {
    try {
        dispatch({
            type: STRIPE_PAYMENT_REQUEST
        })

        const data = await makeRequestPost("/create-subscription", payload, true)
        if (data && data?.status === true) {
            dispatch({
                type: STRIPE_PAYMENT_SUCCESS,
                payload: data
            })
        } else {
            dispatch({
                type: STRIPE_PAYMENT_FAIL,
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: STRIPE_PAYMENT_FAIL,
            payload: error?.message
        })

    }
}