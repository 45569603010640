import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import ProtectedHeader from '../layout/ProtectedHeader';
import Sidebar from './Sidebar';
import { IMAGES } from '../../constants';
import { getLastURL } from '../../helper';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getCategory } from '../../actions/categoryAction';
import EmptyPage from '../layout/404';
import AdPlan from './AdPlan';

const Index = () => {

    const location = useLocation();
    const path = getLastURL(location);
    const dispatch = useDispatch();
    const [pageReset, setPageReset] = useState(0)

    const tabChangeHandler = () => {
        setPageReset(0)
    }
    
    const updateResetPageHandler = (i) => {
        setPageReset(i);
    }
    return (
        <Fragment>
            <ProtectedHeader />
            <ul className="breadcrub">
            <li>
                <FormattedMessage
                 id="myproduct.mylisting"
                 defaultMessage="My Listings"
                />
                </li>
                <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                <li>
                <FormattedMessage
                 id="Products"
                 defaultMessage="Products"
                />
                </li>
                <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                <li>
                <FormattedMessage
                 id="advertisement.plans"
                 defaultMessage="Advertisement Plans"
                /> 
                </li>
                <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
            </ul>
            <section className="container-fluid py-4 conPart">
                <div className="row">
                    <AdPlan /> 
                
            </div>
            </section>
        </Fragment>
    )
}

export default Index
