import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAttributes } from "../../actions/productActions";
import { validateFields } from '../../validation';
import classnames from 'classnames';
import $ from 'jquery';
import { IMAGES } from '../../constants';
import { ImageUrl } from '../../helper';

const Jewellery = ({ props, handleEditExcelForm }) => {

    const dispatch = useDispatch();

    const [addproduct, setAddProduct] = useState({
        stock_no: "",
        brand: "",
        certificate: "",
        certificate_no: "",
        clarity: "",
        diamond_color: "",
        gold_karat: "",
        style: "",
        type: "",
        measurement: "",
        metal_type: "",
        metal_weight: "",
        min_order: "",
        price: "",
        showcase_image: "",
        stone_carat: "",
        stone_shape: "",
        stone_type: "",
        comment: "",
        image_1: "",
        image_2: "",
        image_3: "",
        image_4: "",
        image_5: "",
        city: "",
        country: "",
        state: "",
        s1_stone: "",
        s1_shape: "",
        s1_color: "",
        s1_clarity: "",
        s1_pieces: "",
        s1_carat: "",
        s2_stone: "",
        s2_shape: "",
        s2_color: "",
        s2_clarity: "",
        s2_pieces: "",
        s2_carat: "",
        s3_stone: "",
        s3_shape: "",
        s3_color: "",
        s3_clarity: "",
        s3_pieces: "",
        s3_carat: "",
        s4_stone: "",
        s4_shape: "",
        s4_color: "",
        s4_clarity: "",
        s4_pieces: "",
        s4_carat: "",
        s5_stone: "",
        s5_shape: "",
        s5_color: "",
        s5_clarity: "",
        s5_pieces: "",
        s5_carat: "",
    })

    const [validerror, setValidError] = useState({
        stock_noError: "",
        brandError: "",
        certificateError: "",
        clarityError: "",
        diamond_colorError: "",
        gold_karatError: "",
        styleError: "",
        typeError: "",
        measurementError: "",
        metal_typeError: "",
        metal_weightError: "",
        min_orderError: "",
        priceError: "",
        stone_caratError: "",
        stone_shapeError: "",
        stone_typeError: "",
    });

    const handleInputChange = (event) => {
        setValidError({
            stock_noError: "",
            brandError: "",
            certificateError: "",
            clarityError: "",
            diamond_colorError: "",
            gold_karatError: "",
            styleError: "",
            typeError: "",
            measurementError: "",
            metal_typeError: "",
            metal_weightError: "",
            min_orderError: "",
            priceError: "",
            stone_caratError: "",
            stone_shapeError: "",
            stone_typeError: "",
            s1_stone: "",
            s1_shape: "",
            s1_color: "",
            s1_clarity: "",
            s1_pieces: "",
            s1_carat: "",
            s2_stone: "",
            s2_shape: "",
            s2_color: "",
            s2_clarity: "",
            s2_pieces: "",
            s2_carat: "",
            s3_stone: "",
            s3_shape: "",
            s3_color: "",
            s3_clarity: "",
            s3_pieces: "",
            s3_carat: "",
            s4_stone: "",
            s4_shape: "",
            s4_color: "",
            s4_clarity: "",
            s4_pieces: "",
            s4_carat: "",
            s5_stone: "",
            s5_shape: "",
            s5_color: "",
            s5_clarity: "",
            s5_pieces: "",
            s5_carat: "",
        })
        setAddProduct({ ...addproduct, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const stock_noError = validateFields.validateStock_Number(addproduct.stock_no);
        const brandError = validateFields.validateBrand(addproduct.brand);
        const certificateError = validateFields.validateCertificate(addproduct.certificate);
        const clarityError = validateFields.validateClarity(addproduct.clarity);
        const diamond_colorError = validateFields.validateColor(addproduct.diamond_color);
        const gold_karatError = validateFields.validateCarat(addproduct.gold_karat);
        const styleError = validateFields.validateStyle(addproduct.style);
        const typeError = validateFields.validateType(addproduct.type);
        const measurementError = validateFields.validateMeasurement(addproduct.measurement);
        const metal_typeError = validateFields.validateMetalType(addproduct.metal_type);
        const metal_weightError = validateFields.validateMetalWeight(addproduct.metal_weight);
        const min_orderError = validateFields.validateMinOrder(addproduct.min_order);
        const priceError = validateFields.validatePrice(addproduct.price);
        const stone_caratError = validateFields.validateStoneCarat(addproduct.stone_carat);
        const stone_shapeError = validateFields.validateStoneShape(addproduct.stone_shape);
        const stone_typeError = validateFields.validateStoneType(addproduct.stone_type);

        if ([stock_noError, brandError, priceError, certificateError, clarityError, diamond_colorError, gold_karatError, styleError, typeError, measurementError, metal_typeError, metal_weightError, min_orderError, stone_caratError, stone_shapeError, stone_typeError].every(e => e === false)) {
            setValidError({
                ...validerror, stock_noError, priceError, brandError, certificateError, clarityError, diamond_colorError, gold_karatError, styleError, typeError, measurementError, metal_typeError, metal_weightError, min_orderError, stone_caratError, stone_shapeError, stone_typeError
            })
            handleEditExcelForm(addproduct);
            $('#dismiss-modal').trigger('click');
        }
        else {
            setValidError({
                ...validerror, stock_noError, priceError, brandError, certificateError, clarityError, diamond_colorError, gold_karatError, styleError, typeError, measurementError, metal_typeError, metal_weightError, min_orderError, stone_caratError, stone_shapeError, stone_typeError
            })
        }
        return;
    }

    const { attributes } = useSelector(state => state.attributes)

    useEffect(() => {
        dispatch(getAttributes('jewellery'))
    }, [dispatch])

    useEffect(() => {
        if (props) {
            setAddProduct({
                stock_no: props && props.stock_no,
                brand: props && props.brand,
                certificate: props && props.certificate,
                certificate_no: props && props.certificate_no,
                clarity: props && props.clarity,
                diamond_color: props && props.diamond_color,
                gold_karat: props && props.gold_karat,
                style: props && props.style,
                type: props && props.type,
                measurement: props && props.measurement,
                metal_type: props && props.metal_type,
                metal_weight: props && props.metal_weight,
                min_order: props && props.min_order,
                price: props && props.price,
                showcase_image: props && props.showcase_image,
                stone_carat: props && props.stone_carat,
                stone_shape: props && props.stone_shape,
                stone_type: props && props.stone_type,
                comment: props && props.comment,
                image_1: props && props.image_1,
                image_2: props && props.image_2,
                image_3: props && props.image_3,
                image_4: props && props.image_4,
                image_5: props && props.image_5,
                s1_stone: props && props.s1_stone,
                s1_shape: props && props.s1_shape,
                s1_color: props && props.s1_color,
                s1_clarity: props && props.s1_clarity,
                s1_pieces: props && props.s1_pieces,
                s1_carat: props && props.s1_carat,
                s2_stone: props && props.s2_stone,
                s2_shape: props && props.s2_shape,
                s2_color: props && props.s2_color,
                s2_clarity: props && props.s2_clarity,
                s2_pieces: props && props.s2_pieces,
                s2_carat: props && props.s2_carat,
                s3_stone: props && props.s3_stone,
                s3_shape: props && props.s3_shape,
                s3_color: props && props.s3_color,
                s3_clarity: props && props.s3_clarity,
                s3_pieces: props && props.s3_pieces,
                s3_carat: props && props.s3_carat,
                s4_stone: props && props.s4_stone,
                s4_shape: props && props.s4_shape,
                s4_color: props && props.s4_color,
                s4_clarity: props && props.s4_clarity,
                s4_pieces: props && props.s4_pieces,
                s4_carat: props && props.s4_carat,
                s5_stone: props && props.s5_stone,
                s5_shape: props && props.s5_shape,
                s5_color: props && props.s5_color,
                s5_clarity: props && props.s5_clarity,
                s5_pieces: props && props.s5_pieces,
                s5_carat: props && props.s5_carat,
            })
        }
    }, [props])


    const delete_image = (key) => {
        if (key === 1) {
            setAddProduct({ ...addproduct, image_1: false })
        }
        else if (key === 2) {
            setAddProduct({ ...addproduct, image_2: false })
        }
        else if (key === 3) {
            setAddProduct({ ...addproduct, image_3: false })
        }
        else if (key === 4) {
            setAddProduct({ ...addproduct, image_4: false })
        }
        else if (key === 5) {
            setAddProduct({ ...addproduct, image_5: false })
        }
    }

    return (
        <Fragment>
            <div className="modal fade editproduct_form" id="editproductjewellery" tabIndex="-1" role="dialog" aria-labelledby="editproductTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <form onSubmit={handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header align-items-center">
                                <button id="dismiss-modal" type="button" className="close m-0" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h5 className="modal-title" id="editproductTitle">Jewellery</h5>
                                <button type="submit" className="btn btn-black">Save Changes</button>
                            </div>
                            <div className="modal-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <div className="titles nbrd mt-4 mb-3 pb-3">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h2 className="m-0">Edit Product</h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Stock No.</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.stock_noError === false }, { 'is-invalid': validerror.stock_noError })} type="text" placeholder="Stock No." name="stock_no" value={addproduct.stock_no} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.stock_noError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Type</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.typeError === false }, { 'is-invalid': validerror.typeError })} value={addproduct.type} name="type" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.type && attributes.type.map((type) => {
                                                                return (
                                                                    <option value={type.name}>{type.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.typeError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Style</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.styleError === false }, { 'is-invalid': validerror.styleError })} value={addproduct.style} name="style" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.style && attributes.style.map((style) => {
                                                                return (
                                                                    <option value={style.name}>{style.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.styleError}</p>
                                                </div>
                                            </div>


                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Stone Type</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.stone_typeError === false }, { 'is-invalid': validerror.stone_typeError })} name="stone_type" value={addproduct.stone_type} onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.stone_type && attributes.stone_type.map((stone_type) => {
                                                                return (
                                                                    <option value={stone_type.name}>{stone_type.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.stone_typeError}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Stone Shape</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.stone_shapeError === false }, { 'is-invalid': validerror.stone_shapeError })} value={addproduct.stone_shape} onChange={handleInputChange} name="stone_shape">
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.stone_shape && attributes.stone_shape.map((stone_shape) => {
                                                                return (
                                                                    <option value={stone_shape.name}>{stone_shape.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.stone_shapeError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Stone Carat</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.stone_caratError === false }, { 'is-invalid': validerror.stone_caratError })} value={addproduct.stone_carat} onChange={handleInputChange} name="stone_carat">
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.carat && attributes.carat.map((carat) => {
                                                                return (
                                                                    <option value={carat.name}>{carat.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.stone_caratError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Diamond Color</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.diamond_colorError === false }, { 'is-invalid': validerror.diamond_colorError })} value={addproduct.diamond_color} onChange={handleInputChange} name="diamond_color">
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.color && attributes.color.map((color) => {
                                                                return (
                                                                    <option value={color.name}>{color.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.diamond_colorError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Clarity</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.clarityError === false }, { 'is-invalid': validerror.clarityError })} value={addproduct.clarity} onChange={handleInputChange} name="clarity">
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.clarity && attributes.clarity.map((clarity) => {
                                                                return (
                                                                    <option value={clarity.name}>{clarity.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.clarityError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Measurement</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.measurementError === false }, { 'is-invalid': validerror.measurementError })} type="text" placeholder="measurement" name="measurement" value={addproduct.measurement} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.measurementError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Brand</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.brandError === false }, { 'is-invalid': validerror.brandError })} value={addproduct.brand} name="brand" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.brand && attributes.brand.map((brand) => {
                                                                return (
                                                                    <option value={brand.name}>{brand.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.brandError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Metal Type</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.metal_typeError === false }, { 'is-invalid': validerror.metal_typeError })} value={addproduct.metal_type} name="metal_type" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.metal_type && attributes.metal_type.map((metal_type) => {
                                                                return (
                                                                    <option value={metal_type.name}>{metal_type.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.metal_typeError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Gold Karat</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.gold_karatError === false }, { 'is-invalid': validerror.gold_karatError })} type="text" placeholder="Depth %" name="gold_karat" value={addproduct.gold_karat} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.gold_karatError}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Gold Weight</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.metal_weightError === false }, { 'is-invalid': validerror.metal_weightError })} type="text" placeholder="Gold Weight" name="metal_weight" value={addproduct.metal_weight} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.metal_weightError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Certificate</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.certificateError === false }, { 'is-invalid': validerror.certificateError })} value={addproduct.certificate} name="certificate" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.certificate && attributes.certificate.map((certificate) => {
                                                                return (
                                                                    <option value={certificate.name}>{certificate.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.certificateError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Certificate No.</label>
                                                    <input className="form-control" type="text" placeholder="certificate_no" name="certificate_no" value={addproduct.certificate_no} onChange={handleInputChange} />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Price</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.priceError === false }, { 'is-invalid': validerror.priceError })} type="text" placeholder="price/ct" name="price" value={addproduct.price} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.priceError}</p>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Minimum Order</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.min_orderError === false }, { 'is-invalid': validerror.min_orderError })} type="text" placeholder="Min Order" name="min_order" value={addproduct.min_order} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.min_orderError}</p>
                                                </div>
                                            </div>

                                        </div>


                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Stone</th>
                                                    <th scope="col">Shape</th>
                                                    <th scope="col">Color</th>
                                                    <th scope="col">Clarity</th>
                                                    <th scope="col">Pieces</th>
                                                    <th scope="col">Carat</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="row">S1</th>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s1_stone} name="s1_stone" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.stone_type && attributes.stone_type.map((stone_type) => {
                                                                        return (
                                                                            <option value={stone_type.name}>{stone_type.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s1_shape} name="s1_shape" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.shape && attributes.shape.map((shape) => {
                                                                        return (
                                                                            <option value={shape.name}>{shape.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s1_color} name="s1_color" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.color && attributes.color.map((color) => {
                                                                        return (
                                                                            <option value={color.name}>{color.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s1_clarity} name="s1_clarity" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.clarity && attributes.clarity.map((clarity) => {
                                                                        return (
                                                                            <option value={clarity.name}>{clarity.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" placeholder="s1_pieces" name="s1_pieces" value={addproduct.s1_pieces} onChange={handleInputChange} />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" placeholder="s1_carat" name="s1_carat" value={addproduct.s1_carat} onChange={handleInputChange} />

                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th scope="row">S2</th>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s2_stone} name="s2_stone" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.stone_type && attributes.stone_type.map((stone_type) => {
                                                                        return (
                                                                            <option value={stone_type.name}>{stone_type.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s2_shape} name="s2_shape" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.shape && attributes.shape.map((shape) => {
                                                                        return (
                                                                            <option value={shape.name}>{shape.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s2_color} name="s2_color" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.color && attributes.color.map((color) => {
                                                                        return (
                                                                            <option value={color.name}>{color.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s2_clarity} name="s2_clarity" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.clarity && attributes.clarity.map((clarity) => {
                                                                        return (
                                                                            <option value={clarity.name}>{clarity.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" placeholder="s2_pieces" name="s2_pieces" value={addproduct.s2_pieces} onChange={handleInputChange} />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" placeholder="s2_carat" name="s2_carat" value={addproduct.s2_carat} onChange={handleInputChange} />

                                                        </div>
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <th scope="row">S3</th>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s3_stone} name="s3_stone" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.stone_type && attributes.stone_type.map((stone_type) => {
                                                                        return (
                                                                            <option value={stone_type.name}>{stone_type.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s3_shape} name="s3_shape" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.shape && attributes.shape.map((shape) => {
                                                                        return (
                                                                            <option value={shape.name}>{shape.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s3_color} name="s3_color" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.color && attributes.color.map((color) => {
                                                                        return (
                                                                            <option value={color.name}>{color.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s3_clarity} name="s3_clarity" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.clarity && attributes.clarity.map((clarity) => {
                                                                        return (
                                                                            <option value={clarity.name}>{clarity.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" placeholder="s3_pieces" name="s3_pieces" value={addproduct.s3_pieces} onChange={handleInputChange} />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" placeholder="s3_carat" name="s3_carat" value={addproduct.s3_carat} onChange={handleInputChange} />

                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th scope="row">S4</th>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s4_stone} name="s4_stone" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.stone_type && attributes.stone_type.map((stone_type) => {
                                                                        return (
                                                                            <option value={stone_type.name}>{stone_type.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s4_shape} name="s4_shape" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.shape && attributes.shape.map((shape) => {
                                                                        return (
                                                                            <option value={shape.name}>{shape.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s4_color} name="s4_color" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.color && attributes.color.map((color) => {
                                                                        return (
                                                                            <option value={color.name}>{color.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s4_clarity} name="s4_clarity" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.clarity && attributes.clarity.map((clarity) => {
                                                                        return (
                                                                            <option value={clarity.name}>{clarity.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" placeholder="s4_pieces" name="s4_pieces" value={addproduct.s4_pieces} onChange={handleInputChange} />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" placeholder="s4_carat" name="s4_carat" value={addproduct.s4_carat} onChange={handleInputChange} />

                                                        </div>
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <th scope="row">S5</th>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s5_stone} name="s5_stone" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.stone_type && attributes.stone_type.map((stone_type) => {
                                                                        return (
                                                                            <option value={stone_type.name}>{stone_type.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s5_shape} name="s5_shape" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.shape && attributes.shape.map((shape) => {
                                                                        return (
                                                                            <option value={shape.name}>{shape.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s5_color} name="s5_color" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.color && attributes.color.map((color) => {
                                                                        return (
                                                                            <option value={color.name}>{color.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <select className="form-control" value={addproduct.s5_clarity} name="s5_clarity" onChange={handleInputChange}>
                                                                <option value="">{`Select`}</option>
                                                                {
                                                                    attributes && attributes.clarity && attributes.clarity.map((clarity) => {
                                                                        return (
                                                                            <option value={clarity.name}>{clarity.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" placeholder="s5_pieces" name="s5_pieces" value={addproduct.s5_pieces} onChange={handleInputChange} />

                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <input className="form-control" type="text" placeholder="s5_carat" name="s5_carat" value={addproduct.s5_carat} onChange={handleInputChange} />

                                                        </div>
                                                    </td>
                                                </tr>



                                            </tbody>
                                        </table>


                                        <hr className="w-100" />

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Comment (optional)</label>
                                                    <textarea onChange={handleInputChange} placeholder="Stone looks milky" rows="5" className="form-control" value={addproduct.comment} name="comment"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <h3 className="forms toBrdr">
                                            Upload Product Photos or Videos
                                            <p>JPEG, PNG supported, max 10mb per image</p>
                                        </h3>

                                        <div className="row imgEdits mb-4">
                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_1} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(1)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_2} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(2)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_3} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(3)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_4} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(4)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_5} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(5)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row actions my-5">
                                            <div className="col-md-6">
                                                <button type="submit" className="btns btn-black  mr-2 mb-2">Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default Jewellery
