import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAttributes } from "../../actions/productActions";
import { Year } from '../../helper';
import { validateFields } from '../../validation';
import classnames from 'classnames';
import $ from 'jquery';
import { IMAGES } from '../../constants';

const Watch = ({ props, handleEditExcelForm }) => {

    const dispatch = useDispatch();

    const [addproduct, setAddProduct] = useState({
        stock_no: "",
        brand: "",
        case_material: "",
        case_size_mm: "",
        w_condition: "",
        dial_numerals: "",
        dial_type: "",
        functions: "",
        gender: "",
        model: "",
        movement: "",
        papers_box: "",
        price: "",
        reference: "",
        strap_color: "",
        year: "",
        bezel_material: "",
        comment: "",
        strap_material: "",
        showcase_image: "",
        image_1: "",
        image_2: "",
        image_3: "",
        image_4: "",
        image_5: "",
        description:""
    })

    const [validerror, setValidError] = useState({
        stock_numberError: "",
        brandError: "",
        modelError: "",
        strap_colorError: "",
        case_sizeError: "",
        yearError: "",
        papers_boxError: "",
        conditionError: "",
        genderError: "",
        movementError: "",
        functionsError: "",
        dial_numeralsError: "",
        dial_typeError: "",
        case_materialError: "",
        bezel_materialError: "",
        priceError: "",
        strap_materialError: ""
    });

    const handleInputChange = (event) => {
        setValidError({
            stock_numberError: "",
            brandError: "",
            modelError: "",
            strap_colorError: "",
            case_sizeError: "",
            yearError: "",
            papers_boxError: "",
            conditionError: "",
            genderError: "",
            movementError: "",
            functionsError: "",
            dial_numeralsError: "",
            dial_typeError: "",
            case_materialError: "",
            bezel_materialError: "",
            priceError: "",
            strap_materialError: ""
        })
        setAddProduct({ ...addproduct, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const stock_numberError = validateFields.validateStock_Number(addproduct.stock_no);
        const brandError = validateFields.validateBrand(addproduct.brand);
        const modelError = validateFields.validateModel(addproduct.model);
        const strap_colorError = validateFields.validateStrapColor(addproduct.strap_color);
        const case_sizeError = validateFields.validateCaseSize(addproduct.case_size_mm);
        const yearError = validateFields.validateYear(addproduct.year);
        const papers_boxError = validateFields.validatePapers(addproduct.papers_box);
        const conditionError = validateFields.validateCondition(addproduct.w_condition);
        const genderError = validateFields.validateGender(addproduct.gender);
        const movementError = validateFields.validateMovement(addproduct.movement);
        const functionsError = validateFields.validateFunction(addproduct.functions);
        const dial_numeralsError = validateFields.validateDialNumeral(addproduct.dial_numerals);
        const dial_typeError = validateFields.validateDialType(addproduct.dial_type);
        const case_materialError = validateFields.validateCaseMaterial(addproduct.case_material);
        const bezel_materialError = validateFields.validateBezelMaterial(addproduct.bezel_material);
        const priceError = validateFields.validatePrice(addproduct.price);
        const strap_materialError = validateFields.validateStrapMaterial(addproduct.strap_material);

        if ([stock_numberError, brandError, modelError, strap_colorError, case_sizeError, yearError, papers_boxError, conditionError, genderError, movementError, functionsError, dial_numeralsError, dial_typeError, case_materialError, bezel_materialError, priceError, strap_materialError].every(e => e === false)) {
            setValidError({
                ...validerror, stock_numberError, brandError, modelError, strap_colorError, case_sizeError, yearError, papers_boxError, conditionError, genderError, movementError, functionsError, dial_numeralsError, dial_typeError, case_materialError, bezel_materialError, priceError, strap_materialError
            })
            handleEditExcelForm(addproduct);
            $('#dismiss-modal').trigger('click');
        }
        else {
            setValidError({
                ...validerror, stock_numberError, brandError, modelError, strap_colorError, case_sizeError, yearError, papers_boxError, conditionError, genderError, movementError, functionsError, dial_numeralsError, dial_typeError, case_materialError, bezel_materialError, priceError, strap_materialError
            })
        }
        return;
    }

    const { attributes } = useSelector(state => state.attributes)

    useEffect(() => {
        dispatch(getAttributes('watches'))
    }, [dispatch])

    useEffect(() => {
        if (props) {
            setAddProduct({
                stock_no: props && props.stock_no,
                brand: props && props.brand,
                case_material: props && props.case_material,
                case_size_mm: props && props.case_size_mm,
                w_condition: props && props.condition,
                dial_numerals: props && props.dial_numerals,
                dial_type: props && props.dial_type,
                functions: props && props.functions,
                gender: props && props.gender,
                model: props && props.model,
                movement: props && props.movement,
                papers_box: props && props.papers_box,
                price: props && props.price,
                reference: props && props.reference,
                strap_color: props && props.strap_color,
                year: props && props.year,
                bezel_material: props && props.bezel_material,
                comment: props && props.supplier_comment,
                strap_material: props && props.strap_material,
                showcase_image: props && props.showcase_image,
                image_1: props && props.image_1,
                image_2: props && props.image_2,
                image_3: props && props.image_3,
                image_4: props && props.image_4,
                image_5: props && props.image_5,
                description:props && props.description
            })
        }
    }, [props])


    const delete_image = (key) => {
        if(key === 1)
        {
            setAddProduct({...addproduct, image_1:false})
        }
        else if(key === 2)
        {
            setAddProduct({...addproduct, image_2:false})
        }
        else if(key === 3)
        {
            setAddProduct({...addproduct, image_3:false})
        }
        else if(key === 4)
        {
            setAddProduct({...addproduct, image_4:false})
        }
        else if(key === 5)
        {
            setAddProduct({...addproduct, image_5:false})
        }
    }


    return (
        <Fragment>
            <div className="modal fade editproduct_form" id="editproductwatch" tabindex="-1" role="dialog" aria-labelledby="editproductTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <form onSubmit={handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header align-items-center">
                                <button id="dismiss-modal" type="button" className="close m-0" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h5 className="modal-title" id="editproductTitle">Watches</h5>
                                <button type="submit" className="btn btn-black">Save Changes</button>
                            </div>
                            <div className="modal-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <div className="titles nbrd mt-4 mb-3 pb-3">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h2 className="m-0">Edit Product</h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Stock No.</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.stock_numberError === false }, { 'is-invalid': validerror.stock_numberError })} type="text" placeholder="Stock No." name="stock_no" value={addproduct.stock_no} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.stock_numberError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Description</label>
                                                    <input className="form-control" type="text" name="description" placeholder="Description" value={addproduct.description} onChange={handleInputChange} />
                                                </div>
                                            </div>

                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Brand</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.brandError === false }, { 'is-invalid': validerror.brandError })} value={addproduct.brand} name="brand" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.brand && attributes.brand.map((brand) => {
                                                                return (
                                                                    <option value={brand.name}>{brand.name}</option>
                                                                )
                                                            })
                                                        }

                                                    </select>
                                                    <p className="invalid-feedback">{validerror.brandError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Model</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.modelError === false }, { 'is-invalid': validerror.modelError })} name="model" value={addproduct.model} onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.model && attributes.model.map((model) => {
                                                                return (
                                                                    <option value={model.name}>{model.name}</option>
                                                                )
                                                            })
                                                        }

                                                    </select>
                                                    <p className="invalid-feedback">{validerror.modelError}</p>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Reference No</label>
                                                    <input onChange={handleInputChange} name="reference" className="form-control" type="text" placeholder="Reference No." value={addproduct.reference} />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Movement</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.movementError === false }, { 'is-invalid': validerror.movementError })} value={addproduct.movement} onChange={handleInputChange} name="movement">
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.movement && attributes.movement.map((movement) => {
                                                                return (
                                                                    <option value={movement.name}>{movement.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.movementError}</p>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Case material</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.case_materialError === false }, { 'is-invalid': validerror.case_materialError })} value={addproduct.case_material} onChange={handleInputChange} name="case_material">
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.case_material && attributes.case_material.map((case_material) => {
                                                                return (
                                                                    <option value={case_material.name}>{case_material.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.case_materialError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Case Size</label>
                                                    <input onChange={handleInputChange} className={classnames("form-control", { 'is-valid': validerror.case_sizeError === false }, { 'is-invalid': validerror.case_sizeError })} type="text" placeholder="case size" value={addproduct.case_size_mm} name="case_size_mm" />
                                                    <p className="invalid-feedback">{validerror.case_sizeError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Year of production</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.yearError === false }, { 'is-invalid': validerror.yearError })} value={addproduct.year} name="year" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            Year() && Year().map((year) => {
                                                                return (
                                                                    <option value={year}>{year}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.yearError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Condition</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.conditionError === false }, { 'is-invalid': validerror.conditionError })} value={addproduct.w_condition} name="w_condition" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.condition && attributes.condition.map((condition) => {
                                                                return (
                                                                    <option value={condition.name}>{condition.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.conditionError}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Gender</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.genderError === false }, { 'is-invalid': validerror.genderError })} value={addproduct.gender} name="gender" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.gender && attributes.gender.map((gender) => {
                                                                return (
                                                                    <option value={gender.name}>{gender.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.genderError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Functions</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.functionsError === false }, { 'is-invalid': validerror.functionsError })} value={addproduct.functions} name="functions" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.functions && attributes.functions.map((functions) => {
                                                                return (
                                                                    <option value={functions.name}>{functions.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.functionsError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Papers & Box</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.papers_boxError === false }, { 'is-invalid': validerror.papers_boxError })} value={addproduct.papers_box} name="papers_box" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.papers_box && attributes.papers_box.map((papers_box) => {
                                                                return (
                                                                    <option value={papers_box.name}>{papers_box.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.papers_boxError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Dial Numerals</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.dial_numeralsError === false }, { 'is-invalid': validerror.dial_numeralsError })} value={addproduct.dial_numerals} name="dial_numerals" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.dial_numerals && attributes.dial_numerals.map((dial_numerals) => {
                                                                return (
                                                                    <option value={dial_numerals.name}>{dial_numerals.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.dial_numeralsError}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Bezel material</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.bezel_materialError === false }, { 'is-invalid': validerror.bezel_materialError })} value={addproduct.bezel_material} name="bezel_material" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.bezel_material && attributes.bezel_material.map((bezel_material) => {
                                                                return (
                                                                    <option value={bezel_material.name}>{bezel_material.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.bezel_materialError}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Dial Type</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.dial_typeError === false }, { 'is-invalid': validerror.dial_typeError })} value={addproduct.dial_type} name="dial_type" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.dial_type && attributes.dial_type.map((dial_type) => {
                                                                return (
                                                                    <option value={dial_type.name}>{dial_type.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.dial_typeError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Strap material</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.strap_materialError === false }, { 'is-invalid': validerror.strap_materialError })} value={addproduct.strap_material} name="strap_material" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.strap_material && attributes.strap_material.map((strap_material) => {
                                                                return (
                                                                    <option value={strap_material.name}>{strap_material.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.strap_materialError}</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Strap Color</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.strap_colorError === false }, { 'is-invalid': validerror.strap_colorError })} value={addproduct.strap_color} name="strap_color" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.strap_color && attributes.strap_color.map((strap_color) => {
                                                                return (
                                                                    <option value={strap_color.name}>{strap_color.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.strap_colorError}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                           <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Price</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.priceError === false }, { 'is-invalid': validerror.priceError })} type="text" name="price" placeholder="Price" value={addproduct.price} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.priceError}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="w-100" />

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Comment (optional)</label>
                                                    <textarea onChange={handleInputChange} placeholder="Stone looks milky" rows="5" className="form-control" value={addproduct.comment} name="comment"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <h3 className="forms toBrdr">
                                            Upload Product Photos or Videos
                                            <p>JPEG, PNG supported, max 10mb per image</p>
                                        </h3>

                                        <div className="row imgEdits mb-4">
                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_1} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(1)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_2} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(2)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_3} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(3)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_4} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(4)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_5} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(5)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row actions my-5">
                                            <div className="col-md-6">
                                                <button type="submit" className="btns btn-black  mr-2 mb-2">Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default Watch
