import React, { Fragment, useEffect } from 'react';
import ProtectedHeader from '../../layout/ProtectedHeader';
import { useDispatch, useSelector } from 'react-redux';
import Chatbox from './Chatbox';
import Users from './Users';
import { useAlert } from 'react-alert';
import mixpanel from 'mixpanel-browser';
import { FormattedMessage } from 'react-intl';
import { socket } from '../../../utils/socket';
import { getChatUserAction } from '../../../actions/chatAction';
const Index = (props) => {

    if (socket.connected === false) {
        socket.connected = true;
        // socket.disconnected = false;
    }
    const alert = useAlert();
    const { addChatUser } = useSelector(state => state.addChatUser);
    const { chatuser } = useSelector(state => state.chatuser);
    useEffect(() => {
        if (addChatUser) {
            // alert.success(addChatUser);
        }
    }, [addChatUser])

    useEffect(() => {

        mixpanel.track('Chat')
    }, [])

    return (
        <Fragment>
            <ProtectedHeader />
            <ul className="breadcrub">
                <li><FormattedMessage id="Messages" defaultMessage="Messages" /></li>
            </ul>

            <section className="container-fluid py-4 conPart">
                <div className="row">
                    <Users />
                    <Chatbox productDetails={props?.location?.state?.productDetails} />
                </div>
            </section>
        </Fragment>
    )
}

export default Index
