import React, { Fragment, useEffect } from 'react';
import { url } from '../../../config';
import { IMAGES } from '../../../constants';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';
import MetaData from '../../layout/MetaData';
import ProtectedHeader from '../../layout/ProtectedHeader';
import { flip } from '../../../helper';
import { makeRequestGet } from '../../../utils/apiHelper';
import Loader from '../../layout/Loader';
import $ from 'jquery';
import { FormattedMessage } from 'react-intl';


const NewPayment = () => {

    const paymentForm = async () => {

        const data = await makeRequestGet('payment?first_name=akshit&last_name=gupta&email=aks@gmail.com&amount=40&user_id=2&number=9874587458&address=address&city=Istanbul&country=Turkey&zipcode=34732&ip=85.34.78.112&item_name=subscription&item_category=Collectibles');


        $('script.payment-script').remove();
        let newData = data && data.data && data.data.res.replace('<script type="text/javascript">', '')
        newData = newData.replace('</script>', '')
        let myScript = document.createElement('script');
        myScript.setAttribute('class', 'payment-script');
        myScript.innerHTML = newData;
        document.body.appendChild(myScript)
    }
    // let loading;
    // useEffect(async () => {
    //     loading = true;
    //     const data = await makeRequestGet('payment?first_name=akshit&last_name=gupta&email=aks@gmail.com&amount=40&user_id=2&number=9874587458&address=address&city=Istanbul&country=Turkey&zipcode=34732&ip=85.34.78.112&item_name=subscription&item_category=Collectibles');
    //     if(data)
    //     {
    //         loading = false;
    //     }
    //     let newData = data && data.data && data.data.res.replace('<script type="text/javascript">', '')
    //     newData = newData.replace('</script>', '')
    //     let myScript = document.createElement('script');
    //     myScript.setAttribute('class', 'payment-script');
    //     myScript.innerHTML = newData;
    //     document.body.appendChild(myScript)
    // })



    return (
        <Fragment>
            {/* {loading && <Loader />} */}
            <MetaData title={`New Payment`} />
            <ProtectedHeader />
            <ul className="breadcrub">
                <li>
                    <FormattedMessage
                        id="myaccount.myaccountname"
                        defaultMessage="myaccount"
                    />
                </li>
                <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                <li>
                    <FormattedMessage
                        id="myaccount.payment"
                        defaultMessage="Payment info"
                    />
                </li>
            </ul>

            <section className="container-fluid py-4 conPart">
                <div className="row">
                    <Sidebar />
                    <div className="col-md-9">
                        <Link to={url + '/edit-billing'}><h2 className="mt-4 mb-3"><img src={IMAGES.BACK_BUTTON_SVG} className="mr-2" />
                            <FormattedMessage
                                id="myaccount.payment"
                                defaultMessage="Payment info"
                            />
                        </h2></Link>
                        <div className="row   flip_card  mb-2">
                            <div className="col-lg-6 col-md-8">
                                <span style={{ cursor: 'pointer' }} onClick={flip} className="flip_btn">Flip card <span className="icon-arrow_left"></span></span>
                                <div className="card debit-card">
                                    <div className="front">
                                        <div className="credit_card_box">
                                            <div className="card_name d-flex justify-content-between">
                                                <div className="form-group text-left">
                                                    <p>Cardholder Name</p>
                                                    <h3>Daniel Carter</h3>
                                                </div>
                                                <span><img src={IMAGES.VISA_IMG} alt="" /></span>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-md-3 col-6">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="0000" className="form-control" />

                                                    </div>

                                                </div>
                                                <div className="col-md-3 col-6">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="0000" className="form-control" />

                                                    </div>

                                                </div>
                                                <div className="col-md-3 col-6">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="0000" className="form-control" />

                                                    </div>

                                                </div>
                                                <div className="col-md-3 col-6">
                                                    <div className="form-group">
                                                        <input type="text" placeholder="0000" className="form-control" />

                                                    </div>

                                                </div>
                                                <div className="col-lg--6 col-md-4 col-4">
                                                    <p className="exe">Expires at:</p>
                                                </div>
                                                <div className="col-lg-3 col-md-4 col-4">
                                                    <div className="form-group">

                                                        <select name="" id="" className="form-control">
                                                            <option value="">Month</option>
                                                            <option value="">09/24</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-md-4 col-4">
                                                    <div className="form-group">
                                                        <select name="" id="" className="form-control">
                                                            <option value="">Year</option>
                                                            <option value="">2020</option>
                                                        </select>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="back">
                                        <div className="credit_card_box">
                                            <div className="divider"></div>
                                            <div className="row pl-3 pr-3 align-items-center">

                                                <div className="col-md-8">
                                                    <p className="exe"><img src={IMAGES.VISA_IMG} alt="" /></p>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group d-flex align-items-center">
                                                        <label htmlFor="" className="mr-3 mb-0">Cvv</label>
                                                        <input type="text" placeholder="* * *" className="form-control" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="row  mb-3">
                            <div className="col-lg-6 col-md-8">
                                <div className="form-group chkbx">

                                    <input type="checkbox" id="check1" />
                                    <label htmlFor="check1" className="fr">Set it as my preferred card</label>
                                </div>
                            </div>
                        </div>
                        <div className="row actions my-3">
                            <div className="col-md-6">
                                <button onClick={paymentForm} className="payment-gateway btns mr-3 sndBtn" >Add payment method</button>
                            </div>
                        </div>
                        {/* <div id="iyzipay-checkout-form" className="responsive"></div> */}
                    </div>
                </div>

            </section>

        </Fragment >
    )
}

export default NewPayment
