import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import $ from 'jquery';
import React, { Fragment, useEffect } from 'react';
import { url } from '../../../config';
import { getLastURL } from '../../../helper';
import { FormattedMessage } from 'react-intl';
import { GetFavoriteCategory } from '../../../actions/productActions';


const Sidebar = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const path = getLastURL(location);

    const { FavoriteCategory } = useSelector(state => state.FavoriteCategory);

    useEffect(() => {
        $('.favorites-sidebar li').removeClass('active');
        $('.' + path).addClass('active');
    }, [path, FavoriteCategory])


    useEffect(() => {
        dispatch(GetFavoriteCategory());
    }, [dispatch])

    return (
        <Fragment>
            <div className="col-md-3 lefts pl-5">
                <h2 className="mt-4 mb-3">
                    <FormattedMessage
                        id="Favorites"
                        defaultMessage="Favorites"
                    />
                </h2>
                <ul className="favorites-sidebar">
                    {
                        FavoriteCategory && FavoriteCategory.categories && FavoriteCategory.categories.map((category) => {
                            return (
                                <li className={category.slug}>
                                    <Link to={url + '/favorites/' + category.slug}>
                                        <FormattedMessage
                                            id={"category." + category.slug}
                                            defaultMessage={category.name}
                                        />
                                        <span className="pro_nub"> {category.productsCount} </span>
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </Fragment>
    )
}

export default Sidebar
