import {
    ALL_PRODUCTS_REQUEST,
    ALL_PRODUCTS_SUCCESS,
    DOWNLOAD_REQUEST,
    DOWNLOAD_SUCCESS,
    DOWNLOAD_FAIL,
    ALL_PRODUCTS_FAIL,
    CLEAR_ERRORS,
    ALL_PRODUCT_TYPE_REQUEST,
    ALL_PRODUCT_TYPE_SUCCESS,
    ALL_PRODUCT_TYPE_FAIL,
    ALL_ATTRIBUTES_REQUEST,
    ALL_ATTRIBUTES_SUCCESS,
    ALL_ATTRIBUTES_FAIL,
    ADD_PRODUCT_REQUEST,
    ADD_PRODUCT_SUCCESS,
    ADD_PRODUCT_FAIL,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    MESSAGE_TRANSFER_REQUEST,
    MESSAGE_TRANSFER_SUCCESS,
    MESSAGE_TRANSFER_FAIL,
    MY_PRODUCT_REQUEST,
    MY_PRODUCT_SUCCESS,
    MY_PRODUCT_FAIL,
    MY_PRODUCT_SELECTACTION_REQUEST,
    MY_PRODUCT_SELECTACTION_SUCCESS,
    MY_PRODUCT_SELECTACTION_FAIL,
    MY_PRODUCT_ACTIVEALL_REQUEST,
    MY_PRODUCT_ACTIVEALL_SUCCESS,
    MY_PRODUCT_ACTIVEALL_FAIL,
    ADD_FAVORITE_REQUEST,
    ADD_FAVORITE_SUCCESS,
    ADD_FAVORITE_FAIL,
    GET_FAVORITE_REQUEST,
    GET_FAVORITE_SUCCESS,
    GET_FAVORITE_FAIL,
    GET_FAVORITE_CATEGORY_REQUEST,
    GET_FAVORITE_CATEGORY_SUCCESS,
    GET_FAVORITE_CATEGORY_FAIL,
    GET_PRODUCT_COUNT_REQUEST,
    GET_PRODUCT_COUNT_SUCCESS,
    GET_PRODUCT_COUNT_FAIL,
    GET_MODEL_ATTRIBUTES_REQUEST,
    GET_MODEL_ATTRIBUTES_SUCCESS,
    GET_MODEL_ATTRIBUTES_FAIL,
    GET_RATING_TAGS_REQUEST,
    GET_RATING_TAGS_SUCCESS,
    GET_RATING_TAGS_FAIL,
    UPDATE_SINGLE_PRODUCT_REQUEST,
    UPDATE_SINGLE_PRODUCT_SUCCESS,
    UPDATE_SINGLE_PRODUCT_FAIL,
    ALL_SELLER_REQUEST,
    ALL_SELLER_SUCCESS,
    ALL_SELLER_FAIL,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    ADVERTISEMENT_REQUEST,
    ADVERTISEMENT_SUCCESS,
    ADVERTISEMENT_FAIL,
    Ad_PLAN_REQUEST,
    Ad_PLAN_FAIL,
    Ad_PLAN_SUCCESS,
    CHECK_PLAN_REQUEST,
    CHECK_PLAN_FAIL,
    CHECK_PLAN_SUCCESS,
    CHECK_PLAN_RESET,
    ADVERTISE_PRODUCT_REQUEST,
    ADVERTISE_PRODUCT_SUCCESS,
    ADVERTISE_PRODUCT_FAIL,
    MY_PRODUCT_STATUS_REQUEST,
    MY_PRODUCT_STATUS_SUCCESS,
    MY_PRODUCT_STATUS_FAIL
} from '../constants/productConstants';
import { CLEAR_MESSAGE } from '../constants/userConstants';

export const adProductReducer = (state = { adproducts: [] }, action) => {
    switch (action.type) {
        case ADVERTISE_PRODUCT_REQUEST:
            return {
                loading: true,
                adproducts: []
            }

        case ADVERTISE_PRODUCT_SUCCESS:
            return {
                loading: false,
                adproducts: action.payload
            }

        case ADVERTISE_PRODUCT_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default: return state
    }
}


export const productReducer = (state = { products: [] }, action) => {
    switch (action.type) {
        case ALL_PRODUCTS_REQUEST:
            return {
                loading: true,  
                products: []
            }

        case ALL_PRODUCTS_SUCCESS:
            return {
                loading: false,
                products: action.payload
            }

        case ALL_PRODUCTS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default: return state
    }
}

export const downloadReducer = (state = { products: [] }, action) => {
    switch (action.type) {
        case DOWNLOAD_REQUEST:
            return {
                loading: true,
                documentDetail: []
            }

        case DOWNLOAD_SUCCESS:
            return {
                loading: false,
                documentDetail: action.payload
            }

        case DOWNLOAD_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default: return state
    }
}

export const AdvertisementPlanReducer = (state = { plan: [] }, action) => {

    switch (action.type) {
        case Ad_PLAN_REQUEST:
            return {
                loading: true,
                plan: []
            }
        case Ad_PLAN_SUCCESS:
            return {
                loading: false,
                plan: action.payload
            }
        case Ad_PLAN_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        default: return state
    }
}

export const ProductTypeReducer = (state = { productType: [] }, action) => {
    switch (action.type) {
        case ALL_PRODUCT_TYPE_REQUEST:
            return {
                loading: true,
                productType: []
            }
        case ALL_PRODUCT_TYPE_SUCCESS:
            return {
                loading: false,
                productType: action.payload
            }
        case ALL_PRODUCT_TYPE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}

export const ProductAttributes = (state = { attributes: [] }, action) => {
    switch (action.type) {
        case ALL_ATTRIBUTES_REQUEST:
            return {
                loading: true,
                attributes: []
            }
        case ALL_ATTRIBUTES_SUCCESS:
            return {
                loading: false,
                attributes: action.payload
            }
        case ALL_ATTRIBUTES_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}

export const AddProductReducer = (state = { message: null }, action) => {
    // console.log('action.type ============ ',action.type)
    switch (action.type) {
        case ADD_PRODUCT_REQUEST:
            return {
                loading: true,
                import_error: false,
                data: null
            }
        case "IMPORT_ERROR":
            return {
                loading: false,
                import_error: true,
                errormsg: action.payload,
                data: null
            }
        case ADD_PRODUCT_SUCCESS:
            return {
                loading: false,
                import_error: false,
                data: action.payload
            }
        case ADD_PRODUCT_FAIL:
            return {
                loading: false,
                import_error: false,
                error: action.payload
            }
        case CLEAR_MESSAGE:
            return {
                ...state,
                import_error: false,
                data: null
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                import_error: false,
                error: null
            }
        default: return state
    }
}

export const ProductDetailsReducer = (state = { details: null }, action) => {

    switch (action.type) {
        case PRODUCT_DETAILS_REQUEST:
            return {
                loading: true,
                details: null
            }
        case PRODUCT_DETAILS_SUCCESS:
            return {
                loading: false,
                details: action.payload
            }
        case PRODUCT_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}


export const MyProductReducer = (state = { myproduct: null }, action) => {
    switch (action.type) {
        case MY_PRODUCT_REQUEST:
            return {
                loading: true,
                myproduct: null
            }
        case MY_PRODUCT_SUCCESS:
            return {
                loading: false,
                myproduct: action.payload
            }
        case MY_PRODUCT_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}


export const MyProductSelectActionReducer = (state = { myproductselectaction: null }, action) => {
    switch (action.type) {
        case MY_PRODUCT_SELECTACTION_REQUEST:
            return {
                loading: true,
                myproductselectaction: null
            }
        case MY_PRODUCT_SELECTACTION_SUCCESS:
            return {
                loading: false,
                myproductselectaction: action.payload
            }
        case MY_PRODUCT_SELECTACTION_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_MESSAGE:
            return {
                ...state,
                myproductselectaction: null
            }
        default: return state
    }
}


export const MyProductStatusActionReducer = (state = { myproductstatusaction: null }, action) => {
    switch (action.type) {
        case MY_PRODUCT_STATUS_REQUEST:
            return {
                loading: true,
                myproductstatusaction: null
            }
        case MY_PRODUCT_STATUS_SUCCESS:
            return {
                loading: false,
                myproductstatusaction: action.payload
            }
        case MY_PRODUCT_STATUS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_MESSAGE:
            return {
                ...state,
                myproductstatusaction: null
            }
        default: return state
    }
}

export const MyProductActiveAllReducer = (state = { myproductactiveall: null }, action) => {
    switch (action.type) {
        case MY_PRODUCT_ACTIVEALL_REQUEST:
            return {
                loading: true,
                myproductactiveall: null
            }
        case MY_PRODUCT_ACTIVEALL_SUCCESS:
            return {
                loading: false,
                myproductactiveall: action.payload
            }
        case MY_PRODUCT_ACTIVEALL_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_MESSAGE:
            return {
                ...state,
                myproductactiveall: null
            }
        default: return state
    }
}


export const AddFavoriteReducer = (state = { addFavorite: null }, action) => {
    switch (action.type) {
        case ADD_FAVORITE_REQUEST:
            return {
                loading: true,
                addFavorite: null
            }
        case ADD_FAVORITE_SUCCESS:
            return {
                loading: false,
                addFavorite: action.payload
            }
        case ADD_FAVORITE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}


export const GetFavoriteReducer = (state = { getFavorite: null }, action) => {
    switch (action.type) {
        case GET_FAVORITE_REQUEST:
            return {
                loading: true,
                getFavorite: null
            }
        case GET_FAVORITE_SUCCESS:
            return {
                loading: false,
                getFavorite: action.payload
            }
        case GET_FAVORITE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}


export const GetFavoriteCategoryReducer = (state = { FavoriteCategory: null }, action) => {
    switch (action.type) {
        case GET_FAVORITE_CATEGORY_REQUEST:
            return {
                loading: true,
                FavoriteCategory: null
            }
        case GET_FAVORITE_CATEGORY_SUCCESS:
            return {
                loading: false,
                FavoriteCategory: action.payload
            }
        case GET_FAVORITE_CATEGORY_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}


export const GetProductCountReducer = (state = { ProductCount: null }, action) => {
    switch (action.type) {
        case GET_PRODUCT_COUNT_REQUEST:
            return {
                loading: true,
                ProductCount: null
            }
        case GET_PRODUCT_COUNT_SUCCESS:
            return {
                loading: false,
                ProductCount: action.payload
            }
        case GET_PRODUCT_COUNT_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}



export const GetModelAttributeReducer = (state = { modelattribute: null }, action) => {
    switch (action.type) {
        case GET_MODEL_ATTRIBUTES_REQUEST:
            return {
                loading: true,
                modelattribute: null
            }
        case GET_MODEL_ATTRIBUTES_SUCCESS:
            return {
                loading: false,
                modelattribute: action.payload
            }
        case GET_MODEL_ATTRIBUTES_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}

export const GetRatingTagReducer = (state = { ratingTags: null }, action) => {

    switch (action.type) {
        case GET_RATING_TAGS_REQUEST:
            return {
                loading: true,
                ratingTags: null
            }
        case GET_RATING_TAGS_SUCCESS:
            return {
                loading: false,
                ratingTags: action.payload
            }
        case GET_RATING_TAGS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}


export const UpdateSingleProductReducer = (state = { updateproduct: null }, action) => {
    switch (action.type) {
        case UPDATE_SINGLE_PRODUCT_REQUEST:
            return {
                loading: true,
                updateproduct: null
            }
        case UPDATE_SINGLE_PRODUCT_SUCCESS:
            return {
                loading: false,
                updateproduct: action.payload
            }
        case UPDATE_SINGLE_PRODUCT_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_MESSAGE:
            return {
                ...state,
                updateproduct: null
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: return state
    }
}


export const updateDiamondReducer = (state = { updateproduct: null }, action) => {
    switch (action.type) {
        case UPDATE_PRODUCT_REQUEST:
            return {
                loading: true,
                updateproduct: null
            }
        case UPDATE_PRODUCT_SUCCESS:
            return {
                loading: false,
                updateproduct: action.payload
            }
        case UPDATE_PRODUCT_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_MESSAGE:
            return {
                ...state,
                updateproduct: null
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default: return state
    }
}


export const getSelersReducer = (state = { ProductCount: null }, action) => {

    switch (action.type) {
        case ALL_SELLER_REQUEST:
            return {
                loading: true,
                data: null
            }
        case ALL_SELLER_SUCCESS:
            return {
                loading: false,
                sellers: action.payload
            }
        case ALL_SELLER_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}

export const AdvertisementReducer = (state = { myproduct: null }, action) => {
    switch (action.type) {
        case ADVERTISEMENT_REQUEST:
            return {
                loading: true,
                myproduct: null
            }
        case ADVERTISEMENT_SUCCESS:
            return {
                loading: false,
                myproduct: action.payload
            }
        case ADVERTISEMENT_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}


export const checkPlanReducer = (state = { checkPlan: null }, action) => {
    switch (action.type) {
        case CHECK_PLAN_RESET:
            return {
                loading: false,
                checkPlan: null
            }
        case CHECK_PLAN_REQUEST:
            return {
                loading: true,
                checkPlan: null
            }
        case CHECK_PLAN_SUCCESS:
            return {
                loading: false,
                checkPlan: action.payload
            }
        case CHECK_PLAN_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}

const initialStateFilter = {
    loading: false,
    status: true,
    data: null,
    error: null
}

export const DownloadPdfWithFilterReducer = (state = initialStateFilter, action) => {
    // console.log("DownloadPdfWithFilterReducer>>>>>>>>>>>>>>>>>",action?.payload);
    switch (action.type) {
        case "APPLY_FILTER_SUCCESS":
            return {
                ...state,
                loading: false,
                status: true,
                data: action?.payload
            }
        case "APPLY_FILTER_FAIL":
            return {
                ...state,
                loading: false,
                status: false,
                error: action?.payload
            }
        default:
            return state
    }
}




export const SoryByFilterReducer = (state = initialStateFilter, action) => {
    // console.log("DownloadPdfWithFilterReducer>>>>>>>>>>>>>>>>>",action?.payload);
    switch (action.type) {
        case "SORT_BY_FILTER_SUCCESS":
            return {
                ...state,
                loading: false,
                status: true,
                data: action?.payload
            }
        case "SORT_BY_FILTER_FAIL":
            return {
                ...state,
                loading: false,
                status: false,
                error: action?.payload
            }
        default:
            return state
    }


}

export const MessaageTransferReducer = (state = { details: null }, action) => {

    switch (action.type) {
        case MESSAGE_TRANSFER_REQUEST:
            return {
                loading: true,
                details: null
            }
        case MESSAGE_TRANSFER_SUCCESS:
            return {
                loading: false,
                details: action.payload
            }
        case MESSAGE_TRANSFER_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default: return state
    }
}
