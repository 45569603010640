import React, { Fragment, useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import WeddingBand from './WeddingBand';
import ProtectedHeader from '../../layout/ProtectedHeader';
import MetaData from '../../layout/MetaData';
import { IMAGES } from '../../../constants';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { getAllProducts } from '../../../actions/productActions';
import { getLastURL } from '../../../helper';
import mixpanel from 'mixpanel-browser';
import { FormattedMessage } from 'react-intl';


const Index = () => {


    const dispatch = useDispatch();
    const location = useLocation();

    const pathname = getLastURL(location);

    const [pageReset, setPageReset] = useState(0)


    useEffect(() => {
        dispatch(getAllProducts(pathname));
    }, [dispatch])

    const tabChangeHandler = () => {
        setPageReset(0)
    }
    
    const updateResetPageHandler = (i) => {
        setPageReset(i);
    }

    useEffect(() =>{
        mixpanel.track('FindWeddingBand')
      }, [])


    return (
        <Fragment>
            <MetaData title={`Wedding Band`} />
            <ProtectedHeader />
            <ul className="breadcrub">
                <li><FormattedMessage id="AllCategories" defaultMessage="All Categories"/></li>
                <li><img src={IMAGES.ARROW_SVG} alt=""/></li>
                <li><FormattedMessage id="category.wedding-bands" defaultMessage="Wedding Bands"/></li>
                <li><img src={IMAGES.ARROW_SVG} alt=""/></li>
                <li><FormattedMessage id="SearchResults" defaultMessage="Search results"/> </li>
            </ul>
            <main className="sp-inner-page-body sp_dashboard">
                <section className="sp-order-req-sec">
                    <div className="container-fluid">
                        <span className="menu-btn">
                            <i className="icon-more-options" aria-hidden="true"></i>
                        </span>
                        <div className="dashboard-sec">
                            <div className="row">
                              <Sidebar  tabChangeHandler={tabChangeHandler} />
                              <WeddingBand  updateResetPageHandler={updateResetPageHandler} pageReset={pageReset}  tabChangeHandler={tabChangeHandler} />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Fragment>
    )
}

export default Index
