import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';


const Popup = () => {

    return (
        <Fragment>
            <div className="modal fade PopsData" id="popup" tabindex="-1" role="dialog" aria-labelledby="comment" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                       
                        <div className="modal-body">
                            <div className="succes">
                                <span className="icon">
                                <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                                </span>
                                <h4><FormattedMessage id="Success" defaultMessage="Success"/></h4>
                                <p><FormattedMessage id="submittedSuccessfully" defaultMessage="Your request was submitted successfully!"/></p>
                                <button id="dismiss-modal" type="button" className="close m-0" data-dismiss="modal" aria-label="Close">
                                     <span aria-hidden="true">&times;</span>
                                </button>
                                <button id="dismiss-modal" type="button" className="closes" data-dismiss="modal" aria-label="Close">
                                    Close
                                </button>
                            </div>


                            <div className="unsucces">
                                <span className="icon">
                                <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                                </span>
                                <h4>Error</h4>
                                <p>It appears that there was an issue submitting your request...</p>
                                <button id="dismiss-modal" type="button" className="close m-0" data-dismiss="modal" aria-label="Close">
                                     <span aria-hidden="true">&times;</span>
                                </button>
                                <button id="dismiss-modal" type="button" className="closes" data-dismiss="modal" aria-label="Close">
                                     Ok!
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Popup

