import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAttributes } from '../../../actions/productActions';
import { IMAGES } from '../../../constants';
import $, { data } from 'jquery';
import { getAllProductsFilter } from '../../../actions/productActions';
import { getLastURL, ShapeImageUrl } from '../../../helper';
import { useLocation } from 'react-router';
import { goldKaratJson, CaratJson } from '../../../utils/json';
import { FormattedMessage, useIntl } from 'react-intl';

const Sidebar = ({tabChangeHandler}) => {


    const dispatch = useDispatch();
    const location = useLocation();

    const pathname = getLastURL(location);

    const { attributes } = useSelector(state => state.attributes);
    const [price, setPrice] = useState({
        from: "",
        to: ""
    })
    const [JewelerystyleCheck, setJeweleryStyleCheck] = useState(new Array(attributes && attributes.style && attributes.style.length).fill(false));
    const [jewelery_style, setJeweleryStyle] = useState([]);
    const [goldkaratCheck, setGoldKaratCheck] = useState(new Array(goldKaratJson && goldKaratJson.length).fill(false));
    const [gold_karat, setGoldKarat] = useState([]);
    const [stonetypeCheck, setStoneTypeCheck] = useState(new Array(attributes && attributes.stone_type && attributes.stone_type.length).fill(false));
    const [stone_type, setStoneType] = useState([]);
    const [stoneshapeCheck, setStoneShapeCheck] = useState(new Array(attributes && attributes.stone_shape && attributes.stone_shape.length).fill(false));
    const [stone_shape, setStoneShape] = useState([]);
    const [carat, setCarat] = useState({
        from: "",
        to: ""
    })
    const [caratcheckboxCheck, setCaratCheckboxCheck] = useState(new Array(CaratJson && CaratJson.length).fill(false))
    const [whitediamondcolorCheck, setWhiteDiamondColorCheck] = useState(new Array(attributes && attributes.white_diamond_color && attributes.white_diamond_color.length).fill(false));
    const [whitediamondcolor, setWhiteDiamondColor] = useState([]);
    const [fancydiamondcolorCheck, setFancyDiamondColorCheck] = useState(new Array(attributes && attributes.fancy_diamond_color && attributes.fancy_diamond_color.length).fill(false));
    const [fancydiamondcolor, setFancyDiamondColor] = useState([]);
    const [clarityCheck, setClarityCheck] = useState(new Array(attributes && attributes.clarity && attributes.clarity.length).fill(false));
    const [clarity, setClarity] = useState([]);
    const [brandCheck, setBrandCheck] = useState(new Array(attributes && attributes.brand && attributes.brand.length).fill(false));
    const [brand, setBrand] = useState([]);
    const [type, setType] = useState();
    const [typename, setTypeName] = useState(localStorage.getItem('lang') == 'tr'?'yüzük':'rings');
    const [materialCheck, setMaterialCheck] = useState(new Array(attributes && attributes.metal_type && attributes.metal_type.length).fill(false));
    const [material, setMaterial] = useState([]);
    const [selectColor, setSelectColor] = useState(false);
    const [supplierCheck, setSupplierCheck] = useState(new Array(attributes && attributes.top_sellers && attributes.top_sellers.length).fill(false));
    const [supplier, setSupplier] = useState([]);


    useEffect(() => {
        dispatch(getAttributes('jewellery'))
    }, [dispatch])

    const loadMoreDefault = (p_class) => {
        let see_more = localStorage.getItem('lang') == 'tr'?'Daha fazla gör':'See More';
        let see_less = localStorage.getItem('lang') == 'tr'?'Daha Az Gör':'See Less';
        if ($('.' + p_class).hasClass('mainclass_hide')) {
            $('.' + p_class).removeClass('mainclass_hide')
            $('.' + p_class + ' .c_hide').hide()
            $('.' + p_class + ' .see_more').html(' <span className="icon-add_1"></span> &nbsp; '+see_more)
        } else {
            $('.' + p_class + ' .c_hide').show()
            $('.' + p_class).addClass('mainclass_hide')
            $('.' + p_class + ' .see_more').html(' <span className="icon-close_1"></span> &nbsp; '+see_less)
        }
    }


    
    const loadMoreShape = (p_class) => {
        let see_more = localStorage.getItem('lang') == 'tr'?'Daha fazla gör':'See More';
        let see_less = localStorage.getItem('lang') == 'tr'?'Daha Az Gör':'See Less';
        if ($('.' + p_class).hasClass('mainclass_hide')) {
            $('.' + p_class).removeClass('mainclass_hide')
            // $('.' + p_class + ' .c_hide').hide()
            $('.' + p_class + ' .c_hide').attr('style', 'display: none !important;')
            $('.' + p_class + ' .see_more').html(' <span className="icon-add_1"></span> &nbsp; '+see_more)
        } else {
            // $('.' + p_class + ' .c_hide').show()
            $('.' + p_class).addClass('mainclass_hide')
            $('.' + p_class + ' .c_hide').attr('style', 'display: inline-block !important;')
            $('.' + p_class + ' .see_more').html(' <span className="icon-close_1"></span> &nbsp; '+see_less)
        }
    }


    const handleFilterType = (id, name) => {
        // if (!type.includes(id)) {
        //     setType([...type, id]);
        // } else {
        //     type.splice(type.indexOf(id), 1);
        // }
        setType(id)
        setTypeName(name.replace(' ', '_'))
        $('.shape-items-show li').removeClass('active')
        $('.shape-items-show li.shapec_' + id).addClass('active')

    }

    const handlePrice = (event) => {
        setPrice({ ...price, [event.target.name]: event.target.value });
    }




    const handleFilterJeweleryStyle = (position, id) => {
        const updatedJeweleryStyle = JewelerystyleCheck && JewelerystyleCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setJeweleryStyleCheck(updatedJeweleryStyle);

        if (!jewelery_style.includes(id)) {
            setJeweleryStyle([...jewelery_style, id]);
        } else {
            jewelery_style.splice(jewelery_style.indexOf(id), 1);
        }
    }

    const handleFilterStoneType = (position, id) => {
        const updatedStoneType = stonetypeCheck && stonetypeCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setStoneTypeCheck(updatedStoneType);

        if (!stone_type.includes(id)) {
            setStoneType([...stone_type, id]);
        } else {
            stone_type.splice(stone_type.indexOf(id), 1);
        }
    }



    const handleFilterStoneShape = (position, id) => {
        const updatedStoneShape = stoneshapeCheck && stoneshapeCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setStoneShapeCheck(updatedStoneShape);

        if (!stone_shape.includes(id)) {
            setStoneShape([...stone_shape, id]);
        } else {
            stone_shape.splice(stone_shape.indexOf(id), 1);
        }
    }

    const handleCarat = (event) => {
        setCarat({ ...carat, [event.target.name]: event.target.value });
    }



    const handleFilterWhiteDiamondColor = (position, id) => {
        const updatedWhiteDiamondColor = whitediamondcolorCheck && whitediamondcolorCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setWhiteDiamondColorCheck(updatedWhiteDiamondColor);

        if (!whitediamondcolor.includes(id)) {
            setWhiteDiamondColor([...whitediamondcolor, id]);
        } else {
            whitediamondcolor.splice(whitediamondcolor.indexOf(id), 1);
        }
    }



    const handleFilterFancyDiamondColor = (position, id) => {
        const updatedStoneShape = fancydiamondcolorCheck && fancydiamondcolorCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setFancyDiamondColorCheck(updatedStoneShape);

        if (!fancydiamondcolor.includes(id)) {
            setFancyDiamondColor([...fancydiamondcolor, id]);
        } else {
            fancydiamondcolor.splice(fancydiamondcolor.indexOf(id), 1);
        }
    }

    const handleFilterClarity = (position, id) => {
        const updatedClarity = clarityCheck && clarityCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setClarityCheck(updatedClarity);

        if (!clarity.includes(id)) {
            setClarity([...clarity, id]);
        } else {
            clarity.splice(clarity.indexOf(id), 1);
        }
    }

    const handleFilterBrand = (position, id) => {
        const updatedBrand = brandCheck && brandCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setBrandCheck(updatedBrand);

        if (!brand.includes(id)) {
            setBrand([...brand, id]);
        } else {
            brand.splice(brand.indexOf(id), 1);
        }
    }


    const handleFilter = (e) => {
        e.preventDefault();
        tabChangeHandler()
        dispatch(getAllProductsFilter(pathname, { clarity: clarity, stone_carat: carat, price: price, type: type, jewelry_style: jewelery_style, gold_karat: gold_karat, stone_shape: stone_shape, stone_type: stone_type, color: whitediamondcolor, fancy_color: fancydiamondcolor, brand: brand, metal_type:material, seller:supplier, is_fancy:selectColor  }));
    }


    const handleResetAll = () =>{

        setPrice({
            from: "",
            to: ""
        })
        setJeweleryStyleCheck(new Array(attributes && attributes[typename] && attributes[typename].length).fill(false));
        
        setJeweleryStyle([]);
        setGoldKarat('');
        setGoldKaratCheck(new Array(goldKaratJson && goldKaratJson.length).fill(false)  );
        setStoneTypeCheck(new Array(attributes && attributes.stone_type && attributes.stone_type.length).fill(false));
        setStoneType([]);
        setStoneShapeCheck(new Array(attributes && attributes.stone_shape && attributes.stone_shape.length).fill(false));
        setStoneShape([]);
        setCarat({
            from: "",
            to: ""
        })
        setCaratCheckboxCheck(new Array(CaratJson && CaratJson.length).fill(false));
        
        setMaterialCheck(new Array(attributes && attributes.metal_type && attributes.metal_type.length).fill(false));
        setMaterial([]);
        setWhiteDiamondColorCheck(new Array(attributes && attributes.color && attributes.color.length).fill(false));
        setWhiteDiamondColor([]);
        setFancyDiamondColorCheck(new Array(attributes && attributes.fancy_color && attributes.fancy_color.length).fill(false));
        setFancyDiamondColor([]);
        setClarityCheck(new Array(attributes && attributes.clarity && attributes.clarity.length).fill(false));
        setClarity([]);
        setBrandCheck(new Array(attributes && attributes.brand && attributes.brand.length).fill(false));
        setBrand([]);
        setType('');
        setTypeName('rings');
        setSupplierCheck(new Array(attributes && attributes.top_sellers && attributes.top_sellers.length).fill(false))
        setSupplier([])

        $('.shape-items-show li').removeClass('active')
        tabChangeHandler()
        dispatch(getAllProductsFilter(pathname,  {} ));
    }


    const handleFilterMaterial = (position, id) =>{
        const updatedMaterial = materialCheck && materialCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setMaterialCheck(updatedMaterial);

        if (!material.includes(id)) {
            setMaterial([...material, id]);
        } else {
            material.splice(material.indexOf(id), 1);
        }
    }

    const handleFilterGoldKarat = (position, id) =>{
        const updatedKarat = goldkaratCheck && goldkaratCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setGoldKaratCheck(updatedKarat);

        if (!gold_karat.includes(id)) {
            setGoldKarat([...gold_karat, id]);
        } else {
            gold_karat.splice(gold_karat.indexOf(id), 1);
        }
    }


    const handleFilterCaratCheckbox = (From, To, position) =>{

        const updateCaratCheckbox = caratcheckboxCheck && caratcheckboxCheck.map((item, index) => {
            if(index === position) {
                return !item;
            } else {
                return false;
            }
        });

        setCaratCheckboxCheck(updateCaratCheckbox);
       if(updateCaratCheckbox[position] ){
        setCarat({
            from: From,
            to: To
        })
       }else{
        setCarat({
            from: '',
            to: ''
        })
       }
    }



    const handleSelectColor = (e) => {
        setSelectColor(e.target.checked);
        dispatch(getAllProductsFilter(pathname, { is_fancy: e.target.checked }));
        if(e.target.checked)
        {
            $('#white_color').css({
                display:'none'
            })
            $('#fancy_color').css({
                display:'block'
            })
        }else{
            $('#white_color').css({
                display:'block'
            })
            $('#fancy_color').css({
                display:'none'
            })
        }
    }

    const handleFilterWhiteColor = (id) => {
        if (!whitediamondcolor.includes(id)) {
            setWhiteDiamondColor([...whitediamondcolor, id]);
        } else {
            whitediamondcolor.splice(whitediamondcolor.indexOf(id), 1);
        }

        if ($('.shape-items-show li.colorc_' + id).hasClass('active')) {
            $('.shape-items-show li.colorc_' + id).removeClass('active')
        } else {
            $('.shape-items-show li.colorc_' + id).addClass('active')
        }
    }

    const handleSupplier = (position, id) =>{
        const updatedSupplier = supplierCheck && supplierCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setSupplierCheck(updatedSupplier);

        if (!supplier.includes(id)) {
            setSupplier([...supplier, id]);
        } else {
            supplier.splice(supplier.indexOf(id), 1);
        }
    }


    return (
        <div className="col-lg-3">
            <div className="dashboard-sidebar ">
                <form onSubmit={handleFilter}>
                    <div className="filter_list Jewelery_list">
                        <div className="filter_side">
                            <div className="filter_top j_type">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb page_head"><FormattedMessage id="category.jewellery" defaultMessage="Jewelry"/></h4>
                                    <p className="font-16 black-3B fm "><FormattedMessage id="JewelryType" defaultMessage="Jewelry Type"/></p>
                                </div>
                                <ul id="myList" className="shape-items-show j_top">
                                    {
                                        attributes && attributes?.jewelry_type && attributes.jewelry_type.map((type, key) => {
                                            return (
                                                <li className={key < 12 ? 'c_show shapec_' + type.id : 'c_hide shapec_' + type.id} onClick={() => handleFilterType(type.id, type.name.toLowerCase())}><img src={ShapeImageUrl(type.image)} alt="" /><span>{type.name}</span></li>
                                            )
                                        })
                                    }
                                </ul>
                                {/* <p id="loadMore" onClick={(e) => loadMoreShape('j_type')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; See More</p> */}
                            </div>




                            <div className="filter_top mt-3 mb-3 j_jwstyle">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="JewelryStyle" defaultMessage="Jewelry Style"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes[typename] && attributes[typename].map((data, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={JewelerystyleCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterJeweleryStyle(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('j_jwstyle')} className="see_more font-14 gray-65"><span
                                    className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>

                            <div className="filter_top j_brand">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="JewelryBrand" defaultMessage="Jewelry Brand"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.brand && attributes.brand.map((brand, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={brand.name} checked={brandCheck[key]} id={brand.id} className="checkbox" onChange={() => handleFilterBrand(key, brand.id)} />
                                                    <label htmlFor={brand.id}>{brand.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('j_brand')} className="see_more font-14 gray-65"><span
                                    className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>


                            <div className="filter_top j_material">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="MetalType" defaultMessage="Metal Type"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.metal_type && attributes.metal_type.map((material, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input  type="checkbox" value={material.name} checked={materialCheck[key]} id={material.id} className="checkbox" onChange={() => handleFilterMaterial(key, material.id)}  />
                                                    <label htmlFor={material.id}>{material.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('j_material')} className="see_more font-14 gray-65"><span
                                    className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>


                            <div className="filter_top mb-3 j_goldkarat">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="GoldKarat" defaultMessage="Gold Karat"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        goldKaratJson && goldKaratJson.map((gold, key) => {
                                            return (
                                                <div className={key < 10 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={gold.value} checked={goldkaratCheck[key]} id={gold.id} className="checkbox" onChange={() => handleFilterGoldKarat(key, gold.value)} />
                                                    <label htmlFor={gold.id}>{gold.display_name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {/* <p id="loadMore" onClick={(e) => loadMoreDefault('j_goldkarat')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; See More</p> */}
                            </div>


                                              


                            <div className="filter_top j_stonetype">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="StoneType" defaultMessage="Stone Type"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.stone_type && attributes.stone_type.map((stone_type, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={stone_type.name} checked={stonetypeCheck[key]} id={stone_type.id} className="checkbox" onChange={() => handleFilterStoneType(key, stone_type.id)} />
                                                    <label htmlFor={stone_type.id}>{stone_type.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('j_stonetype')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>


                            
                            <div className="filter_top j_stoneshape">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="StoneShape" defaultMessage="Stone Shape"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.stone_shape && attributes.stone_shape.map((stone_shape, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={stone_shape.name} checked={stoneshapeCheck[key]} id={stone_shape.id} className="checkbox" onChange={() => handleFilterStoneShape(key, stone_shape.id)} />
                                                    <label htmlFor={stone_shape.id}>{stone_shape.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('j_stoneshape')} className="see_more font-14 gray-65"><span
                                    className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>


                            <div className="filter_top mt-3">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="StoneColor" defaultMessage="Stone Color"/></h4>
                                </div>
                                <div className="cus_switch d-flex align-item-center">
                                    <p className="font-14 fm black-3B pr-3"><FormattedMessage id="addsingleproduct.White" defaultMessage="White"/></p>
                                    <label className="switch">
                                        <input type="checkbox" checked={selectColor} onChange={handleSelectColor} />
                                        <span className="slider round"></span>
                                    </label>
                                    <p className="gray-75 font-14 fm pl-3"><FormattedMessage id="addsingleproduct.Fancy" defaultMessage="Fancy"/></p>
                                </div>
                            </div>

                            <div className="filter_top j_fancydiamondcolor" id="fancy_color" style={{display:'none'}}>
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="addsingleproduct" defaultMessage="Fancy Color"/></h4>
                                </div>
                                <div className="multi_price">
                                    {
                                        attributes && attributes?.fancy_color && attributes.fancy_color.map((fancy_diamond_color, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={fancy_diamond_color.name} checked={fancydiamondcolorCheck[key]} id={fancy_diamond_color.id} className="checkbox" onChange={() => handleFilterFancyDiamondColor(key, fancy_diamond_color.id)} />
                                                    <label htmlFor={fancy_diamond_color.id}>{fancy_diamond_color.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('j_fancydiamondcolor')} className="see_more font-14 gray-65"><span
                                    className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>

                            
                            <div className="filter_top d_color mt-3" id="white_color" style={{ display: 'block' }}>
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="addsingleproduct.WhiteDiamondColor" defaultMessage="White Diamond Color"/></h4>
                                </div>
                                <ul id="myList" className="shape-items-show">
                                    {
                                        attributes && attributes?.color && attributes.color.map((color, key) => {
                                            return (
                                                <li className={key < 12 ? 'c_show colorc_' + color.id : 'c_hide colorc_' + color.id} onClick={() => handleFilterWhiteColor(color.id)}><span>{color.name}</span></li>
                                            )
                                        })
                                    }
                                </ul>
                                <p id="loadMore" onClick={(e) => loadMoreShape('d_color')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>

                             
                            {/* <div className="filter_top j_fancydiamondcolor">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm">Fancy Diamond Color</h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.fancy_diamond_color && attributes.fancy_diamond_color.map((fancy_diamond_color, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={fancy_diamond_color.name} checked={fancydiamondcolorCheck[key]} id={fancy_diamond_color.id} className="checkbox" onChange={() => handleFilterFancyDiamondColor(key, fancy_diamond_color.id)} />
                                                    <label htmlFor={fancy_diamond_color.id}>{fancy_diamond_color.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('j_fancydiamondcolor')} className="see_more font-14 gray-65"><span
                                    className="icon-add_1"></span> &nbsp; See More</p>
                            </div>        */}




                            <div className="filter_top j_clarity">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="StoneClarity" defaultMessage="Stone Clarity"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.clarity && attributes.clarity.map((clarity, key) => {
                                            return (
                                                <div className={key < 8 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={clarity.name} checked={clarityCheck[key]} id={clarity.id} className="checkbox" onChange={() => handleFilterClarity(key, clarity.id)} />
                                                    <label htmlFor={clarity.id}>{clarity.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('j_clarity')} className="see_more font-14 gray-65"><span
                                    className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>


                            <div className="filter_top">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.carat" defaultMessage="Carat"/></h4>
                                </div>

                                <div className="price_box d-flex">
                                    <div className="form-group mb-0">
                                        <input type="text" value={carat.from} placeholder={useIntl().formatMessage({id: 'Low'})} name="from" onChange={handleCarat} className="form-control" />
                                    </div>
                                    <span className="icon-minus d-block w-25"></span>
                                    <div className="form-group mb-0">
                                        <input type="text" value={carat.to} className="form-control" name="to" onChange={handleCarat} placeholder={useIntl().formatMessage({id: 'High'})} />
                                    </div>
                                </div>

                                <div className="filter_top mt-3 d_carat_checkbox">
                                    <div className="multi_price">
                                        {
                                            CaratJson && CaratJson.map((data, key) => {
                                                return (
                                                    <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={data.display_name} checked={caratcheckboxCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterCaratCheckbox(data.from, data.to, key)} />
                                                        <label htmlFor={data.id}>{data.display_name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <p id="loadMore" onClick={(e) => loadMoreDefault('d_carat_checkbox')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                                </div>

                            </div>


                            <div className="filter_top mb-3">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.price" defaultMessage="Price"/></h4>
                                </div>
                                <div className="price_box d-flex">
                                    <div className="form-group mb-0">
                                        <input type="text" placeholder={useIntl().formatMessage({id: 'Low'})} value={price.from} name="from" onChange={handlePrice} className="form-control" />
                                    </div>
                                    <span className="icon-minus d-block w-25"></span>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" value={price.to} name="to" onChange={handlePrice} placeholder={useIntl().formatMessage({id: 'High'})} />
                                    </div>
                                </div>
                            </div>


                            <div className="filter_top mt-3  d_seller">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="Supplier" defaultMessage="Supplier"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.top_sellers && attributes.top_sellers.map((data, key) => {
                                            return (
                                                <div className={key < 20 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={supplierCheck[key]} id={data.id} className="checkbox" onChange={() => handleSupplier(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name} ({data.product_count})</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('d_seller')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>

                        </div>

                        <div className="filter_top">
                            <div className="filter_btn">
                                <button className="btn btn-black"><FormattedMessage id="ApplyFilters" defaultMessage="Apply filters"/></button>
                                <p className="font-14 gray-65 text-center mt-3" style={{cursor:'pointer'}} onClick={handleResetAll}><FormattedMessage id="Reset_all" defaultMessage="Reset all"/> <span className="icon-close_1"></span></p>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default Sidebar
