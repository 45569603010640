import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAttributes, GetModelAttribute } from '../../../actions/productActions';
import $ from 'jquery';
import { getLastURL } from '../../../helper';
import { useLocation } from 'react-router';
import { getAllProductsFilter } from '../../../actions/productActions';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import { FormattedMessage, useIntl } from 'react-intl';

const Sidebar = ({tabChangeHandler}) => {


    const dispatch = useDispatch();
    const location = useLocation();

    const pathname = getLastURL(location);

    const { attributes } = useSelector(state => state.attributes);
    const { modelattribute } = useSelector(state => state.ModelAttribute);

    useEffect(() => {
        dispatch(getAttributes(pathname))
        dispatch(GetModelAttribute(pathname, [252]))
    }, [dispatch])

    const [brandCheck, setBrandCheck] = useState(new Array(attributes && attributes.brand && attributes.brand.length).fill(false));
    const [brand, setBrand] = useState([]);
    const [modelCheck, setModelCheck] = useState(new Array(modelattribute && modelattribute && modelattribute.model.length).fill(false));
    const [model, setModel] = useState([]);
    const [price, setPrice] = useState({
        from: "",
        to: ""
    })
    const [year, setYear] = useState({
        from: "",
        to: ""
    })
    const [case_size, setCaseSize] = useState({
        from: "",
        to: ""
    });
    const [paperCheck, setPaperCheck] = useState(new Array(attributes && attributes.papers_box && attributes.papers_box.length).fill(false));
    const [paper, setPaper] = useState([]);
    const [conditionCheck, setConditionCheck] = useState(new Array(attributes && attributes.condition && attributes.condition.length).fill(false));
    const [condition, setCondition] = useState([]);
    const [genderCheck, setGenderCheck] = useState(new Array(attributes && attributes.gender && attributes.gender.length).fill(false));
    const [gender, setGender] = useState([]);
    const [reference_number, setReferenceNumber] = useState();
    const [movementCheck, setMovementCheck] = useState(new Array(attributes && attributes.movement && attributes.movement.length).fill(false));
    const [movement, setMovement] = useState([]);
    const [functionsCheck, setFunctionsCheck] = useState(new Array(attributes && attributes.functions && attributes.functions.length).fill(false));
    const [functions, setFunctions] = useState([]);
    const [dialnumeralCheck, setDialNumeralCheck] = useState(new Array(attributes && attributes.dial_numerals && attributes.dial_numerals.length).fill(false));
    const [dial_numeral, setDialNumeral] = useState([]);
    const [dialtypeCheck, setDialTypeCheck] = useState(new Array(attributes && attributes.dial_type && attributes.dial_type.length).fill(false));
    const [dial_type, setDialType] = useState([]);
    const [casematerialCheck, setCaseMaterialCheck] = useState(new Array(attributes && attributes.case_material && attributes.case_material.length).fill(false));
    const [case_material, setCaseMaterial] = useState([]);
    const [bezelmaterialCheck, setBezelMaterialCheck] = useState(new Array(attributes && attributes.bezel_material && attributes.bezel_material.length).fill(false));
    const [bezel_material, setBezelMaterial] = useState([]);
    const [strapmaterialCheck, setStrapMaterialCheck] = useState(new Array(attributes && attributes.strap_material && attributes.strap_material.length).fill(false));
    const [strap_material, setStrapMaterial] = useState([]);
    const [strapcolorCheck, setStrapColorCheck] = useState(new Array(attributes && attributes.strap_color && attributes.strap_color.length).fill(false));
    const [strap_color, setStrapColor] = useState([]);
    const [supplierCheck, setSupplierCheck] = useState(new Array(attributes && attributes.top_sellers && attributes.top_sellers.length).fill(false));
    const [supplier, setSupplier] = useState([]);


    const loadMoreDefault = (p_class) => {
        let see_more = localStorage.getItem('lang') == 'tr'?'Daha fazla gör':'See More';
        let see_less = localStorage.getItem('lang') == 'tr'?'Daha Az Gör':'See Less';
        if ($('.' + p_class).hasClass('mainclass_hide')) {
            $('.' + p_class).removeClass('mainclass_hide')
            $('.' + p_class + ' .c_hide').hide()
            $('.' + p_class + ' .see_more').html(' <span className="icon-add_1"></span> &nbsp; '+see_more)
        } else {
            $('.' + p_class + ' .c_hide').show()
            $('.' + p_class).addClass('mainclass_hide')
            $('.' + p_class + ' .see_more').html(' <span className="icon-close_1"></span> &nbsp; '+see_less)
        }
    }

    const handleFilterBrand = async (position, id) => {

        var tempArray = brand;

        const updatedBrand = brandCheck && brandCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
       
        setBrandCheck(updatedBrand);

       // tempArray = [id];
        // if (!tempArray.includes(id)) {
        //      tempArray.push(id);
        //  } else {
        //      tempArray.splice(tempArray.indexOf(id), 1);
        //  }
      //  tempArray.push(id)
      //  dispatch(GetModelAttribute(pathname, brand))
        if (!brand.includes(id)) {
            tempArray.push(id);
            setBrand([...brand, id]);
        } else {
            brand.splice(brand.indexOf(id), 1);
            tempArray.splice(tempArray.indexOf(id), 1);
        }
       
        await dispatch(GetModelAttribute(pathname, tempArray))
   
 

       
    }


    const handleFilterModel = (position, id) => {
        const updatedModel = modelCheck && modelCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setModelCheck(updatedModel);

        if (!model.includes(id)) {
            setModel([...model, id]);
        } else {
            model.splice(model.indexOf(id), 1);
        }
    }

    const handlePrice = (event) => {
        setPrice({ ...price, [event.target.name]: event.target.value });
    }

    const handleCaseSize = (event) => {
        setCaseSize({ ...case_size, [event.target.name]: event.target.value });
    }

    const handleFilterYear = (event) => {
        setYear({ ...year, [event.target.name]: event.target.value });
    }

    const handleFilterPaper = (position, id) => {
        const updatedPaper = paperCheck && paperCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setPaperCheck(updatedPaper);

        if (!paper.includes(id)) {
            setPaper([...paper, id]);
        } else {
            paper.splice(paper.indexOf(id), 1);
        }
    }


    const handleFilterCondition = (position, id) => {
        const updatedCondition = conditionCheck && conditionCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setConditionCheck(updatedCondition);

        if (!condition.includes(id)) {
            setCondition([...condition, id]);
        } else {
            condition.splice(condition.indexOf(id), 1);
        }
    }


    const handleFilterGender = (position, id) => {
        const updatedGender = genderCheck && genderCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setGenderCheck(updatedGender);

        if (!gender.includes(id)) {
            setGender([...gender, id]);
        } else {
            gender.splice(gender.indexOf(id), 1);
        }
    }

    const handleReferenceNumber = (event) => {
        setReferenceNumber(event.target.value);
    }


    const handleFilterMovement = (position, id) => {
        const updatedMovement = movementCheck && movementCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setMovementCheck(updatedMovement);

        if (!movement.includes(id)) {
            setMovement([...movement, id]);
        } else {
            movement.splice(movement.indexOf(id), 1);
        }
    }

    const handleFilterFunctions = (position, id) => {
        const updatedFunctions = functionsCheck && functionsCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setFunctionsCheck(updatedFunctions);

        if (!functions.includes(id)) {
            setFunctions([...functions, id]);
        } else {
            functions.splice(functions.indexOf(id), 1);
        }
    }



    const handleFilterDialNumeral = (position, id) => {
        const updateDialNumeral = dialnumeralCheck && dialnumeralCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setDialNumeralCheck(updateDialNumeral);

        if (!dial_numeral.includes(id)) {
            setDialNumeral([...dial_numeral, id]);
        } else {
            dial_numeral.splice(dial_numeral.indexOf(id), 1);
        }
    }


    const handleFilterDialType = (position, id) => {
        const updateDialType = dialtypeCheck && dialtypeCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setDialTypeCheck(updateDialType);

        if (!dial_type.includes(id)) {
            setDialType([...dial_type, id]);
        } else {
            dial_type.splice(dial_type.indexOf(id), 1);
        }
    }


    const handleFilterCaseMaterial = (position, id) => {
        const updateCaseMaterial = casematerialCheck && casematerialCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setCaseMaterialCheck(updateCaseMaterial);

        if (!case_material.includes(id)) {
            setCaseMaterial([...case_material, id]);
        } else {
            case_material.splice(case_material.indexOf(id), 1);
        }
    }


    const handleFilterBezelMaterial = (position, id) => {
        const updatebezelmaterial = bezelmaterialCheck && bezelmaterialCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setBezelMaterialCheck(updatebezelmaterial);

        if (!bezel_material.includes(id)) {
            setBezelMaterial([...bezel_material, id]);
        } else {
            bezel_material.splice(bezel_material.indexOf(id), 1);
        }
    }



    const handleFilterStrapMaterial = (position, id) => {
        const updatestrapmaterial = strapmaterialCheck && strapmaterialCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setStrapMaterialCheck(updatestrapmaterial);

        if (!strap_material.includes(id)) {
            setStrapMaterial([...strap_material, id]);
        } else {
            strap_material.splice(strap_material.indexOf(id), 1);
        }
    }



    const handleFilterStrapColor = (position, id) => {
        const updatestrapcolor = strapcolorCheck && strapcolorCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setStrapColorCheck(updatestrapcolor);

        if (!strap_color.includes(id)) {
            setStrapColor([...strap_color, id]);
        } else {
            strap_color.splice(strap_color.indexOf(id), 1);
        }
    }


    const handleFilter = (e) => {
        e.preventDefault();
        tabChangeHandler()
        dispatch(getAllProductsFilter(pathname, { price: price, brand: brand, model: model, case_size: case_size, year: year, papers_box: paper, condition: condition, gender: gender, reference_number: reference_number, movement: movement, functions: functions, dial_numerals: dial_numeral, dial_type: dial_type, case_material: case_material, bezel_material: bezel_material, strap_material: strap_material, strap_color: strap_color, seller:supplier }));
    }

    const handleResetAll = () =>{

        setBrandCheck(new Array(attributes && attributes.brand && attributes.brand.length).fill(false));
        setBrand([]);
        setModelCheck(new Array(modelattribute && modelattribute && modelattribute.model.length).fill(false));
        setModel([]);
        setPrice({
            from: "",
            to: ""
        })
        setYear({
            from: "",
            to: ""
        })
        setCaseSize({
            from: "",
            to: ""
        });
        setPaperCheck(new Array(attributes && attributes.papers_box && attributes.papers_box.length).fill(false));
        setPaper([]);
        setConditionCheck(new Array(attributes && attributes.condition && attributes.condition.length).fill(false));
        setCondition([]);
        setGenderCheck(new Array(attributes && attributes.gender && attributes.gender.length).fill(false));
        setGender([]);
        setReferenceNumber();
        setMovementCheck(new Array(attributes && attributes.movement && attributes.movement.length).fill(false));
        setMovement([]);
        setFunctionsCheck(new Array(attributes && attributes.functions && attributes.functions.length).fill(false));
        setFunctions([]);
        setDialNumeralCheck(new Array(attributes && attributes.dial_numerals && attributes.dial_numerals.length).fill(false));
        setDialNumeral([]);
        setDialTypeCheck(new Array(attributes && attributes.dial_type && attributes.dial_type.length).fill(false));
        setDialType([]);
        setCaseMaterialCheck(new Array(attributes && attributes.case_material && attributes.case_material.length).fill(false));
        setCaseMaterial([]);
        setBezelMaterialCheck(new Array(attributes && attributes.bezel_material && attributes.bezel_material.length).fill(false));
        setBezelMaterial([]);
        setStrapMaterialCheck(new Array(attributes && attributes.strap_material && attributes.strap_material.length).fill(false));
        setStrapMaterial([]);
        setStrapColorCheck(new Array(attributes && attributes.strap_color && attributes.strap_color.length).fill(false));
        setStrapColor([]);
        setSupplierCheck(new Array(attributes && attributes.top_sellers && attributes.top_sellers.length).fill(false))
        setSupplier([])
        setReferenceNumber('')
        tabChangeHandler()
        dispatch(getAllProductsFilter(pathname,  {} ));
    }


    const handleSupplier = (position, id) =>{
        const updatedSupplier = supplierCheck && supplierCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setSupplierCheck(updatedSupplier);

        if (!supplier.includes(id)) {
            setSupplier([...supplier, id]);
        } else {
            supplier.splice(supplier.indexOf(id), 1);
        }
    }



    return (
        <div className="col-lg-3">
            <div className="dashboard-sidebar ">
                <form onSubmit={handleFilter}>
                    <div className="filter_list Jewelery_list">
                        <div className="filter_side">
                            <div className="filter_top">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb page_head"><FormattedMessage id="category.watches" defaultMessage="Watches"/></h4>
                                </div>

                                <div className="filter_top mt-3 mb-3 w_brand">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fm"><FormattedMessage id="WatchBrand" defaultMessage="Watch Brand"/></h4>
                                    </div>
                                    <div className="multi_price mt-3">
                                        {
                                            attributes && attributes?.brand && attributes.brand.map((brand, key) => {
                                                return (
                                                    <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={brand.name} checked={brandCheck[key]} id={brand.id} className="checkbox" onChange={() => handleFilterBrand(key, brand.id)} />
                                                        <label htmlFor={brand.id}>{brand.name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <p id="loadMore" onClick={(e) => loadMoreDefault('w_brand')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                                </div>
                            </div>



                            <div className="filter_top w_model">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="WatchModel" defaultMessage="Watch Model"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        modelattribute && modelattribute && modelattribute.model.map((model, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={model.name} checked={modelCheck[key]} id={model.id} className="checkbox" onChange={() => handleFilterModel(key, model.id)} />
                                                    <label htmlFor={model.id}>{model.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('w_model')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>


                            <div className="filter_top mb-3">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="addsingleproduct.price" defaultMessage="Price"/></h4>
                                </div>
                                <div className="price_box d-flex">
                                    <div className="form-group mb-0">
                                        <input type="text" placeholder={useIntl().formatMessage({id: 'Low'})} value={price.from} name="from" onChange={handlePrice} className="form-control" />
                                    </div>
                                    <span className="icon-minus d-block w-25"></span>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" value={price.to} name="to" onChange={handlePrice} placeholder={useIntl().formatMessage({id: 'High'})} />
                                    </div>
                                </div>
                            </div>


                            {/* <div className="filter_top mb-3">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb">Case Size (mm)</h4>
                                </div>
                                <div className="price_box d-flex">
                                    <div className="form-group mb-0">
                                        <input type="text" placeholder={useIntl().formatMessage({id: 'Low'})} value={case_size.from} name="from" onChange={handleCaseSize} className="form-control" />
                                    </div>
                                    <span className="icon-minus d-block w-25"></span>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" value={case_size.to} name="to" onChange={handleCaseSize} placeholder={useIntl().formatMessage({id: 'High'})} />
                                    </div>
                                </div>
                            </div> */}


                            <div className="filter_top w_year">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="addsingleproduct.year" defaultMessage="Year"/></h4>
                                </div>
                                <div className="price_box d-flex">
                                    <div className="form-group mb-0">
                                        <input type="text" placeholder={useIntl().formatMessage({id: 'Low'})} value={year.from} name="from" onChange={handleFilterYear} className="form-control" />
                                    </div>
                                    <span className="icon-minus d-block w-25"></span>
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" value={year.to} name="to" onChange={handleFilterYear} placeholder={useIntl().formatMessage({id: 'High'})} />
                                    </div>
                                </div>
                            </div>



                            <div className="filter_top w_paper">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="PapersAndBox" defaultMessage="Papers & Box"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.papers_box && attributes.papers_box.map((papers_box, key) => {
                                            return (
                                                <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={papers_box.name} checked={paperCheck[key]} id={papers_box.id} className="checkbox" onChange={() => handleFilterPaper(key, papers_box.id)} />
                                                    <label htmlFor={papers_box.id}>{papers_box.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>


                            <div className="filter_top w_condition">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="addsingleproduct.condition" defaultMessage="Condition"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.condition && attributes.condition.map((condition, key) => {
                                            return (
                                                <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={condition.name} checked={conditionCheck[key]} id={condition.id} className="checkbox" onChange={() => handleFilterCondition(key, condition.id)} />
                                                    <label htmlFor={condition.id}>{condition.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>


                            <div className="filter_top w_gender">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="addsingleproduct.gender" defaultMessage="Gender"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.gender && attributes.gender.map((gender, key) => {
                                            return (
                                                <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={gender.name} checked={genderCheck[key]} id={gender.id} className="checkbox" onChange={() => handleFilterGender(key, gender.id)} />
                                                    <label htmlFor={gender.id}>{gender.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>


                            <div className="filter_top mb-3">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="ReferenceNo" defaultMessage="Reference No"/>.</h4>
                                </div>
                                <div className="price_box d-flex">
                                    <div className="form-group mb-0">
                                        <input type="text" className="form-control" value={reference_number} name="reference_no" onChange={handleReferenceNumber} placeholder={useIntl().formatMessage({id: 'ReferenceNo'})}  />
                                    </div>
                                </div>
                            </div>


                            <div className="filter_top w_movement">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="addsingleproduct.movement" defaultMessage="Movement"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.movement && attributes.movement.map((movement, key) => {
                                            return (
                                                <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={movement.name} checked={movementCheck[key]} id={movement.id} className="checkbox" onChange={() => handleFilterMovement(key, movement.id)} />
                                                    <label htmlFor={movement.id}>{movement.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>


                            <div className="filter_top w_functions">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="addsingleproduct.functions" defaultMessage="Functions"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.functions && attributes.functions.map((functions, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={functions.name} checked={functionsCheck[key]} id={functions.id} className="checkbox" onChange={() => handleFilterFunctions(key, functions.id)} />
                                                    <label htmlFor={functions.id}>{functions.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('w_functions')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>


                            <div className="filter_top w_dialnumerals">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="DialNumerals" defaultMessage="Dial Numerals"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.dial_numerals && attributes.dial_numerals.map((dial_numerals, key) => {
                                            return (
                                                <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={dial_numerals.name} checked={dialnumeralCheck[key]} id={dial_numerals.id} className="checkbox" onChange={() => handleFilterDialNumeral(key, dial_numerals.id)} />
                                                    <label htmlFor={dial_numerals.id}>{dial_numerals.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>


                            <div className="filter_top w_dialtype">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="addsingleproduct.dialtype" defaultMessage="Dial Type"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.dial_type && attributes.dial_type.map((dial_type, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={dial_type.name} checked={dialtypeCheck[key]} id={dial_type.id} className="checkbox" onChange={() => handleFilterDialType(key, dial_type.id)} />
                                                    <label htmlFor={dial_type.id}>{dial_type.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('w_dialtype')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>



                            <div className="filter_top w_casematerial">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="addsingleproduct.casematerial" defaultMessage="Case Material"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.case_material && attributes.case_material.map((case_material, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={case_material.name} checked={casematerialCheck[key]} id={case_material.id} className="checkbox" onChange={() => handleFilterCaseMaterial(key, case_material.id)} />
                                                    <label htmlFor={case_material.id}>{case_material.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('w_casematerial')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>



                            <div className="filter_top w_bezelmaterial">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="addsingleproduct.bezelmaterial" defaultMessage="Bezel Material"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.bezel_material && attributes.bezel_material.map((bezel_material, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={bezel_material.name} checked={bezelmaterialCheck[key]} id={bezel_material.id} className="checkbox" onChange={() => handleFilterBezelMaterial(key, bezel_material.id)} />
                                                    <label htmlFor={bezel_material.id}>{bezel_material.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('w_bezelmaterial')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>



                            <div className="filter_top w_strapmaterial">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="addsingleproduct.strapmaterial" defaultMessage="Strap Material"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.strap_material && attributes.strap_material.map((strap_material, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={strap_material.name} checked={strapmaterialCheck[key]} id={strap_material.id} className="checkbox" onChange={() => handleFilterStrapMaterial(key, strap_material.id)} />
                                                    <label htmlFor={strap_material.id}>{strap_material.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('w_strapmaterial')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>


                            <div className="filter_top w_strapcolor">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fm"><FormattedMessage id="addsingleproduct.strapcolor" defaultMessage="Strap Color"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.strap_color && attributes.strap_color.map((strap_color, key) => {
                                            return (
                                                <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={strap_color.name} checked={strapcolorCheck[key]} id={strap_color.id} className="checkbox" onChange={() => handleFilterStrapColor(key, strap_color.id)} />
                                                    <label htmlFor={strap_color.id}>{strap_color.name}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('w_strapcolor')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>


                            <div className="filter_top mt-3  d_seller">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="Supplier" defaultMessage="Supplier"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.top_sellers && attributes.top_sellers.map((data, key) => {
                                            return (
                                                <div className={key < 20 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={supplierCheck[key]} id={data.id} className="checkbox" onChange={() => handleSupplier(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name} ({data.product_count})</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('d_seller')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>
                        </div>
                        <div className="filter_top">
                            <div className="filter_btn">
                                <button className="btn btn-black"><FormattedMessage id="ApplyFilters" defaultMessage="Apply filters"/></button>
                                <p className="font-14 gray-65 text-center mt-3" style={{cursor:'pointer'}} onClick={handleResetAll}><FormattedMessage id="Reset_all" defaultMessage="Reset all"/> <span className="icon-close_1"></span></p>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>

    )
}

export default Sidebar
