import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { url } from "../../config";
const PopupFail = ({ title = "Error", props }) => {
  // console.log("propstitle ===================== ", title);
  // console.log("props ===================== ", props);
  const history = useHistory();

  const handlBtnClick = () => {
    // history.push(url + `/plan-details`);
  };
  return (
    <Fragment>
      <div
        className="modal fade PopsData"
        id="popup-fail"
        tabindex="-1"
        role="dialog"
        aria-labelledby="comment"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="unsucces">
                <span className="icon">
                  <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                </span>
                <h4>{title && title}</h4>
                <p>{props && props}</p>
                <button
                  id="dismiss-modal"
                  type="button"
                  className="close m-0"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                {title !== "UPLOADING ERROR" && (
                  <button
                    type="button"
                    className="closes upgrade-redirect"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => handlBtnClick}
                  >
                    <FormattedMessage
                      id="addsingleproduct.Upgrade"
                      defaultMessage="Upgrade"
                    />
                  </button>
                )}
                <button
                  style={{ display: "none" }}
                  className="btn-black active-all"
                  id="popup-click-fail"
                  data-toggle="modal"
                  data-target="#popup-fail"
                >
                  <FormattedMessage
                    id="addsingleproduct.popUp"
                    defaultMessage="pop up"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PopupFail;
