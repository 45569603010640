import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { IMAGES } from '../../../constants';
import Loader from '../../layout/Loader';

const Welcome = () => {


    const {user} = useSelector(state => state.user);
    const { loading } = useSelector(state => state.messageHistory);

    return (
        <Fragment>
          {/* {loading && <Loader />} */}
        <Fragment>
            <div className="col-md-9">
            <div className="viewWelcomeBoard">
                <img
                    className="avatarWelcome"
                    src={IMAGES.LOGO}
                    alt=""
                />
                <span className="textTitleWelcome"><FormattedMessage id="business.welcome" defaultMessage="Welcome"/>,{` ${user && user.name}`}</span><br/>
                <span className="textDesciptionWelcome">
                    <FormattedMessage id="LetsconnenttheWorld" defaultMessage="Let's connect the World"/>
                </span>
            </div>
            </div>
        </Fragment>
        </Fragment>
    )
}

export default Welcome
