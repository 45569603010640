import React, { Fragment, useEffect } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { getTermCondition } from '../../actions/HomePageActions';
import parse from "html-react-parser";
import Loader from '../layout/Loader';
import MetaData from '../layout/MetaData';



const TermCondition = () => {

    const dispatch = useDispatch();
    const { Term, loading } = useSelector(state => state.term);

    useEffect(() => {
        if (!Term) {
            dispatch(getTermCondition())
        }
    }, [dispatch, Term])


    return (
        <Fragment>
            {loading && <Loader />}
            <Fragment>
                <MetaData title={`Terms and Conditions`} />
                <Header />
                <section className="testimonal container py-5">
                    <h2 className="mt-5 mb-3 text-center black fm font-40 ">{Term && Term?.page && Term?.page?.title}</h2>
                    {Term && Term?.page && parse(Term.page.content)}
                </section>
                <Footer />
            </Fragment>
        </Fragment>
    )
}

export default TermCondition
