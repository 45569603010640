import React, { useState } from 'react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { currency, url } from '../../config';
import { savePercentage, deviceDisplay } from '../../helper';
import { setColor } from '../../helper';
import { JEWELER_SMALL, JEWELER_SMALL_SEARCHES } from '../../constants/index';
import { FormattedMessage } from 'react-intl';

const Jeweler = () => {

    const [plan_period, setPlanPeriod] = useState();

    const { Home } = useSelector(state => state.home)
    const { user, loading } = useSelector(state => state.user);
    const handlePlanPeriod = (event) => {
        setPlanPeriod(event.target.checked);
    }

    const stringReplace = (text) => {
        let replace = text.replace(/(<([^>]+)>)/ig, '');
        return replace;
    }


    return (
        <div className="tab-pane fade show active" id="jeweler" role="tabpanel" aria-labelledby="home-tab">
            <div className="row mb-3">
                <div className="col-lg-12">
                    <div className="cus_switch mb-3  d-flex align-item-center">
                        <p className="font-14 fr black-3B pr-3"><FormattedMessage id="Monthly" defaultMessage="Monthly" /></p>
                        <label className="switch">
                            <input type="checkbox" onChange={handlePlanPeriod} />
                            <span className="slider round"></span>
                        </label>
                        <p className="font-14 fr pl-3" style={{ color: "#236653", fontWeight: 600 }}><b
                            className="black-3B"><FormattedMessage id="Annual" defaultMessage="Annual" /></b> <FormattedMessage
                                id="billing.save-percentage"
                                defaultMessage="Save up to 16.19%"
                            /></p>
                    </div>
                </div>
                {
                    Home && Home?.JewelerPlans && Home?.JewelerPlans.map((JP, key) => {
                        return (
                            <div key={key} className="col-lg-3 col-md-4">
                                <div className="sub_plan_box">
                                    <h3 class={setColor(key)}>{JP.name}</h3>
                                    {
                                        JP.trial == 1 ?
                                            <div className="device_d">
                                                <p><FormattedMessage id="CancelAnytime" defaultMessage="Cancel Anytime" /></p>
                                            </div>
                                            :
                                            JP.id == JEWELER_SMALL ?
                                                <div className="device_d">
                                                    <p>{JEWELER_SMALL_SEARCHES} <FormattedMessage id="Searches" defaultMessage="Searches" /></p>
                                                </div>
                                                :
                                                <div className="device_d">
                                                    <span className="icon-device_1"></span>
                                                    <span className="icon-device_2"></span>
                                                    <p>{JP.device_limit + " " + deviceDisplay(JP.device_limit)} </p>
                                                </div>
                                    }
                                    <p style={{ fontWeight: "bold", color: "red" }}> {
                                        JP.features !== null ?
                                            stringReplace(JP?.features)
                                            : ""

                                    }</p>

                                    <div className="plan_price">
                                        {
                                            plan_period ?
                                                <Fragment>
                                                    <span className="year_date"> /<FormattedMessage id="yr" /></span>
                                                    <h3><small>{currency}</small>{JP.yearly_price}</h3>
                                                    <span className="offer_price">{savePercentage(JP.monthly_price, JP.yearly_price)}%</span>
                                                </Fragment>
                                                :
                                                <Fragment>
                                                    <span className="year_date"> /<FormattedMessage id="mo" /></span>
                                                    <h3><small>{currency}</small>{JP.monthly_price}</h3>
                                                </Fragment>
                                        }
                                    </div>
                                    {user || localStorage.getItem('isAuthenticated') ?
                                        <Link to={url + '/plan-details'} className="btn btn-white"><FormattedMessage id="ApplyNow" defaultMessage="Apply now" /></Link>
                                        :
                                        <Link to={url + '/register'} className="btn btn-white"><FormattedMessage id="ApplyNow" defaultMessage="Apply now" /></Link>
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Jeweler
