import React, {Fragment ,useEffect,useState} from 'react';
import {url} from '../../config';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//const user =  JSON.parse(sessionStorage.getItem('user'));

const SupplierProtectedRoute = ({component: Component, ...rest}) => {
   // const [user, setUser] = useState(false);

    var { user } = useSelector(state => state.user)

    if(!user){
        user = JSON.parse(sessionStorage.getItem('user'));
    }
    return (
        <Fragment>     
            <Route 
                    {...rest}
                    render={props => {
                        if(localStorage.getItem('isAuthenticated') === null)
                        {
                            return <Redirect to={url + "/login"} />
                        }else{
                            if(user && user.role == 2){
                                return <Component {...props} />
                            }else{
                                return <Redirect to={url + "/"} />
                            }
                            
                        }
                    }}
            />           
        </Fragment>
    )
}

export default SupplierProtectedRoute
