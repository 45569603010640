import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import ProtectedHeader from '../layout/ProtectedHeader';
import { IMAGES } from '../../constants';
import { useLocation, useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { url } from '../../config';
import EditSingleProduct from './EditSingleProduct';
import { Link } from 'react-router-dom';

const queryString = require('query-string');

const Index = () => {

    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const parsed = queryString.parse(location.search);
    // const { query } = useLocation(); 
    const [productType, setProductType] = useState();
    const [productTypeSlug, setProductTypeSlug] = useState(parsed && parsed.cat);

    const { category } = useSelector(state => state.categories);

    const handleProductType = (event) => {
        setProductType(event.target.options[event.target.selectedIndex].dataset.id);
        setProductTypeSlug(event.target.value);
        history.push({ pathname: `${url + '/add-single-product'}`, search: `?cat=${event.target.value}` })
    }


    return (
        <Fragment>
            <ProtectedHeader />
            <ul className="breadcrub">
                <li>
                    <FormattedMessage
                        id="myaccount.myaccountname"
                        defaultMessage="My Account"
                    />
                </li>
                <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                <li>
                    <FormattedMessage
                        id="Products"
                        defaultMessage="Products"
                    />
                </li>
                <li><img src={IMAGES.ARROW_SVG} alt="" /></li>
                <li>
                  <FormattedMessage id="EditProductPrice" defaultMessage="Edit Product Price"/>
                </li>
            </ul>
            <section className="container-fluid py-4 conPart">
                <div className="row justify-content-center">
                    <div className="col-md-9">
                        <div className="titles nbrd mt-4 mb-3 pb-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="m-0">
                                    <Link to={url + '/myproducts/'+searchParams.get('category_slug')} className="not_a"><i className='fa fa-arrow-left' ></i></Link>
                                      &nbsp; <FormattedMessage id="EditProductPrice" defaultMessage="Edit Product Price"/>
                                    </h2>
                                </div>
                            </div>
                        </div>
                        {
                          <EditSingleProduct /> 
                        }
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Index
