import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAttributes } from "../../actions/productActions";
import { validateFields } from '../../validation';
import classnames from 'classnames';
import $ from 'jquery';
import { IMAGES } from '../../constants';

const WeddingBand = ({ props, handleEditExcelForm }) => {

    const dispatch = useDispatch();

    const [addproduct, setAddProduct] = useState({
        stock_no: "",
        gender: "",
        gold_karat: "",
        metal_type: "",
        metal_weight: "",
        pricegr: "",
        showcase_image: "",
        stone_color: "",
        stone_shape: "",
        stone_type: "",
        style: "",
        thickness: "",
        type: "",
        wideness: "",
        showcase_image: "",
        comment: "",
        image_1: "",
        image_2: "",
        image_3: "",
        image_4: "",
        image_5: "",
    })

    const [validerror, setValidError] = useState({
        stock_noError: "",
        genderError: "",
        gold_karatError: "",
        metal_typeError: "",
        metal_weightError: "",
        pricegrError: "",
        stone_colorError: "",
        stone_shapeError: "",
        stone_typeError: "",
        styleError: "",
        thicknessError: "",
        typeError: "",
        widenessError: ""
    });

    const handleInputChange = (event) => {
        setValidError({
            stock_noError: "",
            genderError: "",
            gold_karatError: "",
            metal_typeError: "",
            metal_weightError: "",
            pricegrError: "",
            stone_colorError: "",
            stone_shapeError: "",
            stone_typeError: "",
            styleError: "",
            thicknessError: "",
            typeError: "",
            widenessError: ""
        })
        setAddProduct({ ...addproduct, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const stock_noError = validateFields.validateStock_Number(addproduct.stock_no);
        const pricegrError = validateFields.validatePrice(addproduct.pricegr);
        const genderError = validateFields.validateGender(addproduct.gender);
        const gold_karatError = validateFields.validateGoldKarat(addproduct.gold_karat);
        const metal_typeError = validateFields.validateMetalType(addproduct.metal_type);
        const metal_weightError = validateFields.validateMetalWeight(addproduct.metal_weight);
        const stone_colorError = validateFields.validateStoneColor(addproduct.stone_color);
        const stone_shapeError = validateFields.validateStoneShape(addproduct.stone_shapeError);
        const stone_typeError = validateFields.validateStoneType(addproduct.stone_type);
        const styleError = validateFields.validateStyle(addproduct.style);
        const thicknessError = validateFields.validateThickness(addproduct.thickness);
        const typeError = validateFields.validateType(addproduct.type);
        const widenessError = validateFields.validateWideness(addproduct.wideness);


        if ([stock_noError, pricegrError, genderError, gold_karatError, metal_typeError, metal_weightError, stone_colorError, stone_shapeError, stone_typeError, styleError, thicknessError, typeError, widenessError].every(e => e === false)) {
            setValidError({
                ...validerror, stock_noError, pricegrError, genderError, gold_karatError, metal_typeError, metal_weightError, stone_colorError, stone_shapeError, stone_typeError, styleError, thicknessError, typeError, widenessError
            })
            handleEditExcelForm(addproduct);
            $('#dismiss-modal').trigger('click');
        }
        else {
            setValidError({
                ...validerror, stock_noError, pricegrError, genderError, gold_karatError, metal_typeError, metal_weightError, stone_colorError, stone_shapeError, stone_typeError, styleError, thicknessError, typeError, widenessError
            })
        }
        return;
    }

    const { attributes } = useSelector(state => state.attributes)

    useEffect(() => {
        dispatch(getAttributes('wedding-bands'))
    }, [dispatch])

    useEffect(() => {
        if (props) {
            setAddProduct({
                stock_no: props && props.stock_no,
                gender: props && props.gender,
                gold_karat: props && props.gold_karat,
                metal_type: props && props.metal_type,
                metal_weight: props && props.metal_weight,
                pricegr: props && props.pricegr,
                showcase_image: props && props.showcase_image,
                stone_color: props && props.stone_color,
                stone_shape: props && props.stone_shape,
                stone_type: props && props.stone_type,
                style: props && props.style,
                thickness: props && props.thickness,
                type: props && props.type,
                wideness: props && props.wideness,
                comment: props && props.comment,
                image_1: props && props.image_1,
                image_2: props && props.image_2,
                image_3: props && props.image_3,
                image_4: props && props.image_4,
                image_5: props && props.image_5,
            })
        }
    }, [props])


    const delete_image = (key) => {
        if (key === 1) {
            setAddProduct({ ...addproduct, image_1: false })
        }
        else if (key === 2) {
            setAddProduct({ ...addproduct, image_2: false })
        }
        else if (key === 3) {
            setAddProduct({ ...addproduct, image_3: false })
        }
        else if (key === 4) {
            setAddProduct({ ...addproduct, image_4: false })
        }
        else if (key === 5) {
            setAddProduct({ ...addproduct, image_5: false })
        }
    }

    return (
        <Fragment>
            <div className="modal fade editproduct_form" id="editproductweddingband" tabindex="-1" role="dialog" aria-labelledby="editproductTitle" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <form onSubmit={handleSubmit}>
                        <div className="modal-content">
                            <div className="modal-header align-items-center">
                                <button id="dismiss-modal" type="button" className="close m-0" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h5 className="modal-title" id="editproductTitle">Wedding Bands</h5>
                                <button type="submit" className="btn btn-black">Save Changes</button>
                            </div>
                            <div className="modal-body">
                                <div className="row justify-content-center">
                                    <div className="col-md-10">
                                        <div className="titles nbrd mt-4 mb-3 pb-3">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h2 className="m-0">Edit Product</h2>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Stock No.</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.stock_noError === false }, { 'is-invalid': validerror.stock_noError })} type="text" placeholder="Stock No." name="stock_no" value={addproduct.stock_no} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.stock_noError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Type</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.typeError === false }, { 'is-invalid': validerror.typeError })} value={addproduct.type} name="type" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.type && attributes.type.map((type) => {
                                                                return (
                                                                    <option value={type.name}>{type.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.typeError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Style</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.styleError === false }, { 'is-invalid': validerror.styleError })} value={addproduct.style} name="style" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.style && attributes.style.map((style) => {
                                                                return (
                                                                    <option value={style.name}>{style.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.styleError}</p>
                                                </div>
                                            </div>


                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Gender</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.genderError === false }, { 'is-invalid': validerror.genderError })} name="gender" value={addproduct.gender} onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.gender && attributes.gender.map((gender) => {
                                                                return (
                                                                    <option value={gender.name}>{gender.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.genderError}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Metal Type</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.metal_typeError === false }, { 'is-invalid': validerror.metal_typeError })} value={addproduct.metal_type} onChange={handleInputChange} name="metal_type">
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.metal_type && attributes.metal_type.map((metal_type) => {
                                                                return (
                                                                    <option value={metal_type.name}>{metal_type.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.metal_typeError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Gold Karat</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.gold_karatError === false }, { 'is-invalid': validerror.gold_karatError })} type="text" placeholder="Gold Karat" name="gold_karat" value={addproduct.gold_karat} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.gold_karatError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Gold Weight</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.metal_weightError === false }, { 'is-invalid': validerror.metal_weightError })} type="text" placeholder="Gold Weight" name="metal_weight" value={addproduct.metal_weight} onChange={handleInputChange} />
                                                    <p className="invalid-feedback">{validerror.metal_weightError}</p> <p className="invalid-feedback">{validerror.metal_weightError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Stone Type</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.stone_typeError === false }, { 'is-invalid': validerror.stone_typeError })} value={addproduct.stone_type} onChange={handleInputChange} name="stone_type">
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.stone_type && attributes.stone_type.map((stone_type) => {
                                                                return (
                                                                    <option value={stone_type.name}>{stone_type.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.stone_typeError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Stone Color</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.stone_colorError === false }, { 'is-invalid': validerror.stone_colorError })} value={addproduct.stone_color} name="stone_color" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.stone_color && attributes.stone_color.map((stone_color) => {
                                                                return (
                                                                    <option value={stone_color.name}>{stone_color.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.stone_colorError}</p>
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Stone Shape</label>
                                                    <select className={classnames("form-control", { 'is-valid': validerror.stone_shapeError === false }, { 'is-invalid': validerror.stone_shapeError })} value={addproduct.stone_shape} name="stone_shape" onChange={handleInputChange}>
                                                        <option value="">{`Select`}</option>
                                                        {
                                                            attributes && attributes.stone_shape && attributes.stone_shape.map((stone_shape) => {
                                                                return (
                                                                    <option value={stone_shape.name}>{stone_shape.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <p className="invalid-feedback">{validerror.stone_shapeError}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Wideness</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.widenessError === false }, { 'is-invalid': validerror.widenessError })} type="text" placeholder="Wideness" name="wideness" value={addproduct.wideness} onChange={handleInputChange} />

                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Thickness</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.thicknessError === false }, { 'is-invalid': validerror.thicknessError })} type="text" placeholder="Thickness" name="thickness" value={addproduct.thickness} onChange={handleInputChange} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Price/gr</label>
                                                    <input className={classnames("form-control", { 'is-valid': validerror.pricegrError === false }, { 'is-invalid': validerror.pricegrError })} type="text" placeholder="Price/gr" name="pricegr" value={addproduct.pricegr} onChange={handleInputChange} />

                                                </div>
                                            </div>
                                        </div>


                                        <hr className="w-100" />

                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Comment (optional)</label>
                                                    <textarea onChange={handleInputChange} placeholder="Stone looks milky" rows="5" className="form-control" value={addproduct.comment} name="comment"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <h3 className="forms toBrdr">
                                            Upload Product Photos or Videos
                                            <p>JPEG, PNG supported, max 10mb per image</p>
                                        </h3>

                                        <div className="row imgEdits mb-4">
                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_1} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(1)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_2} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(2)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_3} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(3)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_4} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(4)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>

                                            <div className="col-md-4 imgBlock">
                                                <img className="w-100" src={addproduct.image_5} alt="" />
                                                <div className="hover">
                                                    <img onClick={(e) => delete_image(5)} src={IMAGES.CLOSE_WHITE} alt="" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row actions my-5">
                                            <div className="col-md-6">
                                                <button type="submit" className="btns btn-black  mr-2 mb-2">Save Changes</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default WeddingBand
