import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addReviewAction, clearError } from '../../../actions/userActions';
import { useLocation } from 'react-router';
import { getLastURL } from '../../../helper';
import Loader from '../../layout/Loader';
import { useAlert } from 'react-alert';
import { ADD_REVIEW_RESET } from '../../../constants/userConstants';
import $ from 'jquery';
import { validateFields } from '../../../validation';
import classnames from 'classnames';
import PopupSucess from '../../layout/PopupSucess';
import PopupFail from '../../layout/PopupFail';
import { GetRatingTagAction } from '../../../actions/productActions';
import { FormattedMessage, useIntl } from 'react-intl';


const AddComment = () => {

    const dispatch = useDispatch();
    const alert = useAlert();
    const location = useLocation();

    const product_id = getLastURL(location) 

    const { details } = useSelector(state => state.productDetails);
    const seller_id = details && details.sellerDetails && details.sellerDetails.id;

    const { review, loading, error } = useSelector(state => state.Review);
    const { ratingTags } = useSelector(state => state.GetRatingTagReducer);

    const name = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')).name;
    
    const [addRating, setAddRating] = useState({
        product_id: product_id,
        seller_id: "",
        name: name,
        product_title:"",
        is_anonymous:"",
        rating:"",
        heading:"",
        comment:""
    })
    const [tag, setTag] = useState([]);

    const [validerror, setValidError] = useState({
        nameError: "",
        ratingError:"",
        headingError:"",
        commentError:""
    });

    useEffect(() =>{
        setAddRating({...addRating, seller_id: seller_id})
    }, [details])

    useEffect(() =>{
         dispatch(GetRatingTagAction())
    }, [])

    useEffect(() =>{
        
        if(review)
        {
            // alert.success(review);
            $("#popup-click-sucess").trigger('click');
            dispatch({
                type:ADD_REVIEW_RESET
            })
        }
        if(error)
        {
            // alert.error(error)
            $("#popup-click-fail").trigger('click');
            // clearError();
            dispatch({
                type:ADD_REVIEW_RESET
            })
        }
    }, [review, error])


    const handleAddRatingChange = (e) =>{
        setValidError({
            nameError: "",
            ratingError:"",
            headingError:"",
            commentError:""
        })
        setAddRating({...addRating, [e.target.name] : e.target.value})
    }

    const handleAnonymousChange = (e) =>{
        setAddRating({...addRating, is_anonymous : e.target.checked})
    }

    const handleSubmit = (e) =>{
        // console.log('handleSubmit--------------->',e); 
        e.preventDefault();
        const ratingError = validateFields.validateRating(addRating.rating);
        const headingError = validateFields.validateHeading(addRating.heading);
        var commentError = false
        
        if(addRating.rating <= 2){
            var commentError = validateFields.validateComment(addRating.comment);
        }
        
        if ([ ratingError, headingError, commentError ].every(e => e === false)) {
            
            setValidError({
                ...validerror, ratingError, headingError, commentError
            })
            
            const allObject = Object.assign({}, addRating, {tags: tag,product_title: $('.H_title').text()})
            console.log('allObject--------------->',allObject);
            dispatch(addReviewAction(allObject));       
            $('#dismiss-modal').trigger('click');
        }
        else {
            setValidError({
                ...validerror, ratingError, headingError, commentError
            })
        }
    }

    const handleTags = (tagname, id) =>{
        if($('#quick_box_id'+id).hasClass('active')){
            $('#quick_box_id'+id).removeClass('active')
        }else{
            $('#quick_box_id'+id).addClass('active')
        }

        if (!tag.includes(tagname)) {
            setTag([...tag, tagname]);
            setAddRating({...addRating, 'heading' :tagname+', '+String(tag)})
        } else {
            tag.splice(tag.indexOf(tagname), 1);
            setAddRating({...addRating, 'heading' :String(tag)})
        }
    }
    return (
        <Fragment>
            { loading && <Loader />}
            <Fragment>
            <div className="modal fade rateMOdel" id="comment" tabindex="-1" role="dialog" aria-labelledby="comment" aria-hidden="true">
                <div className="modal-dialog" role="document">
                <form onSubmit={handleSubmit}>
                    <div className="modal-content bg-white">
                        <div className="modal-header align-items-center">
                            <h5 className="modal-title" id="editproductTitle"><FormattedMessage
                                                        id="addsingleproduct.WriteAReview"
                                                        defaultMessage="Write a review"
                                                    /></h5>
                            <button id="dismiss-modal" type="button" className="close m-0" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                            
                        <div className="modal-body">

                            <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group stars">
                                            <div className='info-div'>

                                            <label htmlFor=""><FormattedMessage
                                                            id="Rating"
                                                            defaultMessage="Rating"
                                                        /></label>
                                                        <i className="fa fa-info-circle mr-2" aria-hidden="true"></i>
                                                        <FormattedMessage
                                                        id="addsingleproduct.PrivacyPolicyRating"
                                                        defaultMessage="For your privacy, we don’t share your name and product details for reviews." />
                                            </div>
                                            <p style={{ textAlign: "center", fontSize: "medium"}}><FormattedMessage
                                                            id="Addrating.how"
                                                            defaultMessage="How would you rate your overall experience with"
                                                        />, <strong>{details && details.sellerDetails && details.sellerDetails.business && details.sellerDetails.business.company_name}?</strong></p>
                                            
                                            <p style={{ textAlign: "center", fontSize: "medium"}}> <strong>{$('.H_title').text()} </strong></p>

                                        <center> <div className={classnames("rate ", { 'is-valid': validerror.ratingError === false }, { 'is-invalid': validerror.ratingError })}>
                                                <input type="radio" id="star5" name="rating" value="5"  onChange={handleAddRatingChange}/>
                                                <label htmlFor="star5" title="text">5 stars</label>
                                                <input type="radio" id="star4" name="rating" value="4"  onChange={handleAddRatingChange}/>
                                                <label htmlFor="star4" title="text">4 stars</label>
                                                <input type="radio" id="star3" name="rating" value="3"  onChange={handleAddRatingChange}/>
                                                <label htmlFor="star3" title="text">3 stars</label>
                                                <input type="radio" id="star2" name="rating" value="2"  onChange={handleAddRatingChange}/>
                                                <label htmlFor="star2" title="text">2 stars</label>
                                                <input type="radio" id="star1" name="rating" value="1"  onChange={handleAddRatingChange}/>
                                                <label htmlFor="star1" title="text">1 star</label>
                                            </div>
                                            <p className="invalid-feedback">{validerror.ratingError}</p>
                                            </center>
                                            
                                        </div>
                                    </div>
                                </div>

                            <div className='quick_box'>
                                {/* <p>What could be improved?</p> */}
                                <ul>
                                    {
                                    ratingTags && ratingTags.tags && ratingTags.tags.map((data, key) =>{
                                        return(
                                            <li id={'quick_box_id'+data.id} onClick={() => handleTags(data.name, data.id)}>{data.name}</li>
                                        )
                                    })
                                    }
                                </ul>
                            </div>

                            <div className="row" style={{'display':'none'}}>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor=""><FormattedMessage
                                                        id="Name"
                                                        defaultMessage="Name"
                                                    /></label>
                                        <input className={classnames("form-control", { 'is-valid': validerror.nameError === false }, { 'is-invalid': validerror.nameError })} type="text" placeholder={useIntl().formatMessage({id: 'PleaseEnterAPublic'})}  name="name" value={addRating.name}  onChange={handleAddRatingChange} />
                                        <p className="invalid-feedback">{validerror.nameError}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{'display':'none'}}>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <input className="checkbox" type="checkbox" name="is_anonymous" value={addRating.is_anonymous}  onChange={handleAnonymousChange} />
                                        <label htmlFor=""><FormattedMessage
                                                        id="ShowMyName"
                                                        defaultMessage="Show my name"
                                                    /></label>
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{'display':'none'}}>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor=""><FormattedMessage
                                                        id="AddAheading"
                                                        defaultMessage="Add a heading"
                                                    /></label>
                                        <input className={classnames("form-control", { 'is-valid': validerror.headingError === false }, { 'is-invalid': validerror.headingError })} type="text"  placeholder={useIntl().formatMessage({id: 'rating.heading.placeholder'})} name="heading" value={addRating.heading}  onChange={handleAddRatingChange} />
                                        <p className="invalid-feedback">{validerror.headingError}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label htmlFor=""><FormattedMessage
                                                        id="addsingleproduct.comment"
                                                        defaultMessage="Comment"
                                                    /></label>
                                        <textarea className={classnames("form-control", { 'is-valid': validerror.commentError === false }, { 'is-invalid': validerror.commentError })} type="text" rows="5" placeholder={useIntl().formatMessage({id: 'rating.comment.placeholder'})} name="comment" value={addRating.comment}   onChange={handleAddRatingChange} ></textarea>
                                        <p className="invalid-feedback">{validerror.commentError}</p>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-black" type="submit"><FormattedMessage
                                                        id="submit"
                                                        defaultMessage="Submit"
                                                    /></button>
                        </div> 
                    </div>
                    </form>
                </div>
            </div>
        </Fragment>
            {
                review && 
                <PopupSucess props={review}/>
            }
         
            {
                error &&
                <PopupFail props={error}/> 
            }
               
        </Fragment>
    )
}

export default AddComment

