import {
    SUBSCRIPTION_DETAILS_REQUEST,
    SUBSCRIPTION_DETAILS_SUCCESS,
    SUBSCRIPTION_DETAILS_FAIL
}
    from "../constants/SubscriptionConstant"

const initialStateSubscription = {
    loading: false,
    status: false,
    data: null,
    error: null
}

export const SubscriptionStripeReducer = (state = initialStateSubscription, action) => {
    switch (action.type) {
        case SUBSCRIPTION_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                status: true,
                data: action?.payload
            }
        case SUBSCRIPTION_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                status: false,
                error: action?.payload
            }
        default:
            return state
    }
}