import { makeRequestGet, makeRequestPost, makeFormDataRequestPost } from '../utils/apiHelper';
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOAD_REQUEST,
    USER_LOAD_SUCCESS,
    USER_LOAD_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,
    FORGET_PASSWORD_REQUEST,
    FORGET_PASSWORD_FAIL,
    FORGET_PASSWORD_SUCCESS,
    GET_PROFILE_REQUEST,
    GET_PROFILE_SUCCESS,
    GET_PROFILE_FAIL,
    GET_PLAN_REQUEST,
    GET_PLAN_SUCCESS,
    GET_PLAN_FAIL,
    CLEAR_ERRORS,
    GET_CITY_REQUEST,
    GET_CITY_SUCCESS,
    GET_CITY_FAIL,
    GET_COUNTRY_REQUEST,
    GET_COUNTRY_SUCCESS,
    GET_COUNTRY_FAIL,
    GET_STATE_REQUEST,
    GET_STATE_SUCCESS,
    SUBMIT_BUSINESSINFO_REQUEST,
    SUBMIT_BUSINESSINFO_SUCCESS,
    SUBMIT_BUSINESSINFO_FAIL,
    SUBMIT_PAYMENTINFO_REQUEST,
    SUBMIT_PAYMENTINFO_SUCCESS,
    SUBMIT_PAYMENTINFO_FAIL,
    CONTACT_US_REQUEST,
    CONTACT_US_SUCCESS,
    CONTACT_US_FAIL,
    CURRENT_PLAN_REQUEST,
    CURRENT_PLAN_SUCCESS,
    CURRENT_PLAN_FAIL,
    GET_PLAN_ROLE_REQUEST,
    GET_PLAN_ROLE_SUCCESS,
    GET_PLAN_ROLE_FAIL,
    GET_STATE_FAIL,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    SEND_OTP_REQUEST,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAIL,
    ADD_REVIEW_REQUEST,
    ADD_REVIEW_SUCCESS,
    ADD_REVIEW_FAIL,
    GET_REVIEW_REQUEST,
    GET_REVIEW_SUCCESS,
    GET_REVIEW_FAIL,
    PAYMENTHISTORY_REQUEST,
    PAYMENTHISTORY_SUCCESS,
    PAYMENTHISTORY_FAIL,
    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_SUCCESS,
    CANCEL_SUBSCRIPTION_FAIL



} from '../constants/userConstants';

import { hashResEncodeData } from "../helper";

export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_REQUEST })
        var uuid = localStorage.getItem("uuid");
        const data = await makeRequestPost('signin', { email, password, device_type: "WEB", device_token: uuid });

        if (data.status) {
            localStorage.setItem('isAuthenticated', data.security_token);
            sessionStorage.setItem('user', JSON.stringify(data.data));
            dispatch({
                type: LOGIN_SUCCESS,
                payload: data.data
            })
        } else {
            dispatch({
                type: LOGIN_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error
        })
    }
}




export const register = (userRegistration) => async (dispatch) => {
    try {
        dispatch({ type: REGISTER_REQUEST })

        var uuid = localStorage.getItem("uuid") || 'uuid';
        const formData = new FormData();
        var obj = userRegistration;
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                formData.append(key, obj[key])
            }
        }
        formData.append('device_type', 'WEB')
        formData.append('device_token', uuid)

        const data = await makeRequestPost('signup', formData);

        if (data.status) {
            await localStorage.setItem('isAuthenticated', data.security_token);
            await sessionStorage.setItem('user', JSON.stringify(data.data));
            dispatch({
                type: REGISTER_SUCCESS,
                payload: data.data
            })
        } else {
            dispatch({
                type: REGISTER_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: REGISTER_FAIL,
            payload: error.message
        })
    }
}



export const loadUser = () => async (dispatch) => {

    try {
        dispatch({ type: USER_LOAD_REQUEST })

        const data = JSON.parse(sessionStorage.getItem('user'))
        if (data && data !== undefined) {
            dispatch({
                type: USER_LOAD_SUCCESS,
                payload: data
            })
        }
        else {
            const response = await makeRequestGet('get-profile')
            sessionStorage.setItem('user', JSON.stringify(response.data))
            if (response.status) {
                dispatch({
                    type: USER_LOAD_SUCCESS,
                    payload: response.data
                })
            } else {
                dispatch({
                    type: USER_LOAD_FAIL,
                    payload: response.data
                })
            }
        }
    } catch (error) {
        dispatch({
            type: USER_LOAD_FAIL,
            payload: error
        })
    }
}



export const logout = () => async (dispatch) => {

    try {
        var uuid = localStorage.getItem("uuid");
        const data = await makeRequestPost('logout', { device_type: "WEB", device_token: uuid })

        if (data.status) {
            dispatch({
                type: LOGOUT_SUCCESS
            })
        }
        else {
            dispatch({
                type: LOGOUT_FAIL,
                payload: data
            })
        }

    } catch (error) {
        dispatch({
            type: LOGOUT_FAIL,
            payload: error.message
        })
    }
}


export const changePassword = (otp, new_password) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PASSWORD_REQUEST })

        const data = await makeRequestPost('change-password', { otp, new_password });

        if (data.status) {
            dispatch({
                type: UPDATE_PASSWORD_SUCCESS,
                payload: data.status
            })
        } else {
            dispatch({
                type: UPDATE_PASSWORD_FAIL,
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: UPDATE_PASSWORD_FAIL,
            payload: error.message
        })
    }
}


export const forgetPassword = (email) => async (dispatch) => {
    try {
        dispatch({ type: FORGET_PASSWORD_REQUEST })

        const data = await makeRequestPost('forgot-password', { email });

        if (data.status) {
            dispatch({
                type: FORGET_PASSWORD_SUCCESS,
                payload: data.message
            })
        } else {
            dispatch({
                type: FORGET_PASSWORD_FAIL,
                payload: data.message
            })
        }
    }
    catch (error) {
        dispatch({
            type: FORGET_PASSWORD_FAIL,
            payload: error
        })
    }
}


export const getPlan = () => async (dispatch) => {

    try {
        dispatch({ type: GET_PLAN_REQUEST })

        const data = await makeRequestGet('get-plans')

        if (data.status) {
            dispatch({
                type: GET_PLAN_SUCCESS,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_PLAN_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: GET_PLAN_FAIL,
            payload: error
        })
    }
}


export const getProfileData = () => async (dispatch) => {

    try {
        dispatch({ type: GET_PROFILE_REQUEST })

        const data = await makeRequestGet('get-profile-with-business');

        if (data.status) {
            dispatch({
                type: GET_PROFILE_SUCCESS,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_PROFILE_FAIL,
                payload: data.data
            })
        }
    } catch (error) {
        dispatch({
            type: GET_PROFILE_FAIL,
            payload: error
        })
    }
}


export const getCountry = () => async (dispatch) => {

    try {
        dispatch({ type: GET_COUNTRY_REQUEST })

        const data = await makeRequestGet('get-countries')

        if (data.status) {
            dispatch({
                type: GET_COUNTRY_SUCCESS,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_COUNTRY_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: GET_COUNTRY_FAIL,
            payload: error
        })
    }
}


export const getState = (country_id) => async (dispatch) => {

    try {
        dispatch({ type: GET_STATE_REQUEST })

        const data = await makeRequestPost('get-states', { country_id });

        if (data.status) {
            dispatch({
                type: GET_STATE_SUCCESS,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_STATE_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: GET_STATE_FAIL,
            payload: error
        })
    }
}

export const getCity = (state_id) => async (dispatch) => {

    try {
        dispatch({ type: GET_CITY_REQUEST })

        const data = await makeRequestPost('get-cities', { state_id })

        if (data.status) {
            dispatch({
                type: GET_CITY_SUCCESS,
                payload: data.data
            })
        } else {
            dispatch({
                type: GET_CITY_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: GET_CITY_FAIL,
            payload: error
        })
    }
}

export const submitBusinessInfo = (businessInfo) => async (dispatch) => {

    try {
        dispatch({ type: SUBMIT_BUSINESSINFO_REQUEST })

        const formData = new FormData();

        var obj = businessInfo;
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                formData.append(key, obj[key])
            }
        }

        const data = await makeRequestPost('add-business-info', formData)

        if (data.status) {
            sessionStorage.setItem('user', JSON.stringify(data.data.user))
            dispatch({
                type: SUBMIT_BUSINESSINFO_SUCCESS,
                payload: data.message
            })
        }
        else {
            dispatch({
                type: SUBMIT_BUSINESSINFO_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type: SUBMIT_BUSINESSINFO_FAIL,
            payload: error
        })
    }
}


export const submitPaymentInfo = (paymentInfo) => async (dispatch) => {
    try {
        dispatch({ type: SUBMIT_PAYMENTINFO_REQUEST })

        const formData = new FormData();

        var obj = paymentInfo;
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                formData.append(key, obj[key])
            }
        }
        const data = await makeRequestPost('payment', formData)
        if (data.status) {
            sessionStorage.setItem('user', JSON.stringify(data.data.user))
            if (data.paymentRequest) {
                dispatch({
                    type: SUBMIT_PAYMENTINFO_SUCCESS,
                    paymentscript: data.data.res,
                    payload: data.message
                })
            } else {
                dispatch({
                    type: SUBMIT_PAYMENTINFO_SUCCESS,
                    paymentscript: null,
                    payload: data.message
                })
            }
        }
        else {
            dispatch({
                type: SUBMIT_PAYMENTINFO_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type: SUBMIT_PAYMENTINFO_FAIL,
            payload: error
        })
    }
}


export const paymentHistory = () => async (dispatch) => {
    try {
        dispatch({ type: PAYMENTHISTORY_REQUEST })
        const data = await makeRequestPost('history',)

        if (data.status) {
            if (data.data.payments) {
                dispatch({
                    type: PAYMENTHISTORY_SUCCESS,
                    payload: data.data.payments.data
                })
            } else {
                dispatch({
                    type: PAYMENTHISTORY_SUCCESS,
                    payload: data.message
                })
            }
        }
        else {
            dispatch({
                type: PAYMENTHISTORY_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type: PAYMENTHISTORY_FAIL,
            payload: error
        })
    }
}


export const contactUs = (contactInfo) => async (dispatch) => {

    try {
        dispatch({ type: CONTACT_US_REQUEST })

        const formData = new FormData();

        var obj = contactInfo;
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                formData.append(key, obj[key])
            }
        }

        const data = await makeRequestPost('add-contact', formData)

        if (data.status) {
            dispatch({
                type: CONTACT_US_SUCCESS,
                payload: data.message
            })
        }
        else {
            dispatch({
                type: CONTACT_US_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type: CONTACT_US_FAIL,
            payload: error
        })
    }
}


export const currentPlan = () => async (dispatch) => {
    const token = localStorage.getItem('isAuthenticated');

    try {
        dispatch({ type: CURRENT_PLAN_REQUEST })

        const data = await makeRequestGet('get-user-plan');

        if (data.status) {
            dispatch({
                type: CURRENT_PLAN_SUCCESS,
                payload: data.data
            })
        }
        else {
            dispatch({
                type: CURRENT_PLAN_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type: CURRENT_PLAN_FAIL,
            payload: error
        })
    }
}

export const cancelPlan = (transaction_id) => async (dispatch) => {
    const token = localStorage.getItem('isAuthenticated');

    try {
        dispatch({ type: CANCEL_SUBSCRIPTION_REQUEST })

        const data = await makeRequestPost('cancel-subscription', { transaction_id });

        if (data.status) {
            dispatch({
                type: CANCEL_SUBSCRIPTION_SUCCESS,
                payload: data
            })
        }
        else {
            dispatch({
                type: CANCEL_SUBSCRIPTION_FAIL,
                payload: data
            })
        }

    } catch (error) {
        dispatch({
            type: CURRENT_PLAN_FAIL,
            payload: error
        })
    }
}


export const getPlanByRole = () => async (dispatch) => {

    try {
        dispatch({ type: GET_PLAN_ROLE_REQUEST })

        const data = await makeRequestGet('get-plans-by-role')

        if (data.status) {
            dispatch({
                type: GET_PLAN_ROLE_SUCCESS,
                payload: data.data
            })
        }
        else {
            dispatch({
                type: GET_PLAN_ROLE_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_PLAN_ROLE_FAIL,
            payload: error
        })
    }
}

export const updateProfileAction = (profile) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROFILE_REQUEST })

        const formData = new FormData();

        var obj = profile;
        let obj2 = {};

        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (key === 'profile_picture') {
                    formData.append('profile_picture', obj[key]);
                }
                // formData.append(key, obj[key])
                else {
                    obj2[key] = obj[key]
                }
            }
        }

        if (obj2 && Object.keys(obj2).length > 0) {
            const encodedData = hashResEncodeData(obj2);
            formData.append('req', encodedData?.data);
        }

        const data = await makeFormDataRequestPost('edit-profile', formData);

        if (data.status) {
            sessionStorage.setItem('user', JSON.stringify(data.data))
            dispatch({
                type: UPDATE_PROFILE_SUCCESS,
                payload: data.message
            })
        } else {
            dispatch({
                type: UPDATE_PROFILE_FAIL,
                payload: data.message
            })
        }
    } catch (error) {
        dispatch({
            type: UPDATE_PROFILE_FAIL,
            payload: error.message
        })
    }
}



export const sendOtpAction = () => async (dispatch) => {

    try {
        dispatch({ type: SEND_OTP_REQUEST })

        const data = await makeRequestPost('otp')

        if (data.status) {
            dispatch({
                type: SEND_OTP_SUCCESS,
                payload: data.message
            })
        }
        else {
            dispatch({
                type: SEND_OTP_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type: SEND_OTP_FAIL,
            payload: error
        })
    }
}


export const addReviewAction = (addRating) => async (dispatch) => {

    try {
        dispatch({ type: ADD_REVIEW_REQUEST })

        const data = await makeRequestPost('add-rating', addRating)

        if (data.status) {
            dispatch({
                type: ADD_REVIEW_SUCCESS,
                payload: data.message
            })
        }
        else {
            dispatch({
                type: ADD_REVIEW_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type: ADD_REVIEW_FAIL,
            payload: error
        })
    }
}



export const getReviewAction = (product_id, seller_id, page) => async (dispatch) => {

    try {
        dispatch({ type: GET_REVIEW_REQUEST })

        const data = await makeRequestPost('get-seller-rating', { product_id: product_id, seller_id: seller_id, page: page, limit: 4 })

        if (data.status) {
            dispatch({
                type: GET_REVIEW_SUCCESS,
                payload: data.data
            })
        }
        else {
            dispatch({
                type: GET_REVIEW_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_REVIEW_FAIL,
            payload: error
        })
    }
}



export const getReviewActionProfile = (product_id, seller_id, page) => async (dispatch) => {

    try {
        dispatch({ type: GET_REVIEW_REQUEST })

        const data = await makeRequestPost('get-seller-rating', { product_id: product_id, seller_id: seller_id, page: page, limit: 4 })

        if (data.status) {
            dispatch({
                type: GET_REVIEW_SUCCESS,
                payload: data
            })
        }
        else {
            dispatch({
                type: GET_REVIEW_FAIL,
                payload: data.message
            })
        }

    } catch (error) {
        dispatch({
            type: GET_REVIEW_FAIL,
            payload: error
        })
    }
}

export const clearError = () => (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}