import React, { Fragment, useState, useEffect } from 'react';
import $ from 'jquery';
import { useAlert } from 'react-alert';
import Sidebar from './Sidebar';
import ProtectedHeader from '../../layout/ProtectedHeader';
import { IMAGES } from '../../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { clearError, contactUs, getProfileData } from '../../../actions/userActions';
import MetaData from '../../layout/MetaData';
import MessageSent from './MessageSent';
import { validateFields } from '../../../validation';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

const ContactUs = () => {

    const alert = useAlert()
    const dispatch = useDispatch()

    const { contactus, error } = useSelector(state => state.contact)
    const { getProfile } = useSelector(state => state.GetProfileWithBusiness);

    const [contactInfo, setContactInfo] = useState({
        user_id: "",
        name: "",
        email: "",
        purpose_id: "",
        message: ""
    })
    useEffect(async () => {
        await dispatch(getProfileData());
        dispatch(clearError());
            $('#conform').show()
            $('#conthanks').hide()
    }, [])
    useEffect(() => {
        if (getProfile) {
            setContactInfo({
                user_id:  getProfile && getProfile.user_detail && getProfile.user_detail.id,
                name: getProfile && getProfile.user_detail && getProfile.user_detail.name,
                email: getProfile && getProfile.user_detail && getProfile.user_detail.email,
            })
           
        }
        
    }, [getProfile]);

    useEffect(() => {
       
        if (contactus) {
           // alert.success(contactus);
            dispatch(clearError());
            $('#conform').hide()
            $('#conthanks').show()
            return
        }

        if (error) {
            alert.error(error)
            dispatch(clearError());
            return
        }

    }, [dispatch, error, contactus])


    const [contactError, setContactError] = useState({
        purposeError: '',
        messageError: ''
    })

    const handleContact = (event) => {
        setContactInfo({ ...contactInfo, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const purposeError = validateFields.validatePurpose(contactInfo.purpose_id);
        const messageError = validateFields.validateMessage(contactInfo.message);
        if ([purposeError, messageError].every(e => e === false)) {
            setContactError({
                ...contactError, purposeError, messageError
            })
            dispatch(contactUs(contactInfo))
        }
        else {
            setContactError({
                ...contactError, purposeError, messageError
            })
        }
    }

    const handleCancel = () => {
        setContactInfo({
            purpose_id: '',
            message: ''
        })
    }

    return (
        <Fragment>
            <MetaData title={`Contact Us`} />
            <ProtectedHeader />
            <ul className="breadcrub">
                <li>
                    <FormattedMessage
                        id="myaccount.myaccountname"
                        defaultMessage="myaccount"
                    />

                </li>
                <li><img src={IMAGES.ARROW_SVG} /></li>
                <li>
                    <FormattedMessage
                        id="myaccount.contactus"
                        defaultMessage="Contact us"
                    />
                </li>
            </ul>

            <section className="container-fluid py-4 conPart">
                <div className="row">
                    <Sidebar />
                    <div className="col-md-9 pt-4 conform" id="conform">
                        <div className="titles mb-4 nbrd">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="m-0">
                                        <FormattedMessage
                                            id="myaccount.contactus"
                                            defaultMessage="Contact us"
                                        />
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-11 col-lg-6">
                                    <div className="form-group">
                                        <label htmlFor=""><FormattedMessage id="ChooseATopic"/> <span className="strike_red">*</span></label>
                                        <select name="purpose_id" onChange={handleContact} value={contactInfo.purpose_id} className={classnames("form-control", { 'is-valid': contactError.purposeError === false }, { 'is-invalid': contactError.purposeError })} >
                                            <option value="">{useIntl().formatMessage({id: 'AllCategories'})}</option>
                                            <option value={1}>{useIntl().formatMessage({id: 'Memberships'})}</option>
                                            <option value={2}>{useIntl().formatMessage({id: 'Help&Support'})}</option>
                                        </select>
                                        <p className="invalid-feedback">{contactError.purposeError}</p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor=""><FormattedMessage id="Message"/> <span className="strike_red">*</span></label>
                                        <textarea name="message" rows="6" placeholder={useIntl().formatMessage({id: 'Write a message'})} className={classnames("form-control", { 'is-valid': contactError.messageError === false }, { 'is-invalid': contactError.messageError })} value={contactInfo.message} onChange={handleContact} ></textarea>
                                        <p className="invalid-feedback">{contactError.messageError}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row actions my-5">
                                <div className="col-md-11 col-lg-6">
                                    <button type="button" onClick={handleCancel} className="btns outline b-op"><FormattedMessage id="addsingleproduct.cancel"  /></button>
                                    <button type="submit" className="btns mr-3 sndBtn"><FormattedMessage id="Send"/></button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <MessageSent />
                </div>
            </section>
        </Fragment>
    )
}

export default ContactUs
