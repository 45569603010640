import React from 'react';
import Jeweler from './Jeweler';
import Dealer from './Dealer';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const Pricing = () => {


    const { Home } = useSelector(state => state.home)

    return (
        <section id="pricings" className="container pb-5 pricings">
            <h2 className="mt-4 mb-3 text-center black fb font-40 ">{Home && Home?.homePage && Home?.homePage?.pricing_sect_title}</h2>
            <div className="row justify-content-center">
            <ul className="nav nav-tabs text-center col-md-5" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <a className="nav-link active" id="home-tab" data-toggle="tab" href="#jeweler" role="tab" aria-controls="jeweler" aria-selected="true"><FormattedMessage id="Jeweler" defaultMessage="Jeweler"/></a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link" id="profile-tab" data-toggle="tab" href="#dealer" role="tab" aria-controls="dealer" aria-selected="false"><FormattedMessage id="SupplierCap" defaultMessage="Supplier"/></a>
                </li>
            </ul>
            </div>
            <div className="tab-content pt-5" id="myTabContent">
                <Jeweler />
                <Dealer />
            </div>
        </section>
    )
}

export default Pricing
