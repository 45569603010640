import socketIOClient from 'socket.io-client';
import { BACKEND_BASE_URL, TOKEN } from '../config';
import { encryptData, encryptUserId } from '../helper';

const isValidJSON = str => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

//var user_id = 1;
if (sessionStorage.length >= 0) {
  let check = isValidJSON(sessionStorage.getItem('user'));
  if (check == true) {
    var user_id = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user')) && JSON.parse(sessionStorage.getItem('user')).id && JSON.parse(sessionStorage.getItem('user')).id
  }

}
export let socket;
if (user_id) {
  socket = socketIOClient(BACKEND_BASE_URL, {
    //  transports: ['websocket', 'polling', 'flashsocket'], wsEngine: "ws", forceNew: true,
    //  reconnection: true,
    //  reconnectionDelay: 1000,
    //  reconnectionDelayMax: 3000,
    //  allowUpgrades: false,
    //  pingTimeout: 30000, 
    //  query: `user_id=${user_id}`
    forceNew: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 3000,
    transports: ['websocket'],
    allowUpgrades: false,
    pingTimeout: 30000,
    query: { 'sc': '1', user_id: encryptData(user_id) },
    // query: { 'sc': '0', user_id: user_id },
    auth: { 'token': TOKEN }
  });
}