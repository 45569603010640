import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAttributes } from '../../../actions/productActions';
import $ from 'jquery';
import { getLastURL } from '../../../helper';
import { useLocation } from 'react-router';
import { getAllProductsFilter } from '../../../actions/productActions';
import { thicknessJson, widenessJson, goldKaratJson } from '../../../utils/json';
import { FormattedMessage } from 'react-intl';

const Sidebar = ({tabChangeHandler}) => {

    const dispatch = useDispatch();
    const location = useLocation();

    const pathname = getLastURL(location);

    const { attributes } = useSelector(state => state.attributes);

    useEffect(() => {
        dispatch(getAttributes('wedding-bands'))
    }, [dispatch])

    const [price, setPrice] = useState({
        from: "",
        to: ""
    })
    
    const [genderCheck, setGenderCheck] = useState(new Array(attributes && attributes.gender && attributes.gender.length).fill(false));
    const [gender, setGender] = useState([]);
    const [metaltypeCheck, setMetalTypeCheck] = useState(new Array(attributes && attributes.metal_type && attributes.metal_type.length).fill(false));
    const [metal_type, setMetalType] = useState([]);
    const [metalcolorCheck, setMetalColorCheck] = useState(new Array(attributes && attributes.metal_color && attributes.metal_color.length).fill(false));
    const [metal_color, setMetalColor] = useState([]);
    const [goldkaratCheck, setGoldKaratCheck] = useState(new Array(goldKaratJson && goldKaratJson.length).fill(false));
    const [gold_karat, setGoldKarat] = useState([])
    const [widenessCheck, setWidenessCheck] = useState(new Array(widenessJson && widenessJson.length).fill(false));
    const [wideness, setWideness] = useState([])
    const [thicknessCheck, setThicknessCheck] = useState(new Array(thicknessJson && thicknessJson.length).fill(false));
    const [thickness, setThickness] = useState([])
    const [stonetypeCheck, setStoneTypeCheck] = useState(new Array(attributes && attributes.stone_type && attributes.stone_type.length).fill(false));
    const [stone_type, setStoneType] = useState([]);
    const [stonecolorCheck, setStoneColorCheck] = useState(new Array(attributes && attributes.stone_color && attributes.stone_color.length).fill(false));
    const [stone_color, setStoneColor] = useState([]);
    const [stoneshapeCheck, setStoneShapeCheck] = useState(new Array(attributes && attributes.stone_shape && attributes.stone_shape.length).fill(false));
    const [stone_shape, setStoneShape] = useState([]);
    const [kasaCheck, setKasaCheck] = useState(new Array(attributes && attributes.band_surface && attributes.band_surface.length).fill(false));
    const [kasa, setKasa] = useState([]);
    const [zeminCheck, setZeminCheck] = useState(new Array(attributes && attributes.wBand_Finish && attributes.wBand_Finish.length).fill(false));
    const [zemin, setZemin] = useState([]);
    const [supplierCheck, setSupplierCheck] = useState(new Array(attributes && attributes.top_sellers && attributes.top_sellers.length).fill(false));
    const [supplier, setSupplier] = useState([]);

    const loadMoreDefault = (p_class) => {
        let see_more = localStorage.getItem('lang') == 'tr'?'Daha fazla gör':'See More';
        let see_less = localStorage.getItem('lang') == 'tr'?'Daha Az Gör':'See Less';
        if ($('.' + p_class).hasClass('mainclass_hide')) {
            $('.' + p_class).removeClass('mainclass_hide')
            $('.' + p_class + ' .c_hide').hide()
            $('.' + p_class + ' .see_more').html(' <span className="icon-add_1"></span> &nbsp; '+see_more)
        } else {
            $('.' + p_class + ' .c_hide').show()
            $('.' + p_class).addClass('mainclass_hide')
            $('.' + p_class + ' .see_more').html(' <span className="icon-close_1"></span> &nbsp; '+see_less)
        }
    }


    const handlePrice = (event) => {
        setPrice({ ...price, [event.target.name]: event.target.value });
    }


    const handleFilterGender = (position, id) => {
        const updatedGender = genderCheck && genderCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setGenderCheck(updatedGender);

        if (!gender.includes(id)) {
            setGender([...gender, id]);
        } else {
            gender.splice(gender.indexOf(id), 1);
        }
    }


    const handleFilterMetalType = (position, id) => {
        const updatedMetalType = metaltypeCheck && metaltypeCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setMetalTypeCheck(updatedMetalType);

        if (!metal_type.includes(id)) {
            setMetalType([...metal_type, id]);
        } else {
            metal_type.splice(metal_type.indexOf(id), 1);
        }
    }

    const handleFilterMetalColor = (position, id) => {
        const updatedMetalColor = metalcolorCheck && metalcolorCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setMetalColorCheck(updatedMetalColor);

        if (!metal_color.includes(id)) {
            setMetalColor([...metal_color, id]);
        } else {
            metal_color.splice(metal_color.indexOf(id), 1);
        }
    }

    const handleGoldKarat = (position, id) => {
        const updategold = goldkaratCheck && goldkaratCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setGoldKaratCheck(updategold);

        if (!gold_karat.includes(id)) {
            setGoldKarat([...gold_karat, id]);
        } else {
            gold_karat.splice(gold_karat.indexOf(id), 1);
        }
    }


    const handleFilterStoneType = (position, id) => {
        const updatestonetype = stonetypeCheck && stonetypeCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setStoneTypeCheck(updatestonetype);

        if (!stone_type.includes(id)) {
            setStoneType([...stone_type, id]);
        } else {
            stone_type.splice(stone_type.indexOf(id), 1);
        }
    }



    const handleFilterStoneColor = (position, id) => {
        const updatedStoneColor = stonecolorCheck && stonecolorCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setStoneColorCheck(updatedStoneColor);

        if (!stone_color.includes(id)) {
            setStoneColor([...stone_color, id]);
        } else {
            stone_color.splice(stone_color.indexOf(id), 1);
        }
    }



    const handleFilterStoneShape = (position, id) => {
        const updatedStoneShape = stoneshapeCheck && stoneshapeCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setStoneShapeCheck(updatedStoneShape);

        if (!stone_shape.includes(id)) {
            setStoneShape([...stone_shape, id]);
        } else {
            stone_shape.splice(stone_shape.indexOf(id), 1);
        }
    }

    
    const handleFilterKasa = (position, id) => {
        const updatedKasa = kasaCheck && kasaCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setKasaCheck(updatedKasa);

        if (!kasa.includes(id)) {
            setKasa([...kasa, id]);
        } else {
            kasa.splice(kasa.indexOf(id), 1);
        }
    }


    const handleFilterZemin = (position, id) => {
        const updatedZemin = zeminCheck && zeminCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setZeminCheck(updatedZemin);

        if (!zemin.includes(id)) {
            setZemin([...zemin, id]);
        } else {
            zemin.splice(zemin.indexOf(id), 1);
        }
    }


    const handleFilterWideness = (position, id) => {
        const updatedWideness = widenessCheck && widenessCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setWidenessCheck(updatedWideness);

        if (!wideness.includes(id)) {
            setWideness([...wideness, id]);
        } else {
            wideness.splice(wideness.indexOf(id), 1);
        }
    }


    const handleFilterThickness = (position, id) => {
        const updatedThickness = thicknessCheck && thicknessCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setThicknessCheck(updatedThickness);

        if (!thickness.includes(id)) {
            setThickness([...thickness, id]);
        } else {
            thickness.splice(thickness.indexOf(id), 1);
        }
    }





    const handleFilter = (e) => {
        e.preventDefault();
        tabChangeHandler()
        dispatch(getAllProductsFilter(pathname, { price: price, band_surface:kasa, band_finish:zemin, gender: gender, metal_type: metal_type, metal_color: metal_color, gold_karat: gold_karat, stone_type: stone_type, stone_color: stone_color, stone_shape: stone_shape, wideness: wideness, thickness: thickness, seller:supplier }));
    }


    const handleResetAll = () =>{

        setPrice({
            from: "",
            to: ""
        })  
        setGenderCheck(new Array(attributes && attributes.gender && attributes.gender.length).fill(false));
        setGender([]);
        setMetalTypeCheck(new Array(attributes && attributes.metal_type && attributes.metal_type.length).fill(false));
        setMetalType([]);
        setMetalColorCheck(new Array(attributes && attributes.metal_color && attributes.metal_color.length).fill(false));
        setMetalColor([]);
        setGoldKaratCheck(new Array(goldKaratJson && goldKaratJson.length).fill(false));
        setGoldKarat([])
        setWidenessCheck(new Array(wideness && wideness.length).fill(false));
        setWideness([]);
        setThicknessCheck(new Array(thickness && thickness.length).fill(false));
        setThickness([]);
        setStoneTypeCheck(new Array(attributes && attributes.stone_type && attributes.stone_type.length).fill(false));
        setStoneType([]);
        setStoneColorCheck(new Array(attributes && attributes.stone_color && attributes.stone_color.length).fill(false));
        setStoneColor([]);
        setStoneShapeCheck(new Array(attributes && attributes.stone_shape && attributes.stone_shape.length).fill(false));
        setStoneShape([]);
        setKasaCheck(new Array(attributes && attributes.band_surface && attributes.band_surface.length).fill(false));
        setKasa([]);
        setZeminCheck(new Array(attributes && attributes.wBand_Finish && attributes.wBand_Finish.length).fill(false));
        setZemin([]);
        setSupplierCheck(new Array(attributes && attributes.top_sellers && attributes.top_sellers.length).fill(false))
        setSupplier([])
        tabChangeHandler()
        dispatch(getAllProductsFilter(pathname,  {} ));
    }


    const handleSupplier = (position, id) =>{
        const updatedSupplier = supplierCheck && supplierCheck.map((item, index) => {
            if (index === position) {
                return !item;
            } else {
                return item;
            }
        });
        setSupplierCheck(updatedSupplier);

        if (!supplier.includes(id)) {
            setSupplier([...supplier, id]);
        } else {
            supplier.splice(supplier.indexOf(id), 1);
        }
    }




    return (
        <div className="col-lg-3">
            <div className="dashboard-sidebar ">
                <form onSubmit={handleFilter}>
                    <div className="filter_list Jewelery_list">
                        <div className="filter_side">
                            <div className="filter_top">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb page_head "><FormattedMessage id="category.wedding-bands" defaultMessage="Wedding Bands"/></h4>
                                </div>

                                {/* <div className="filter_top mb-3">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fb">Price (gr)</h4>
                                    </div>
                                    <div className="price_box d-flex">
                                        <div className="form-group mb-0">
                                            <input type="text" placeholder="Low" value={price.from} name="from" onChange={handlePrice} className="form-control" />
                                        </div>
                                        <span className="icon-minus d-block w-25"></span>
                                        <div className="form-group mb-0">
                                            <input type="text" className="form-control" value={price.to} name="to" onChange={handlePrice} placeholder="High" />
                                        </div>
                                    </div>
                                </div> */}


                                <div className="filter_top wb_kasa">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fm"><FormattedMessage id="BandSurface" defaultMessage="Band Surface"/></h4>
                                    </div>
                                    <div className="multi_price mt-3">
                                        {
                                            attributes && attributes?.band_surface && attributes.band_surface.map((data, key) => {
                                                return (
                                                    <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={data.name} checked={kasaCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterKasa(key, data.id)} />
                                                        <label htmlFor={data.id}>{data.name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                   
                                </div>


                                
                                <div className="filter_top wb_zemin">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fm"><FormattedMessage id="BandFinish" defaultMessage="Band Finish"/></h4>
                                    </div>
                                    <div className="multi_price mt-3">
                                        {
                                            attributes && attributes?.wBand_Finish && attributes.wBand_Finish.map((data, key) => {
                                                return (
                                                    <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={data.name} checked={zeminCheck[key]} id={data.id} className="checkbox" onChange={() => handleFilterZemin(key, data.id)} />
                                                        <label htmlFor={data.id}>{data.name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <p id="loadMore" onClick={(e) => loadMoreDefault('wb_zemin')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                                </div>


                                <div className="filter_top wb_gender">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fm"><FormattedMessage id="Gender" defaultMessage="Gender"/></h4>
                                    </div>
                                    <div className="multi_price mt-3">
                                        {
                                            attributes && attributes?.gender && attributes.gender.map((gender, key) => {
                                                return (
                                                    <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={gender.name} checked={genderCheck[key]} id={gender.id} className="checkbox" onChange={() => handleFilterGender(key, gender.id)} />
                                                        <label htmlFor={gender.id}>{gender.name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="filter_top wb_metaltype">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fm"><FormattedMessage id="MetalType" defaultMessage="Metal Type"/></h4>
                                    </div>
                                    <div className="multi_price mt-3">
                                        {
                                            attributes && attributes?.metal_type && attributes.metal_type.map((metal_type, key) => {
                                                return (
                                                    <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={metal_type.name} checked={metaltypeCheck[key]} id={metal_type.id} className="checkbox" onChange={() => handleFilterMetalType(key, metal_type.id)} />
                                                        <label htmlFor={metal_type.id}>{metal_type.name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <p id="loadMore" onClick={(e) => loadMoreDefault('wb_metaltype')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                                </div>

                                {/* <div className="filter_top wb_metalcolor">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fm">Metal Color</h4>
                                    </div>
                                    <div className="multi_price mt-3">
                                        {
                                            attributes && attributes.metal_color && attributes.metal_color.map((metal_color, key) => {
                                                return (
                                                    <div className={key < 9 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={metal_color.name} checked={metalcolorCheck[key]} id={metal_color.id} className="checkbox" onChange={() => handleFilterMetalColor(key, metal_color.id)} />
                                                        <label htmlFor={metal_color.id}>{metal_color.name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <p id="loadMore" onClick={(e) => loadMoreDefault('wb_metalcolor')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                                </div> */}

                                <div className="filter_top mb-3">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fb"><FormattedMessage id="GoldKarat" defaultMessage="Gold Karat"/></h4>
                                    </div>
                                    <div className="multi_price mt-3">
                                        {
                                            goldKaratJson && goldKaratJson.map((gold, key) => {
                                                return (
                                                    <div className={key < 50 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={gold.value} checked={goldkaratCheck[key]} id={gold.id} className="checkbox" onChange={() => handleGoldKarat(key, gold.value)} />
                                                        <label htmlFor={gold.id}>{gold.display_name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="filter_top wb_stonetype">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fm"><FormattedMessage id="StoneType" defaultMessage="Stone Type"/></h4>
                                    </div>
                                    <div className="multi_price mt-3">
                                        {
                                            attributes && attributes?.stone_type && attributes.stone_type.map((stone_type, key) => {
                                                return (
                                                    <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={stone_type.name} checked={stonetypeCheck[key]} id={stone_type.id} className="checkbox" onChange={() => handleFilterStoneType(key, stone_type.id)} />
                                                        <label htmlFor={stone_type.id}>{stone_type.name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <p id="loadMore" onClick={(e) => loadMoreDefault('wb_stonetype')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                                </div>

                                <div className="filter_top wb_stonecolor">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fm"><FormattedMessage id="StoneColor" defaultMessage="Stone Color"/></h4>
                                    </div>
                                    <div className="multi_price mt-3">
                                        {
                                            attributes && attributes?.stone_color && attributes.stone_color.map((stone_color, key) => {
                                                return (
                                                    <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={stone_color.name} checked={stonecolorCheck[key]} id={stone_color.id} className="checkbox" onChange={() => handleFilterStoneColor(key, stone_color.id)} />
                                                        <label htmlFor={stone_color.id}>{stone_color.name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <p id="loadMore" onClick={(e) => loadMoreDefault('wb_stonecolor')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                                </div>

                                <div className="filter_top wb_stoneshape">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fm"><FormattedMessage id="StoneShape" defaultMessage="Stone Shape"/></h4>
                                    </div>
                                    <div className="multi_price mt-3">
                                        {
                                            attributes && attributes?.stone_shape && attributes.stone_shape.map((stone_shape, key) => {
                                                return (
                                                    <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={stone_shape.name} checked={stoneshapeCheck[key]} id={stone_shape.id} className="checkbox" onChange={() => handleFilterStoneShape(key, stone_shape.id)} />
                                                        <label htmlFor={stone_shape.id}>{stone_shape.name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <p id="loadMore" onClick={(e) => loadMoreDefault('wb_stoneshape')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                                </div>



                                <div className="filter_top mb-3 wb_wideness">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fb"><FormattedMessage id="WidenessMM" defaultMessage="Wideness(mm)"/></h4>
                                    </div>
                                    <div className="multi_price mt-3">
                                        {
                                            widenessJson && widenessJson.map((wideness, key) => {
                                                return (
                                                    <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={wideness.value} checked={widenessCheck[key]} id={wideness.id} className="checkbox" onChange={() => handleFilterWideness(key, wideness.value)} />
                                                        <label htmlFor={wideness.id}>{wideness.display_name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <p id="loadMore" onClick={(e) => loadMoreDefault('wb_wideness')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                                </div>



                                <div className="filter_top mb-3 wb_thickness">
                                    <div className="filter_head">
                                        <h4 className="font-16 black-3B fb"><FormattedMessage id="ThicknessMicron" defaultMessage="Thickness(micron)"/></h4>
                                    </div>
                                    <div className="multi_price mt-3">
                                        {
                                            thicknessJson && thicknessJson.map((thickness, key) => {
                                                return (
                                                    <div className={key < 5 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                        <input type="checkbox" value={thickness.value} checked={thicknessCheck[key]} id={thickness.id} className="checkbox" onChange={() => handleFilterThickness(key, thickness.value)} />
                                                        <label htmlFor={thickness.id}>{thickness.display_name}</label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <p id="loadMore" onClick={(e) => loadMoreDefault('wb_thickness')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                                </div>


                                <div className="filter_top mt-3  d_seller">
                                <div className="filter_head">
                                    <h4 className="font-16 black-3B fb"><FormattedMessage id="Supplier" defaultMessage="Supplier"/></h4>
                                </div>
                                <div className="multi_price mt-3">
                                    {
                                        attributes && attributes?.top_sellers && attributes.top_sellers.map((data, key) => {
                                            return (
                                                <div className={key < 20 ? 'form-group mb-2 c_show' : 'form-group mb-2 c_hide'}>
                                                    <input type="checkbox" value={data.name} checked={supplierCheck[key]} id={data.id} className="checkbox" onChange={() => handleSupplier(key, data.id)} />
                                                    <label htmlFor={data.id}>{data.name} ({data.product_count})</label>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <p id="loadMore" onClick={(e) => loadMoreDefault('d_seller')} className="see_more font-14 gray-65"><span className="icon-add_1"></span> &nbsp; <FormattedMessage id="SeeMore" defaultMessage="See More"/></p>
                            </div>



                            </div>
                        </div>
                        <div className="filter_top">
                            <div className="filter_btn">
                                <button className="btn btn-black" type="submit"><FormattedMessage id="ApplyFilters" defaultMessage="Apply filters"/></button>
                                <p className="font-14 gray-65 text-center mt-3" style={{cursor:'pointer'}} onClick={handleResetAll}><FormattedMessage id="Reset_all" defaultMessage="Reset all"/> <span className="icon-close_1"></span></p>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default Sidebar
